import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ConfigView } from "@venue/api"
import { Observable } from "rxjs"
import { ApiUrl } from "./api-url.service"

@Injectable()
export class ConfigViewService {
    constructor(private http: HttpClient, private apiUrl: ApiUrl) {}

    private url(subPath = ""): string {
        return this.apiUrl.getMotAddress("/configview")
    }

    get(): Observable<ConfigView> {
        return this.http.get<ConfigView>(this.url())
    }

    save(configView: ConfigView): Observable<any> {
        return this.http.put(this.url(), configView)
    }
}
