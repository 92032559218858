import { HttpResponse } from "@angular/common/http"
import { Injectable, Injector, OnDestroy } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { TranslateService } from "@ngx-translate/core"
import { ProgressDialog, ProgressDialogData } from "@venue/shared"
import { WithUnsubscribe } from "@venue/shared/utils/with-unsubscribe.mixin"
import { OAuthResourceServerErrorHandler, OAuthService } from "angular-oauth2-oidc"
import { empty, Observable, of, throwError, timer, zip } from "rxjs"
import { map, takeUntil } from "rxjs/operators"

@Injectable()
export class OAuthErrorInterceptor
  extends WithUnsubscribe
  implements OAuthResourceServerErrorHandler, OnDestroy
{
  private readonly REDIRECT_DELAY = 1000 // ms

  constructor(
    private oauthService: OAuthService,
    private injector: Injector, // To get TranslateService lazily, without circural dependency error
    private dialog: MatDialog
  ) {
    super()
  }

  handleError(err: HttpResponse<any>): Observable<any> {
    switch (err.status) {
      // 401 Unauthorized = Not authenticated, redirect to login
      case 401: {
        const dialogRef = this.translate()
          .get("auth.login-redirect")
          .pipe(
            map(
              (progressTextTranslationKey): ProgressDialogData => ({ progressTextTranslationKey })
            ),
            map((data) => this.dialog.open(ProgressDialog, { data }))
          )

        // Init login after delay, when dialog is shown
        zip(timer(this.REDIRECT_DELAY), dialogRef)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => this.oauthService.initLoginFlow())

        return empty()
      }
    }

    return throwError(err)
  }

  private translate(): TranslateService {
    return this.injector.get(TranslateService)
  }
}
function just(err: HttpResponse<any>): Observable<any> {
    throw new Error("Function not implemented.")
}

