import { Subject } from "rxjs"

// Note: Do not use with @Injectable - it broke
export function withUnsubscribe<T extends { new (...args: any[]): any }>(target: T): T {
  // Components
  const origOnDestroy = target.prototype.ngOnDestroy

  // Data sources
  const origDisconnect = target.prototype.disconnect

  Object.defineProperty(target.prototype, "unsubscribe", {
    get: function() {
      if (!this._unsubscribe) {
        this._unsubscribe = new Subject<void>()
      }
      return this._unsubscribe
    }
  })

  target.prototype.ngOnDestroy = function() {
    this.unsubscribe.next()
    this.unsubscribe.complete()

    if (origOnDestroy) {
      origOnDestroy.bind(this)()
    }
  }

  target.prototype.disconnect = function() {
    this.unsubscribe.next()
    this.unsubscribe.complete()

    if (origDisconnect) {
      origDisconnect.bind(this)()
    }
  }

  return target
}
