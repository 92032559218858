import { Component, Input } from "@angular/core"
import mdiAlertCircle from "@iconify/icons-mdi/alert-circle"
import mdiCheckCircle from "@iconify/icons-mdi/check-circle"
import mdiCloseCircle from "@iconify/icons-mdi/close-circle"
import { ValidationError, ValidationResult } from "@openapi/venue"
import { from } from "rxjs"
import { filter, take } from "rxjs/operators"

const CATEGORIES = ["floor", "wgs_cs", "ap", "walls"] as const

type Category = typeof CATEGORIES[number]

@Component({
  selector: "vv-package-validation-popover",
  templateUrl: "./package-validation-popover.component.html",
})
export class PackageValidationPopover {
  validIcon = mdiCheckCircle
  warningIcon = mdiAlertCircle
  errorIcon = mdiCloseCircle

  @Input() validation: Partial<ValidationResult>

  readonly categories = CATEGORIES

  errorsInCategory(category: Category): ValidationError[] {
    return this.validation.validation[category] ?? []
  }

  isError(category: Category): boolean {
    let error = false

    from(this.validation.validation[category].values())
      .pipe(
        filter((e) => e.severity == "ERROR"),
        take(1)
      )
      .subscribe((v) => (error = true))

    return error
  }
}
