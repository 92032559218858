import { Feature } from "ol"
import { Fill, Stroke, Style } from "ol/style"

const USER_DOORS_DEFAULT_STROKE = new Stroke({
  color: "rgb(100,200, 0)",
  width: 3,
})
const doorDefaultFill = new Fill({
  color: "rgba(100,200, 0, 0)",
})
const doorSelectedStroke = new Stroke({
  color: "rgb(0,100,200)",
  width: 3,
})
const doorSelectedFill = new Fill({
  color: "rgba(0,160,255,0.2)",
})
const doorHoveredStroke = new Stroke({
  color: "rgb(0,0,255)",
  width: 3,
})
const doorHoveredFill = new Fill({
  color: "rgba(0,0,255,0.3)",
})

const doorDefaultStyle = new Style({
  stroke: USER_DOORS_DEFAULT_STROKE,
  fill: doorDefaultFill,
})
const doorSelectedStyle = new Style({
  stroke: doorSelectedStroke,
  fill: doorSelectedFill,
})
const doorHoveredStyle = new Style({
  stroke: doorHoveredStroke,
  fill: doorHoveredFill,
})

export function defaultStyle(feature: Feature<any>): Style {
  if (feature.get("hovered")) {
    return doorHoveredStyle
  } else if (feature.get("selected")) {
    return doorSelectedStyle
  } else {
    return doorDefaultStyle
  }
}

export {
  USER_DOORS_DEFAULT_STROKE,
  doorDefaultFill,
  doorSelectedStroke,
  doorSelectedFill,
  doorHoveredStroke,
  doorHoveredFill,
}
