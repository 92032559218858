<mat-toolbar color="primary">
    <h3 class="title">
        <ic-icon [icon]="dialogIcon"> </ic-icon>
        <span>{{ (data.create ? 'ap-form-dialog.create-title' : 'ap-form-dialog.edit-title') | translate }}</span>
    </h3>
</mat-toolbar>
<div class="content-wrapper"
     fxLayout="column">
    <mat-dialog-content fxLayout="column"
                        fxFlex>
        <form [formGroup]="form"
              fxLayout="column">

            <mac-input formControlName="mac"
                       [required]="true">
            </mac-input>

            <mat-form-field>
                <mat-label translate>ap-form-dialog.power</mat-label>
                <input matInput
                       type="number"
                       required
                       formControlName="power">
            </mat-form-field>

            <div>
                <mat-label class="left-slide-label">
                    {{ 'ap-form-dialog.enabled' | translate }}
                </mat-label>
                <mat-slide-toggle formControlName="enabled"
                                  color="primary">
                </mat-slide-toggle>
            </div>

            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="1em">
                <span>Bssids</span>

                <button mat-icon-button
                        (click)="addBssid()"
                        color="primary"
                        matTooltip="{{ 'ap-form-dialog.add-bssid' | translate }}">
                    <ic-icon [icon]="addBssidIcon"></ic-icon>
                </button>
            </div>

            <div formArrayName="bssids"
                 fxLayout="column">
                <div [formGroupName]="i"
                     *ngFor="let item of bssidsForm.controls; let i = index"
                     fxLayout="row"
                     fxLayoutAlign="start center">
                    <mac-input formControlName="bssid"
                               label=""
                               [required]="true">
                    </mac-input>
                    <button mat-icon-button
                            (click)="removeBssid(i)"
                            matTooltip="{{ 'ap-form-dialog.remove-bssid' | translate }}">
                        <ic-icon [icon]="removeBssidIcon"></ic-icon>
                    </button>
                </div>

            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row"
                        fxLayoutAlign="end">
        <button mat-raised-button
                type="submit"
                color="primary"
                [disabled]="!form.valid"
                (click)="onOk()">
            {{ 'Save' | translate }}
        </button>
        <button mat-button
                (click)="onCancel()">
            {{ 'Cancel' | translate }}
        </button>
    </mat-dialog-actions>
</div>
