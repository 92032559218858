import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core"

@Directive({
    selector: "[letVar]"
})
export class LetVarDirective<T> {
    private context: LetVarContext<T> = { letVar: null }

    constructor(viewContainer: ViewContainerRef, templateRef: TemplateRef<LetVarContext<T>>) {
        viewContainer.createEmbeddedView(templateRef, this.context)
    }

    @Input() set letVar(value: T) {
        this.context.letVar = value
    }
}

interface LetVarContext<T> {
    letVar: T
}
