<div fxLayout="column"
     class="fill-height">
    <header-bar [mainSidenav]="mainSidenav"></header-bar>

    <mat-sidenav-container *letVar="mainSidenavService.isForcedOpen | async as forcedOpen"
                           fxFlex>
        <mat-sidenav #sidenav
                     mode="side"
                     class="main-sidenav-sidecontent"
                     opened
                     [disableClose]="forcedOpen"
                     [mode]="forcedOpen ? 'side' : 'over'"
                     fxLayout="column">
            <main-sidenav flex></main-sidenav>

        </mat-sidenav>
        <mat-sidenav-content class="main-sidenav-maincontent">
            <ui-view class="fill-height"></ui-view>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
