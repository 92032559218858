import { VENUE_ROOT_STATE_NAME } from "@venue/venues/venues-state-names"
import { FloorFormComponent } from "../components/floor-form/floor-form.component"
import { CREATE_FLOOR_STATE_NAME } from "../floor.state-names"

export const CREATE_FLOOR_STATE = {
  parent: VENUE_ROOT_STATE_NAME,
  name: CREATE_FLOOR_STATE_NAME,
  url: "/floorsmgmt/create",
  component: FloorFormComponent
}
