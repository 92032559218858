<mat-toolbar color="primary">
    <h3 class="title">
        <ic-icon [icon]="icon"> </ic-icon>
        <span>{{ data.title }}</span>
    </h3>
</mat-toolbar>
<div class="content-wrapper save-config-dialog-wrapper"
     fxLayout="column">
    <mat-dialog-content fxLayout="column"
                        fxFlex>
        {{ data.content }}
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row"
                        fxLayoutAlign="end">
        <button mat-raised-button
                type="submit"
                color="primary"
                (click)="onOk()">
            {{ 'yes' | translate }}
        </button>
        <button mat-button
                (click)="onCancel()">
            {{ 'no' | translate }}
        </button>
    </mat-dialog-actions>
</div>
