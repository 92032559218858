import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable, Injector } from "@angular/core"
import { ConfigService } from "@venue/config"
import { OAuthStorage } from "angular-oauth2-oidc"
import { Observable, of } from "rxjs"
import { map, switchMap } from "rxjs/operators"

@Injectable()
export class OAuthInjectTokenInterceptor implements HttpInterceptor {
  // Lazy get, break circural dependency
  private get venueUrl(): Observable<string> {
    return this.injector.get(ConfigService).venueUrl
  }

  constructor(
    private authStorage: OAuthStorage,
    private injector: Injector // To get ConfigService lazily, without circural dependency error
  ) {}

  private checkUrl(url: string): Observable<boolean> {
    // If this is the ui-config request, then skip. Otherwise, we would have a deadlock ;)
    if (url.includes("ui-config.json")) {
      return of(false)
    }

    return this.venueUrl.pipe(map((venueUrl) => url.startsWith(venueUrl + "/api")))
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = req.url.toLowerCase()

    return this.checkUrl(url).pipe(
      switchMap((shouldAddToken) => {
        if (shouldAddToken) {
          let token = this.authStorage.getItem("access_token")
          let header = "Bearer " + token

          let headers = req.headers.set("Authorization", header)

          req = req.clone({ headers })
        }

        return next.handle(req)
      })
    )
  }
}
