<div fxLayout="column">
    <h4 class="header">
        {{ 'Automatic Tuning' | translate }}
    </h4>

    <form (ngSubmit)="submit()"
          [formGroup]="autoTuningConfig"
          fxLayout="column">
        <mat-slide-toggle formControlName="status"
                          color="primary"
                          #autoTuningToggle>
            {{ 'Automatic Tuning' | translate }}
            <b>{{autoTuningToggle.checked ? "enabled" : "disabled"}}.</b>
        </mat-slide-toggle>
        <mat-form-field>
            <mat-label>
                {{ 'Tuning Repeat' | translate }}
            </mat-label>
            <mat-select formControlName="tuningRepeat"
                        required>
                <mat-option *ngFor="let repeatValue of tuningOptions"
                            [value]="repeatValue">
                    {{ repeatValue | translate }}
                </mat-option>
            </mat-select>
            <mat-hint>
                {{ 'Tuning Repeat Text' | translate }}
            </mat-hint>
        </mat-form-field>
        <time-picker formControlName="startTime" label="{{ 'Start time' | translate }}">
        </time-picker>
        <mat-form-field>
            <mat-label>{{ 'Tuning duration' | translate }}</mat-label>
            <input matInput
                   type="number"
                   formControlName="tuningDuration"
                   required
                   min="1"
                   max="23" />
            <mat-hint>
                {{ 'Hours' | translate }}
            </mat-hint>
        </mat-form-field>
        <button mat-raised-button
                color="primary"
                [disabled]="!autoTuningConfig.valid"
                type="submit">
            {{ 'Save' | translate }}
        </button>
    </form>
</div>
