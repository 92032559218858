import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import faSolidHourglassHalf from "@iconify/icons-fa-solid/hourglass-half"

@Component({
  selector: "progress-dialog",
  templateUrl: "./progress.dialog.html",
})
export class ProgressDialog {
  title?: string
  icon = faSolidHourglassHalf
  progressTextTranslationKey: string

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ProgressDialogData,
    private dialogRef: MatDialogRef<ProgressDialog>
  ) {
    dialogRef.addPanelClass("dialog-with-toolbar")
    dialogRef.disableClose = true

    this.icon = data.icon || this.icon
    this.progressTextTranslationKey = data.progressTextTranslationKey
    this.title = data.title
  }
}

export interface ProgressDialogData {
  icon?: typeof faSolidHourglassHalf
  progressTextTranslationKey: string
  title?: string
}
