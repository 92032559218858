import { NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { SharedModule } from "../shared"
import * as m from "./"

@NgModule({
    imports: [UIRouterModule.forChild({ states: m.WHITELIST_STATES }), SharedModule],
    declarations: [m.WhitelistComponent, m.AddWhitelistMacDialog, m.BuildingWhitelistComponent]
})
export class WhitelistModule {}
