import { VENUE_ROOT_STATE_NAME } from "@venue/venues/venues-state-names"
import { GeneratePackageForm } from "./"
import { PACKAGE_GENERATOR_STATE_NAME } from "./config-packages.state-name"

const generatePackageState = {
  parent: VENUE_ROOT_STATE_NAME,
  name: PACKAGE_GENERATOR_STATE_NAME,
  url: "/package-generator",
  component: GeneratePackageForm,
}

export const CONFIG_PACKAGES_STATES = [generatePackageState]
