import { VENUE_ROOT_STATE_NAME } from "@venue/venues/venues-state-names"
import { TracesListComponent } from "../components/traces-list/traces-list.component"
import { TRACES_LIST_STATE_NAME } from "../traces.state-names"

export const TRACES_LIST_STATE = {
  parent: VENUE_ROOT_STATE_NAME,
  name: TRACES_LIST_STATE_NAME,
  // TODO Temprorary url - fix state hierarchy after finishing floor editors upgrade
  url: "/floorstmp/:floorId/traces",
  component: TracesListComponent,
}
