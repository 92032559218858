<loading-overlay *ngIf="loading | async"></loading-overlay>
<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">
        <section class="sidebar"
                 fxLayout="column"
                 fxLayoutGap="0.5rem"
                 fxFlex>
            <floor-select>
            </floor-select>

            <button mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="saveDisabled"
                    matTooltip="{{ saveDisabled ? ('no_permissions_msg' | translate) : '' }}"
                    (click)="saveClicks.next()">{{ 'Save' | translate }}</button>
        </section>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-container *ngIf="noCS || noImgDim">
            <div fxFlex
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 class="error-overlay">
                <h1 translate>editor-error.header</h1>
                <span *ngIf="noCS"
                      translate>editor-error.no-cs</span>
                <span *ngIf="noImgDim"
                      translate>editor-error.no-img-dim</span>
            </div>
        </ng-container>
        <openlayers-map fxFlex
                        *letVar="toggledMapTool | async as mapTool">
            <floor-image-layer [floor]="currentFloor.floor | async"
                               (imgDim)="onImageDimChange($event)">
            </floor-image-layer>
            <vector-layer #taLayer
                          name="taLayer"
                          [style]="taStyle">
            </vector-layer>
            <draw-interaction [layer]="taLayer"
                              [drawType]="'Polygon'"
                              [snap]="true"
                              [disabled]="mapTool !== 'ADD'"
                              (featureDrawn)="onFeatureDrawn($event)">
            </draw-interaction>
            <remove-interaction [layers]="[ taLayer ]"
                                [disabled]="mapTool !== 'REMOVE'"
                                (featureRemoved)="onFeatureRemoved($event)">
            </remove-interaction>
            <move-interaction [layers]="[ taLayer ]"
                              [disabled]="mapTool !== 'MOVE'"
                              (featuresMoved)="onFeaturesMoved($event)">
            </move-interaction>
            <modify-interaction [layers]="[ taLayer ]"
                                [disabled]="mapTool !== 'MODIFY'"
                                (featuresModified)="onFeaturesModified($event)">
            </modify-interaction>
            <map-toolbar>
                <mat-button-toggle-group name="mapTool"
                                         multiple
                                         [disabled]="!hasUserWritePermissions()"
                                         matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate) : '' }}"
                                         (change)="changeMapTool($event)">
                    <mat-button-toggle title='{{ "polygon_editor.controls.draw_polygon_tooltip" | translate }}'
                                       value="ADD"
                                       [disableRipple]="true">
                        <ic-icon [icon]="addPolygonIcon"></ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "polygon_editor.controls.delete_polygon_tooltip" | translate }}'
                                       value="REMOVE"
                                       [disableRipple]="true">
                        <img height="32px"
                             width="32px"
                             src="assets/images/shape-polygon-minus.svg">
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "polygon_editor.controls.move_polygon_tooltip" | translate }}'
                                       value="MOVE"
                                       [disableRipple]="true">
                        <ic-icon [icon]="movePolygonIcon"> </ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "polygon_editor.controls.modify_polygon_tooltip" | translate }}'
                                       value="MODIFY"
                                       [disableRipple]="true">
                        <ic-icon [icon]="modifyPolygonIcon"> </ic-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <button title='{{ "polygon_editor.controls.undo_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate) : '' }}"
                        (click)="undo()"
                        [disabled]="!canUndo">
                    <ic-icon width="32px"
                             height="32px"
                             [icon]="undoIcon"> </ic-icon>
                </button>
                <button title='{{ "polygon_editor.controls.redo_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate) : '' }}"
                        (click)="redo()"
                        [disabled]="!canRedo">
                    <ic-icon width="32px"
                             height="32px"
                             [icon]="redoIcon"> </ic-icon>
                </button>
                <button title='{{ "polygon_editor.controls.delete_all_polygons_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate) : '' }}"
                        [disabled]="!hasUserWritePermissions()"
                        (click)="removeAll()">
                    <ic-icon width="32px"
                             height="32px"
                             [icon]="removeAllIcon"> </ic-icon>
                </button>
                <button title='{{ "polygon_editor.controls.reset_polygon_layer_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate) : '' }}"
                        [disabled]="!hasUserWritePermissions()"
                        (click)="restoreSaved()">
                    <ic-icon width="32px"
                             height="32px"
                             [icon]="restoreSavedIcon"> </ic-icon>
                </button>
            </map-toolbar>
        </openlayers-map>
    </mat-sidenav-content>
</mat-sidenav-container>
