<div fxLayout="row"
     fxLayoutAlign="start center"
     [fxLayoutGap]="spacing">
    <ic-icon [icon]="icon"> </ic-icon>
    <mat-form-field>
        <mat-label>
            {{ dateLabel }}
        </mat-label>
        <input matInput
               class="date-input"
               [formControl]="date"
               [matDatepicker]="datePicker"
               (click)="datePicker.open()"
               (blur)="touched.next()"
               autocomplete="off"
               [min]="min"
               [max]="max"
               readonly />
        <mat-datepicker #datePicker>
        </mat-datepicker>
    </mat-form-field>
    <time-picker [formControl]="time"
                 [label]="timeLabel">
    </time-picker>
</div>
