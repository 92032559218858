import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core"
import { FormArray, FormBuilder, Validators } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import mdiClose from "@iconify/icons-mdi/close"
import mdiHelpCircle from "@iconify/icons-mdi/help-circle"
import mdiPlus from "@iconify/icons-mdi/plus"
import { AccessPoint } from "@openapi/venue"

export interface AccessPointFormDialogData {
  // create dialog if true, edit dialog otherwise
  create: boolean
  ap?: AccessPoint
}

@Component({
  selector: "ap-form-dialog",
  templateUrl: "./ap-form.dialog.html",
})
export class AccessPointFormDialog implements OnInit {
  readonly dialogIcon = mdiHelpCircle
  readonly addBssidIcon = mdiPlus
  readonly removeBssidIcon = mdiClose

  form = this.fb.group({
    mac: ["", [Validators.required, Validators.minLength(12), Validators.maxLength(12)]], // TODO What about EUI-64 ?
    power: [0, [Validators.required, Validators.min(-128), Validators.max(0)]],
    enabled: [false, [Validators.required]],
    bssids: this.fb.array([]),
  })

  get bssidsForm(): FormArray {
    return this.form.controls.bssids as FormArray
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AccessPointFormDialogData,
    private dialogRef: MatDialogRef<AccessPointFormDialog>,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
    dialogRef.addPanelClass("dialog-with-toolbar")

    dialogRef.backdropClick().subscribe(() => this.onNoClick())
  }

  ngOnInit(): void {
    if (this.data.ap) {
      this.form.patchValue({
        mac: this.data.ap.mac,
        power: this.data.ap.power,
        enabled: this.data.ap.enabled,
      })

      this.data.ap.bssids.forEach((bssid) => this.addBssid(bssid.bssid))
    }

    // Fixes error caused by automatically focusing first form field of the dialog
    this.cdRef.detectChanges()
  }

  addBssid(bssid = ""): void {
    this.bssidsForm.push(
      this.fb.group({
        bssid: [bssid, [Validators.required]],
        frequency: null, // We are not using it it seems
      })
    )
  }

  removeBssid(index: number): void {
    this.bssidsForm.removeAt(index)
  }

  onOk(): void {
    this.dialogRef.close(this.form.value)
  }

  onNoClick(): void {
    this.dialogRef.close(this.data.ap)
  }

  onCancel(): void {
    this.dialogRef.close(this.data.ap)
  }
}
