import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core"
import { MatSidenav } from "@angular/material/sidenav"
import { MainSidenavService, withUnsubscribe } from "@venue/shared"
import { Observable } from "rxjs"
import { distinctUntilChanged, takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "app",
  templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit {
  @ViewChild("sidenav") mainSidenav: MatSidenav

  private unsubscribe: Observable<any>

  constructor(public mainSidenavService: MainSidenavService, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.mainSidenavService.isForcedOpen
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
      .subscribe((forcedOpen) => {
        if (forcedOpen) {
          this.mainSidenav.open()
        } else {
          this.mainSidenav.close()
        }
      })

    this.cdr.detectChanges()
  }
}
