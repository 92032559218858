import { PageInfo } from '.'

export class PageInfoImpl implements PageInfo {

    size = 0
    totalElements = 0
    totalPages = 0
    number = 0

    constructor(pageInfo: Partial<PageInfo>) {
        if (pageInfo.size) this.size = pageInfo.size
        if (pageInfo.totalElements) this.totalElements = pageInfo.totalElements
        if (pageInfo.totalPages) this.totalPages = pageInfo.totalPages
        if (pageInfo.number) this.number = pageInfo.number
    }

}
