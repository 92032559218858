<div class="fill-parent"
     fxLayout="column"
     fxFlex>
    <mat-toolbar>
        <div class="toolbar-content"
             fxLayout="row"
             fxFlex>
            <span fxFlex></span>
            <mat-autocomplete #userFilterAutocomplete="matAutocomplete">
                <mat-option *ngFor="let name of userAutocompleteOpts | async"
                            [value]="name">
                    {{ name }}
                </mat-option>
            </mat-autocomplete>
            <mat-form-field>
                <mat-spinner class="autocomplete-spinner"
                             diameter="18"
                             *ngIf="userAutocompleteFetchInProgress | async">
                </mat-spinner>
                <mat-label>{{ 'traces-list.user-filter' | translate }}</mat-label>
                <input id="user-filter-input"
                       matInput
                       type="text"
                       [formControl]="userFilter"
                       [matAutocomplete]="userFilterAutocomplete">
            </mat-form-field>

            <input #traceInput
                   type="file"
                   hidden="true"
                   [formControl]="selectTraceForm">
            <button mat-icon-button
                    color="primary"
                    (click)="traceInput.click()"
                    matTooltip="{{ 'traces-list.select-trace-tooltip' | translate }}">
                <ic-icon [icon]="selectTraceIcon"></ic-icon>
            </button>
        </div>
    </mat-toolbar>
    <div class="table-wrapper"
         fxFlex
         fxLayout="column">
        <loading-overlay *ngIf="loading | async"></loading-overlay>
        <table mat-table
               matSort
               [dataSource]="dataSource">

            <ng-container matColumnDef="traceId">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>{{ 'traces-list.traceId' | translate }}</th>
                <td mat-cell
                    *matCellDef="let entry">{{ entry.traceId }}</td>
            </ng-container>

            <ng-container matColumnDef="user">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>{{ 'traces-list.user' | translate }}</th>
                <td mat-cell
                    *matCellDef="let entry">{{ entry.user }}</td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>{{ 'traces-list.created-at' | translate }}</th>
                <td mat-cell
                    *matCellDef="let entry">{{ entry.createdAt | datetime }}</td>
            </ng-container>

            <ng-container matColumnDef="download">
                <th mat-header-cell
                    *matHeaderCellDef>{{ 'traces-list.download' | translate }}</th>
                <td mat-cell
                    *matCellDef="let entry">
                    <button mat-icon-button
                            color="primary"
                            (click)="downloadTrace(entry)"
                            matTooltip="{{ 'traces-list.download-trace-tooltip' | translate:entry }}">
                        <ic-icon [icon]="downloadTraceIcon"></ic-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="tracePath">
                <th mat-header-cell
                    *matHeaderCellDef>{{ 'traces-list.trace-path' | translate }}</th>
                <td mat-cell
                    *matCellDef="let entry">
                    <button mat-icon-button
                            color="primary"
                            (click)="openTracePath(entry)"
                            matTooltip="{{ 'traces-list.trace-path-tooltip' | translate:entry }}">
                        <ic-icon [icon]="tracePathIcon"></ic-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                   showFirstLastButtons></mat-paginator>
</div>
