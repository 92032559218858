import { mapStyleFactory } from "@venue/maps"
import Fill from "ol/style/Fill"
import Style from "ol/style/Style"
import {
    HOVERED_WALL_FILL,
    HOVERED_WALL_STROKE,
    SELECTED_WALL_FILL,
    SELECTED_WALL_STROKE
} from "./common-wall-styles"
import tinycolor = require("tinycolor2")

export const generatedWallMainColor = "#FF6600"
export const generatedWallSecondaryColor = (function() {
    const color = tinycolor(generatedWallMainColor)
    color.setAlpha(0.3)
    return color.toRgbString()
})()

export const GENERATED_WALLS_DEFAULT_FILL = new Fill({
    color: generatedWallSecondaryColor
})

const defaultStyle = new Style({
    fill: GENERATED_WALLS_DEFAULT_FILL
})

const selectedStyle = new Style({
    stroke: SELECTED_WALL_STROKE,
    fill: SELECTED_WALL_FILL
})

const hoveredStyle = new Style({
    stroke: HOVERED_WALL_STROKE,
    fill: HOVERED_WALL_FILL
})

export const GENERATED_WALLS_STYLE = mapStyleFactory({
    defaultStyle,
    selectedStyle,
    hoveredStyle
})
