<div class="editorView" layout="column">
    <div layout="row">
        <div class="md-padding" flex-gt-xs="40">
            <h2>{{'User settings' | translate}}</h2>

            <p>{{'Choose interface language' | translate}}</p>
            <mat-form-field>
                <mat-label>{{ 'Language' | translate }}</mat-label>
                <mat-select [(value)]="language" (selectionChange)="changeLanguage()">
                    <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div flex-gt-xs="60" flex-xs="10"></div>
    </div>
</div>
