import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import mdiCheckCircle from "@iconify/icons-mdi/check-circle"
import mdiCloseCircle from "@iconify/icons-mdi/close-circle"
import mdiDelete from "@iconify/icons-mdi/delete"
import mdiFileMarkerOutline from "@iconify/icons-mdi/file-marker-outline"
import mdiPencil from "@iconify/icons-mdi/pencil"
import mdiPlus from "@iconify/icons-mdi/plus"
import { TranslateService } from "@ngx-translate/core"
import { Floor, FloorsService } from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import { CurrentVenue } from "@venue/core"
import { CREATE_FLOOR_STATE_NAME, EDIT_FLOOR_STATE_NAME } from "@venue/floor/floor.state-names"
import { ModeService } from "@venue/mode"
import { ConfirmDialog, ConfirmDialogData, withUnsubscribe } from "@venue/shared"
import { TRACES_LIST_STATE_NAME } from "@venue/traces"
import { combineLatest, Observable } from "rxjs"
import { filter, flatMap, switchMap, takeUntil } from "rxjs/operators"
import { FloorsDataSource } from "./floors.data-source"

@withUnsubscribe
@Component({
  selector: "floor-list",
  templateUrl: "./floor-list.component.html",
})
export class FloorListComponent implements AfterViewInit {
  createFloorIcon = mdiPlus
  editFloorIcon = mdiPencil
  removeFloorIcon = mdiDelete

  hasImageIcon = mdiCheckCircle
  noImageIcon = mdiCloseCircle

  tracesIcon = mdiFileMarkerOutline

  readonly displayedColumns = [
    "level",
    "name",
    "hasImage",
    "createdTime",
    "updatedAt",
    "edit",
    "remove",
    "traces",
  ]

  dataSource: FloorsDataSource

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  private unsubscribe: Observable<any>

  readonly WRITE_USER_ROLE = "venue:floors:write"

  constructor(
    private currentVenue: CurrentVenue,
    private floorsService: FloorsService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private state: StateService,
    private cdRef: ChangeDetectorRef,
    private modeService: ModeService,
    private authUtilService: AuthUtilService
  ) {
    this.isCRUDModeEnabled().subscribe((cond) => {
      if (!cond) this.displayedColumns.splice(5, 2)
    }) // Removes CRUD options
  }

  ngAfterViewInit(): void {
    this.sort.disableClear = true
    this.sort.sort({ id: "level", start: "asc", disableClear: true })
    this.dataSource = new FloorsDataSource(
      this.currentVenue,
      this.floorsService,
      this.paginator,
      this.sort
    )

    this.cdRef.detectChanges()
  }

  create(): void {
    this.state.go(CREATE_FLOOR_STATE_NAME)
  }

  edit(floor: Floor): void {
    this.state.go(EDIT_FLOOR_STATE_NAME, { floorId: floor.id })
  }

  remove(floor: Floor): void {
    let title = this.translate.get("floor-list.delete-confirm-dialog.title")
    let content = this.translate.get("floor-list.delete-confirm-dialog.content", {
      floorName: floor.name,
      floorLevel: floor.level.toString(),
    })

    combineLatest(title, content, (t, c) => ({ title: t, content: c } as ConfirmDialogData))
      .pipe(
        flatMap((data) => this.dialog.open(ConfirmDialog, { data }).afterClosed()),
        filter((shouldSave: boolean) => !!shouldSave),
        switchMap(() => this.floorsService.deleteFloor(floor.id)),
        takeUntil(this.unsubscribe)
        // TODO Block ui until request completes
      )
      .subscribe(
        (ok) => {
          // TODO Snackbar - delete successful
          this.dataSource.refresh()
        },
        (error) => {
          // TODO Use snackbar
          console.error(error)
        }
      )
  }

  openFloorTracesList(floor: Floor): void {
    this.state.go(TRACES_LIST_STATE_NAME, { floorId: floor.id })
  }

  isCRUDModeEnabled(): Observable<boolean> {
    return this.modeService.isCRUDModeEnabled()
  }

  hasUserWritePermissions(): boolean {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }
}
