import { Component, OnInit } from "@angular/core"
import { FormBuilder, Validators } from "@angular/forms"
import { Building, BuildingsService } from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { CurrentVenue } from "@venue/core"
import { withUnsubscribe } from "@venue/shared"
import { VENUES_STATE_NAME } from "@venue/venues/venues-state-names"
import { Observable, Subject } from "rxjs"
import { filter, takeUntil } from "rxjs/operators"

enum VenueFormMode {
  CREATE = "create",
  UPDATE = "update",
}

@withUnsubscribe
@Component({
  selector: "venue-form",
  templateUrl: "./venue-form.component.html",
})
export class VenueFormComponent implements OnInit {
  mode: VenueFormMode

  loading = true

  form = this.fb.group({
    name: ["", [Validators.required, Validators.maxLength(255)]],
    slug: ["", [Validators.required, Validators.pattern("[0-9a-z-]+"), Validators.maxLength(255)]],
    description: ["", [Validators.maxLength(300)]],
    country: ["", [Validators.maxLength(255)]],
  })

  private venueId: number = null

  private unsubscribe: Subject<void>

  constructor(
    private fb: FormBuilder,
    private buildings: BuildingsService,
    private state: StateService,
    private currentVenue: CurrentVenue
  ) {}

  ngOnInit() {
    let venueId = this.state.params.venueId

    if (venueId === undefined) {
      this.venueId = null
      this.mode = VenueFormMode.CREATE
      this.loading = false
    } else {
      this.venueId = Number.parseInt(venueId)
      this.mode = VenueFormMode.UPDATE
      this.currentVenue.venue
        .pipe(
          filter((v) => v != null),
          takeUntil(this.unsubscribe)
        )
        .subscribe((v) => {
          this.form.patchValue(v)
          this.loading = false
        })
    }
  }

  save(): void {
    let req = this.mode == VenueFormMode.CREATE ? this.create : this.update
    req = req.bind(this)

    req(this.form.value).subscribe(
      (building) => {
        // Redirect to venues
        this.state.go(VENUES_STATE_NAME)
      },
      (error) => {
        // TODO Snackbar
        console.error("Error creating venue")
      }
    )
  }

  private create(building: Building): Observable<Building> {
    return this.buildings.createBuilding(building)
  }

  private update(building: Building): Observable<Building> {
    building.id = this.venueId

    return this.buildings.updateBuilding(building.id, building)
  }
}
