import { cloneDeep, isEqualWith } from "lodash"
import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { Circle, Geometry, LineString, Polygon } from "ol/geom"
import { Vector as VectorSource } from "ol/source"
import { Subject } from "rxjs"
import { ObjectProperties, VectorLayerComponent } from "."

const MAX_HISTORY_SIZE = 20

export class LayerHistory {
  events = new Subject<LayerHistoryEvent>()

  private currentStateIdx = 0
  private states: LayerHistoryState[] = [new LayerHistoryState()]

  constructor(private layers: VectorLayerComponent[]) {}

  canUndo(): boolean {
    return this.currentStateIdx > 0
  }

  canRedo(): boolean {
    return this.currentStateIdx < this.states.length - 1
  }

  init(): void {
    this.states = []
    this.currentStateIdx = -1
    this.doSaveState()
    this.events.next(LayerHistoryEvent.INITIALIZED)
  }

  saveState(): void {
    if (this.doSaveState()) {
      this.events.next(LayerHistoryEvent.SAVED)
    }
  }

  private doSaveState(): boolean {
    const state = new LayerHistoryState()
    this.layers.forEach((layerComponent) => {
      // This could be other VectorSource than Circle | Polygon
      // .. FeatureState.from will check it and throw in such case
      const polygonSource = layerComponent.source as VectorSource<Circle | Polygon>
      const features = polygonSource.getFeatures()
      const featureStates = features.map((f) => FeatureState.from(f))
      state.layerStates.set(layerComponent, featureStates)
    })

    const customNumberEqual = (a: any, b: any): boolean | undefined =>
      typeof a === "number" && typeof b === "number" ? Math.abs(a - b) < 0.01 : undefined

    if (isEqualWith(state, this.states[this.currentStateIdx], customNumberEqual)) {
      return false
    }
    this.states.splice(++this.currentStateIdx, this.states.length - this.currentStateIdx, state)
    if (this.currentStateIdx === MAX_HISTORY_SIZE) {
      this.states.splice(0, 1)
      this.currentStateIdx--
    }

    return true
  }

  undo(): void {
    if (this.canUndo()) {
      this.currentStateIdx--
      this.doLoadState()
      this.events.next(LayerHistoryEvent.LOADED)
    }
  }

  redo(): void {
    if (this.canRedo()) {
      this.currentStateIdx++
      this.doLoadState()
      this.events.next(LayerHistoryEvent.LOADED)
    }
  }

  doLoadState(): void {
    const state = this.states[this.currentStateIdx]
    this.layers.forEach((layerComponent) => {
      layerComponent.clearFeatures(false)
      const polygonSource = layerComponent.source
      const featureStates = state.layerStates.get(layerComponent)
      if (featureStates && featureStates.length) {
        const features = featureStates.map((fs) => fs.toFeature())
        polygonSource.addFeatures(features)
      }
    })
  }

  clear(): void {
    this.doClear()
    this.events.next(LayerHistoryEvent.CLEARED)
  }

  private doClear(): void {
    this.states = [new LayerHistoryState()]
    this.currentStateIdx = 0
  }
}

class LayerHistoryState {
  layerStates = new Map<VectorLayerComponent, FeatureState<LineString | Circle | Polygon>[]>()
}

abstract class FeatureState<T extends Geometry> {
  protected properties: ObjectProperties

  protected constructor(feature: Feature<T>) {
    this.properties = toSaveableProperties(feature.getProperties())
  }

  abstract toFeature(): Feature<T>

  static from(
    feature: Feature<LineString | Circle | Polygon>
  ): FeatureState<LineString | Circle | Polygon> {
    const geom = feature.getGeometry()

    if (geom instanceof LineString) {
      return new LineStringFeatureState(feature as Feature<LineString>)
    } else if (geom instanceof Circle) {
      return new CircleFeatureState(feature as Feature<Circle>)
    } else if (geom instanceof Polygon) {
      return new PolygonFeatureState(feature as Feature<Polygon>)
    } else {
      throw new Error("Geometry not supported.")
    }
  }
}

class LineStringFeatureState extends FeatureState<LineString> {
  private coords: Coordinate[]

  constructor(feature: Feature<LineString>) {
    super(feature)

    this.coords = cloneDeep(feature.getGeometry().getCoordinates())
  }

  toFeature(): Feature<LineString> {
    const geometry = new LineString(this.coords)
    const opts = Object.assign(cloneDeep(this.properties), { geometry })
    return new Feature<LineString>(opts)
  }
}

class CircleFeatureState extends FeatureState<Circle> {
  private center: Coordinate
  private radius: number

  constructor(feature: Feature<Circle>) {
    super(feature)
    const geom = feature.getGeometry()
    this.center = geom.getCenter()
    this.radius = geom.getRadius()
  }

  toFeature(): Feature<Circle> {
    const geometry = new Circle(this.center, this.radius)
    const opts = Object.assign(cloneDeep(this.properties), { geometry })
    return new Feature<Circle>(opts)
  }
}

class PolygonFeatureState extends FeatureState<Polygon> {
  private coords: Coordinate[][]

  constructor(feature: Feature<Polygon>) {
    super(feature)
    const geom = feature.getGeometry()
    this.coords = cloneDeep(geom.getCoordinates())
  }
  toFeature(): Feature<Polygon> {
    const geometry = new Polygon(this.coords)
    const opts = Object.assign(cloneDeep(this.properties), { geometry })
    return new Feature<Polygon>(opts)
  }
}

export enum LayerHistoryEvent {
  INITIALIZED,
  SAVED,
  LOADED,
  CLEARED,
}

function toSaveableProperties(properties: ObjectProperties): ObjectProperties {
  const geometry = properties.geometry
  delete properties.geometry
  const props = cloneDeep(properties)
  if (geometry) {
    properties.geometry = geometry
  }
  delete props.hovered
  delete props.selected
  delete props.isDragged
  return props
}
