import { Injectable } from "@angular/core"
import { Building, Floor, FloorsService } from "@openapi/venue"
import { WithUnsubscribe } from "@venue/shared/utils/with-unsubscribe.mixin"
import { BehaviorSubject, Observable } from "rxjs"
import { filter, switchMap, takeUntil } from "rxjs/operators"

@Injectable()
export class CurrentVenue extends WithUnsubscribe {
  private venueSubject = new BehaviorSubject<Building>(null)
  private floorsSubject = new BehaviorSubject<Floor[]>(null)

  readonly venue: Observable<Building> = this.venueSubject.asObservable()
  // XXX This list of floors creates problems with floor update (issue #31)
  readonly floors: Observable<Floor[]> = this.floorsSubject.asObservable()

  constructor(floorsService: FloorsService) {
    super()

    this.venue
      .pipe(
        filter((v) => !!v),
        // TODO Should we download all floors or implement some paging / virtual scroll?
        switchMap((v: Building) => floorsService.getFloors(v.id, 0, 300)),
        takeUntil(this.unsubscribe)
      )
      .subscribe((floors) => this.floorsSubject.next(floors.content))
  }

  getFloors(): Floor[] {
    return this.floorsSubject.getValue()
  }

  getVenue(): Building {
    return this.venueSubject.getValue()
  }

  setVenue(venue: Building): void {
    this.floorsSubject.next(null)
    this.venueSubject.next(venue)
  }

  // Workaround for floors cache - refresh cache
  // TODO Change architecture to not store floors in this service
  refreshFloors() {
    this.setVenue(this.getVenue())
  }
}
