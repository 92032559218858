<div *ngIf="floors.length > 0" class="floorSelect">
    <mat-form-field fxFlex>
        <mat-label class="header">{{ 'Select floor' | translate }}</mat-label>
        <mat-select [formControl]="floor">
            <mat-option *ngFor="let floor of floors" [value]="floor">
                {{ floor.name }} {{ '__Level_lvl_num__' | translate:floor }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<!-- Show info about selecting venue first ? -->
