import { NgModule } from "@angular/core"
import * as maps from "."
import { SharedModule } from "../shared"

const COMPONENTS = [
  maps.DrawInteractionComponent,
  maps.FloorImageLayerComponent,
  maps.ImageLayerComponent,
  maps.MapControlComponent,
  maps.MapLegendComponent,
  maps.MapLegendElementComponent,
  maps.MapOverlayComponent,
  maps.MapToolbarComponent,
  maps.ModifyInteractionComponent,
  maps.ModifyRotateInteractionComponent,
  maps.MoveInteractionComponent,
  maps.MultiVertexModifyInteractionComponent,
  maps.OSMLayerComponent,
  maps.OlFullscreenComponent,
  maps.OlLayerswitcherComponent,
  maps.OpenlayersMapComponent,
  maps.RemoveInteractionComponent,
  maps.SelectFeatureInteractionComponent,
  maps.TransformInteractionComponent,
  maps.VectorLayerComponent,
]

@NgModule({
    imports: [SharedModule],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS]
})
export class MapsModule {}
