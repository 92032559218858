import { Component, Inject, OnInit } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { StateService } from "@uirouter/core"
import { BuildingWhitelistServiceWrapper, WhitelistService } from "@venue/api"
import { MacWhiteListControllerService } from "@openapi/mipsengine"
import { withUnsubscribe } from "@venue/shared"
import { empty, Observable } from "rxjs"
import { catchError, distinctUntilChanged, filter, switchMap, takeUntil, tap } from "rxjs/operators"

@withUnsubscribe
@Component({
    selector: "BuildingWhitelist",
    templateUrl: "./building-whitelist.component.html",
    providers: [
        {
            provide: WhitelistService,
            useFactory: buildingWhitelistServiceFactory,
            deps: [StateService, MacWhiteListControllerService]
        }
    ]
})
export class BuildingWhitelistComponent implements OnInit {
    overrideGlobalControl = this.fb.control(false)

    reactToOverrideGlobalControl = false

    private unsubscribe: Observable<void>

    constructor(
        @Inject(WhitelistService) private whitelistService: BuildingWhitelistServiceWrapper,
        private fb: FormBuilder
    ) {
        this.overrideGlobalControl.disable()
    }

    ngOnInit(): void {
        this.whitelistService
            .overridesGlobal()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(overridesGlobal => this.initOverrideGlobalControl(overridesGlobal))
    }

    private initOverrideGlobalControl(overrideGlobalInitValue: boolean): void {
        this.overrideGlobalControl.setValue(overrideGlobalInitValue)
        this.overrideGlobalControl.enable()
        this.reactToOverrideGlobalControl = true

        this.overrideGlobalControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                filter(() => this.reactToOverrideGlobalControl),
                tap(() => this.overrideGlobalControl.disable()),
                switchMap(override =>
                    this.whitelistService.setOverrideGlobal(override).pipe(
                        catchError(err => {
                            this.reactToOverrideGlobalControl = false
                            this.overrideGlobalControl.setValue(!this.overrideGlobalControl.value)
                            this.reactToOverrideGlobalControl = true
                            this.overrideGlobalControl.enable()
                            return empty()
                        })
                    )
                ),
                tap(() => this.overrideGlobalControl.enable()),
                takeUntil(this.unsubscribe)
            )
            .subscribe()
    }
}

function buildingWhitelistServiceFactory(state: StateService, api: MacWhiteListControllerService): BuildingWhitelistServiceWrapper {
    return new BuildingWhitelistServiceWrapper(parseInt(state.params.venueId), api)
}
