import { Pipe, PipeTransform } from "@angular/core"
import { standarizedMac } from "@venue/shared/utils/standarize-mac"

@Pipe({
    name: "mac"
})
export class MacPipe implements PipeTransform {
    transform(value: string): string {
        return standarizedMac(value)
    }
}
