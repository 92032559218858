import { Component, Host, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core"
import { ImageTransforms } from "@venue/components/floor/floormap/transformations/ImageTransforms"
import TransformInteractions from "@venue/components/floor/floormap/transformations/interactions"
import HighlightFeatureInteraction from "@venue/components/map/interactions/HighlightFeatureInteraction"
import { SelectFeatureInteraction } from "@venue/components/map/interactions/SelectFeatureInteraction"
import { Feature } from "ol"
import { Geometry } from "ol/geom"
import { Subject } from "rxjs"
import { OpenlayersMapComponent, VectorLayerComponent } from ".."

/**
 * Interaction that allows to select and then move, rotate and scale a feature.
 */
@Component({
  selector: "transform-interaction",
  template: "",
})
export class TransformInteractionComponent implements OnChanges, OnDestroy {
  @Input() layers: VectorLayerComponent[] = []
  @Input() disabled?: boolean

  @Output() featureTransformed = new Subject<Feature<any>>()

  private selectInteraction: SelectFeatureInteraction
  private highlightInteraction: HighlightFeatureInteraction

  private transforms: ImageTransforms
  private transformInteractions: TransformInteractions
  private transformInteractionsCallback = () =>
    this.featureTransformed.next(this.selectInteraction.selectedFeatures[0])

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.init()
  }

  ngOnDestroy(): void {
    this.dispose()
  }

  init(): void {
    this.dispose()

    if (this.disabled || !this.layers || !this.layers.length) {
      return
    }

    const layers = this.layers.map((l) => l.layer)
    const layerNames = this.layers.map((l) => l.name)

    this.selectInteraction = new SelectFeatureInteraction({
      layers,
      layerNames,
      multiSelect: false,
      onSelectionChange: () =>
        this.handleSelectionChange(this.selectInteraction.selectedFeatures[0]),
    })

    this.highlightInteraction = new HighlightFeatureInteraction({ layers })

    this.mapComponent.map.addInteraction(this.selectInteraction)
    this.mapComponent.map.addInteraction(this.highlightInteraction)
  }

  dispose(): void {
    this.removeTransform()

    this.mapComponent.map.removeInteraction(this.selectInteraction)
    this.selectInteraction = null

    this.mapComponent.map.removeInteraction(this.highlightInteraction)
    this.highlightInteraction = null
  }

  removeTransform(): void {
    this.transformInteractions?.removeCallback(this.transformInteractionsCallback)
    this.transformInteractions?.removeInteractions()
    this.transformInteractions = null
  }

  handleSelectionChange(selectedFeature: Feature<any>): void {
    this.removeTransform()

    if (!selectedFeature) {
      return
    }

    this.transforms = new ImageTransforms()

    const geom = selectedFeature.getGeometry() as Geometry
    const margin = 10
    const extent = [
      geom.getExtent()[0] - margin,
      geom.getExtent()[1] - margin,
      geom.getExtent()[2] + margin,
      geom.getExtent()[3] + margin,
    ]

    this.transformInteractions = new TransformInteractions(
      this.mapComponent.map,
      extent,
      this.transforms
    )

    this.transformInteractions.addCallback(this.transformInteractionsCallback)

    this.transformInteractions.addInteractions()

    this.transforms.onChange((transformFun) => geom.applyTransform(transformFun))
  }
}
