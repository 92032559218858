/**
 * Venue Service Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MapPackagePartMetadata { 
    /**
     * Type of the package part.
     */
    type: MapPackagePartMetadataTypeEnum;
    /**
     * Unique id of the package part.
     */
    id: number;
    /**
     * Package part version hash.  Can be used to compare different packages for equality.
     */
    packageVersion: string;
    /**
     * Size of the package part in bytes.
     */
    dataSize: number;
    /**
     * Date when the package part has been created.
     */
    createdAt: string;
}
export enum MapPackagePartMetadataTypeEnum {
    Map = 'MAP'
};



