<div fxLayout="column">
    <table mat-table
           matSort
           [dataSource]="dataSource"
           fxFlex>
        <ng-container [matColumnDef]="column"
                      *ngFor="let column of displayedColumns">
            <th mat-header-cell
                mat-sort-header
                *matHeaderCellDef>{{ column }}</th>
            <td mat-cell
                *matCellDef="let element">
                <span *ngIf="column === 'Filename'"
                      matTooltip="{{ element[column] }}"
                      matTooltipClass="filename-tooltip"> {{ element[column] }}</span>
                <span *ngIf="column !== 'Filename'"> {{ element[column] }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="summary">
            <td mat-footer-cell
                *matFooterCellDef
                [colSpan]="displayedColumns.length">
                <div class="summary"
                     fxLayout="row"
                     fxLayoutAlign="space-around center"
                     fxLayoutGap="2em">
                    <span>
                        <span class="summary-header"> Global Average:</span> {{ summary.globalAverage | number }}
                    </span>
                    <span>
                        <span class="summary-header"> Average Error: </span> {{ summary.averageError | number }}
                    </span>
                    <span>
                        <span class="summary-header"> Min Error: </span> {{ summary.minError | number }}
                    </span>
                    <span>
                        <span class="summary-header"> Max error: </span> {{ summary.maxError | number }}
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row
            *matFooterRowDef="['summary']">
    </table>
    <mat-paginator [hidePageSize]="true"
                   [pageSize]="5"
                   showFirstLastButtons></mat-paginator>
</div>
