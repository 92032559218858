import { Pipe, PipeTransform } from "@angular/core"
import { DateTime } from "luxon"

@Pipe({
    name: "datetime"
})
export class DatetimePipe implements PipeTransform {
    transform(value: string | number): string {
        if (!value) {
            return "-"
        }
        // TODO Use profile locale or foundation locale ?
        const dateTime =
            typeof value === "string"
                ? DateTime.fromISO(value)
                : DateTime.fromJSDate(new Date(value))
        return dateTime.toLocaleString(DateTime.DATETIME_MED)
    }
}
