import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "loading-overlay",
  templateUrl: "./loading-overlay.component.html"
})
export class LoadingOverlayComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
