<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">
        <floor-wgs-sidebar class="sidebar"
                           [saveDisabled]="!protoPoints.allPointsDefined()"
                           (submit)="save()">
        </floor-wgs-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <openlayers-map fxFlex>
            <floor-image-layer [floor]="currentFloor.floor | async"
                               (imgDim)="onImageDimChange($event)">
            </floor-image-layer>
            <vector-layer #prototypeLayer
                          name="prototypeLayer"
                          [style]="prototypeStyle">
            </vector-layer>
            <select-feature-interaction #selectEditInteraction
                                        [layers]="[ prototypeLayer ]"
                                        (selectionChange)="onEditDevice($event[0])"
                                        [disabled]="(toggledMapTool | async) !== 'EDIT'">
            </select-feature-interaction>
            <draw-interaction [layer]="prototypeLayer"
                              drawType="Point"
                              [snap]="false"
                              (featureDrawn)="onFeatureDrawn($event)"
                              [disabled]="(toggledMapTool | async) !== 'ADD' || !protoPoints.canAddPoint()">
            </draw-interaction>
            <remove-interaction [layers]="[ prototypeLayer ]"
                                [disabled]="(toggledMapTool | async) !== 'REMOVE'"
                                (featureRemoved)="onFeatureRemoved($event)">
            </remove-interaction>
            <move-interaction [layers]="[ prototypeLayer ]"
                              [disabled]="(toggledMapTool | async) !== 'MOVE'">
            </move-interaction>
            <map-overlay *ngFor="let feature of featuresWithVisibleOverlays"
                         [ignorePositionChanges]="feature.get('isDragged')"
                         [position]="feature.getGeometry().getCoordinates()"
                         [offsetX]="50">
                <mat-card class="wgsOverlay"
                          fxLayout="row"
                          fxLayoutGap="0.5rem">
                    <!-- TODO Consider extracting as separate component -->
                    <form #wgsForm="ngForm"
                          [formGroup]="getWgsForm(feature)"
                          fxLayout="row"
                          fxLayoutGap="0.5rem">
                        <mat-form-field>
                            <mat-label translate>
                                Longitude
                            </mat-label>
                            <input matInput
                                   type="number"
                                   id="longitude"
                                   formControlName="longitude"
                                   required
                                   min="-180"
                                   max="180"
                                   step="0.01" />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label translate>
                                Latitude
                            </mat-label>
                            <input matInput
                                   type="number"
                                   id="latitude"
                                   formControlName="latitude"
                                   required
                                   min="-90"
                                   max="90"
                                   step="0.01" />
                        </mat-form-field>
                    </form>
                    <div class="overlay-actions"
                         fxLayout="row"
                         fxLayoutAlign="end center"
                         fxLayoutGap="0.5rem">
                        <button mat-raised-button
                                (click)="cancelWgsEdit(feature)">
                            <ic-icon [icon]="cancelWgsEditIcon"> </ic-icon>
                        </button>
                        <button mat-raised-button
                                color="primary"
                                [disabled]="!wgsForm.valid"
                                (click)="applyWgsEdit(feature, wgsForm.value)">
                            <ic-icon [icon]="applyWgsEditIcon"> </ic-icon>
                        </button>
                    </div>
                </mat-card>
            </map-overlay>
            <map-toolbar>
                <mat-button-toggle-group name="mapTool"
                                         multiple
                                         (change)="changeMapTool($event)">
                    <mat-button-toggle title="TMP-ADD"
                                       value="ADD"
                                       [disableRipple]="true">
                        <ic-icon [icon]="addPointIcon"> </ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='TMP-REMOVE'
                                       value="REMOVE"
                                       [disableRipple]="true">
                        <ic-icon [icon]="removePointIcon"> </ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='TMP-MOVE'
                                       value="MOVE"
                                       [disableRipple]="true">
                        <ic-icon [icon]="moveIcon"> </ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='TMP-EDIT'
                                       value="EDIT"
                                       [disableRipple]="true">
                        <ic-icon [icon]="editIcon"> </ic-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <button title='TMP-REMOVE-ALL'
                        (click)="removePoints()">
                    <ic-icon [icon]="removeAllIcon"> </ic-icon>
                </button>
            </map-toolbar>
        </openlayers-map>
    </mat-sidenav-content>
</mat-sidenav-container>
