import { AfterViewInit, Component, Directive, OnDestroy, ViewChild } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import mdiRestore from "@iconify/icons-mdi/restore"
import { CoordinateSystem, FloorCSConfigResponse, FloorCSConfigsService } from "@openapi/venue"
import { ImageDimensions, isImageDimensions } from "@venue/api"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import {
  findTransform,
  ImageTransforms,
} from "@venue/components/floor/floormap/transformations/ImageTransforms"
import TransformInteractions from "@venue/components/floor/floormap/transformations/interactions"
import { CurrentFloor } from "@venue/core"
import { AutoAdjustHelperService, FloorCSConvertHelperService } from "@venue/floor-cs/services"
import { ImageDimensionsReceiverMixin, OpenlayersMapComponent } from "@venue/maps"
import { ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { mat3 } from "gl-matrix"
import { isEqual } from "lodash"
import { Projection } from "ol/proj"
import { BehaviorSubject, combineLatest, empty, merge, Observable, of, Subject } from "rxjs"
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators"

@Directive()
abstract class FloorProdCSComponentBase extends ImageDimensionsReceiverMixin {}

@withUnsubscribe
@Component({
  selector: "floor-prod-cs",
  templateUrl: "./floor-prod-cs.component.html",
})
export class FloorProdCSComponent
  extends FloorProdCSComponentBase
  implements AfterViewInit, OnDestroy
{
  readonly resetImageIcon = mdiRestore

  readonly WRITE_USER_ROLE = "venue:floorcs:write"

  /** Observable indicating if loading overlay should be displayed. */
  loading = new BehaviorSubject<boolean>(true)

  /** Flag indicating that there was an error with getting coordinate system. */
  noCS = new BehaviorSubject(false)

  /** Flag indicating that there was an error with getting image dimensions. */
  noImgDim = new BehaviorSubject(false)

  /** Flag indicating that there was an error with getting prod image dimensions. */
  noProdImgDim = new BehaviorSubject(false)

  /**
   * Background (base) map projection
   *
   * Used to transform coordinates between background image and prod image layer.
   */
  mapProjection: Projection

  /** Subject to inject newly saved coordinate data and reload component */
  private csOverrideStream = new Subject<FloorCSConfigResponse>()

  /** Emits when user clicks save button. */
  saveClicks = new Subject<void>()

  transforms = new ImageTransforms()
  transformInteractions: TransformInteractions

  @ViewChild(OpenlayersMapComponent) mapComponent: OpenlayersMapComponent

  private prodImgDimSubject = new BehaviorSubject<ImageDimensions | string>(null)

  private savedBaseCS: CoordinateSystem

  private unsubscribe: Observable<void>

  constructor(
    public currentFloor: CurrentFloor,
    private floorCSConfigs: FloorCSConfigsService,
    private autoAdjustHelper: AutoAdjustHelperService,
    private csConverter: FloorCSConvertHelperService,
    private dialog: MatDialog,
    private authUtilService: AuthUtilService
  ) {
    super()
  }

  ngAfterViewInit(): void {
    // Get current floor id
    const floorIdStream = this.currentFloor.floor.pipe(
      map((f) => f?.id),
      distinctUntilChanged(isEqual),
      takeUntil(this.unsubscribe),
      shareReplay(1)
    )

    // On floor id change - clean up and enable loading
    floorIdStream.subscribe(() => {
      this.cleanup()
      this.loading.next(true)
    })

    // imgDim with error check - set image load error flag on error
    const iDimOrNullStream = this.imgDimSubject.pipe(
      map((dimOrErr) => {
        if (isImageDimensions(dimOrErr)) {
          return dimOrErr
        }

        this.onImgDimError(dimOrErr as string)
        return null
      })
    )

    // prodImgDim with error check - set production image load error flag on error
    const piDimOrNullStream = this.prodImgDimSubject.pipe(
      map((dimOrErr) => {
        if (isImageDimensions(dimOrErr)) {
          return dimOrErr
        }

        this.onProdImgDimError(dimOrErr as string)
        return null
      })
    )

    // Map current floor to its coordinate system config
    const csStream = merge(
      // Would be cleaner with mergeWith operator - sth. to consider after upgrade to rxjs7
      this.csOverrideStream.pipe(tap(() => this.loading.next(true))), // Enable loading after cs override
      floorIdStream.pipe(
        filter((fId) => !!fId),
        switchMap((fId) =>
          this.floorCSConfigs
            .getFloorCSConfig(fId)
            .pipe(catchError((err) => this.catchCSError(err)))
        )
      )
    ).pipe(startWith(null))

    // Combine cs with both image dimensions
    const csWithImgDimStream = combineLatest(csStream, iDimOrNullStream, piDimOrNullStream).pipe(
      filter(([cs, imgDim, prodImgDim]) => !!cs && !!imgDim && !!prodImgDim),
      takeUntil(this.unsubscribe)
    )

    // Load saved setup after all data is fetched
    csWithImgDimStream.subscribe(([cs, imgDim, prodImgDim]) =>
      this.loadSetup(cs, imgDim, prodImgDim)
    )

    this.saveClicks
      .pipe(debounceTime(200), takeUntil(this.unsubscribe))
      .subscribe(() => this.save())
  }

  /**
   * Save button should be disabled if downloading floor coordinate system config failed
   * or user does not have permissions to perform CRUD operations.
   */
  saveDisabled = combineLatest(this.noCS, of(this.hasUserWritePermissions())).pipe(
    map(([noCS, perms]) => noCS || !perms)
  )

  ngOnDestroy(): void {
    this.cleanup()
  }

  loadSetup(cs: FloorCSConfigResponse, imgDim: ImageDimensions, prodImgDim: ImageDimensions) {
    this.cleanup()

    this.savedBaseCS = cs.basePixelCS

    // If there is no prod cs setup yet, fall back to base cs
    const prodCS = cs.prodPixelCS ?? cs.basePixelCS

    const ppx = this.csConverter.rectangleDefinitionFrom(prodCS, prodImgDim.height)
    const bpx = this.csConverter.rectangleDefinitionFrom(cs.basePixelCS, imgDim.height)

    const to = this.csConverter.mat3From(bpx)
    const from = this.csConverter.mat3From(ppx)
    const transformMatrix = findTransform(from, to)

    this.transforms.setTransform(transformMatrix)

    this.transformInteractions = new TransformInteractions(
      this.mapComponent.map,
      [0, 0, prodImgDim.width, prodImgDim.height],
      this.transforms,
      null
    )

    this.transformInteractions.addInteractions()

    this.loading.next(false)
  }

  /**
   * Auto adjust image by calling image processing endpoints
   */
  autoAdjustImage() {
    this.autoAdjustHelper.autoAdjustImage(this.transforms, true, this.unsubscribe)
  }

  /**
   * Reset production image position
   */
  resetProdImage() {
    this.transforms.setTransform(mat3.create())
  }

  /**
   * Used to receive image dimensions of production image layer.
   */
  onProdImageDimChange(imgDimOrErrorMsg: ImageDimensions | string): void {
    this.prodImgDimSubject.next(imgDimOrErrorMsg)
  }

  /**
   * Check if user has permissions to perform CRUD operations.
   */
  hasUserWritePermissions() {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  /**
   * Clean up component state.
   */
  private cleanup(): void {
    this.transforms.setTransform(mat3.create())

    if (this.transformInteractions) {
      this.transformInteractions.removeInteractions()
      this.transformInteractions = null
    }

    this.resetErrors()
  }

  private resetErrors(): void {
    this.noCS.next(false)
    this.noImgDim.next(false)
    this.noProdImgDim.next(false)
  }

  private catchCSError(err: any): Observable<FloorCSConfigResponse> {
    this.noCS.next(true)
    this.loading.next(false)

    return empty()
  }

  private onImgDimError(err: any) {
    this.noImgDim.next(true)
    this.loading.next(false)
  }

  private onProdImgDimError(err: any) {
    this.noProdImgDim.next(true)
    this.loading.next(false)
  }

  private save(): void {
    const imgDim = this.imgDimSubject.getValue()
    if (!isImageDimensions(imgDim)) {
      // Should not happen
      throw new Error("Save not allowed without imgDim")
    }

    // Convert base pixel cs to map coordinates of base image
    const baseRect = this.csConverter.rectangleDefinitionFrom(this.savedBaseCS, imgDim.height)

    // Transform base map coordinates to prod coordinates with defined transform matrix
    const points = {
      blue: this.transforms.toLocal(baseRect.blue),
      yellow: this.transforms.toLocal(baseRect.yellow),
      pink: this.transforms.toLocal(baseRect.pink),
    }

    // Convert prod map coordinates to prod pixel cs
    const prodImgDim = this.prodImgDimSubject.getValue()
    if (!isImageDimensions(prodImgDim)) {
      // Should not happen
      throw new Error("Save not allowed without prodImgDim")
    }

    const prodPixelCS = this.csConverter.pixelCSFrom(points, prodImgDim.height)

    const progressDialog = this.openSaveProgressDialog()

    this.floorCSConfigs
      .setFloorCSProdConfig(this.currentFloor.getFloor().id, { prodPixelCS })
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      .subscribe((cs) => this.csOverrideStream.next(cs))
  }

  /**
   * Open ProgressDialog for save operation
   */
  // XXX copy from floor-cs
  private openSaveProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "floor.save_in_progress",
    }
    return this.dialog.open(ProgressDialog, { data })
  }
}
