<mat-list class="sidebar"
          fxFlex>
    <mat-list-item>
        <a mat-button
           uiSref="venues"
           uiSrefActive="active"
           (click)="close()">
            <ic-icon [icon]="buildingsIcon"> </ic-icon>
            {{ 'List of venues' | translate }}
        </a>
    </mat-list-item>
    <mat-list-item *ngIf="'dev' | appConstant">
        <a mat-button
           uiSref="mips-config"
           uiSrefActive="active"
           (click)="close()">
            <ic-icon [icon]="mipsConfigIcon"> </ic-icon>
            {{ 'frontpage.mipsconfig' | translate }}
        </a>
    </mat-list-item>
    <mat-list-item *ngIf="'dev' | appConstant">
        <a mat-button
           uiSref="tuning"
           uiSrefActive="active"
           (click)="close()">
            <ic-icon [icon]="tuningIcon"> </ic-icon>
            {{ 'frontpage.tuning' | translate }}
        </a>
    </mat-list-item>
</mat-list>
