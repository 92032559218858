import { DomPortalOutlet, TemplatePortal } from "@angular/cdk/portal"
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core"
import Control from "ol/control/Control"
import { OpenlayersMapComponent } from ".."

@Component({
  selector: "map-control",
  template: "<ng-template><ng-content></ng-content></ng-template>",
})
export class MapControlComponent implements OnInit, OnDestroy {
  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>

  private viewRef: EmbeddedViewRef<any>
  private control: Control

  constructor(
    private currRef: ElementRef,
    private mapComponent: OpenlayersMapComponent,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private ar: ApplicationRef,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    const templatePortal = new TemplatePortal(this.content, this.viewContainerRef)
    const element = document.createElement("div")
    element.classList.add("ol-control", "ol-unselectable")

    let currElement = this.currRef.nativeElement as HTMLElement
    currElement.classList.forEach((cls) => element.classList.add(cls))

    this.viewRef = new DomPortalOutlet(
      element,
      this.cfr,
      this.ar,
      this.injector
    ).attachTemplatePortal(templatePortal)
    this.control = new Control({ element: element })

    this.mapComponent.map.addControl(this.control)
  }

  ngOnDestroy(): void {
    this.mapComponent.map.removeControl(this.control)
    this.viewRef.destroy()
  }
}
