<mat-form-field>
    <mat-label>
        {{ label }}
    </mat-label>
    <input matInput
           class="date-input"
           [formControl]="date"
           [matDatepicker]="datePicker"
           (click)="datePicker.open()"
           (blur)="touched.next()"
           autocomplete="off"
           readonly />
    <mat-datepicker #datePicker>
    </mat-datepicker>
</mat-form-field>
