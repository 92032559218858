import { Component, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Building, BuildingsService } from "@openapi/venue"
import { CurrentVenue } from "@venue/core"
import { withUnsubscribe } from "@venue/shared"
import { Subject } from "rxjs"
import { filter, map, takeUntil } from "rxjs/operators"

// TODO React to floor in state ?
@withUnsubscribe
@Component({
  selector: "venue-select",
  templateUrl: "./venue-select.component.html",
})
export class VenueSelectComponent implements OnInit {
  venue = new FormControl(null)
  venues: Building[] = []

  private unsubscribe: Subject<void>

  constructor(private currentVenue: CurrentVenue, private buildings: BuildingsService) {}

  ngOnInit(): void {
    // XXX Need paging?
    this.buildings.getBuildings(0, 300).subscribe((buildings) => {
      this.venues = buildings.content

      this.currentVenue.venue
        .pipe(
          takeUntil(this.unsubscribe),
          filter((building) => building !== this.venue.value),
          map((v) => this.getFromList(v))
        )
        .subscribe((building) => this.venue.setValue(building))

      this.venue.valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((building) => this.currentVenue.setVenue(building))
    })
  }

  private getFromList(venue: Building): Building {
    return venue ? this.venues.find((v) => v.id === venue.id) : null
  }
}
