import {
    Input,
    Output,
    Directive,
    ElementRef,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef
} from "@angular/core"

import { standarizedMac } from "@venue/shared/utils/standarize-mac"

const MAC_CHAR = /[a-fA-F0-9]/

// XXX This directive does not fully work with forms
//     Form can keep extra characters past the input limit
//     As such - for safety - do not use it directly.
//     Use mac-input
@Directive({
    selector: "[mac]",
    host: {
        "[value]": "mac",
        "(input)": "format($event.target)"
    }
})
export class MacDirective implements OnChanges {
    @Input() mac: string
    @Input() macDisabled = false
    @Output() macChange = new EventEmitter<string>()

    constructor(private element: ElementRef, private cdRef: ChangeDetectorRef) {
        this.element.nativeElement.onkeypress = (e: KeyboardEvent) => {
            if (!this.macDisabled) {
                if (!MAC_CHAR.test(e.key)) {
                    e.preventDefault()
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.macDisabled &&
            changes.macDisabled.previousValue === true &&
            changes.macDisabled.currentValue === false
        ) {
            this.format(this.element.nativeElement)
        }
    }

    format(element: HTMLInputElement): void {
        if (!this.macDisabled && element.value) {
            this.mac = standarizedMac(element.value)
            // Required when selecting text and pasting into it
            element.value = this.mac
            this.macChange.emit(this.mac)
            this.cdRef.detectChanges()
        }
    }
}
