import { TranslateOptions } from "@venue/types/ol-translate-options"
import { Collection, Feature, Map, MapBrowserEvent } from "ol"
import { Coordinate } from "ol/coordinate"
import { Translate } from "ol/interaction"
import { Layer } from "ol/layer"
import AbstractMouseInteraction from "./AbstractMouseInteraction"
import SelectFeatureInteraction from "./SelectFeatureInteraction"

interface MoveFeatureOptions extends TranslateOptions {
  selectInteraction: SelectFeatureInteraction
  layers: Layer<any>[]
  layerNames: string[]
}

export const MOVE_FEATURES_EVENT = "movefeatures"

export default class MoveFeatureInteraction extends AbstractMouseInteraction {
  // XXX Using internal api of openlayers
  private translateInteraction: any
  private selectInteraction: SelectFeatureInteraction
  private layers: Layer<any>[]
  private layerNames: string[]
  private initDrag: boolean

  constructor(options: MoveFeatureOptions) {
    super()
    this.translateInteraction = new Translate(options)
    this.selectInteraction = options.selectInteraction
    this.layers = options.layers
    this.layerNames = options.layerNames

    const oldFeaturesAtPixel = this.translateInteraction.featuresAtPixel_.bind(
      this.translateInteraction
    )
    this.translateInteraction.featuresAtPixel_ = (p: Coordinate, m: Map) =>
      this.translateInteraction.features_ || oldFeaturesAtPixel(p, m)
  }

  down(e: MapBrowserEvent<any>): boolean {
    this.initDrag = true
    return this.translateInteraction.handleDownEvent(e)
  }

  drag(e: MapBrowserEvent<any>): boolean {
    if (this.initDrag) {
      const [feature /*, layer */] = this.getFeatureAtPixel(e.pixel, this.layers)
      let movedFeatures = []
      if (feature) {
        const selectedFeatures = this.selectInteraction.selectedFeatures
        if (selectedFeatures.includes(feature)) {
          movedFeatures = selectedFeatures
        } else {
          this.selectInteraction.deselectAllFeatures()
          movedFeatures = [feature]
        }
        this.translateInteraction.features_ = new Collection(movedFeatures)
        this.translateInteraction.features_.forEach((f: Feature<any>) => f.set("isDragged", true))
      }
      this.initDrag = false
    }
    const ret = this.translateInteraction.handleDragEvent(e)
    this.layers.forEach((l, lIdx) => {
      l.getSource().dispatchEvent("preview_" + this.layerNames[lIdx])
    })
    return ret
  }

  move(e: MapBrowserEvent<any>): void {
    return this.translateInteraction.handleMoveEvent(e)
  }

  up(e: MapBrowserEvent<any>): boolean {
    this.translateInteraction.handleUpEvent(e)

    if (this.translateInteraction.features_) {
      const features = this.translateInteraction.features_.getArray() as Feature<any>[]
      this.translateInteraction.features_ = null

      features.forEach((f: Feature<any>) => f.set("isDragged", false))

      this.layers.forEach((l, lIdx) => {
        l.getSource().dispatchEvent("changed_" + this.layerNames[lIdx])
      })

      // @ts-ignore
      this.dispatchEvent({ type: MOVE_FEATURES_EVENT, features })
    }

    return false
  }

  setMap(map: Map): void {
    super.setMap(map)
    this.translateInteraction.setMap(map)
  }
}
