import { Component, OnDestroy, OnInit } from "@angular/core"
import { BuildingsService } from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { CurrentVenue } from "@venue/core"
import { withUnsubscribe } from "@venue/shared"
import { Observable } from "rxjs"
import { takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "venue-root",
  template: '<ui-view class="fill-height" fxLayout="column" fxFlex></ui-view>',
})
export class VenueRootComponent implements OnInit, OnDestroy {
  private unsubscribe: Observable<void>

  constructor(
    private state: StateService,
    private currentVenue: CurrentVenue,
    private buildings: BuildingsService
  ) {}

  ngOnInit(): void {
    this.buildings
      .getBuilding(parseInt(this.state.params.venueId))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((building) => this.currentVenue.setVenue(building))
  }

  ngOnDestroy(): void {
    this.currentVenue.setVenue(null)
  }
}
