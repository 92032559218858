import { ImageDimensions } from "@venue/api"
import { BehaviorSubject } from "rxjs"

/**
 * Created to stop copy-pasting image dimensions handler boilerplate into all of editors.
 * Still not sure if this should not be moved to one of components of map module.
 * TODO Decide how and possibly reimplement image dimensions handling
 */
export class ImageDimensionsReceiverMixin {
  protected imgDimSubject = new BehaviorSubject<ImageDimensions | string>(null)

  onImageDimChange(imgDimOrErrorMsg: ImageDimensions | string): void {
    this.imgDimSubject.next(imgDimOrErrorMsg)
  }
}
