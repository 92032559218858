import { CollectionViewer, DataSource } from "@angular/cdk/collections"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import { Floor, FloorsService } from "@openapi/venue"
import { Page, Pageable, pageableOf } from "@venue/api"
import { CurrentVenue } from "@venue/core"
import { withUnsubscribe } from "@venue/shared"
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs"
import { filter, map, mergeMap, startWith, takeUntil, tap } from "rxjs/operators"

@withUnsubscribe
export class FloorsDataSource implements DataSource<Floor> {
  private dataSubject = new BehaviorSubject<Floor[]>([])
  private unsubscribe: Observable<void>
  private refreshSubject = new Subject<void>()

  constructor(
    private currentVenue: CurrentVenue,
    private floorsService: FloorsService,
    private paginator: MatPaginator,
    private sort: MatSort
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<Floor[]> {
    const pageableStream = combineLatest(
      this.sort.sortChange.pipe(startWith(this.sort)),
      this.paginator.page.pipe(startWith(this.paginator))
    ).pipe(map(([sort, page]) => pageableOf(page, sort)))

    combineLatest(
      this.currentVenue.venue.pipe(filter((v) => !!v)),
      pageableStream,
      this.refreshSubject.pipe(startWith({})),
      (venue, pageable, refresh) => ({ venueId: venue.id, pageable })
    )
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(({ venueId, pageable }) => this.fetchPage(venueId, pageable)),
        tap((page) => this.updatePaginator(page))
      )
      .subscribe((page) => this.dataSubject.next(page.content))
    return this.dataSubject.asObservable()
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete()
    this.refreshSubject.complete()
  }

  refresh(): void {
    this.refreshSubject.next()
  }

  private fetchPage(venueId: number, p: Partial<Pageable>): Observable<Page<Floor>> {
    return this.floorsService.getFloors(venueId, p.page, p.size, [p.sort])
  }

  private updatePaginator(page: Page<any>): void {
    this.paginator.length = page.totalElements
  }
}
