import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "tuningSolution"
})
export class TuningSolutionPipe implements PipeTransform {
    transform(value: string | number): string {
        if (value === null || value <0 ) {
            return "-"
        }
        else if (typeof value === "number") {
            return value.toFixed(2) + "m"
        }
        return value
    }
}
