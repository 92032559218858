<loading-overlay *ngIf="loading | async"></loading-overlay>
<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">
        <section class="sidebar"
                 fxLayout="column"
                 fxLayoutGap="0.5rem"
                 fxFlex>
            <floor-select>
            </floor-select>

            <mat-autocomplete #locationSearchAutocomplete="matAutocomplete"
                              (optionSelected)="centerMap($event.option.value)"
                              panelWidth="20em">
                <mat-option *ngFor="let loc of locationAutocompleteOpts | async"
                            class="location-option"
                            [value]="loc">
                    <span class="name">{{ loc.name }}</span>
                    <span class="city">{{ loc.city }}</span>
                    <span class="country">{{ loc.country_code }}</span>
                </mat-option>
            </mat-autocomplete>
            <mat-form-field>
                <mat-spinner class="autocomplete-spinner"
                             diameter="18"
                             *ngIf="locationAutocompleteFetchInProgress | async">
                </mat-spinner>
                <mat-label translate>floor-wgs-map.search-location-hint</mat-label>
                <input id="location-search-input"
                       autocomplete="off"
                       matInput
                       type="text"
                       [formControl]="locationSearchForm"
                       (focus)="locationSearchForm.setValue('')"
                       [matAutocomplete]="locationSearchAutocomplete"
                       matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}">
            </mat-form-field>

            <button mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="saveDisabled"
                    matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}"
                    (click)="save()">{{ 'Save' | translate }}</button>
        </section>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-container *ngIf="noCS || noImgDim">
            <div fxFlex
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 class="error-overlay">
                <h1 translate>editor-error.header</h1>
                <span *ngIf="noCS"
                      translate>editor-error.no-cs</span>
                <span *ngIf="noImgDim"
                      translate>editor-error.no-img-dim</span>
            </div>
        </ng-container>
        <openlayers-map fxFlex>
            <map-control class="ol-opacity-container">
                <label translate>opacity</label>
                <input #opacitySlider
                       type="range"
                       class="ol-opacity-slider"
                       value="70">
                <label>{{ opacitySlider.value }}%</label>
            </map-control>
            <osm-layer (projection)="setMapProjection($event)"></osm-layer>
            <floor-image-layer [floor]="currentFloor.floor | async"
                               [opacity]="+opacitySlider.value / 100"
                               [mapProjection]="mapProjection"
                               [transforms]="transforms"
                               [initMapProjection]="false"
                               (imgDim)="onImageDimChange($event)">
            </floor-image-layer>
            <vector-layer #transformBoxLayer
                          name="transformBoxLayer"
                          [style]="transformBoxLayerStyle">
            </vector-layer>
            <move-interaction [layers]="[ transformBoxLayer ]">
            </move-interaction>
            <modify-rotate-interaction [layers]="[ transformBoxLayer ]">
            </modify-rotate-interaction>
            <map-toolbar>
                <button title='{{ "floor-wgs-map.center-floor" | translate }}'
                        [disabled]="loading | async"
                        (click)="centerFloor()">
                    <ic-icon width="32px"
                             height="32px"
                             [icon]="centerFloorIcon"> </ic-icon>
                </button>
            </map-toolbar>
        </openlayers-map>
    </mat-sidenav-content>
</mat-sidenav-container>
