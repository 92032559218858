import { VENUE_ROOT_STATE_NAME } from "@venue/venues/venues-state-names"
import { TracePathComponent } from "../components/trace-path/trace-path.component"
import { TRACE_PATH_STATE_NAME } from "../traces.state-names"

export const TRACE_PATH_STATE = {
  parent: VENUE_ROOT_STATE_NAME,
  name: TRACE_PATH_STATE_NAME,
  // TODO Temprorary url - fix state hierarchy after finishing floor editors upgrade
  url: "/floorstmp/:floorId/traces/:user/:traceId/path",
  component: TracePathComponent,
}
