import { Injectable } from "@angular/core"
import { FloorCSConfig } from "@openapi/venue"
import { Point } from "@venue/api"
import { findTransform } from "@venue/components/floor/floormap/transformations/ImageTransforms"
import { mat3, vec3 } from "gl-matrix"

// TODO Create CurrentFloorImage service and use its value together with CurrentFloor to initialize converter
// Or make it part of openlayers map component? or floor image layer component?
@Injectable()
export class CoordinateConverter {
  private toPixelTransform: mat3
  private toMetricTransform: mat3

  load(cs: FloorCSConfig, imgH: number): void {
    this.toPixelTransform = null
    this.toMetricTransform = null

    const px = cs?.adjustedPixelCS
    const m = cs?.metricCS

    if (!px || !m) {
      return
    }

    const [pb, py, pp] = [m.blue, m.yellow, m.pink]

    // prettier-ignore
    const from = mat3.fromValues(
      pb.x, pb.y, 1,
      py.x, py.y, 1,
      pp.x, pp.y, 1
    )

    const [fb, fy, fp] = [px.blue, px.yellow, px.pink].map((v) => [v.x, imgH - v.y])

    // prettier-ignore
    const to = mat3.fromValues(
      fb[0], fb[1], 1,
      fy[0], fy[1], 1,
      fp[0], fp[1], 1
    )

    this.toPixelTransform = findTransform(from, to)
    this.toMetricTransform = mat3.invert(mat3.create(), this.toPixelTransform)
  }

  toPixel(p: Point): Point {
    const out = vec3.transformMat3(vec3.create(), [p.x, p.y, 1], this.toPixelTransform)
    return { x: out[0], y: out[1] }
  }

  toMetric(p: Point): Point {
    const out = vec3.transformMat3(vec3.create(), [p.x, p.y, 1], this.toMetricTransform)
    return { x: out[0], y: out[1] }
  }
}
