import { CollectionViewer, DataSource } from "@angular/cdk/collections"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import { IpsTracesService, TraceMetadata } from "@openapi/venue"
import { PageOfTracesMetadata } from "@openapi/venue/model/page-of-traces-metadata"
import { Page, Pageable, pageableOf } from "@venue/api"
import { CurrentFloor, CurrentVenue } from "@venue/core"
import { withUnsubscribe } from "@venue/shared"
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs"
import { debounceTime, filter, map, startWith, switchMap, takeUntil, tap } from "rxjs/operators"

@withUnsubscribe
export class TracesDataSource implements DataSource<TraceMetadata> {
  private loadingSubject = new BehaviorSubject(true)
  readonly loading = this.loadingSubject.asObservable()

  private dataSubject = new BehaviorSubject<TraceMetadata[]>([])
  private unsubscribe: Observable<void>
  private refreshSubject = new Subject<void>()

  constructor(
    private currentVenue: CurrentVenue,
    private currentFloor: CurrentFloor,
    private traces: IpsTracesService,
    private userFilter: Observable<string>,
    private paginator: MatPaginator,
    private sort: MatSort
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<TraceMetadata[]> {
    const pageableStream = combineLatest(
      this.sort.sortChange.pipe(startWith(this.sort)),
      this.paginator.page.pipe(startWith(this.paginator))
    ).pipe(map(([sort, page]) => pageableOf(page, sort)))

    combineLatest(
      this.currentVenue.venue.pipe(filter((v) => !!v)),
      this.currentFloor.floor.pipe(filter((f) => !!f)),
      this.userFilter,
      pageableStream,
      this.refreshSubject.pipe(startWith({})),
      (venue, floor, user, pageable, refresh) => ({
        venueId: venue.id,
        floorId: floor.id,
        user,
        pageable,
      })
    )
      .pipe(
        tap(() => this.loadingSubject.next(true)),
        debounceTime(500),
        switchMap((v) => this.fetchPage(v.venueId, v.floorId, v.user, v.pageable)),
        tap((page) => this.updatePaginator(page)),
        takeUntil(this.unsubscribe)
      )
      .subscribe((page) => {
        this.dataSubject.next(page.content)
        this.loadingSubject.next(false)
      })

    return this.dataSubject.asObservable()
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete()
    this.refreshSubject.complete()
  }

  refresh(): void {
    this.refreshSubject.next()
  }

  private fetchPage(
    venueId: number,
    floorId: number,
    user: string,
    pageable: Partial<Pageable>
  ): Observable<PageOfTracesMetadata> {
    return this.traces.getTraces(venueId, floorId, user, pageable.page, pageable.size, [
      pageable.sort,
    ])
  }

  private updatePaginator(page: Page<any>): void {
    this.paginator.length = page.totalElements
  }
}
