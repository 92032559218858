import { VENUE_ROOT_STATE_NAME } from "@venue/venues/venues-state-names"
import { FloorListComponent } from "../components/floor-list/floor-list.component"
import { FLOOR_LIST_STATE_NAME } from "../floor.state-names"

export const FLOOR_LIST_STATE = {
  parent: VENUE_ROOT_STATE_NAME,
  name: FLOOR_LIST_STATE_NAME,
  // TODO Would be nice to have it as /floors/
  //      and use the list as an entry point to floor setup,
  //      but this is not a thing for this moment,
  //      we need to port editors to Angular first
  url: "/floorsmgmt",
  component: FloorListComponent
}
