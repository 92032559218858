import {
  AfterViewInit,
  Component,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core"
import { MODIFY_FEATURES_EVENT } from "@venue/components/map/interactions/ModifyFeatureInteraction"
import { ModifyRotateInteraction } from "@venue/components/map/interactions/ModifyRotateInteraction"
import { Feature } from "ol"
import { Subject } from "rxjs"
import { OpenlayersMapComponent, VectorLayerComponent } from ".."

@Component({
  selector: "modify-rotate-interaction",
  template: "",
})
export class ModifyRotateInteractionComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() layers: VectorLayerComponent[]
  @Input() disabled?: boolean

  @Output() featuresModified = new Subject<Feature<any>[]>()

  private initialized = false
  private modifyInteractions: ModifyRotateInteraction[] = []

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngAfterViewInit(): void {
    this.initComponent()
    this.initialized = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      this.initComponent()
    }
  }

  ngOnDestroy(): void {
    this.dispose()
  }

  private initComponent() {
    this.dispose()

    if (this.disabled || !this.layers || !this.layers.length) {
      return
    }

    this.modifyInteractions = this.layers.map(
      (l) =>
        new ModifyRotateInteraction({
          layer: l.layer,
        })
    )

    this.modifyInteractions.forEach((mi) => {
      // @ts-ignore
      mi.on(MODIFY_FEATURES_EVENT, (e) => this.featuresModified.next(e.features))
      this.mapComponent.map.addInteraction(mi)
    })
  }

  private dispose(): void {
    if (this.modifyInteractions.length) {
      this.modifyInteractions.forEach((mi) => this.mapComponent.map.removeInteraction(mi))
      this.modifyInteractions = []
    }
  }
}
