import { HttpErrorResponse } from "@angular/common/http"
import { AfterViewInit, Component, Directive, ElementRef, ViewChild } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { MatSnackBar } from "@angular/material/snack-bar"
import mdiCursorMove from "@iconify/icons-mdi/cursor-move"
import mdiMapMarkerMinus from "@iconify/icons-mdi/map-marker-minus"
import mdiMapMarkerPlus from "@iconify/icons-mdi/map-marker-plus"
import mdiPencil from "@iconify/icons-mdi/pencil"
import { TranslateService } from "@ngx-translate/core"
import {
  AccessPoint,
  AccessPoints,
  AccessPointsService as AccessPointsApi,
  AccessPointsService,
  FloorCSConfigResponse,
  FloorCSConfigsService,
} from "@openapi/venue"
import { ImageDimensions, isImageDimensions } from "@venue/api"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import { devicesStyle } from "@venue/components/map/styles/DevicesStyle"
import { CoordinateConverter, CurrentFloor } from "@venue/core"
import {
  ImageDimensionsReceiverMixin,
  MapToolReceiverMixin,
  ObjectData,
  SelectFeatureInteractionComponent,
  VectorLayerComponent,
} from "@venue/maps"
import { withUnsubscribe } from "@venue/shared"
import { Feature } from "ol"
import Point from "ol/geom/Point"
import VectorSource from "ol/source/Vector"
import { BehaviorSubject, combineLatest, empty, Observable, of, Subject, throwError } from "rxjs"
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators"
import { Mixin } from "ts-mixer"
import { AccessPointFormDialog, AccessPointFormDialogData } from "./ap-form-dialog/ap-form.dialog"

interface AccessPointProperties {
  id: string
  type: string
  filtered: () => boolean
  data: AccessPoint
}

type AccessPointObjectData = ObjectData & { properties: AccessPointProperties }

abstract class APMapToolRecvMixin extends MapToolReceiverMixin<MapTool> {}

@Directive()
class AccessPointsComponentBase extends Mixin(ImageDimensionsReceiverMixin, APMapToolRecvMixin) {}

@withUnsubscribe
@Component({
  selector: "access-points",
  templateUrl: "./access-points.component.html",
})
export class AccessPointsComponent extends AccessPointsComponentBase implements AfterViewInit {
  readonly devicesStyle = devicesStyle
  readonly addApIcon = mdiMapMarkerPlus
  readonly removeApIcon = mdiMapMarkerMinus
  readonly moveApIcon = mdiCursorMove
  readonly editApIcon = mdiPencil

  readonly WRITE_USER_ROLE = "venue:aps:write"

  /** Observable indicating if loading overlay should be displayed. */
  loading = new BehaviorSubject<boolean>(true)

  /** Flag indicating that downloading floor coordinate system config failed. */
  noCS = false

  /** Flag indicating that geting image dimensions failed. */
  noImgDim = false

  @ViewChild("apLayer") apLayer: VectorLayerComponent
  @ViewChild("selectEditInteraction") selectEditInteraction: SelectFeatureInteractionComponent

  apRefreshStream = new Subject<void>()

  apImportForm = this.fb.control(null)
  @ViewChild("apImportInput") apImportInput: ElementRef<HTMLInputElement>

  private accessPoints: AccessPointObjectData[]

  private unsubscribe: Observable<any>

  constructor(
    public currentFloor: CurrentFloor,
    private coordinateConverter: CoordinateConverter,
    private apService: AccessPointsService,
    private floorCSConfigs: FloorCSConfigsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private apApi: AccessPointsApi,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private authUtilService: AuthUtilService
  ) {
    super()
  }

  ngAfterViewInit(): void {
    // Get current floor (can later be changed to not rely on fetching floor data - only floor id is needed)
    const floorStream = this.currentFloor.floor.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
      shareReplay(1)
    )

    // On floor change - display loading indicator
    floorStream.subscribe(() => this.loading.next(true))

    // On floor change - reset errors
    floorStream.subscribe(() => this.resetErrors())

    // Get current floor coordinate system config
    const csStream = floorStream.pipe(
      filter((f) => !!f),
      switchMap((f) =>
        this.floorCSConfigs.getFloorCSConfig(f.id).pipe(catchError((err) => this.catchCSError(err)))
      ),
      takeUntil(this.unsubscribe)
    )

    // View data loading flow
    combineLatest(
      csStream,
      this.imgDimSubject.pipe(
        switchMap((dimOrErr) => {
          if (isImageDimensions(dimOrErr)) {
            return of(dimOrErr)
          }

          return this.catchIDError(dimOrErr)
        })
      ),
      this.apRefreshStream.pipe(startWith({}))
    )
      .pipe(
        // Clear data on floor cs or image dimensions change
        tap(() => this.clearData()),
        // If floor cs or image dim not present, then skip further processing
        filter(([cs, imgDim]) => !!cs && !!imgDim),
        // Initialize coordinate converter
        tap(([cs, imgDim]) => this.coordinateConverter.load(cs, imgDim.height)),
        // Discard image dimensions - we no longer need it after loading converter
        map(([cs]) => cs),
        // Fetch data from the backend
        switchMap((cs) =>
          this.apService
            .getAccessPoints(cs.floorId)
            .pipe(catchError((err) => this.catchAPError(err)))
        ),
        // And obviously do this until unsubscribe is signalled
        takeUntil(this.unsubscribe)
      )
      // Finally, set access points map data
      .subscribe((aps) => this.load(aps))

    this.apImportForm.valueChanges
      .pipe(
        filter((v) => !!v),
        map(() => this.apImportInput.nativeElement.files[0]),
        tap(() => this.apImportForm.setValue(null)),
        switchMap((file) =>
          this.apApi
            .importAccessPoints(this.currentFloor.getFloor().id, file)
            .pipe(catchError((err) => this.catchImportError(err)))
        ),
        switchMap(() =>
          this.translate
            .get("access-points.import.success")
            .pipe(tap((msg) => this.snackBar.open(msg, "", { duration: 5 * 1000 })))
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.apRefreshStream.next())
  }

  /**
   * On AP fetch error, return null observable if error was 404, otherwise rethrow error
   */
  private catchAPError(error: HttpErrorResponse): Observable<AccessPoints | null> {
    // Not Found - no problem, return null
    if (error.status === 404) {
      return of(null)
    }

    // Otherwise, rethrow
    return throwError(error)
  }

  /**
   * Check if user has permissions to perform CRUD operations.
   */
  hasUserWritePermissions(): boolean {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  /**
   * Save button should be disabled if downloading floor coordinate system config failed
   * or user does not have permissions to perform CRUD operations.
   */
  saveDisabled = this.noCS || !this.hasUserWritePermissions()

  /**
   * Import button should be disabled if downloading floor coordinate system config failed
   * or user does not have permissions to perform CRUD operations.
   */
  importDisabled = this.noCS || !this.hasUserWritePermissions()

  onFeatureEdit(feature: Feature<Point>): void {
    if (!feature) {
      // If there is no feature, that means it was 'deselect' event
      return
    }

    // TODO Some time ago 'customProperties' was marked as 'old way' in vector layer
    //      As I no longer remember the reasoning, I'm using that for now
    //      The supposed 'new way' is used in floor-wgs.component.ts, by storing invidual properties
    //      instead of one 'blob'
    let apData: AccessPointProperties = feature.get("customProperties")

    let dialogData: AccessPointFormDialogData = {
      create: false,
      ap: apData.data,
    }

    this.dialog
      .open(AccessPointFormDialog, { data: dialogData })
      .afterClosed()
      .subscribe((ap: AccessPoint) => {
        this.selectEditInteraction.deselectFeature(feature)

        Object.assign(apData.data, ap)
        apData.id = ap.mac

        feature.changed()
      })
  }

  onFeatureDrawn(feature: Feature<Point>): void {
    let dialogData: AccessPointFormDialogData = {
      create: true,
    }

    this.dialog
      .open(AccessPointFormDialog, { data: dialogData })
      .afterClosed()
      .subscribe((ap?: AccessPoint) => {
        if (!ap) {
          this.apLayer.source.removeFeature(feature)
          return
        }

        let apData: AccessPointProperties = {
          data: ap,
          id: ap.mac,
          type: "AP",
          filtered: () => true, // TODO Can we remove it?
        }

        // TODO customProperties - see TODO above about it
        feature.set("customProperties", apData)

        feature.changed()
      })
  }

  onFeatureRemoved(feature: Feature<Point>): void {
    // Nothing to do ?
  }

  save(): void {
    // TODO Save progress dialog
    // TODO Throttle in case save multi-click
    const accessPoints = this.getAccessPointsFromMap()

    this.apService.saveAccessPoints(this.currentFloor.getFloor().id, { accessPoints }).subscribe(
      (aps) => {
        // TODO Update loaded data
        this.load(aps)
      },
      (error) => {
        // TODO Snackbar
        console.error(error)
      }
    )
  }

  /**
   * On cs error, set noCS error flag and return empty observable.
   *
   * Also hide loading indicator, nothing to load after this error.
   */
  private catchCSError(err: any): Observable<FloorCSConfigResponse> {
    console.error("Error fetching floor coordinate system config", err)
    this.noCS = true

    this.loading.next(false)

    return empty()
  }

  /**
   * On image dimensions error, set noImgDim flag and return empty observable.
   */
  private catchIDError(err: any): Observable<ImageDimensions> {
    console.error("Error getting image dimensions", err)

    this.noImgDim = true

    this.loading.next(false)

    return empty()
  }

  private catchImportError(err: HttpErrorResponse): Observable<void> {
    console.error("Error importing aps.", err)

    return this.translate.get("access-points.import.error").pipe(
      tap((msg) => this.snackBar.open(msg, "", { duration: 5 * 1000 })),
      switchMap(() => empty()),
      takeUntil(this.unsubscribe)
    )
  }

  private resetErrors(): void {
    this.noCS = false
    this.noImgDim = false
  }

  private load(aps: AccessPoints): void {
    this.loading.next(false)

    // Nothing to do if there is no access point data for this floor
    if (aps?.accessPoints?.length === 0) {
      return
    }

    let mapObjects = this.mapAccessPointData(aps.accessPoints)

    this.setAccessPoints(mapObjects)
  }

  /**
   * Clear previously loaded data
   */
  private clearData(): void {
    this.setAccessPoints([])
  }

  /**
   * Map backend access points data to view map objects
   */
  private mapAccessPointData(data: AccessPoint[]): AccessPointObjectData[] {
    return data.map((ap) => {
      ap.position = this.coordinateConverter.toPixel(ap.position)
      return {
        point: ap.position,
        properties: {
          id: ap.mac,
          type: "AP",
          filtered: () => true, // TODO Can we remove it?
          data: ap,
        },
      }
    })
  }

  /**
   * Set access points data
   */
  private setAccessPoints(aps: AccessPointObjectData[]): void {
    this.accessPoints = aps
    this.apLayer.loadFeatures(aps, "Point")
  }

  private getAccessPointsFromMap(): AccessPoint[] {
    const apFeatures = (this.apLayer.source as VectorSource<Point>).getFeatures()

    return apFeatures.map((f) => this.featureToAccessPoint(f))
  }

  private featureToAccessPoint(f: Feature<Point>): AccessPoint {
    const point = f.getGeometry()
    const [x, y] = point.getCoordinates()

    // TODO Custom properties - see other TODO about it
    let apProps: AccessPointProperties = f.get("customProperties")
    let ap = apProps.data

    ap.position = this.coordinateConverter.toMetric({ x, y })

    return ap
  }
}

enum MapTool {
  ADD = "ADD",
  REMOVE = "REMOVE",
  MOVE = "MOVE",
  EDIT = "EDIT",
}
