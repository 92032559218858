import { Component, Host, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core"
import { isPoint, Point } from "@venue/api"
import { defaultStyle as DEFAULT_STYLE } from "@venue/components/map/styles/WallStyle"
import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { Circle, LineString, Point as olPoint, Polygon } from "ol/geom"
import { Vector as VectorLayer } from "ol/layer"
import { Vector as VectorSource } from "ol/source"
import { StyleLike } from "ol/style/Style"
import { OpenlayersMapComponent } from "."

export type ObjectProperties = {
  [key: string]: any
}

type ObjectShapeCoordinates = {
  points: Point[] | Coordinate[]
}

type ObjectCoordinates = {
  point: Point | Coordinate
}

type ObjectCircleCoordinates = {
  center: Point | Coordinate
  radius: number
}

export type ObjectData = (ObjectShapeCoordinates | ObjectCoordinates | ObjectCircleCoordinates) & {
  properties?: ObjectProperties
}

function isShape(data: ObjectData): data is ObjectShapeCoordinates {
  return (<ObjectShapeCoordinates>data).points !== undefined
}

function isCircle(data: ObjectData): data is ObjectCircleCoordinates {
  return (<ObjectCircleCoordinates>data).center !== undefined
}

function isPointArray(o: Point[] | Coordinate[]): o is Point[] {
  return o.length !== 0 && (<Point>o[0]).x !== undefined
}

@Component({
  selector: "vector-layer",
  template: "",
})
export class VectorLayerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() style?: StyleLike
  @Input() name?: string
  @Input() zIndex: number

  @Input() source = new VectorSource<Circle | Polygon | LineString | olPoint>()

  layer = new VectorLayer({
    source: this.source,
    style: DEFAULT_STYLE, // TODO Allow customization
    updateWhileAnimating: false, // TODO Allow customization
  })

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnInit(): void {
    this.mapComponent.map.addLayer(this.layer)
    this.mapComponent.register(this)

    if (this.zIndex) {
      this.layer.setZIndex(this.zIndex ?? 0)
    }
  }

  ngOnDestroy(): void {
    this.mapComponent.map.removeLayer(this.layer)
    this.mapComponent.deregister(this)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source) {
      this.mapComponent.map.removeLayer(this.layer)

      if (!changes.source.currentValue) {
        this.source = new VectorSource<Circle | Polygon | LineString | olPoint>()
      }

      this.layer = new VectorLayer({
        source: this.source,
        style: DEFAULT_STYLE, // TODO Allow customization
        updateWhileAnimating: false, // TODO Allow customization
      })

      this.mapComponent.map.addLayer(this.layer)
    }

    if (this.name) {
      this.layer.set("title", this.name)
    }

    if (this.style) {
      this.layer.setStyle(this.style)
    }

    if (changes.zIndex) {
      this.layer.setZIndex(this.zIndex ?? 0)
    }
  }

  clearFeatures(fast = true): void {
    this.source.clear(fast)
  }

  // TODO VectorLayer could take care of conversion to pixels
  loadFeatures(
    objectData: ObjectData[],
    type: "Polygon" | "LineString" | "Point" = "Polygon"
  ): Feature<Circle | Polygon | LineString | olPoint>[] {
    this.clearFeatures()

    if (objectData && objectData.length) {
      const features: Feature<Circle | Polygon | LineString | olPoint>[] = []
      objectData.forEach((v) => {
        let feature: Feature<Circle | Polygon | LineString | olPoint>
        if (isShape(v) && type !== "Point") {
          const points = <Coordinate[]>(
            (isPointArray(v.points) ? v.points.map((p) => [p.x, p.y]) : v.points)
          )
          if (type === "Polygon") {
            feature = new Feature<Polygon>({
              geometry: new Polygon([points]).simplify(1),
            })
          } else {
            feature = new Feature<LineString>({
              geometry: new LineString(points).simplify(1),
            })
          }
        } else if (!isShape(v) && !isCircle(v) && type === "Point") {
          const point: Coordinate = isPoint(v.point) ? [v.point.x, v.point.y] : v.point
          feature = new Feature<olPoint>({
            geometry: new olPoint(point),
          })
        } else if (isCircle(v) && type === "Polygon") {
          const center: Coordinate = isPoint(v.center) ? [v.center.x, v.center.y] : v.center
          const radius = v.radius
          feature = new Feature<Circle>({
            geometry: new Circle(center, radius),
          })
        } else {
          throw `Incorrect data for object type ${type}`
        }
        // XXX Old way - if possible, access properties by separate keys
        // and remove 'customProperties' from here when it stops being used
        feature.set("customProperties", v.properties)
        if (v.properties) {
          Object.entries(v.properties).forEach(([k, v]) => feature.set(k, v))
        }

        features.push(feature)
      })
      this.source.addFeatures(features)
      return features
    }

    return []
  }
}
