import { NgModule } from "@angular/core"

import { UIRouterModule } from "@uirouter/angular"

import * as configPackages from "./"
import { SharedModule } from "../shared"

@NgModule({
  imports: [
    UIRouterModule.forChild({ states: configPackages.CONFIG_PACKAGES_STATES }),
    SharedModule,
  ],
  declarations: [
    configPackages.GeneratePackageForm,
    configPackages.PackageValidationPopover,
  ],
})
export class ConfigPackagesModule {}
