import { Component, OnInit } from "@angular/core"
import { StateService } from "@uirouter/core";

@Component({
    selector: "mips-config",
    templateUrl: "./mips-config.component.html"
})
export class MipsConfigComponent implements OnInit {

    constructor(private stateService: StateService) {}

    ngOnInit(): void {
        if (this.stateService.$current.name === "mips-config") {
            this.stateService.go("frontend-config")
        }
    }
}
