import { Component, ViewChild } from "@angular/core"
import { ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { Observable, of } from "rxjs"
import { CurrentVenue } from "@venue/core"
import { CommitImportInstruction, ImportService } from "@openapi/venue"
import { delay, finalize, takeUntil } from "rxjs/operators"
import { MatStepper } from "@angular/material/stepper"
import { VENUES_STATE_NAME } from "@venue/venues/venues-state-names"
import { StateService } from "@uirouter/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { MatSnackBar } from "@angular/material/snack-bar"
import { TranslateService } from "@ngx-translate/core"

@withUnsubscribe
@Component({
  selector: "venue-import",
  templateUrl: "./venue-import.component.html",
})
export class VenueImportComponent {
  private unsubscribe: Observable<void>

  importSessionId: number = undefined

  @ViewChild("stepper") private stepper: MatStepper

  constructor(
    private currentVenue: CurrentVenue,
    private dialog: MatDialog,
    private importService: ImportService,
    private snackBar: MatSnackBar,
    private state: StateService,
    private translate: TranslateService
  ) {}

  commit(rawInstructions: CommitImportInstruction[]): void {
    this.stepper.next()
    const progressDialog = this.openCommitProgressDialog()
    const instructions = this.getFilteredCommitStruct(rawInstructions)

    this.importService
      .commitImport(this.importSessionId, instructions)
      .pipe(
        delay(500), // Delay 500ms to not instantly blink with the loading overlay
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      .subscribe({
        next: (r) => {
          this.state.go(VENUES_STATE_NAME)
        },
        error: () => {
          this.translate.stream("import-venue.done.error-message").subscribe((msg) => {
            this.snackBar.open(msg, null, {
              duration: 3000, // Delay 3000ms to not instantly blink with the snack bar
            })
          })
        },
      })
  }

  upload(importPck: File) {
    const progressDialog = this.openUploadProgressDialog()

    this.importService
      .startImport(this.getBuildingSlug(), importPck)
      .pipe(
        delay(500), // Delay 500ms to not instantly blink with the loading overlay
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      .subscribe({
        next: (r) => {
          this.importSessionId = r.sessionId
          this.stepper.next()
        },
        error: () => {
          this.translate.stream("import-venue.pck-upload.error-message").subscribe((msg) => {
            this.snackBar.open(msg, null, {
              duration: 3000, // Delay 3000ms to not instantly blink with the snack bar
            })
          })
        },
      })
  }

  private getBuildingSlug(): string {
    return !!this.currentVenue.getVenue() ? this.currentVenue.getVenue().slug : undefined
  }

  private getFilteredCommitStruct(
    rawInstructions: CommitImportInstruction[]
  ): CommitImportInstruction[] {
    return rawInstructions.filter((instr) => instr.packageFloorSlug != undefined)
  }

  private openUploadProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "import-venue.pck-upload.upload-in-progress",
    }
    return this.dialog.open(ProgressDialog, { data })
  }

  private openCommitProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "import-venue.done.in-progress",
    }
    return this.dialog.open(ProgressDialog, { data })
  }
}
