import {
  AfterViewInit,
  Component,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core"
import HighlightFeatureInteraction from "@venue/components/map/interactions/HighlightFeatureInteraction"
import {
  FeatureFilter,
  SelectFeatureInteraction,
} from "@venue/components/map/interactions/SelectFeatureInteraction"
import { Feature } from "ol"
import { OpenlayersMapComponent, VectorLayerComponent } from "."

@Component({
  selector: "select-feature-interaction",
  template: "",
})
export class SelectFeatureInteractionComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() layers: VectorLayerComponent[]
  @Input() filter: FeatureFilter = () => true
  @Input() multiSelect = false
  @Input() disabled?: boolean

  @Input() ajsViewModel?: any // XXX Remove after angular upgrade is finished

  @Output() selectionChange = new EventEmitter<Feature<any>[]>()

  readonly selectedFeatures: Feature<any>[] = []

  private selectInteraction: SelectFeatureInteraction
  private highlightInteraction: HighlightFeatureInteraction

  private initialized = false

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnDestroy(): void {
    this.dispose()
  }

  ngAfterViewInit(): void {
    this.initComponent()
    this.initialized = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      this.initComponent()
    }
  }

  // TODO support multiselect
  selectFeature(feature: Feature<any>): void {
    this.selectInteraction.selectFeature(feature)
  }

  deselectFeature(feature: Feature<any>): void {
    this.selectInteraction.selectFeature(null)
  }

  refilter(): void {
    if (this.selectInteraction) {
      this.selectInteraction.refilter()
    }
  }

  private initComponent(): void {
    this.initInteraction()

    if (this.ajsViewModel) {
      this.ajsViewModel.editFeature = {}
      // This is temporary code, hence eslint-disable
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      Object.defineProperty(this.ajsViewModel.editFeature, "featureToEdit", {
        get: function () {
          return self.selectedFeatures[0]
        },
        set: function (x) {
          self.selectFeature(x)
        },
      })
    }
  }

  private initInteraction(): void {
    this.dispose()

    if (this.disabled === true || !this.layers) {
      return
    }

    this.selectInteraction = new SelectFeatureInteraction({
      layers: this.layers.map((l) => l.layer),
      layerNames: this.layers.map((l) => l.name),
      multiSelect: this.multiSelect,
      selectedFeaturesArray: this.selectedFeatures,
      filter: this.filter,
      onSelectionChange: () => {
        this.selectionChange.emit(this.selectedFeatures)
      },
      enabled: () => true,
    })

    this.highlightInteraction = new HighlightFeatureInteraction({
      layers: this.layers.map((l) => l.layer),
    })

    this.mapComponent.map.addInteraction(this.selectInteraction)
    this.mapComponent.map.addInteraction(this.highlightInteraction)
  }

  private dispose(): void {
    if (this.selectInteraction) {
      this.mapComponent.map.removeInteraction(this.selectInteraction)
      this.selectInteraction = null
    }

    if (this.highlightInteraction) {
      this.mapComponent.map.removeInteraction(this.highlightInteraction)
      this.highlightInteraction = null
    }
  }
}
