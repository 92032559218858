import { PageInfo } from '.'

export interface Links {
    [ key: string ]: { href: string }
}

export interface HateoasResponse<T> {
    page: PageInfo
    _embedded: T
    _links: Links
}

export function hateoasFromAny<T>(obj: any): HateoasResponse<T> {
    for (const x in obj._embedded) { // Should be 1
        obj._embedded = obj._embedded[x]
        return <HateoasResponse<T>> obj
    }
}
