<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">
        <section class="sidebar"
                 fxLayout="column"
                 fxLayoutGap="0.5rem"
                 fxFlex>
            <file-selector tooltip="{{ 'imdf-view.select-file-tooltip' | translate }}"
                           accept="application/zip"
                           (fileSelected)="openImdf($event)">
                {{ 'imdf-view.select-file' | translate }}
            </file-selector>
        </section>
    </mat-sidenav>
    <mat-sidenav-content>
        <openlayers-map fxFlex>
            <osm-layer name="World Map"></osm-layer>
            <vector-layer *ngFor="let layerName of layerNames; index as i"
                          name="{{ layerName }}"
                          [source]="layerSources[i]"></vector-layer>
            <ol-layerswitcher></ol-layerswitcher>
        </openlayers-map>
    </mat-sidenav-content>
</mat-sidenav-container>
