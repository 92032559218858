<mat-form-field style="width: 100%;">
    <mat-label>
        {{ label }}
    </mat-label>
    <input #timeInput
           class="time-input"
           matInput
           value="{{ getTimeString() }}"
           (click)="openTimepicker()"
           (blur)="touched.next()"
           readonly />
</mat-form-field>

<ng-template #timePopover>
    <!-- Elevation z4 gives same box-shadow as the one on material datepicker -->
    <mat-card class="mat-elevation-z4">
        <ngb-timepicker class="time-picker" [formControl]="time">
        </ngb-timepicker>
    </mat-card>
</ng-template>
