<table class="table">
    <tbody>
        <tr *ngFor="let category of categories">
            <td>{{ 'validation.' + category + '.name' | translate }}</td>
            <td>
                <div class="validation-errors"
                     fxFlex
                     fxLayout="row"
                     fxLayoutAlign="start center">
                    <div *ngIf="errorsInCategory(category).length == 0; then validBlock; else errorsBlock"></div>

                    <ng-template #validBlock>
                        <ic-icon [icon]="validIcon"
                                 class="no-error"></ic-icon>
                    </ng-template>

                    <ng-template #errorsBlock>
                        <ic-icon *ngIf="isError(category); else warningIconBlock"
                                 [icon]="errorIcon"
                                 class="error">
                        </ic-icon>
                        <ng-template #warningIconBlock>
                            <ic-icon [icon]="warningIcon"
                                     class="warning"></ic-icon>
                        </ng-template>
                        <ul>
                            <li *ngFor="let error of errorsInCategory(category)">
                                {{ 'validation.' + category + '.' + error.error | translate }}
                            </li>
                        </ul>
                    </ng-template>
                </div>
            </td>
        </tr>
    </tbody>
</table>
