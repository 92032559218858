import { Point } from "@venue/api"
import { isMacSource, Source, standarizedMac } from "@venue/shared/utils/standarize-mac"
import { Feature } from "ol"
import { Geometry, Point as olPoint } from "ol/geom"
import { Circle, Fill, Icon, Stroke, Style, Text } from "ol/style"

const CIRCLE_RADIUS = 17
const CIRCLE_BORDER = 2
const LABEL_STROKE_WIDTH = 3
const LABEL_FONT = "16px sans-serif,halvetica"

function style(opts?: DeviceStyleOptions): Style {
  opts = opts || {}
  // eslint-disable-next-line prefer-const
  let { color, borderColor, geometry, radius, strokeWidth } = opts
  if (!("strokeWidth" in opts)) strokeWidth = CIRCLE_BORDER
  if (!("radius" in opts)) radius = CIRCLE_RADIUS
  if (!("color" in opts)) color = "rgba(0, 102, 255, 0.7)"
  if (!("borderColor" in opts)) borderColor = "#000"
  return new Style({
    image: new Circle({
      radius: radius,
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        color: borderColor,
        width: strokeWidth,
      }),
    }),
    fill: new Fill({
      color: "rgba(0, 102, 255, 0.7)",
    }),
    geometry,
  })
}

function labelStyle(label: string, opts: LabelStyleOptions): Style {
  opts = opts || {}
  return new Style({
    text: new Text({
      font: opts.font || LABEL_FONT,
      text: label,
      fill: new Fill({
        color: opts.fillColor || "black",
      }),
      stroke: new Stroke({
        color: "white",
        width: LABEL_STROKE_WIDTH,
      }),
      offsetX: opts.offsetX,
      offsetY: "offsetY" in opts ? opts.offsetY : 25,
      textAlign: opts.textAlign || "left",
    }),
    geometry: opts.geometry,
  })
}

function circleStyle(type: DeviceOnMap, deviceEnabled: boolean): Style {
  switch (type) {
    case "MIPS":
      return style({ color: "rgba(69 , 186, 0  , 0.7)" })
    case "SIPS":
      return style({ color: "rgba(213, 134, 0  , 0.7)" })
    case "BLE":
      return style({ color: "rgba(0  , 102, 255, 0.7)" })
    case "AP":
      if (deviceEnabled) {
        return style({ color: "rgba(100, 200, 0  , 0.7)" })
      }
      return style({ color: "rgba(255, 100, 0  , 0.7)" })
    default:
      return style()
  }
}

function historyStyle(geometry: Geometry): Style {
  return style({ strokeWidth: 0, radius: 5, geometry })
}

function iconStyle(type: DeviceOnMap): Style {
  const iconUrls: { [x in DeviceOnMap]: string } = {
    SIPS: "assets/images/wifi.svg",
    MIPS: "assets/images/phone.svg",
    BLE: "assets/images/bluetooth.svg",
    AP: "assets/images/access-point-network.svg",
  }

  return new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      src: iconUrls[type],
    }),
  })
}

function partialLocationStyle(opts: PartialLocationStyleOptions): Style {
  const partialColors = {
    CT: "rgba(63 , 169, 43 , 0.8)",
    IPM: "rgba(68 , 50 , 141, 0.8)",
    WCT: "rgba(204, 176, 55 , 0.8)",
    NPM: "rgba(199, 54 , 65 , 0.8)",
  }
  return style({ color: partialColors[opts.algorithmType], geometry: opts.geometry })
}

function isDeviceOnMap(s: DeviceOnMap): s is DeviceOnMap {
  return s === "AP" || isMacSource(s)
}

// TODO Refactor this - it is almost unreadable
// TODO Remove customProperties field when possible
export function devicesStyle(feature: Feature<any> & { customProperties?: any }): Style[] {
  const props = feature.customProperties || feature.get("customProperties") || {}
  if (props.filtered && !props.filtered()) {
    return []
  }
  let styles: Style[] = []

  const styleCache: Partial<StyleCache> = feature.get("__styleCache") || {}

  if (props.showHistory && props.history) {
    const history: Point[] = props.history

    history
      .map((l) => new olPoint([l.x, l.y]))
      .map((geom) => historyStyle(geom))
      .forEach((s) => styles.push(s))
  }

  const type = props.type
  let enabled = true
  // Ap enabled
  if (props && props.data && props.data.enabled === false) {
    enabled = false
  }
  styles.push(circleStyle(type, enabled))
  if (type) {
    styles.push(iconStyle(type))
  }
  const id = props.id

  if (id) {
    if (styleCache.previousId !== id) {
      styleCache.previousId = id
      styleCache.displayedId = isDeviceOnMap(type) ? standarizedMac(id) : id
    }

    const canvas = document.querySelector("canvas")
    const ctx = canvas.getContext("2d")
    ctx.font = LABEL_FONT
    ctx.lineWidth = LABEL_STROKE_WIDTH
    const idMetrics = ctx.measureText(styleCache.displayedId)
    styles.push(
      labelStyle(styleCache.displayedId, {
        offsetX: Math.floor(-idMetrics.width / 2),
      })
    )
    if (props.filter) {
      let idFilter
      if (props.filter.id) {
        // Old api TODO Remove later
        idFilter = props.filter.id
      } else if (typeof props.filter === "function") {
        // New api - use like this or TODO change to getter later
        idFilter = props.filter()
      }

      if (idFilter) {
        if (styleCache.previousIdFilter !== idFilter) {
          styleCache.previousIdFilter = idFilter

          // If mac type then standarize also filter, as it can be non-standarized due to using other type filter
          if (isDeviceOnMap(type)) {
            idFilter = standarizedMac(idFilter)
          }

          const regexResults = new RegExp(idFilter, "i").exec(styleCache.displayedId)
          if (regexResults) {
            styleCache.filterText = regexResults[0]
            const partIdx = regexResults.index
            const idStart = styleCache.displayedId.substring(0, partIdx)
            const idStartMetrics = ctx.measureText(idStart)
            styleCache.filterTextWidth = idStartMetrics.width
          } else {
            styleCache.filterText = null
          }
        }
        if (styleCache.filterText) {
          styles.push(
            labelStyle(styleCache.filterText, {
              fillColor: "blue",
              offsetX: Math.floor(-idMetrics.width / 2 + styleCache.filterTextWidth),
            })
          )
        }
      }
    }
  }

  if (feature.get("hovered")) {
    const color = "rgba(0,160,255,0.3)"
    styles.push(style({ color, borderColor: color }))
  } else if (feature.get("selected")) {
    const color = "rgba(0,0,255,0.3)"
    styles.push(style({ color, borderColor: color }))
  }

  if (feature.get("selected") && props.partialLocations) {
    Object.keys(props.partialLocations)
      .map((algorithmType: PositioningAlgorithm) => {
        const location = props.partialLocations[algorithmType]
        const geometry = new olPoint([location.x, location.y])
        return [
          partialLocationStyle({
            geometry,
            algorithmType,
          }),
          labelStyle(algorithmType, {
            geometry,
            textAlign: "center",
            offsetY: 0,
            font: "13px sans-serif,halvetica",
          }),
        ]
      })
      .forEach((l) => (styles = styles.concat(l)))
  }

  feature.set("__styleCache", styleCache, true)
  return styles
}

interface StyleCache {
  previousId: string
  displayedId: string
  previousIdFilter: string
  filterText: string
  filterTextWidth: number
}

type DeviceStyleOptions = {
  color?: string
  borderColor?: string
  geometry?: Geometry
  radius?: number
  strokeWidth?: number
}

type LabelStyleOptions = {
  font?: string
  fillColor?: string
  offsetX?: number
  offsetY?: number
  textAlign?: string
  geometry?: Geometry
}

type PositioningAlgorithm = "CT" | "IPM" | "WCT" | "NPM"

type PartialLocationStyleOptions = {
  algorithmType: PositioningAlgorithm
  geometry: Geometry
}

type DeviceOnMap = "AP" | Source
