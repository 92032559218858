import { Directive } from "@angular/core"
import { MatButtonToggleChange } from "@angular/material/button-toggle"
import { BehaviorSubject } from "rxjs"

@Directive()
export class MapToolReceiverMixin<MapTool> {
  toggledMapTool = new BehaviorSubject<MapTool>(null)

  changeMapTool(event: MatButtonToggleChange): void {
    const toggle = event.source
    let mapTool: MapTool = null
    if (toggle) {
      const group = toggle.buttonToggleGroup
      const toggledTools: MapTool[] = event.value
      if (toggledTools.includes(toggle.value)) {
        group.value = [toggle.value]
        mapTool = toggle.value
      }
    }
    this.toggledMapTool.next(mapTool)
  }
}
