import { APP_INITIALIZER, NgModule } from "@angular/core"
import {
  ApiModule as MipsApiModule,
  Configuration as MipsApiConfiguration,
} from "@openapi/mipsengine"
import { ApiModule as VenueApiModule, Configuration as VenueApiConfiguration } from "@openapi/venue"
import { ConfigService } from "@venue/config"
import * as services from "./services"
import { ApiUrl } from "./services/api-url.service"

const apiGatewayPath = `${window.location.protocol}//${window.location.host}`

// TODO We are no longer running Mips/Sips, this should be removed
const mipsApiConfig = new MipsApiConfiguration({
  basePath: apiGatewayPath + "/sips",
})

@NgModule({
  providers: [
    services.ServerConfig,
    services.AuthTokenService,
    ApiUrl,
    services.ConfigViewService,
    services.TuningHistoryService,
    {
      provide: services.WhitelistService,
      useClass: services.GlobalWhitelistServiceWrapper,
    },
    {
      provide: MipsApiConfiguration,
      useValue: mipsApiConfig,
    },
    {
      provide: VenueApiConfiguration,
      useValue: new VenueApiConfiguration(),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initVsApiConfigFactory,
      deps: [VenueApiConfiguration, ConfigService],
      multi: true,
    },
  ],
  imports: [MipsApiModule, VenueApiModule],
})
export class ApiModule {}

export function initVsApiConfigFactory(apiConfig: VenueApiConfiguration, uiConfig: ConfigService) {
  return () => initVsApiConfig(apiConfig, uiConfig)
}

// Kinda hacky way and relies on internal implementation of generated api,
// not sure if there is another way to provide configuration asynchronously
export async function initVsApiConfig(apiConfig: VenueApiConfiguration, uiConfig: ConfigService) {
  const venueUrl = await uiConfig.venueUrl.toPromise()

  // Remove old base path property
  delete apiConfig.basePath

  // Replace it with getter returning correct value
  Object.defineProperty(apiConfig, "basePath", {
    get: () => venueUrl,
  })
}
