import { CREATE_VENUE_STATE } from "./create-venue.state"
import { EDIT_VENUE_STATE } from "./edit-venue.state"
import { IMPORT_VENUE_STATE } from "./import-venue.state"
import { VENUE_MENU_STATE } from "./venue-menu.state"
import { VENUE_ROOT_STATE } from "./venue-root.state"
import { VENUES_STATE } from "./venues.state"

export const VENUES_STATES = [
  VENUES_STATE,
  VENUE_ROOT_STATE,
  VENUE_MENU_STATE,
  CREATE_VENUE_STATE,
  EDIT_VENUE_STATE,
  IMPORT_VENUE_STATE
]
