import * as tuning from "./"

export const tuningState = {
    name: "tuning",
    url: "/tuning",
    component: tuning.TuningComponent
}

export const autoTuningState = {
    parent: tuningState.name,
    name: "auto-tuning",
    url: "/auto-tuning",
    component: tuning.AutoTuningComponent
}

export const manualTuningState = {
    parent: tuningState.name,
    name: "manual-tuning",
    url: "/manual-tuning",
    component: tuning.ManualTuningComponent
}

export const mobileRecordsState = {
    parent: tuningState.name,
    name: "mobile-records",
    url: "/mobile-records",
    component: tuning.MobileRecordsComponent
}

export const STATES = [ tuningState, autoTuningState, manualTuningState, mobileRecordsState ]
