<mat-toolbar color="primary">
    <h3 class="title">
        <ic-icon [icon]="icon"> </ic-icon>
        <span> {{ 'export-trace-dialog.header' | translate }} </span>
    </h3>
</mat-toolbar>
<div class="content-wrapper">
    <form fxLayout="column"
          [formGroup]="traceMetadataForm">

        <mat-dialog-content fxLayout="column"
                            fxFlex>

            <mat-form-field>
                <mat-label translate>
                    export-trace-dialog.trace-user-input
                </mat-label>
                <input id="trace-user-input"
                       matInput
                       type="text"
                       required
                       formControlName="user">
            </mat-form-field>

            <mat-form-field>
                <mat-label translate>
                    export-trace-dialog.trace-id-input
                </mat-label>
                <input id="trace-id-input"
                       matInput
                       type="text"
                       required
                       formControlName="traceId">
            </mat-form-field>

        </mat-dialog-content>

        <mat-dialog-actions fxLayoutAlign="end">
            <button mat-raised-button
                    type="submit"
                    color="primary"
                    [disabled]="!traceMetadataForm.valid"
                    (click)="onOk()">
                {{ 'export-trace-dialog.export-button' | translate }}
            </button>
            <button mat-button
                    (click)="onCancel()">{{ 'Cancel' | translate }}</button>
        </mat-dialog-actions>

    </form>
</div>
