import { Injectable } from "@angular/core"
import { MediaChange, MediaObserver } from "@angular/flex-layout"
import { Transition, UIRouterGlobals } from "@uirouter/core"
import { VENUES_STATE_NAME } from "@venue/venues/venues-state-names"
import { BehaviorSubject, combineLatest } from "rxjs"
import { map } from "rxjs/operators"

@Injectable()
export class MainSidenavService {
  isForcedOpen = new BehaviorSubject(false)

  constructor(private media: MediaObserver, routerGlobals: UIRouterGlobals) {
    const stateOpened = routerGlobals.success$.pipe(map((t) => this.shouldOpenInState(t)))
    const sizeOpened = media.media$.pipe(map((change) => this.shouldOpenInSize(change)))

    combineLatest([stateOpened, sizeOpened], (a, b) => a && b).subscribe((open) =>
      this.isForcedOpen.next(open)
    )
  }

  private shouldOpenInState(stateTransition: Transition): boolean {
    return stateTransition.$to().name === VENUES_STATE_NAME
  }

  private shouldOpenInSize(mediaChange: MediaChange): boolean {
    return this.media.isActive("gt-md")
  }
}
