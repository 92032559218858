<div fxLayout="column"
     fxLayoutGap="1em">
    <h4 class="header">
        {{ 'manualTuning.header' | translate }}
    </h4>

    <div fxLayout="row wrap"
         fxLayoutGap="1em"
         class="dataset-selector">
        <div fxLayout="column">
            <span class="subsection-header">{{ 'manualTuning.selectConfigPackage' | translate }}</span>

            <mat-radio-group [formControl]="configPackage"
                             fxLayout="column"
                             fxFlex
                             class="config-packages-list">
                <mat-radio-button color="primary"
                                  *ngFor="let p of configPackages"
                                  [value]="p">
                    <span>
                        {{ p.createdAt | datetime }}
                        {{ 'manualTuning.building' | translate }}: {{ p.venueId }}
                        {{ 'manualTuning.version' | translate }}: {{ p.packageVersion | overflow:14 }}
                    </span>
                </mat-radio-button>
            </mat-radio-group>
            <div *ngIf="!configPackages.length">
                {{ 'manualTuning.noPackages' | translate }}
            </div>
        </div>

        <div fxLayout="column"
             fxLayoutGap="1em">
            <span class="subsection-header">{{ 'manualTuning.selectRecords' | translate }}</span>
            <div fxLayout="column"
                 fxFlex
                 class="records-list"
                 *ngIf="packageRecords.length">
                <mat-checkbox color="primary"
                              *ngFor="let r of packageRecords"
                              [disabled]="tuningInProgress.value"
                              (click)="$event.stopPropagation()"
                              (change)="$event ? packageRecordsSelection.toggle(r) : null"
                              [checked]="packageRecordsSelection.isSelected(r)">
                    {{ r.time  | datetime }} floors: {{ r.floors }}
                </mat-checkbox>
            </div>

            <div *ngIf="!packageRecords.length"
                 fxFlex>
                <span>{{ (configPackage.value ? 'manualTuning.noRecords' : 'manualTuning.selectPackageFirst') | translate }}</span>
            </div>

            <button mat-raised-button
                    color="primary"
                    [disabled]="tuningInProgress.value || !packageRecords?.length"
                    (click)="checkAllRecords()">
                {{ (allRecordsSelected() ? 'manualTuning.uncheckAll' : 'manualTuning.checkAll') | translate }}
            </button>
        </div>
    </div>

    <div fxLayout="column"
         fxLayoutGap="1em">
        <span class="subsection-header">{{ 'manualTuning.tuningDuration' | translate }}</span>

        <mat-slide-toggle [formControl]="durationTimeEnabled"
                          color="primary">
            {{ 'manualTuning.durationTime' | translate }}
            {{ (durationTimeEnabled.value ? 'manualTuning.enabled' : 'manualTuning.disabled') | translate }}.
        </mat-slide-toggle>

        <div fxLayout="row"
             fxLayoutGap="1em"
             fxFlex>
            <mat-form-field>
                <mat-label>
                    {{ 'manualTuning.durationTime' | translate }}
                </mat-label>
                <!-- TODO Change max time according to selected unit -->
                <input matInput
                       autocomplete="off"
                       [formControl]="durationTime"
                       type="number"
                       min="1"
                       max="60"
                       required />
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    {{ 'manualTuning.durationUnit' | translate }}
                </mat-label>
                <mat-select [formControl]="durationUnit"
                            required>
                    <!-- TODO Enum! -->
                    <mat-option [value]="1"> {{ 'manualTuning.seconds' | translate }} </mat-option>
                    <mat-option [value]="60"> {{ 'manualTuning.minutes' | translate }} </mat-option>
                    <mat-option [value]="3600"> {{ 'manualTuning.hours' | translate }} </mat-option>
                    <mat-option [value]="86400"> {{ 'manualTuning.days' | translate }} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="tuningInProgress.value || tuningResult">
        <div class="subsection-header">
            <div> {{ 'manualTuning.tuningBestSolution' | translate }} </div>
        </div>
        <div class="best-solution">
            <div>
                <span class="best-solution-header"> {{ 'manualTuning.learningSet' | translate }} </span>{{ bestSolutionLearningSet | tuningSolution }}
            </div>
            <div>
                <span class="best-solution-header">{{ 'manualTuning.verificationSet' | translate }}</span>
                <span *ngIf="tuningResult">
                    {{ 'manualTuning.origin' | translate }} {{ tuningResult.originFitness | tuningSolution }},
                    {{ 'manualTuning.new' | translate }} {{ tuningResult.newFitness | tuningSolution }},
                    {{ 'manualTuning.tuned' | translate }} {{ tuningResult.tunedFitness | tuningSolution }}
                </span>
            </div>
        </div>
    </div>

    <div fxFlex
         fxLayout="column">
        <button mat-raised-button
                color="primary"
                [disabled]="(!tuningInProgress.value && packageRecordsSelection.isEmpty()) || tuningStatusLoading.value"
                (click)="toggleTuning()">
            <span *ngIf="!tuningStatusLoading.value">
                {{ (tuningInProgress.value ? 'manualTuning.submitStop' : 'manualTuning.submitStart') | translate }}
            </span>

            <div *ngIf="tuningStatusLoading.value"
                 fxLayout="column"
                 fxLayoutAlign="start center">
                <mat-spinner color="accent"
                             diameter="20"> </mat-spinner>
            </div>
        </button>
    </div>

    <div fxFlex
         fxLayout="column">
        <button mat-raised-button
                color="primary"
                [disabled]="tuningInProgress.value || tuningStatusLoading.value || packageRecordsSelection.isEmpty() || loadingModelVerification.value"
                (click)="showPositioningModel()">
            <span *ngIf="!loadingModelVerification.value">
                {{ 'manualTuning.positioningModelVerification' | translate }}
            </span>

            <div *ngIf="loadingModelVerification.value"
                 fxLayout="column"
                 fxLayoutAlign="start center">
                <mat-spinner color="accent"
                             diameter="20"> </mat-spinner>
            </div>
        </button>
    </div>
</div>
