import { VENUE_ROOT_STATE_NAME } from "@venue/venues/venues-state-names"
import { EditorComponent } from "../components/editor/editor.component"
import { EDITOR_STATE_NAME } from "../editor.state-names"

export const EDITOR_STATE = {
  parent: VENUE_ROOT_STATE_NAME,
  name: EDITOR_STATE_NAME,
  // Note: /floors/:floorId would be better here, instead of adding /:floorId to substates,
  //       but that will cause reload of this state. Venue is not ready for it.
  url: "/floors",
  component: EditorComponent,
}
