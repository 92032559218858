import { Component, Input, OnInit, Output } from "@angular/core"
import { Subject } from "rxjs"

@Component({
  selector: "access-points-sidebar",
  templateUrl: "./access-points-sidebar.component.html",
})
export class AccessPointsSidebarComponent implements OnInit {
  @Input() saveDisabled?: boolean
  @Input() importDisabled?: boolean

  @Output() submit = new Subject<void>()
  @Output() import = new Subject<void>()

  constructor() {}

  ngOnInit() {}
}
