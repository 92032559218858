import { NgModule } from "@angular/core"
import { MapsModule } from "@venue/maps"
import { SharedModule } from "@venue/shared"
import * as m from "."

@NgModule({
  imports: [
    SharedModule,
    MapsModule,
  ],
  declarations: [
    m.PackageUploaderComponent,
    m.FloorsMapperComponent
  ],
  exports: [
    m.PackageUploaderComponent,
    m.FloorsMapperComponent
  ]
})
export class ImportModule {}
