<section fxLayout="column"
         fxLayoutGap="0.5rem"
         fxFlex>
    <floor-select>
    </floor-select>

    <mat-form-field>
        <mat-label>{{ 'cs-config-model.width' | translate }}</mat-label>
        <input matInput
               type="number"
               step="0.01"
               min="0.01"
               required
               [formControl]="widthForm">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'cs-config-model.length' | translate }}</mat-label>
        <input matInput
               type="number"
               step="0.01"
               min="0.01"
               required
               [formControl]="lengthForm">
    </mat-form-field>

    <button mat-raised-button
            color="primary"
            matTooltip="{{ ( submitDisabled ? ( 'no_permissions_msg' | translate ) : '' ) }}"
            [disabled]="submitDisabled || (!widthForm.disabled && !widthForm.valid) || (!lengthForm.disabled && !lengthForm.valid)"
            type="submit"
            (click)="submit.next()">{{ 'Save' | translate }}</button>
</section>
