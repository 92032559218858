import { Injectable } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { ImageProcessingService } from "@openapi/venue"
import { ImageTransforms } from "@venue/components/floor/floormap/transformations/ImageTransforms"
import { CurrentFloor } from "@venue/core"
import { ProgressDialog, ProgressDialogData } from "@venue/shared"
import { mat3 } from "gl-matrix"
import { Observable } from "rxjs"
import { finalize, takeUntil } from "rxjs/operators"

@Injectable()
export class AutoAdjustHelperService {
  constructor(
    private currentFloor: CurrentFloor,
    private imageProcessing: ImageProcessingService,
    private dialog: MatDialog
  ) {}

  /**
   * Auto adjust image by calling image processing endpoints.
   *
   * This will:
   * - open progress dialog
   * - call image processing match endpoint
   * - update image transforms
   * - close progress dialog
   *
   * @param transforms  - image transforms to update
   * @param prodImage   - flag to choose match endpoint,
   *                      prod image match if true,
   *                      adjust image match otherwise
   * @param unsubscribe - observable used to stop the request
   */
  // TODO Error handling
  autoAdjustImage(
    transforms: ImageTransforms,
    prodImage: boolean,
    unsubscribe: Observable<any>
  ): void {
    const progressDialog = this.openAutoAdjustProgressDialog()

    const floorId = this.currentFloor.getFloor().id

    const req = prodImage
      ? this.imageProcessing.matchFloorImagesProd(floorId)
      : this.imageProcessing.matchFloorImages(floorId)

    req
      .pipe(
        takeUntil(unsubscribe),
        finalize(() => progressDialog.close())
      )
      .subscribe((data) => {
        transforms.setTransform(data.transform as any as mat3) // Not really mat3, but works
      })
  }

  /**
   * Open ProgressDialog for auto adjust operation
   */
  private openAutoAdjustProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "floor.second_setup.adjust_image.progress_text",
    }

    return this.dialog.open(ProgressDialog, { data })
  }
}
