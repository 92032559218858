import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core"
import { MatSnackBar } from "@angular/material/snack-bar"
import { MatTableDataSource } from "@angular/material/table"
import mdiContentSaveAll from "@iconify/icons-mdi/content-save-all"
import mdiDelete from "@iconify/icons-mdi/delete"
import mdiPlus from "@iconify/icons-mdi/plus"
import { TranslateService } from "@ngx-translate/core"
import {
  CommitImportInstruction,
  CommitImportInstructionActionEnum,
  Floor,
  FloorsService,
  ImportService,
  PackageFloor,
} from "@openapi/venue"
import { CurrentVenue } from "@venue/core"
import { withUnsubscribe } from "@venue/shared"
import { Dictionary } from "lodash"
import { BehaviorSubject, Observable, zip } from "rxjs"
import { delay, filter, finalize, shareReplay, switchMap, takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "floors-mapper",
  templateUrl: "./floors-mapper.component.html",
})
export class FloorsMapperComponent implements OnInit, OnChanges {
  private unsubscribe: Observable<any>
  private venueFloors: Observable<Floor[]>

  @ViewChild("mappingArea") private mappingArea: ElementRef

  packageFloors: Observable<PackageFloor[]>
  venueFloorsNames: Dictionary<string>
  dataSource = new MatTableDataSource<CommitImportInstruction>([])
  displayedColumns = ["venueFloorName", "packageFloorName", "removeRow"]

  readonly addRowIcon = mdiPlus
  readonly deleteRowIcon = mdiDelete
  readonly commitIcon = mdiContentSaveAll

  @Input() importSessionId: number
  @Output() commitEvent: EventEmitter<CommitImportInstruction[]> = new EventEmitter()

  private importSessionIdSubject = new BehaviorSubject<number>(null)

  /**
   * Disables loading overlay
   */
  loading = true

  constructor(
    private currentVenue: CurrentVenue,
    private importService: ImportService,
    private floorsService: FloorsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.venueFloors = this.currentVenue.venue.pipe(
      switchMap((v) => this.floorsService.getFloorsUnpaged(v.id)),
      shareReplay(1)
    )
    this.packageFloors = this.importSessionIdSubject.pipe(
      filter((sid) => !!sid),
      switchMap((sid) => this.importService.getPackageFloors(sid)),
      shareReplay(1)
    )
  }

  ngOnChanges(): void {
    this.importSessionIdSubject.next(this.importSessionId)

    zip(this.venueFloors, this.packageFloors)
      .pipe(
        delay(500), // Delay 500ms to not instantly blink with the loading overlay
        takeUntil(this.unsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe({
        next: ([venueFloors, pckFloors]: [Floor[], PackageFloor[]]) => {
          const fittedPckFloors = this.appendDoubleComparisons(venueFloors, pckFloors)
          const leftoverPckFloors = this.extractLeftoverFloors(pckFloors, fittedPckFloors)
          this.appendSingleComparisons(leftoverPckFloors)

          this.extractVenueFloorsNames(venueFloors)

          this.loading = false
        },
        error: () => {
          this.translate.stream("import-venue.floors-map.error-message").subscribe((msg) => {
            this.snackBar.open(msg, null, {
              duration: 3000, // Delay 3000ms to not instantly blink with the snack bar
            })
          })
        },
      })
  }

  addMapping(): void {
    let newFloorComparison: CommitImportInstruction = {
      venueFloorSlug: undefined,
      packageFloorSlug: undefined,
      action: CommitImportInstructionActionEnum.Create,
    }

    this.dataSource.data = [...this.dataSource.data, newFloorComparison]

    this.mappingArea.nativeElement.scrollTop = this.mappingArea.nativeElement.scrollHeight
  }

  commit(): void {
    this.commitEvent.emit(this.dataSource.data)
  }

  getFloorName(floorSlug: string): string {
    return this.venueFloorsNames[floorSlug]
  }

  removeMapping(floorComparison: CommitImportInstruction): void {
    let tempData = this.dataSource.data
    tempData.splice(tempData.indexOf(floorComparison), 1)
    this.dataSource.data = tempData
  }

  private appendDoubleComparisons(venueFloors: Floor[], pckFloors: PackageFloor[]): string[] {
    let fittedPckFloors: string[] = []

    venueFloors.forEach((vF) => {
      let pckFloor = pckFloors.find((pF) => pF.slug === vF.slug)

      let newFloorComparison: CommitImportInstruction = {
        venueFloorSlug: vF.slug,
        packageFloorSlug: pckFloor ? pckFloor.slug : undefined,
        action: CommitImportInstructionActionEnum.Replace,
      }

      fittedPckFloors.push(newFloorComparison.packageFloorSlug)
      this.dataSource.data = [...this.dataSource.data, newFloorComparison]
    })

    return fittedPckFloors
  }

  private appendSingleComparisons(pckFloors: PackageFloor[]): void {
    pckFloors.forEach((pF) => {
      let newFloorComparison: CommitImportInstruction = {
        venueFloorSlug: undefined,
        packageFloorSlug: pF.slug,
        action: CommitImportInstructionActionEnum.Create,
      }

      this.dataSource.data = [...this.dataSource.data, newFloorComparison]
    })
  }

  private extractVenueFloorsNames(venueFloors: Floor[]): void {
    venueFloors.forEach((vF) => {
      this.venueFloorsNames = { ...this.venueFloorsNames, [vF.slug]: vF.name }
    })
  }

  private extractLeftoverFloors(
    pckFloors: PackageFloor[],
    fittedPckFloors: string[]
  ): PackageFloor[] {
    var leftoverPckFloors = pckFloors.map((x) => Object.assign({}, x))

    fittedPckFloors.forEach((fittedPckSlug) => {
      const spliceIdx = leftoverPckFloors.findIndex((pckFloor) => pckFloor.slug === fittedPckSlug)

      if (spliceIdx != -1) {
        leftoverPckFloors.splice(spliceIdx, 1)
      }
    })

    return leftoverPckFloors
  }
}
