import { Component } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import mdiServer from "@iconify/icons-mdi/server"
import { TranslateService } from "@ngx-translate/core"
import { BuildingsService } from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { AuthTokenService, ServerConfig } from "@venue/api"
import { AppConstants } from "@venue/app.constants"

@Component({
  templateUrl: "./server-selection.component.html",
})
export class ServerSelectionComponent {
  readonly serverIcon = mdiServer

  server = new FormControl(null, Validators.required)
  serverSelectForm = new FormGroup({
    server: this.server,
  })

  languageSelectorEnabled: boolean

  constructor(
    private translate: TranslateService,
    private venues: BuildingsService,
    private state: StateService,
    private serverConfig: ServerConfig,
    private authToken: AuthTokenService
  ) {
    this.languageSelectorEnabled = AppConstants.dev || AppConstants.flags.quickLanguageSelection
  }

  setLanguage(lang: string): void {
    if (AppConstants.flags.quickLanguageSelection) {
      this.translate.use(lang)
      localStorage.setItem("language", lang)
    }
  }

  connect(): void {
    this.selectServer(this.server.value)
    this.changeAuthToken(this.server.value)

    this.venues.getBuildings(0, 1).subscribe(
      () => this.state.go("venues"),
      (error) => this.state.go("login")
    )
  }

  private selectServer(server: ServerConfig): void {
    Object.assign(this.serverConfig, server)
  }

  private changeAuthToken(server: ServerConfig): void {
    const tokens = JSON.parse(localStorage.getItem("tokens")) || {}
    const serverToken = tokens[server.name]
    if (serverToken) {
      this.authToken.setToken(serverToken)
      console.log("restoring server token")
    }
  }
}
