import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { MatSnackBar } from "@angular/material/snack-bar"
import { TranslateService } from "@ngx-translate/core"
import { FloorCSConfigsService } from "@openapi/venue"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import { CurrentFloor } from "@venue/core"
import { ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { BehaviorSubject, Observable, of } from "rxjs"
import { catchError, filter, finalize, map, switchMap, takeUntil, tap } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "floor-ips-image",
  templateUrl: "./floor-ips-image.component.html",
})
export class FloorIpsImageComponent implements OnInit, AfterViewInit, OnDestroy {
  imageForm = this.fb.control(null)
  @ViewChild("imageInput") imageInput: ElementRef<HTMLInputElement>

  /**
   * Flag indicating that user selected new image. Unblocks save button.
   */
  imageChanged = false

  image: Blob
  imageUrl: string

  loading = new BehaviorSubject(true)

  private unsubscribe: Observable<void>

  readonly WRITE_USER_ROLE = "venue:floorcs:write"

  constructor(
    private fb: FormBuilder,
    private currentFloor: CurrentFloor,
    private floorCSConfigs: FloorCSConfigsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private authUtilService: AuthUtilService
  ) {}

  ngOnInit(): void {
    this.currentFloor.floor
      .pipe(
        filter((f) => !!f),
        tap(() => this.loading.next(true)),
        switchMap((f) =>
          this.floorCSConfigs.getFloorIpsImage(f.id).pipe(catchError((err) => of(null)))
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe((img) => this.loadImage(img))
  }

  ngAfterViewInit(): void {
    this.imageForm.valueChanges
      .pipe(
        map(() => this.imageInput.nativeElement.files[0]),
        takeUntil(this.unsubscribe)
      )
      .subscribe((imageFile) => {
        this.loadImage(imageFile)
        this.imageChanged = true
      })
  }

  ngOnDestroy(): void {
    this.cleanupImageUrl()
  }

  private cleanupImageUrl(): void {
    if (this.imageUrl) {
      URL.revokeObjectURL(this.imageUrl)
      this.imageUrl = null
    }
  }

  loadImage(img: Blob): void {
    this.cleanupImageUrl()

    this.image = img

    if (img) {
      this.imageUrl = URL.createObjectURL(img)
    }

    this.loading.next(false)
  }

  save(): void {
    const progressDialog = this.saveProgressDialog()

    this.floorCSConfigs
      .uploadFloorIpsImage(this.currentFloor.getFloor().id, this.image)
      .pipe(
        map(() => true /* success */),
        catchError(() => of(false /* error */)),
        map((success) =>
          success
            ? ([true, "floor-ips-image.save.success"] as const)
            : ([false, "floor-ips-image.save.error"] as const)
        ),
        switchMap(([success, msgKey]) => this.translate.get(msgKey).pipe(map((m) => [success, m]))),
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      .subscribe(([success, msg]) => {
        if (success) {
          this.imageChanged = false
        }

        this.snackBar.open(msg, "", { duration: 5 * 1000 })
      })
  }

  /**
   * Check if user has permissions to perform CRUD operations.
   */
  hasUserWritePermissions(): boolean {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  private saveProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "floor-ips-image.save.in-progress-msg",
    }

    return this.dialog.open(ProgressDialog, { data })
  }
}
