import { Component } from "@angular/core"
import { FormBuilder, Validators } from "@angular/forms"
import { MatDialogRef } from "@angular/material/dialog"
import mdiServer from "@iconify/icons-mdi/server"

@Component({
  selector: "add-server-dialog",
  templateUrl: "./add-server.dialog.html",
})
export class AddServerDialog {
  readonly icon = mdiServer

  readonly protocols = ["http", "https"]

  addServerForm = this.fb.group({
    name: [""],
    protocol: ["https"],
    host: [""],
    port: [8765, [Validators.max(65535), Validators.min(1)]],
  })

  constructor(private dialogRef: MatDialogRef<AddServerDialog>, private fb: FormBuilder) {
    dialogRef.addPanelClass("dialog-with-toolbar")
  }

  onOk(): void {
    this.dialogRef.close(this.addServerForm.value)
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onCancel(): void {
    this.dialogRef.close()
  }
}
