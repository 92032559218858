<mat-toolbar color="primary"
             layout="row">
    <h3 class="title">
        <ic-icon [icon]="icon"> </ic-icon>
        {{ 'tuning_history.details_dialog.title' | translate }}
    </h3>
</mat-toolbar>
<div class="content-wrapper">
    <mat-dialog-content fxLayout="column">
        <json-formatter [json]="data"
                        [open]="2"></json-formatter>
    </mat-dialog-content>
    <mat-dialog-actions>
        <span fxFlex></span>
        <button mat-raised-button
                color="primary"
                (click)="onClose()">{{ 'Close' | translate }}</button>
    </mat-dialog-actions>
</div>
