import "@venue/definitions/globals"
import "@venue/polyfills"

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import { NgZone } from "@angular/core"

import { AppModule } from "./app/app.module"

import { UIRouter, UrlService } from "@uirouter/core"
import { UIRouterRx } from "@uirouter/rx"

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((platformRef) => {})
  .catch((e) => console.error(e))
