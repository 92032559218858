import { Component } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { AppConstants } from "@venue/app.constants"

const LANGUAGE_STORE_KEY = "language"

@Component({
  templateUrl: "./profile.component.html",
})
export class ProfileComponent {
  language = AppConstants.defaultLanguage
  languages = ["en", "ko", "pl"]

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    // XXX Extract getting and setting language to separate service
    this.language = localStorage.getItem(LANGUAGE_STORE_KEY) || this.language
  }

  changeLanguage(): void {
    this.translate.use(this.language)
    localStorage.setItem(LANGUAGE_STORE_KEY, this.language)
  }
}
