/**
 * Venue Service Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Structure containing floor slugs and assigned action to perform during import.
 */
export interface CommitImportInstruction { 
    /**
     * Action that should be performed when importing the floors` configuration.  If \"replace\" action is set, the venueFloorSlug field must be present.
     */
    action: CommitImportInstructionActionEnum;
    /**
     * Slug of floor that is to be replaced  If \"replace\" action is set, the field must be present.
     */
    venueFloorSlug?: string;
    /**
     * Slug of floor that is to be imported
     */
    packageFloorSlug: string;
}
export enum CommitImportInstructionActionEnum {
    Create = 'create',
    Replace = 'replace'
};



