interface Params {
    [key: string]: any
}

export function toParams(...objects: any): Params {
    const v: Params = {}
    for (const obj of objects) {
        for (const key in obj) {
            if (obj[key] != null) {
                v[key] = obj[key].toString()
            }
        }
    }
    return v
}
