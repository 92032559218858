import { Component, Host, OnInit, OnDestroy, } from "@angular/core"
import { OpenlayersMapComponent } from ".";
// @ts-ignore
import LayerSwitcher from '@lib/ol-layerswitcher/ol-layerswitcher'
import Control from "ol/control/Control";

// XXX Consider changing to directive
@Component({
    selector: "ol-layerswitcher",
    template: ""
})
export class OlLayerswitcherComponent implements OnInit, OnDestroy {

    private layerSwitcher = new LayerSwitcher({}) as any as Control

    constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

    ngOnInit(): void {
        this.mapComponent.map.addControl(this.layerSwitcher)
    }

    ngOnDestroy(): void {
        this.mapComponent.map.removeControl(this.layerSwitcher)
    }
}
