<div fxLayout="column"
     fxFlex>
    <mat-tab-group #editorTabs
                   color="accent"
                   animationDuration="0ms"
                   (selectedTabChange)="tabChanged($event)">
        <mat-tab *ngFor="let tab of tabs"
                 [label]="'editor-tabs.' + tab | translate">
        </mat-tab>
    </mat-tab-group>

    <ui-view class="fill-height"
             fxLayout="column"
             fxFlex></ui-view>
</div>
