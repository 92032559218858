import { HttpErrorResponse } from "@angular/common/http"
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { FormBuilder, Validators } from "@angular/forms"
import { MatSnackBar } from "@angular/material/snack-bar"
import { DomSanitizer } from "@angular/platform-browser"
import { Floor, FloorsService } from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { toBlobWithDim } from "@venue/api"
import { CurrentFloor } from "@venue/core"
import { FLOOR_LIST_STATE_NAME } from "@venue/floor/floor.state-names"
import { withUnsubscribe } from "@venue/shared"
import { cloneDeep } from "lodash"
import { Observable, of, throwError } from "rxjs"
import { catchError, filter, finalize, first, map, switchMap, takeUntil } from "rxjs/operators"

enum FloorFormMode {
  CREATE = "create",
  UPDATE = "update",
}

@withUnsubscribe
@Component({
  selector: "floor-form",
  templateUrl: "./floor-form.component.html",
})
export class FloorFormComponent implements OnInit, AfterViewInit, OnDestroy {
  mode: FloorFormMode
  loading = true

  form = this.fb.group({
    // Required, but should not be set by the user
    buildingId: [null, [Validators.required]],
    name: ["", [Validators.required]],
    slug: ["", [Validators.required, Validators.pattern("[0-9a-z-]+")]],
    level: [0, [Validators.required]], // TODO Min / Max ?
    levelHeight: [1, [Validators.required, Validators.min(1)]], // TODO Max ?
  })

  image: Blob
  imageObjectUrl: string
  imageChanged = false

  imageForm = this.fb.control(null)
  @ViewChild("imageInput") imageInput: ElementRef<HTMLInputElement>

  private floorId: number = null

  private unsubscribe: Observable<void>

  constructor(
    public domSanitizer: DomSanitizer,
    private fb: FormBuilder,
    private floors: FloorsService,
    private state: StateService,
    private currentFloor: CurrentFloor,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    let buildingId = Number.parseInt(this.state.params.venueId)
    let floorId = this.state.params.floorId

    this.form.patchValue({ buildingId })

    if (floorId === undefined) {
      this.mode = FloorFormMode.CREATE
      this.loading = false
    } else {
      this.floorId = Number.parseInt(floorId)
      this.mode = FloorFormMode.UPDATE

      // When current floor is set, patch the update form and turn the loading off
      this.currentFloor.floor
        .pipe(
          filter((floor) => !!floor),
          // Load image before enabling the form
          switchMap((floor) =>
            this.floors.getFloorProdImage(floor.id, null, null, null, "response").pipe(
              map(toBlobWithDim),
              map(([img, imgDim]) => img),
              catchError((err: HttpErrorResponse) => {
                if (err.status == 404) {
                  // Its fine - just there is no image
                  return of(null as Blob)
                }

                // Other error - rethrow
                return throwError(err)
              }),
              map((img) => [floor, img] as [Floor, Blob])
            )
          ),
          first(),
          takeUntil(this.unsubscribe),
          finalize(() => (this.loading = false))
        )
        .subscribe(([floor, img]) => {
          this.loadFloor(floor)
          this.loadImage(img)
        })

      // Set CurrentFloor - if we introduce intermediate floor-root step then this can be done in such state
      this.floors
        .getFloor(this.floorId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((floor) => this.currentFloor.setFloor(floor))
    }
  }

  ngAfterViewInit(): void {
    // Load image data and update preview when user chooses image file
    this.imageForm.valueChanges
      .pipe(
        map(() => this.imageInput.nativeElement.files[0]),
        takeUntil(this.unsubscribe)
      )
      .subscribe((imageFile) => {
        this.loadImage(imageFile)
        this.imageChanged = true
      })
  }

  ngOnDestroy(): void {
    this.currentFloor.setFloor(null)

    if (this.imageObjectUrl) {
      URL.revokeObjectURL(this.imageObjectUrl)
    }
  }

  save(): void {
    let floor = {} as Floor

    if (this.mode == FloorFormMode.UPDATE) {
      floor = cloneDeep(this.currentFloor.getFloor())
    }
    Object.assign(floor, this.form.value)

    this.loading = true

    let request = this.mode == FloorFormMode.CREATE ? this.create(floor) : this.update(floor)

    request
      .pipe(
        catchError((err) => throwError(new FloorSaveError(err))),
        switchMap((floor) => {
          // If image changed then save it
          if (this.imageChanged) {
            return this.floors.uploadFloorProdImage(floor.id, this.image).pipe(
              catchError((err) => {
                let imgError = new ImageUploadError(err)

                // In create mode on image upload error - remove the saved floor and rethrow
                if (this.mode === FloorFormMode.CREATE) {
                  return this.floors
                    .deleteFloor(floor.id)
                    .pipe(switchMap((v) => throwError(imgError)))
                }

                // Otherwise, in update mode, just rethrow
                return throwError(imgError)
              }),
              map((v) => floor)
            )
          }

          return of(floor)
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (floor) => {
          // Redirect to floors
          this.state.go(FLOOR_LIST_STATE_NAME)
        },
        (error) => {
          let create = this.mode === FloorFormMode.CREATE
          console.error("Error creating floor", error)

          // TODO Translate
          this.snackBar.open(error.message, "", { duration: 5 * 1000 })
        }
      )
  }

  private loadFloor(floor: Floor): void {
    this.form.patchValue(floor)
  }

  private loadImage(img: Blob): void {
    if (this.imageObjectUrl) {
      URL.revokeObjectURL(this.imageObjectUrl)
      this.imageObjectUrl = null
    }

    this.image = img || null

    if (this.image) {
      this.imageObjectUrl = URL.createObjectURL(this.image)
    }
  }

  private create(floor: Floor): Observable<Floor> {
    return this.floors.createFloor(floor)
  }

  private update(floor: Floor): Observable<Floor> {
    floor.id = this.floorId

    return this.floors.updateFloor(floor.id, floor)
  }
}

class FloorSaveError extends Error {
  constructor(public sourceError: Error) {
    super("Failed to save floor data")
  }
}

class ImageUploadError extends Error {
  constructor(public sourceError: Error) {
    super("Failed to upload image")
  }
}
