<loading-overlay *ngIf="loading | async"></loading-overlay>
<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">
        <section class="sidebar"
                 fxLayout="column"
                 fxLayoutGap="0.5rem"
                 fxFlex>
            <floor-select>
            </floor-select>

            <input #imageInput
                   type="file"
                   hidden="true"
                   accept="image/*"
                   [formControl]="imageForm">
            <button class="select-image-button"
                    matTooltip="{{ ( hasUserWritePermissions() ? '' : ( 'no_permissions_msg' | translate ) ) }}"
                    mat-raised-button
                    color="accent"
                    (click)="imageInput.click()"
                    [disabled]="!hasUserWritePermissions()">
                {{ 'floor-ips-image.select-image' | translate }}
            </button>

            <button mat-raised-button
                    matTooltip="{{ ( hasUserWritePermissions() ? '' : ( 'no_permissions_msg' | translate ) ) }}"
                    color="primary"
                    type="submit"
                    [disabled]="!imageChanged"
                    (click)="save()">{{ 'Save' | translate }}</button>
        </section>
    </mat-sidenav>
    <mat-sidenav-content>
        <openlayers-map fxFlex>
            <image-layer [imageUrl]="imageUrl">
            </image-layer>
        </openlayers-map>
    </mat-sidenav-content>
</mat-sidenav-container>
