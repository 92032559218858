<div class="fill-parent"
     fxLayout="column">
    <mat-toolbar>
        {{ 'recordManagement.header' | translate }}
        <span fxFlex></span>
        <input type="file"
               accept=".record"
               [hidden]="true"
               [formControl]="recordFiles"
               multiple="true"
               id="recordsInput" />
        <button mat-button
                [disabled]="uploadInProgress"
                (click)="selectRecordFiles()"
                matTooltip="{{ 'recordManagement.uploadFile' | translate }}">
            <ic-icon [icon]="uploadRecordIcon"> </ic-icon>
        </button>
        <button mat-button
                (click)="removeRecords()"
                [disabled]="recordsDataSource?.selection.isEmpty()"
                matTooltip="{{ 'recordManagement.Delete' | translate }}">
            <ic-icon [icon]="deleteRecordIcon"> </ic-icon>
        </button>
    </mat-toolbar>
    <!-- TODO Show upload progress using flow.js? -->
    <mat-progress-bar *ngIf="uploadInProgress"
                      mode="indeterminate"></mat-progress-bar>

    <div fxLayout="column">
        <table mat-table
               matSort
               [dataSource]="recordsDataSource"
               fxFlex>
            <ng-container matColumnDef="select">
                <th mat-header-cell
                    *matHeaderCellDef
                    style="width: 6em;">
                    <mat-checkbox (change)="$event ? recordsDataSource.toggleAllSelected() : null"
                                  [checked]="recordsDataSource?.selection.hasValue() && recordsDataSource.isAllSelected()"
                                  [indeterminate]="recordsDataSource?.selection.hasValue() && !recordsDataSource.isAllSelected()">

                    </mat-checkbox>
                </th>
                <td mat-cell
                    *matCellDef="let entry">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? recordsDataSource?.selection.toggle(entry) : null"
                                  [checked]="recordsDataSource?.selection.isSelected(entry)">

                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="time">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header> {{ 'recordManagement.Time' | translate }} </th>
                <td mat-cell
                    *matCellDef="let record"> {{ record.time | datetime }} </td>
            </ng-container>
            <ng-container matColumnDef="version">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header> {{ 'recordManagement.Version' | translate }} </th>
                <td mat-cell
                    *matCellDef="let record"
                    matTooltip="{{ record.version }}"
                    matTooltipClass="record-version-tooltip"> {{ record.version }} </td>
            </ng-container>
            <ng-container matColumnDef="floors">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header> {{ 'recordManagement.Floors' | translate }} </th>
                <td mat-cell
                    *matCellDef="let record"> {{ record.floors }} </td>
            </ng-container>
            <ng-container matColumnDef="download">
                <th mat-header-cell
                    *matHeaderCellDef> {{ 'recordManagement.Download' | translate }} </th>
                <td mat-cell
                    *matCellDef="let record">
                    <button mat-raised-button
                            color="primary"
                            (click)="downloadRecord(record, $event)"
                            matTooltip="{{ 'recordManagement.Download' | translate }}">
                        <ic-icon [icon]="downloadRecordIcon"> </ic-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="recordsDataSource?.selection.toggle(row)"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                       showFirstLastButtons></mat-paginator>
    </div>
</div>
