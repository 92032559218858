<input #fileInput
    type="file"
    hidden="true"
    [accept]="accept"
    [formControl]="fileForm">
<button *ngIf="iconButton"
        mat-icon-button
        color="primary"
        (click)="fileInput.click()"
        [matTooltip]="tooltip">
    <ng-content></ng-content>
</button>
<button *ngIf="!iconButton"
        mat-raised-button
        color="primary"
        (click)="fileInput.click()"
        [matTooltip]="tooltip">
    <ng-content></ng-content>
</button>
