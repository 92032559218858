export enum TuningIntervalType {
    DAILY = "DAILY",
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
    ONLY_ONCE = "ONLY_ONCE"
}

export interface AutoTuningSettings {
    tuningRepeat: TuningIntervalType
    startTime: string
    tuningDuration: number
    status: boolean
    createdTime: string // TODO this is not needed on front - remove? or maybe use but change to date ??
}
