export enum ResultCode {
    PARENT_BEST = "PARENT_BEST",
    OLD_TUNED_BEST = "OLD_TUNED_BEST",
    NEW_TUNED_BEST = "NEW_TUNED_BEST"
}

export enum TuningType {
    AUTO = "AUTO",
    MANUAL = "MANUAL"
}

export enum TuningPackageScoreSource {
    PARENT = "PARENT",
    PREVIOUSLY_TUNED = "PREVIOUSLY_TUNED",

    AUTO_REGRESSION = "AUTO_REGRESSION",
    AUTO_REDUCED_VARIABLE_NEIGHBOURHOOD_SEARCH = "AUTO_REDUCED_VARIABLE_NEIGHBOURHOOD_SEARCH",

    MANUAL_REDUCED_VARIABLE_NEIGHBOURHOOD_SEARCH = "MANUAL_REDUCED_VARIABLE_NEIGHBOURHOOD_SEARCH"
}

export interface TuningResult {
    result: ResultCode
    type: TuningType
    score: number
}
