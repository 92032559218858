<div class="server-select-view"
     fxFlex>
    <mat-card class="server-card">
        <mat-card-header>
            <mat-card-title fxLayoutAlign="start center">
                <ic-icon [icon]="serverIcon"> </ic-icon>{{'serverselect.title' | translate }}
            </mat-card-title>
            <span fxFlex></span>
            <!-- TODO Move to header or somewhere else -->
            <div class="language-selector"
                 *ngIf="languageSelectorEnabled">
                <img (click)="setLanguage('en')"
                     class="flag flag-us"
                     src="assets/images/blank.gif"
                     alt="" />
                <img (click)="setLanguage('ko')"
                     class="flag flag-kr"
                     src="assets/images/blank.gif"
                     alt="" />
            </div>
        </mat-card-header>

        <mat-card-content fxLayoutAlign="center center">
            <form [formGroup]="serverSelectForm"
                  (ngSubmit)="connect()"
                  fxLayout="column"
                  fxFlex>
                <server-select [serverFormControl]="server"></server-select>
                <button mat-raised-button
                        color="primary"
                        class="connect-button"
                        type="submit"
                        [disabled]="!serverSelectForm.valid">
                    {{ 'serverselect.connect_button' | translate }}
                </button>
            </form>
        </mat-card-content>
    </mat-card>
</div>
