import { NgModule } from '@angular/core'

import { UIRouterModule } from "@uirouter/angular"

import * as serverSelection from './'
import { SharedModule } from '../shared'

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: serverSelection.SERVER_SELECT_STATES }),
        SharedModule
    ],
    declarations: [
        serverSelection.ServerSelectComponent,
        serverSelection.ServerSelectionComponent,
        serverSelection.AddServerDialog
    ]
})
export class ServerSelectionModule {}
