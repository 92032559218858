import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import mdiFileDownloadOutline from "@iconify/icons-mdi/file-download-outline"
import mdiFileOutline from "@iconify/icons-mdi/file-outline"
import mdiVectorPolyline from "@iconify/icons-mdi/vector-polyline"
import { FloorsService, IpsTracesService, TraceMetadata } from "@openapi/venue"
import { StateService, UIRouter } from "@uirouter/core"
import { CurrentFloor, CurrentVenue } from "@venue/core"
import { downloadBlob, ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { TRACE_PATH_FILE_STATE_NAME, TRACE_PATH_STATE_NAME } from "@venue/traces/traces.state-names"
import { BehaviorSubject, Observable, of } from "rxjs"
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators"
import { TracesDataSource } from "./traces.data-source"

@withUnsubscribe
@Component({
  selector: "traces-list",
  templateUrl: "./traces-list.component.html",
})
export class TracesListComponent implements OnInit, AfterViewInit {
  readonly downloadTraceIcon = mdiFileDownloadOutline
  readonly tracePathIcon = mdiVectorPolyline
  readonly selectTraceIcon = mdiFileOutline

  dataSource: TracesDataSource

  loading: Observable<boolean>

  readonly displayedColumns = ["traceId", "user", "createdAt", "download", "tracePath"]

  userFilter = this.fb.control("")

  userAutocompleteOpts = new BehaviorSubject<string[]>([])
  userAutocompleteFetchInProgress = new BehaviorSubject(false)

  selectTraceForm = this.fb.control(null)
  @ViewChild("traceInput") traceInput: ElementRef<HTMLInputElement>

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  private unsubscribe: Observable<any>

  constructor(
    private currentVenue: CurrentVenue,
    private currentFloor: CurrentFloor,
    private floors: FloorsService,
    private traces: IpsTracesService,
    private uiRouter: UIRouter,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private state: StateService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Set CurrentFloor - if we introduce intermediate floor-root step then this can be done in such state
    this.uiRouter.globals.params$
      .pipe(
        map((params) => Number.parseInt(params.floorId)),
        switchMap((floorId) => this.floors.getFloor(floorId)),
        takeUntil(this.unsubscribe),
        finalize(() => this.currentFloor.setFloor(null))
      )
      .subscribe((floor) => this.currentFloor.setFloor(floor))

    this.userFilter.valueChanges
      .pipe(
        startWith(""),
        pairwise(),
        distinctUntilChanged(),
        filter(([o, n]) => o !== n),
        tap(() => this.userAutocompleteFetchInProgress.next(true)),
        debounceTime(100),
        switchMap((vals) => this.fetchUserAutocomplete(vals).pipe(catchError((err) => of([])))),
        takeUntil(this.unsubscribe)
      )
      .subscribe((users) => {
        this.userAutocompleteOpts.next(users)
        this.userAutocompleteFetchInProgress.next(false)
      })

    // After user selects trace file, open path editor with path from it
    this.selectTraceForm.valueChanges
      .pipe(
        map(() => this.traceInput.nativeElement.files[0]),
        takeUntil(this.unsubscribe)
      )
      .subscribe((traceFile) => {
        this.state.go(TRACE_PATH_FILE_STATE_NAME, {
          floorId: this.state.params.floorId,
          traceFile,
        })
      })
  }

  ngAfterViewInit(): void {
    this.dataSource = new TracesDataSource(
      this.currentVenue,
      this.currentFloor,
      this.traces,
      this.userFilter.valueChanges.pipe(startWith(this.userFilter.value), distinctUntilChanged()),
      this.paginator,
      this.sort
    )

    this.loading = this.dataSource.loading

    this.cdRef.detectChanges()
  }

  downloadTrace(trace: TraceMetadata): void {
    const progressDialog = this.openDownloadTraceProgressDialog()

    this.traces
      .getTrace(trace.user, trace.traceId, trace.buildingId)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      .subscribe((data) =>
        downloadBlob(data, `${trace.user}_${trace.buildingId}_${trace.traceId}.json`)
      )
  }

  openTracePath(trace: TraceMetadata): void {
    this.state.go(TRACE_PATH_STATE_NAME, {
      user: trace.user,
      traceId: trace.traceId,
      floorId: trace.floorId,
    })
  }

  private fetchUserAutocomplete([oldVal, newVal]: [string, string]): Observable<string[]> {
    if (!newVal?.length) {
      return of([])
    }

    return this.traces
      .getTracesUsers(
        this.currentVenue.getVenue().id,
        this.currentFloor.getFloor().id,
        newVal,
        0,
        5
      )
      .pipe(map((p) => p.content.map((u) => u.username)))
  }

  /**
   * Open ProgressDialog for download operation
   */
  private openDownloadTraceProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "traces-list.download-trace-in-progress",
    }
    return this.dialog.open(ProgressDialog, { data })
  }
}
