/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PackagePart { 
    createdAt?: string;
    dataSize?: number;
    id?: number;
    packageVersion?: string;
    tunedPackagePartId?: number;
    type?: PackagePartTypeEnum;
    updatedAt?: string;
    venueId?: number;
    version?: number;
}
export enum PackagePartTypeEnum {
    Config = 'config',
    Map = 'map'
};



