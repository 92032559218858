import { Injectable } from '@angular/core'

@Injectable()
export class ServerConfig {
    name: string = null
    protocol = "http"
    context = "ipp"
    host: string = null
    port: number = null
}
