import { NgModule } from '@angular/core'

import { UIRouterModule } from "@uirouter/angular"

import { ProfileComponent, PROFILE_STATES } from './'
import { SharedModule } from '../shared'

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: PROFILE_STATES }),
        SharedModule
    ],
    declarations: [
        ProfileComponent
    ]
})
export class ProfileModule {}
