<mat-toolbar color="primary" layout="row">
    <h3 class="title">
        <ic-icon [icon]="icon"> </ic-icon>
        {{ 'mipsWhiteList.addMac' | translate }}
    </h3>
</mat-toolbar>
<div class="content-wrapper add-server-dialog-wrapper">
    <form [formGroup]="macForm" fxLayout="column">
        <mat-dialog-content fxLayout="column" fxFlex>
            <mat-form-field>
                <mat-label>{{ 'MAC' | translate }}</mat-label>
                <input matInput [(mac)]="macForm.value.mac" autocomplete="off" type="text" required formControlName="mac" maxlength="17">
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button type="submit" color="primary" [disabled]="!macForm.valid" (click)="onOk()">
                {{ 'Ok' | translate }}
            </button>
            <button mat-button (click)="onCancel()">{{ 'Cancel' | translate }}</button>
        </mat-dialog-actions>
    </form>
</div>
