import { NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { OSMNamesApiModule } from "@venue/api-osmnames/osmnames-api.module"
import { MapsModule } from "@venue/maps"
import { SharedModule } from "@venue/shared"
import * as m from "."

@NgModule({
  imports: [
    UIRouterModule.forChild({ states: m.FLOOR_CS_STATES }),
    SharedModule,
    MapsModule,
    OSMNamesApiModule,
  ],
  declarations: [
    m.DrawRectangleInteractionComponent,
    m.FloorCSComponent,
    m.FloorCSSidebarComponent,
    m.FloorIpsImageComponent,
    m.FloorProdCSComponent,
    m.FloorWgsComponent,
    m.FloorWgsMapComponent,
    m.FloorWgsSidebarComponent,
  ],
  providers: [m.AutoAdjustHelperService, m.FloorCSConvertHelperService],
})
export class FloorCSModule {}
