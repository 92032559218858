import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { HateoasResponse, Pageable, TuningHistory, hateoasFromAny } from '@venue/api'
import { ApiUrl } from './api-url.service'
import { toParams } from '@venue/api/utils/params'

@Injectable()
export class TuningHistoryService {
    constructor(private http: HttpClient, private apiUrl: ApiUrl) { }

    private url(subPath = ""): string {
        return this.apiUrl.getSipsAddress(`/rest/tuning_history${subPath}`)
    }

    list(pageable?: Partial<Pageable>): Observable<HateoasResponse<TuningHistory[]>> {
        const params = toParams(pageable)
        return this.http.get<any>(this.url(), { params })
            .pipe(map(v => hateoasFromAny<TuningHistory[]>(v)))
    }

    findByVenueId(venueId: number, pageable?: Partial<Pageable>): Observable<HateoasResponse<TuningHistory[]>> {
        const params = toParams({ venueId }, pageable)
        return this.http.get<any>(this.url('/search/findByVenueId'), { params })
            .pipe(map(v => hateoasFromAny<TuningHistory[]>(v)))
    }
}

