import { HTTP_INTERCEPTORS } from "@angular/common/http"
import { APP_INITIALIZER, NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { SharedModule } from "@venue/shared"
import {
  OAuthModule,
  OAuthResourceServerErrorHandler,
  OAuthService,
  OAuthStorage,
} from "angular-oauth2-oidc"
import { AccessDeniedComponent, AUTH_STATES } from "."
import { OAuthErrorInterceptor } from "./interceptors/oauth-error.interceptor"
import { OAuthInjectTokenInterceptor } from "./interceptors/oauth-inject-token.interceptor"
import { AuthConfigService } from "./services/auth-config.service"
import { AuthUtilService } from "./services/auth-util.service"

@NgModule({
  declarations: [AccessDeniedComponent],
  providers: [
    AuthConfigService,
    AuthUtilService,
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInjectTokenInterceptor, multi: true },
    {
      provide: OAuthResourceServerErrorHandler,
      useClass: OAuthErrorInterceptor,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initOAuthFactory,
      deps: [OAuthService, AuthConfigService],
      multi: true,
    },
    {
      provide: OAuthStorage,
      useFactory: oauthStorageFactory,
    },
  ],
  imports: [
    OAuthModule.forRoot({ resourceServer: { sendAccessToken: false } }),
    UIRouterModule.forChild({ states: AUTH_STATES }),
    SharedModule,
  ],
})
export class AuthModule {}

export function initOAuthFactory(oauthService: OAuthService, authConfig: AuthConfigService) {
  return () => initOAuth(oauthService, authConfig)
}

export function oauthStorageFactory(): OAuthStorage {
  return {
    getItem: (key: string) => localStorage.getItem(`vv-${key}`),
    removeItem: (key: string) => localStorage.removeItem(`vv-${key}`),
    setItem: (key: string, data: string) => localStorage.setItem(`vv-${key}`, data),
  }
}

async function initOAuth(oauthService: OAuthService, authConfig: AuthConfigService) {
  const config = await authConfig.getConfig().toPromise()

  if (!config) {
    throw new Error("Unable to load auth configuration.")
  }

  if (!config.vsAuthEnabled) {
    return
  }

  oauthService.configure(config)

  // angular-oauth2-oidc docs named this hasReceivedTokens, but it seems to be always true
  const hasReceivedTokens = await oauthService.loadDiscoveryDocumentAndTryLogin()

  // Check if we have a valid, but expired token and refresh it here,
  // before app makes any request to setup-store api
  if (oauthService.hasValidAccessToken() && oauthService.hasValidIdToken()) {
    const expireDate = new Date(oauthService.getAccessTokenExpiration())
    const now = new Date()

    const expired = expireDate < now

    if (expired) {
      try {
        await oauthService.refreshToken()
      } catch (e) {
        console.log("Removing old token - failed to refresh.")
        // If we failed to refresh the token, then lets remove all our tokens
        removeTokens(oauthService)
      }
    }
  } else {
    // If we have invalid token (or it expired long ago? couldn't find it in the docs)
    // then lets remove it here
    if (oauthService.getAccessToken()) {
      console.log("Remove old token - expired or invalid token found.")
      removeTokens(oauthService)
    }
  }

  oauthService.setupAutomaticSilentRefresh()
}

/**
 * Remove all tokens without calling auth server's logout endpoint,
 * by calling OAuthService.logOut() function without logoutUrl.
 *
 * This is used to clean already expired tokens without roundtrip to auth server
 * and should not be used as a real log out, where we have valid, non-expired tokens.
 */
function removeTokens(oauthService: OAuthService) {
  // Clean logout url
  const logoutUrl = oauthService.logoutUrl
  oauthService.logoutUrl = undefined

  // Remove tokens
  oauthService.logOut()

  // Restore logout url
  oauthService.logoutUrl = logoutUrl
}
