import { Injectable } from "@angular/core"
import RenderEvent from "ol/render/Event"
import { generatedWallSecondaryColor } from "../map-styles/generated-wall.map-style"

/**
 * Draws walls like they are interpreted by positioning engine.
 * Outer wall should be composed before inner walls, so order of map layers matters.
 */
@Injectable()
export class WallsCompositorService {
    private compositorCanvas: HTMLCanvasElement = document.createElement("canvas")

    constructor() {}

    precompose(event: RenderEvent, outerWall = false): void {
        const eventCanvas = event.context.canvas
        this.resizeCompositorCanvas(eventCanvas.width, eventCanvas.height)

        if (outerWall) {
            this.precomposeOuterWall(event)
        } else {
            this.precomposeInnerWalls(event)
        }
    }

    postcompose(event: RenderEvent, outerWall = false): void {
        if (outerWall) {
            this.postcomposeOuterWall(event)
        } else {
            this.postcomposeInnerWalls(event)
        }
    }

    private precomposeOuterWall(event: RenderEvent): void {
        const c = this.compositorCanvas
        const ctx = c.getContext("2d")

        const ec = event.context.canvas
        const ectx = ec.getContext("2d")

        ctx.clearRect(0, 0, c.width, c.height)
        ctx.drawImage(ec, 0, 0)

        // Clear current path in case we have no walls
        ectx.beginPath()
    }

    private postcomposeOuterWall(event: RenderEvent): void {
        const c = this.compositorCanvas
        const ec = event.context.canvas
        const ectx = ec.getContext("2d")
        ectx.save()
        // Current path contains outer wall - add rectangle on whole canvas to this path.
        ectx.rect(0, 0, ec.width, ec.height)
        // Clear whole canvas.
        ectx.clearRect(0, 0, ec.width, ec.height)
        // Clip using evenodd rule - outer wall path + whole canvas rectangle = negated outer wall path.
        ectx.clip("evenodd")

        // Fill rectangle on whole canvas - clipping will remove outer wall from it.
        // As a result, we will draw area outside the building with inaccessible area color.
        ectx.fillStyle = generatedWallSecondaryColor
        ectx.fillRect(0, 0, ec.width, ec.height)

        ectx.restore()

        // Draw backed up canvas data - restore floor image
        ectx.globalCompositeOperation = "destination-over"
        ectx.drawImage(c, 0, 0, ec.width, ec.height)

        ectx.globalCompositeOperation = "source-over"
    }

    private precomposeInnerWalls(event: RenderEvent): void {
        const ec = event.context.canvas
        const ectx = ec.getContext("2d")
        ectx.globalCompositeOperation = "source-over"
    }

    private postcomposeInnerWalls(event: RenderEvent): void {
        const ec = event.context.canvas
        const ectx = ec.getContext("2d")

        ectx.globalCompositeOperation = "source-over"
    }

    private resizeCompositorCanvas(width: number, height: number): void {
        if (this.compositorCanvas.width !== width) {
            this.compositorCanvas.width = width
        }
        if (this.compositorCanvas.height !== height) {
            this.compositorCanvas.height = height
        }
    }
}
