import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import { Coordinate } from "ol/coordinate"
import { WallsComponent } from "./walls.component"
import { WALLS_EDITOR_STATE_NAME } from "./walls.state-names"

const WALLS_EDITOR_STATE = {
  parent: EDITOR_STATE_NAME,
  name: WALLS_EDITOR_STATE_NAME,
  url: "/:floorId/walls",
  component: WallsComponent,
  params: {
    importPolygons: [] as Array<Array<Coordinate[]>>,
  },
}

export const WALLS_STATES = [WALLS_EDITOR_STATE]
