import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms"
import mdiCalendarClock from "@iconify/icons-mdi/calendar-clock"
import { TranslateService } from "@ngx-translate/core"
import { withUnsubscribe } from "@venue/shared"
import { DateTime } from "luxon"
import { combineLatest, Subject } from "rxjs"
import { distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "datetime-picker",
  templateUrl: "./datetime-picker.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
  ],
})
export class DatetimePickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  readonly icon = mdiCalendarClock

  date = new FormControl()
  time = new FormControl()

  @Input() min?: DateTime
  @Input() max?: DateTime
  @Input() dateLabel = this.translateService.instant("datetime-picker.date-label")
  @Input() timeLabel = this.translateService.instant("datetime-picker.time-label")
  @Input() spacing = "1rem"

  touched = new Subject<void>()

  private initialized = false
  private dateTime = new FormControl(DateTime.local())

  private unsubscribe: Subject<void>

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    let updating = false
    this.dateTime.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        map((v) => this.validateDateTime(v))
      )
      .subscribe((v) => {
        updating = true
        this.date.setValue(v)
        this.time.setValue(v)
        updating = false
      })

    combineLatest(this.date.valueChanges, this.time.valueChanges)
      .pipe(
        takeUntil(this.unsubscribe),
        // IMHO it shouldn't be needed, but is
        // AngularJS digest is constantly updating min/max values,
        // these update date input field,
        // which triggers this code...
        // TODO Try to remove after removing AngularJS
        distinctUntilChanged((a, b) => +a[0] === +b[0] && +a[1] === +b[1]),
        filter((v) => !updating),
        map(([date, time]: [DateTime, DateTime]) =>
          time.set({
            year: date.year,
            month: date.month,
            day: date.day,
          })
        ),
        map((v) => this.validateDateTime(v))
      )
      .subscribe((dateTime) => {
        this.dateTime.setValue(dateTime)
      })

    this.dateTime.setValue(this.dateTime.value) // Forced update
    this.initialized = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) return
    const dateTime = this.validateDateTime(this.dateTime.value)
    if (+this.dateTime.value !== +dateTime) {
      this.dateTime.setValue(dateTime)
    }
  }

  private validateDateTime(dateTime: DateTime): DateTime {
    if (this.min && this.min > dateTime) {
      dateTime = this.min
    }
    if (this.max && this.max < dateTime) {
      dateTime = this.max
    }
    return dateTime
  }

  writeValue(obj: DateTime): void {
    this.dateTime.setValue(obj)
  }
  registerOnChange(fn: any): void {
    this.dateTime.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        filter(() => this.initialized)
      )
      .subscribe(fn)
  }
  registerOnTouched(fn: any): void {
    this.touched.pipe(takeUntil(this.unsubscribe)).subscribe(fn)
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      throw new Error("Method not implemented.")
    }
  }
}
