import { Component, OnInit } from "@angular/core"
import { withUnsubscribe } from "@venue/shared"
import JSZip from "jszip"
import { GeoJSON } from "ol/format"
import { Vector as VectorSource } from "ol/source"
import { concat, from, Observable, zip, of } from "rxjs"
import { finalize, map, takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "imdf-view",
  templateUrl: "./imdf-view.component.html",
})
export class ImdfViewComponent implements OnInit {
  layerSources: VectorSource<any>[] = []
  layerNames: string[] = []

  private unsubscribe: Observable<any>

  constructor() {}

  ngOnInit() {}

  openImdf(file: File) {
    from(JSZip.loadAsync(file))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((archive) => {
        let entries: JSZip.JSZipObject[] = []

        archive.forEach((relativePath, zipEntry) => {
          if (zipEntry.name.endsWith("geojson")) {
            entries.push(zipEntry)
          }
        })

        // Clean before loading
        this.layerNames = []

        concat(...entries.map((v) => zip(from(v.async("string")), of(v.name))))
          .pipe(
            // Parse json
            map(([data, name]) => [JSON.parse(data), name]),
            // When all sources have been created, then populate layerNames
            finalize(() => {
              this.layerNames = entries.map((v) => {
                let parts = v.name.split("/")
                return parts[parts.length - 1]
              })
            }),
            takeUntil(this.unsubscribe)
          )
          // Add new source
          .subscribe(([data, name]) => {
            // Swap coordinates for section.json
            //   Seems our imdf has [lat,lon], but we expect [lon,lat]
            if (name.endsWith("section.geojson")) {
              for (let f of data.features) {
                let g = f.geometry
                if (!g) {
                  continue
                }

                let c = g.coordinates
                if (g.type == "Point") {
                  g.coordinates = [c[1], c[0]]
                } else if (g.type == "LineString") {
                  g.coordinates = c.map((v: any) => [v[1], v[0]])
                } else {
                  g.coordinates = c.map((poly: any) => poly.map((v: any) => [v[1], v[0]]))
                }
              }
            }

            let source = new VectorSource({
              features: new GeoJSON().readFeatures(data, { featureProjection: "EPSG:3857" }),
            })
            this.layerSources.push(source)
          })
      })
  }
}
