import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core"
import { Map } from "ol"
import { VectorLayerComponent } from "."

interface VectorLayersMap {
  [key: string]: VectorLayerComponent
}

@Component({
  selector: "openlayers-map",
  template: "<ng-content></ng-content>",
})
export class OpenlayersMapComponent implements OnInit, OnDestroy {
  // XXX Remove after angular upgrade
  @Input() ajsViewModel?: any
  @Input() ajsInjectAs?: string
  map: Map

  // TODO This could be removed and replaced with @ViewChildren or @ContentChildren, whichever is correct
  readonly vectorLayersMap: VectorLayersMap = {}

  private resizeObserver = new ResizeObserver(() => this.onResize())

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.map = new Map({
      target: this.elementRef.nativeElement,
    })

    if (this.ajsViewModel && this.ajsInjectAs) {
      this.ajsViewModel[this.ajsInjectAs] = this
    }

    this.resizeObserver.observe(this.elementRef.nativeElement)
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.elementRef.nativeElement)
  }

  get vectorLayers(): VectorLayerComponent[] {
    return Object.values(this.vectorLayersMap)
  }

  register(vectorLayer: VectorLayerComponent): void {
    const name = vectorLayer.name
    if (name) {
      if (this.vectorLayersMap[name]) {
        throw new Error("Can't register multiple vector layers under same name")
      } else {
        this.vectorLayersMap[name] = vectorLayer
      }
    }
  }

  deregister(vectorLayer: VectorLayerComponent): void {
    const name = vectorLayer.name
    if (name) {
      this.vectorLayersMap[name] = undefined
    }
  }

  private onResize(): void {
    this.map.updateSize()
  }
}
