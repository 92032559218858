import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core"
import { FormControl } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import mdiDelete from "@iconify/icons-mdi/delete"
import mdiFileTable from "@iconify/icons-mdi/file-table"
import mdiFileUpload from "@iconify/icons-mdi/file-upload"
import mdiPlus from "@iconify/icons-mdi/plus"
import { WhitelistMacAddress } from "@openapi/mipsengine"
import { WhitelistService, WhitelistServiceInterface } from "@venue/api"
import { withUnsubscribe } from "@venue/shared"
import { Subject } from "rxjs"
import { filter, takeUntil } from "rxjs/operators"
import { AddWhitelistMacDialog } from ".."
import { WhitelistDataSource } from "./whitelist.data-source"

@withUnsubscribe
@Component({
  selector: "whitelist",
  templateUrl: "./whitelist.component.html",
})
export class WhitelistComponent implements OnInit, AfterViewInit {
  readonly addMacIcon = mdiPlus
  readonly importExcelIcon = mdiFileUpload
  readonly downloadTemplateIcon = mdiFileTable
  readonly removeMacsIcon = mdiDelete

  whitelistEnabled = new FormControl(false)
  excelFile = new FormControl("")

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  displayedColumns = ["select", "mac"]
  dataSource: WhitelistDataSource

  private unsubscribe: Subject<void>
  private importExcelInput: HTMLInputElement

  constructor(
    @Inject(WhitelistService) private whitelistService: WhitelistServiceInterface,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.whitelistEnabled.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((enabled: boolean) => this.toggleWhitelist(enabled))
    this.whitelistService
      .isEnabled()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((enabled) => {
        this.whitelistEnabled.setValue(enabled, { emitEvent: false })
      })
    this.excelFile.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.uploadExcelFile())

    this.importExcelInput = <HTMLInputElement>document.getElementById("importExcelInput")
  }

  ngAfterViewInit(): void {
    this.sort.disableClear = true
    this.sort.sort({ id: "mac", start: "asc", disableClear: true })
    this.dataSource = new WhitelistDataSource(this.whitelistService, this.paginator, this.sort)

    this.cdRef.detectChanges()
  }

  toggleWhitelist(enabled: boolean): void {
    this.whitelistService
      .setEnabled(enabled)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {},
        (error) => this.whitelistEnabled.setValue(!enabled, { emitEvent: false })
      )
  }

  addMac(): void {
    const dialogRef = this.dialog.open(AddWhitelistMacDialog)

    dialogRef
      .afterClosed()
      .pipe(filter((entry) => !!entry))
      .subscribe((mac: WhitelistMacAddress) => {
        this.whitelistService
          .addEntry(mac)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => this.dataSource.refresh(),
            (error) => {
              // TODO Show info on snackbar
              console.error("Error")
            }
          )
      })
  }

  removeMacs(): void {
    this.whitelistService
      .removeEntries(this.dataSource.selection.selected.map((v) => v.mac))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => this.dataSource.refresh(),
        (error) => {
          // TODO Show info on snackbar
          console.error("Error")
        }
      )
  }

  selectWhitelistExcelFile(): void {
    this.importExcelInput.click()
  }

  private uploadExcelFile(): void {
    const file = this.importExcelInput.files[0]
    if (!file) {
      return
    }
    this.importExcelInput.value = ""

    this.whitelistService
      .importExcel(file)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          // TODO Snackbar
          console.log("Uploaded successfully")
          this.dataSource.refresh()
        },
        (error) => {
          // TODO Snackbar
          console.warn("Upload error")
        }
      )
  }
}
