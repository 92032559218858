import { MapBrowserEvent } from "ol"
import { Coordinate } from "ol/coordinate"
import VectorLayer from "ol/layer/Vector"
import Map from "ol/Map"
import VectorSource from "ol/source/Vector"
import AbstractMouseInteraction from "./AbstractMouseInteraction"

export default class DeleteFeatureInteraction extends AbstractMouseInteraction {
  private layers: VectorLayer<VectorSource<any>>[]

  constructor(options: DeleteFeatureInteractionOptions) {
    super()
    this.layers = options.layers
  }

  click(e: MapBrowserEvent<any>): void {
    const [feature, layer] = this.getFeatureAtPixel(e.pixel, this.layers)
    if (feature) {
      const source = layer.getSource()
      source.removeFeature(feature)

      this.dispatchEvent({ type: "removefeature", feature } as any)
      this.emitHoverEvent(e.map, e.pixel)
    }
  }

  private emitHoverEvent(map: Map, pixel: Coordinate): void {
    map.dispatchEvent({
      type: "hoverfeature",
      pixel: pixel,
    } as any)
  }
}

export interface DeleteFeatureInteractionOptions {
  layers: VectorLayer<VectorSource<any>>[]
}
