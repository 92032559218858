<div class="element">
    <svg width="30" height="30">
        <rect width="20" height="20"
              x="5" y="5"
              rx="3" ry="3"
              [style.stroke]="'white'"
              [style.fill]="fillColor ? 'white' : 'none'"
              style="stroke-width:4;"/>
        <rect width="24" height="24"
              x="3" y="3"
              rx="3" ry="3"
                     [style.fill]="fillColor ? fillColor : 'none'" />
        <rect width="20" height="20"
              x="5" y="5"
              rx="3" ry="3"
              [style.stroke]="strokeColor ? strokeColor : 'none'"
              style="fill: none; stroke-width:4;"/>
        <rect width="24" height="24"
              x="3" y="3"
              rx="3" ry="3"
              style="stroke:black; fill: none; stroke-width:1;"/>
    </svg>
    <span>{{ translationKey | translate }}</span>
</div>
