import { WhitelistComponent } from "@venue/whitelist"
import * as mipsconfig from "./"

const mipsConfigState = {
    name: "mips-config",
    url: "/mips-config",
    component: mipsconfig.MipsConfigComponent
}

const whitelistConfigState = {
    parent: mipsConfigState.name,
    name: "global-whitelist",
    url: "/whitelist",
    component: WhitelistComponent
}

export const MIPS_CONFIG_STATES = [mipsConfigState, whitelistConfigState]
