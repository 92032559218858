import { Injectable, InjectionToken } from "@angular/core"
import {
  MacWhiteListControllerService,
  PageWhitelistMacAddress,
  WhitelistMacAddress,
} from "@openapi/mipsengine"
import { MacGlobalWhitelistControllerService } from "@openapi/mipsengine/api/mac-global-whitelist-controller.service"
import { Observable } from "rxjs"
import { Pageable } from ".."

export const WhitelistService = new InjectionToken<WhitelistServiceInterface>(
  "Service that provides whitelist api."
)

export interface WhitelistServiceInterface {
  isEnabled(): Observable<boolean>
  setEnabled(enabled: boolean): Observable<any>
  getEntries(pageable: Partial<Pageable>): Observable<PageWhitelistMacAddress>
  addEntry(mac: WhitelistMacAddress): Observable<any>
  removeEntries(macs: string[]): Observable<any>
  importExcel(file: File): Observable<WhitelistMacAddress[]>
}

@Injectable()
export class GlobalWhitelistServiceWrapper implements WhitelistServiceInterface {
  constructor(private whitelistService: MacGlobalWhitelistControllerService) {}

  isEnabled(): Observable<boolean> {
    return this.whitelistService.isEnabledUsingGET()
  }

  setEnabled(enabled: boolean): Observable<any> {
    return this.whitelistService.setGlobalEnabledUsingPOST(enabled)
  }

  getEntries(pageable: Partial<Pageable>): Observable<PageWhitelistMacAddress> {
    return this.whitelistService.getGlobalWhitelistEntriesUsingGET(
      pageable.page,
      pageable.size,
      pageable.sort
    )
  }

  addEntry(macEntry: { mac: string }): Observable<any> {
    return this.whitelistService.addGlobalWhitelistEntriesUsingPOST([macEntry])
  }

  removeEntries(macs: string[]): Observable<any> {
    return this.whitelistService.removeGlobalWhitelistEntriesUsingPOST(macs)
  }

  importExcel(excelFile: File): Observable<WhitelistMacAddress[]> {
    return this.whitelistService.loadExcelUsingPOST(excelFile)
  }
}

export class BuildingWhitelistServiceWrapper implements WhitelistServiceInterface {
  constructor(
    private buildingId: number,
    private whitelistService: MacWhiteListControllerService
  ) {}

  isEnabled(): Observable<boolean> {
    return this.whitelistService.isBuildingEnabledUsingGET(this.buildingId)
  }

  setEnabled(enabled: boolean): Observable<any> {
    return this.whitelistService.setBuildingEnabledUsingPOST(this.buildingId, enabled)
  }

  getEntries(pageable: Partial<Pageable>): Observable<PageWhitelistMacAddress> {
    return this.whitelistService.getBuildingWhitelistEntriesUsingGET(
      this.buildingId,
      pageable.page,
      pageable.size,
      pageable.sort
    )
  }

  addEntry(macEntry: { mac: string }): Observable<any> {
    return this.whitelistService.addBuildingSpecificWhitelistEntriesUsingPOST(this.buildingId, [
      macEntry.mac,
    ])
  }

  removeEntries(macs: string[]): Observable<any> {
    return this.whitelistService.removeEntriesFromBuildingSpecificWhitelistUsingPOST(
      this.buildingId,
      macs
    )
  }

  importExcel(file: File): Observable<WhitelistMacAddress[]> {
    return this.whitelistService.loadBuildingSpecificExcelUsingPOST(this.buildingId, file)
  }

  overridesGlobal(): Observable<boolean> {
    return this.whitelistService.overridesGlobalUsingGET(this.buildingId)
  }

  setOverrideGlobal(override: boolean): Observable<boolean> {
    return this.whitelistService.setOverridesGlobalUsingPOST(this.buildingId, override)
  }
}
