import { Directive } from "@angular/core"
import { Observable, Subject } from "rxjs"

// Mixin for @Injectable, as @withUnsubscribe decorator broke
// If you want to override ngOnDestroy - remember to call super.ngOnDestroy() !
@Directive()
export class WithUnsubscribe {
  private _unsubscribe = new Subject<void>()

  protected readonly unsubscribe: Observable<void> = this._unsubscribe

  ngOnDestroy(): void {
    this._unsubscribe.next()
    this._unsubscribe.complete()
  }
}
