import { AfterViewInit, Component, Directive, ViewChild } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import carbonCenterSquare from "@iconify/icons-carbon/center-square"
import { FloorCSConfigResponse, FloorCSConfigsService, WgsCoordinateSystem } from "@openapi/venue"
import { ImageDimensions, isImageDimensions, Location, Point } from "@venue/api"
import { ONPage } from "@venue/api-osmnames/ONPage"
import { ONResult } from "@venue/api-osmnames/ONResult"
import { OSMNamesApiService } from "@venue/api-osmnames/osmnames-api.service"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import { WgsTransforms } from "@venue/components/floor/floormap/transformations/WgsTransforms"
import { CoordinateConverter, CurrentFloor } from "@venue/core"
import {
  ImageDimensionsReceiverMixin,
  OpenlayersMapComponent,
  OSMLayerComponent,
  VectorLayerComponent,
} from "@venue/maps"
import { ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { Geodesic } from "geographiclib"
import { vec2 } from "gl-matrix"
import { isEqual } from "lodash"
import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { Point as olPoint, Polygon } from "ol/geom"
import { Projection, transform } from "ol/proj"
import { Circle, Fill, Stroke, Style } from "ol/style"
import { StyleFunction } from "ol/style/Style"
import { BehaviorSubject, combineLatest, empty, merge, Observable, of, Subject } from "rxjs"
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  pairwise,
  share,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators"

@Directive()
abstract class FloorWgsMapComponentBase extends ImageDimensionsReceiverMixin {}

@withUnsubscribe
@Component({
  selector: "floor-wgs-map",
  templateUrl: "./floor-wgs-map.component.html",
})
export class FloorWgsMapComponent extends FloorWgsMapComponentBase implements AfterViewInit {
  readonly centerFloorIcon = carbonCenterSquare

  readonly WRITE_USER_ROLE = "venue:floorcs:write"

  transforms: WgsTransforms
  mapProjection: Projection

  /** Observable indicating if loading overlay should be displayed. */
  loading = new BehaviorSubject<boolean>(true)

  /** Flag indicating that downloading floor coordinate system config failed. */
  noCS = false

  /** Flag indicating that getting image dimensions failed. */
  noImgDim = false

  @ViewChild(OpenlayersMapComponent) mapComponent: OpenlayersMapComponent
  @ViewChild(OSMLayerComponent) osmLayer: OSMLayerComponent
  @ViewChild("transformBoxLayer") transformBoxLayer: VectorLayerComponent

  transformBoxLayerStyle: StyleFunction = (f, res) => {
    const stroke = new Stroke({
      width: 2,
      color: "rgb(0,100,200)",
    })

    const fill = new Fill({
      color: "rgb(0,100,200,0.01)",
    })

    const geometry = f.get("modifyGeometry")?.geometry ?? f.getGeometry()

    const style = [
      new Style({
        stroke,
        fill,
        geometry,
      }),
    ]

    if (geometry instanceof Polygon) {
      const coords = geometry.getCoordinates()[0]

      coords.forEach((p) =>
        style.push(
          new Style({
            image: new Circle({
              radius: 7,
              stroke,
              fill: new Fill({
                color: "rgb(0, 100, 200)",
              }),
            }),
            geometry: new olPoint(p),
          })
        )
      )
    }

    return style
  }

  locationSearchForm = this.fb.control("")
  locationAutocompleteOpts = new Subject<ONResult[]>()
  locationAutocompleteFetchInProgress = new BehaviorSubject<boolean>(false)

  private currentFloorCS: FloorCSConfigResponse
  private currentImgDim: ImageDimensions

  /** Subject to inject newly saved coordinate data and reload component */
  private csOverrideStream = new Subject<FloorCSConfigResponse>()

  private unsubscribe: Observable<void>

  constructor(
    public currentFloor: CurrentFloor,
    private floorCSConfigs: FloorCSConfigsService,
    private coordinateConverter: CoordinateConverter,
    private osmNamesApi: OSMNamesApiService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private authUtilService: AuthUtilService
  ) {
    super()
    if (!this.hasUserWritePermissions()) this.locationSearchForm.disable()
  }

  ngAfterViewInit(): void {
    // Get current floor (can later be changed to not rely on fetching floor data - only floor id is needed)
    const floorStream = this.currentFloor.floor.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
      shareReplay(1)
    )

    // Get current floor coordinate system config
    const csStream = merge(
      this.csOverrideStream.pipe(tap(() => this.loading.next(true))),
      floorStream.pipe(
        filter((f) => !!f),
        switchMap((f) =>
          this.floorCSConfigs
            .getFloorCSConfig(f.id)
            .pipe(catchError((err) => this.catchCSError(err)))
        )
      )
    ).pipe(takeUntil(this.unsubscribe), share())

    // Combine cs, transition areas and imgDim, then initialize converter and load data
    combineLatest(
      csStream,
      this.imgDimSubject.pipe(
        switchMap((dimOrErr) => {
          if (isImageDimensions(dimOrErr)) {
            return of(dimOrErr)
          }

          return this.catchIDError(dimOrErr)
        })
      )
    )
      .pipe(
        filter(([cs, imgDim]) => !!cs && !!imgDim),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([cs, imgDim]) => {
        this.currentFloorCS = cs
        this.currentImgDim = imgDim

        // Init metric-pixel coordinate converter
        this.coordinateConverter.load(cs, imgDim.height)

        // Init wgs-pixel transforms
        this.transforms = new WgsTransforms(this.coordinateConverter, this.mapProjection, imgDim)

        if (cs.wgsCS) {
          this.transforms.initWgs(cs.wgsCS, cs.metricCS)

          // Center view
          const blueWgs = cs.wgsCS.blue
          const blueCoord = transform(
            [blueWgs.longitude, blueWgs.latitude],
            "EPSG:4326",
            this.mapProjection
          )
          this.osmLayer.centerView(blueCoord)
        } else {
          this.transforms.initWgs(
            { longitude: 127.05268816647289, latitude: 37.54066293529101 },
            110
          )
        }

        // Transform box is available only if user has permissions to perform CRUD ops.
        if (this.hasUserWritePermissions()) {
          // Transform box
          const transformBox = new Feature(new Polygon([this.transformBoxCoords(imgDim)]))

          // move-interaction callback is called after user is done moving the feature
          // instead of adding new callback, just rely on Feature change event
          // we can do that as we have only one feature that we care about
          transformBox.on("change", () => {
            const geom = transformBox.get("modifyGeometry")?.geometry ?? transformBox.getGeometry()
            const coords = geom.getCoordinates()[0]
            const origin = transform(coords[0], this.mapProjection, "EPSG:4326")
            const rotate = transform(coords[1], this.mapProjection, "EPSG:4326")

            const gd = Geodesic.WGS84.Inverse(origin[1], origin[0], rotate[1], rotate[0])

            this.transforms.initWgs({ longitude: origin[0], latitude: origin[1] }, gd.azi1)
          })

          this.transformBoxLayer.source.clear()
          this.transformBoxLayer.source.addFeature(transformBox)
        }
        this.loading.next(false)
      })

    this.locationSearchForm.valueChanges
      .pipe(
        startWith(""),
        pairwise(),
        distinctUntilChanged(isEqual),
        filter(([o, n]) => o !== n),
        map(([o, n]) => n as string),
        filter((v) => !!v?.length),
        tap(() => this.locationAutocompleteFetchInProgress.next(true)),
        debounceTime(100),
        switchMap((query) => this.osmNamesApi.query(query)),
        takeUntil(this.unsubscribe)
      )
      .subscribe((v: ONPage) => {
        this.locationAutocompleteOpts.next(v.results)
        this.locationAutocompleteFetchInProgress.next(false)
      })
  }

  setMapProjection(projection: Projection) {
    this.mapProjection = projection
  }

  /**
   * Check if user has permissions to perform CRUD operations.
   */
  hasUserWritePermissions() {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  /**
   * Save button should be disabled if downloading floor coordinate system config failed
   * or getting image dimensions failed
   * or user does not have permissions to perform CRUD operations.
   */
  saveDisabled = this.noCS || this.noImgDim || !this.hasUserWritePermissions()

  /**
   * Center floor in the current view.
   *
   * WgsTransforms use top-left point of the image as an origin point,
   * so to center the image this top-left point's wgs coordinates are
   * calculated using wgs coordinates of the viewport's center.
   */
  centerFloor(): void {
    const _imgDim = this.imgDimSubject.getValue()

    // Calling centerFloor should be disabled if there are no image dimensions present - assert that
    if (_imgDim instanceof String || _imgDim == null) {
      throw new Error("No image dimensions!")
    }

    const imgDim = _imgDim as ImageDimensions

    // C = center, O = origin (top-left of the image), R = rotation point (top-right of the image)
    // 1. Get pixel coords for C, O, R
    const pC = { x: imgDim.width / 2, y: imgDim.height / 2 }

    // Images on the map have inverted coordinates, hence image height instead of 0
    const pO = { x: 0, y: imgDim.height }
    const pR = { x: imgDim.width, y: imgDim.height }

    // 2. Convert to meters
    const mC = this.coordinateConverter.toMetric(pC)
    const mO = this.coordinateConverter.toMetric(pO)
    const mR = this.coordinateConverter.toMetric(pR)

    // 3. Calculate metric |OC|
    const mOC = vec2.fromValues(mC.x - mO.x, mC.y - mO.y)
    const distOC = vec2.length(mOC)

    // 4. Calculate angle between OR and OC = α
    const mOR = vec2.fromValues(mR.x - mO.x, mR.y - mO.y)

    // In degrees
    const α = (vec2.angle(mOR, mOC) * 180) / Math.PI

    // 5. Get current OR azimuth
    const aziOR = this.transforms.getAziOR()

    // 6. Calculate OC azimuth: OR azimuth + α
    const aziOC = aziOR + α

    // 7. Calculate CO azimuth: back azimuth of OC
    const aziCO = aziOC <= 180 ? aziOC + 180 : aziOC - 180

    // 8. Get wgsC
    const _wgsC = transform(
      this.mapComponent.map.getView().getCenter(),
      this.mapProjection,
      "EPSG:4326"
    )
    const wgsC = { longitude: _wgsC[0], latitude: _wgsC[1] }

    // 9. Calculate new wgsO
    const geoDataCO = Geodesic.WGS84.Direct(wgsC.latitude, wgsC.longitude, aziCO, distOC)

    const newWgsO = { longitude: geoDataCO.lon2, latitude: geoDataCO.lat2 }

    // 10. Update wgsTransforms with new wgsO
    this.transforms.initWgs(newWgsO, aziOR)

    // 11. Update transform box
    const transformBox = this.transformBoxLayer.source.getFeatures()[0].getGeometry() as Polygon
    transformBox.setCoordinates([this.transformBoxCoords(imgDim)])
  }

  /**
   * Center map on given location.
   */
  centerMap(location: ONResult): void {
    const coords = transform([location.lon, location.lat], "EPSG:4326", this.mapProjection)

    this.mapComponent.map.getView().setCenter(coords)
  }

  save(): void {
    const pixelCS = this.currentFloorCS.adjustedPixelCS

    const getWgs = (p: Point): Location => {
      const coord = transform(
        this.transforms.toWorld([p.x, this.currentImgDim.height - p.y]),
        this.mapProjection,
        "EPSG:4326"
      )
      return { longitude: coord[0], latitude: coord[1] }
    }

    const wgsCS: WgsCoordinateSystem = {
      blue: getWgs(pixelCS.blue),
      yellow: getWgs(pixelCS.yellow),
      pink: getWgs(pixelCS.pink),
    }

    const progressDialog = this.openSaveProgressDialog()

    this.floorCSConfigs
      .setFloorCSWgsConfig(this.currentFloor.getFloor().id, { wgsCS })
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      // TODO Handle error
      .subscribe((cs) => this.csOverrideStream.next(cs))
  }

  private transformBoxCoords(imgDim: ImageDimensions): Coordinate[] {
    const [w, h] = [imgDim.width, imgDim.height]

    return [
      [0, h],
      [w, h],
      [w, 0],
      [0, 0],
    ].map((p) => this.transforms.toWorld(p))
  }

  /**
   * On cs error, set noCS error flag and return empty observable.
   *
   * Also hide loading indicator, nothing to load after this error.
   */
  private catchCSError(err: any): Observable<FloorCSConfigResponse> {
    console.error("Error fetching floor coordinate system config", err)
    this.noCS = true

    this.loading.next(false)

    return empty()
  }

  /**
   * On image dimensions error, set noImgDim flag and return empty observable.
   */
  private catchIDError(err: any): Observable<ImageDimensions> {
    console.error("Error getting image dimensions", err)

    this.noImgDim = true

    this.loading.next(false)

    return empty()
  }

  /**
   * Open ProgressDialog for save operation
   */
  private openSaveProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "floor-wgs-map.save-in-progress",
    }
    return this.dialog.open(ProgressDialog, { data })
  }
}
