import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import { FloorProdCSComponent } from "../components"
import { FLOOR_PROD_CS_STATE_NAME } from "../floor-cs.state-names"

export const FLOOR_PROD_CS_STATE = {
  parent: EDITOR_STATE_NAME,
  name: FLOOR_PROD_CS_STATE_NAME,
  url: "/:floorId/prod-cs",
  component: FloorProdCSComponent,
}
