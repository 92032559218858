import { Component, Host, Input, OnDestroy, OnInit } from "@angular/core"
import { withUnsubscribe } from "@venue/shared"
import { View } from "ol"
import { getCenter } from "ol/extent"
import { Image as ImageLayer } from "ol/layer"
import { Projection } from "ol/proj"
import { ImageStatic } from "ol/source"
import { fromEvent, Observable, partition, Subject } from "rxjs"
import { distinctUntilChanged, map, switchMap, takeUntil } from "rxjs/operators"
import { OpenlayersMapComponent } from ".."

@withUnsubscribe
@Component({
  selector: "image-layer",
  template: "",
})
export class ImageLayerComponent implements OnInit, OnDestroy {
  /**
   * Url of the image to display.
   */
  @Input() imageUrl: string
  private imageUrlSubject = new Subject<string>()

  private imageLayer = new ImageLayer()

  private unsubscribe: Observable<any>

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnInit(): void {
    this.mapComponent.map.addLayer(this.imageLayer)

    const [urlStream, nullUrlStream] = partition(
      this.imageUrlSubject.pipe(distinctUntilChanged()),
      (url) => !!url
    )

    nullUrlStream.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.imageLayer.setSource(null))

    urlStream
      .pipe(
        distinctUntilChanged(),
        switchMap((url) => {
          const img = new Image()

          const imgLoad = fromEvent(img, "load").pipe(map(() => img))

          img.src = this.imageUrl

          return imgLoad
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe((img) => {
        const [width, height] = [img.width, img.height]
        const proj = this.createProjection(width, height)
        const view = this.createView(proj)

        this.mapComponent.map.setView(view)
        this.updateSource(this.imageUrl, width, height)
      })

    this.imageUrlSubject.next(this.imageUrl)
  }

  ngOnChanges(): void {
    this.imageUrlSubject.next(this.imageUrl)
  }

  ngOnDestroy(): void {
    this.mapComponent.map.removeLayer(this.imageLayer)
  }

  private createProjection(width: number, height: number): Projection {
    return new Projection({
      code: null,
      units: "pixels",
      extent: [-width, -height, 2 * width, 2 * height],
    })
  }

  private createView(projection: Projection): View {
    return new View({
      extent: projection.getExtent(),
      zoom: 3,
      minZoom: 2,
      maxZoom: 7,
      projection,
      center: getCenter(projection.getExtent()),
    })
  }

  private updateSource(imgUrl: string, width: number, height: number): void {
    this.imageLayer.setSource(
      new ImageStatic({
        url: this.imageUrl,
        imageSize: [width, height],
        imageExtent: [0, 0, width, height],
      })
    )
  }
}
