<section fxLayout="column"
         fxLayoutGap="0.5rem"
         fxFlex>
    <floor-select>
    </floor-select>

    <button mat-raised-button
            color="primary"
            [disabled]="saveDisabled"
            type="submit"
            (click)="submit.next()">{{ 'Save' | translate }}</button>
</section>
