import { NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { MapsModule } from "@venue/maps"
import { SharedModule } from "@venue/shared"
import * as m from "."

@NgModule({
  imports: [
    UIRouterModule.forChild({ states: m.IMDF_VIEW_STATES }),
    SharedModule,
    MapsModule,
  ],
  declarations: [
    m.ImdfViewComponent
  ],
  providers: [],
})
export class ImdfViewModule {}
