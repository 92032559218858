import { Component, OnInit } from "@angular/core"
import mdiArchivePlus from "@iconify/icons-mdi/archive-plus"
import mdiCellphoneWireless from "@iconify/icons-mdi/cellphone-wireless"
import mdiFloorPlan from "@iconify/icons-mdi/floor-plan"
import mdiMapMarkerRadius from "@iconify/icons-mdi/map-marker-radius"
import mdiSpeedometer from "@iconify/icons-mdi/speedometer"
import mdiStoreCog from "@iconify/icons-mdi/store-cog"
import { StateService } from "@uirouter/core"
import { AppConstants } from "@venue/app.constants"
import { ConfigService } from "@venue/config"
import { PACKAGE_GENERATOR_STATE_NAME } from "@venue/config-packages/config-packages.state-name"
import { CurrentVenue } from "@venue/core"
import { FLOOR_IPS_IMAGE_STATE_NAME } from "@venue/floor-cs/floor-cs.state-names"
import { FLOOR_LIST_STATE_NAME } from "@venue/floor/floor.state-names"
import { withUnsubscribe } from "@venue/shared"
import { BUILDING_WHITELIST_STATE } from "@venue/whitelist/states/building-whitelist.state"
import { Observable } from "rxjs"
import { delay, filter, map, takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "venue-menu",
  templateUrl: "./venue-menu.component.html",
})
export class VenueMenuComponent implements OnInit {
  // TODO Translate
  // TODO Fix hierarchy of editor states.
  //      One of the problems - root editor state 'app.editor' does not have venueId
  //      Thats why we transition directly to the FLOOR_CS_STATE state
  private floorsManagementLink: VenueMenuEntry = {
    name: "Floors Management",
    description: "Manage floors and floorplan images.",
    iconifyIcon: mdiFloorPlan,
    state: FLOOR_LIST_STATE_NAME,
  }

  private floorsPositioningSetupLink: VenueMenuEntry = {
    name: "Floors Positioning Setup",
    description:
      "Configure positioning on venue floors and manage config packages used by the indoor positioning system.",
    iconifyIcon: mdiMapMarkerRadius,
    state: FLOOR_IPS_IMAGE_STATE_NAME,
    requiresFloor: true,
  }

  private packageGeneratorLink: VenueMenuEntry = {
    name: "Package Generator",
    description: "Generate package with map configuration for use in mobile positioning.",
    iconifyIcon: mdiArchivePlus,
    state: PACKAGE_GENERATOR_STATE_NAME,
  }

  private setupStoreLink: VenueMenuEntry = {
    name: "Setup Store",
    description: "Go to the external service storing config packages.",
    iconifyIcon: mdiStoreCog,
    url: undefined,
  }

  links: VenueMenuEntry[] = [
    this.floorsManagementLink,
    this.floorsPositioningSetupLink,
    this.packageGeneratorLink,
    this.setupStoreLink,
  ]

  /**
   * Indicates if at least one floor is created in current venue.
   *
   * Used to disable links that require floor.
   */
  floorsPresent = true

  /**
   * Enables loading overlay
   */
  loading = true

  private unsubscribe: Observable<any>

  constructor(
    private state: StateService,
    private currentVenue: CurrentVenue,
    private configService: ConfigService
  ) {
    // Only show these in dev, as they do not really work right now
    if (AppConstants.dev) {
      this.links.push(
        {
          name: "Tuning",
          description:
            "Improve positioning accuracy by manual or automatic tuning of the positioning system.",
          iconifyIcon: mdiSpeedometer,
          state: "todo",
        },
        {
          name: "Mac Whitelist",
          description: "Configure building-specific mac address whitelist.",
          iconifyIcon: mdiCellphoneWireless,
          state: BUILDING_WHITELIST_STATE.name,
        }
      )
    }
  }

  ngOnInit(): void {
    this.currentVenue.refreshFloors()
    this.currentVenue.floors
      .pipe(
        filter((f) => !!f),
        map((floors) => floors.length != 0),
        delay(500), // Delay 500ms to not instantly blink with the loading overlay
        takeUntil(this.unsubscribe)
      )
      .subscribe((v) => {
        this.floorsPresent = v
        this.loading = false
      })

    this.configService
      .getConfigData()
      .pipe(map((config) => config.ss))
      .subscribe((ssConfig) => {
        var setupStoreURL = ""
        if (ssConfig.base_url != null && ssConfig.base_url != undefined) {
          setupStoreURL += ssConfig.base_url + "/"
        }
        if (ssConfig.base_path != null && ssConfig.base_path != undefined) {
          setupStoreURL += ssConfig.base_path + "/"
        }

        this.setupStoreLink.url = setupStoreURL
      })
  }

  onMenuClick(entry: VenueMenuEntry) {
    let params: { venueId: number; floorId?: number } = { venueId: this.state.params.venueId }

    if (entry.requiresFloor) {
      // If entry requires floors and there is no floor present - bail out
      if (!this.floorsPresent) {
        return
      }

      // Otherwise get id of first floor on the list
      params.floorId = this.currentVenue.getFloors()[0].id
    }

    if (entry.state) {
      this.state.go(entry.state, params)
    } else if (entry.url) {
      window.open(entry.url, "_blank").focus()
    }
  }
}

export interface VenueMenuEntry {
  name: string
  description: string
  iconifyIcon: object
  state?: string // TODO Possibly change to Ng2StateDeclaration after porting to angular7 is done
  url?: string
  requiresFloor?: boolean
}
