<div class="fill-parent"
     fxLayout="column">
    <mat-toolbar>
        {{ 'mipsWhiteList.header' | translate }}
        <span fxFlex></span>
        <div>
            <button mat-button
                    (click)="addMac()"
                    matTooltip="{{ 'mipsWhiteList.addMac' | translate }}">
                <ic-icon [icon]="addMacIcon"> </ic-icon>
            </button>
            <input type="file"
                   id="importExcelInput"
                   [hidden]="true"
                   accept=".xlsx"
                   [formControl]="excelFile">
            <button mat-button
                    (click)="selectWhitelistExcelFile()"
                    matTooltip="{{ 'mipsWhiteList.uploadExcel' | translate }}">
                <ic-icon [icon]="importExcelIcon"> </ic-icon>
            </button>
            <a mat-button
               href="assets/MacsFile.xlsx"
               matTooltip="{{ 'mipsWhiteList.downloadExcelTemplate' | translate }}">
                <ic-icon [icon]="downloadTemplateIcon"> </ic-icon>
            </a>
            <button mat-button
                    (click)="removeMacs()"
                    [disabled]="dataSource?.selection.isEmpty()"
                    matTooltip="{{ 'mipsWhiteList.removeMac' | translate }}">
                <ic-icon [icon]="removeMacsIcon"> </ic-icon>
            </button>
        </div>
    </mat-toolbar>
    <mat-slide-toggle [formControl]="whitelistEnabled"
                      color="primary"
                      #whitelistToggle>
        {{ 'mipsWhiteList.settings.rule' | translate }}
        <b>{{ (whitelistToggle.checked ? "mipsWhiteList.settings.enabled" : "mipsWhiteList.settings.disabled") | translate }}.</b>
    </mat-slide-toggle>

    <div fxLayout="column">
        <table mat-table
               matSort
               [dataSource]="dataSource"
               fxFlex>
            <ng-container matColumnDef="select">
                <th mat-header-cell
                    *matHeaderCellDef
                    style="width: 6em;">
                    <mat-checkbox (change)="$event ? dataSource.toggleAllSelected() : null"
                                  [checked]="dataSource?.selection.hasValue() && dataSource.isAllSelected()"
                                  [indeterminate]="dataSource?.selection.hasValue() && !dataSource.isAllSelected()">

                    </mat-checkbox>
                </th>
                <td mat-cell
                    *matCellDef="let entry">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? dataSource?.selection.toggle(entry) : null"
                                  [checked]="dataSource?.selection.isSelected(entry)">

                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="mac">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>{{ 'MAC' | translate }}</th>
                <td mat-cell
                    class="monospace"
                    *matCellDef="let entry">{{ entry.mac | mac }}</td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="dataSource?.selection.toggle(row)"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                       showFirstLastButtons></mat-paginator>
    </div>
</div>
