import { AfterViewInit, Component, Directive, ViewChild } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import mdiBackupRestore from "@iconify/icons-mdi/backup-restore"
import mdiCursorMove from "@iconify/icons-mdi/cursor-move"
import mdiDeleteForever from "@iconify/icons-mdi/delete-forever"
import mdiRedo from "@iconify/icons-mdi/redo"
import mdiShapePolygonPlus from "@iconify/icons-mdi/shape-polygon-plus"
import mdiUndo from "@iconify/icons-mdi/undo"
import mdiVectorPolyline from "@iconify/icons-mdi/vector-polyline"
import {
  FloorCSConfigResponse,
  FloorCSConfigsService,
  TransitionAreas,
  TransitionAreaShape,
  TransitionAreasService,
} from "@openapi/venue"
import { ImageDimensions, isImageDimensions, Point } from "@venue/api"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import { defaultStyle } from "@venue/components/map/styles/WallStyle"
import { CoordinateConverter, CurrentFloor } from "@venue/core"
import {
  ImageDimensionsReceiverMixin,
  LayerHistoryMixin,
  ObjectData,
  VectorLayerComponent,
} from "@venue/maps"
import { MapToolReceiverMixin } from "@venue/maps/utils/map-tool-receiver.mixin"
import { ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { Polygon } from "ol/geom"
import { BehaviorSubject, combineLatest, empty, merge, Observable, of, Subject } from "rxjs"
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  share,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators"
import { Mixin } from "ts-mixer"

class TAMapToolRecvMixin extends MapToolReceiverMixin<MapTool> {}

@Directive()
abstract class TransitionAreasComponentBase extends Mixin(
  ImageDimensionsReceiverMixin,
  TAMapToolRecvMixin,
  LayerHistoryMixin
) {}

@withUnsubscribe
@Component({
  selector: "transition-areas",
  templateUrl: "./transition-areas.component.html",
})
export class TransitionAreasComponent
  extends TransitionAreasComponentBase
  implements AfterViewInit
{
  readonly addPolygonIcon = mdiShapePolygonPlus
  readonly movePolygonIcon = mdiCursorMove
  readonly modifyPolygonIcon = mdiVectorPolyline
  readonly undoIcon = mdiUndo
  readonly redoIcon = mdiRedo
  readonly removeAllIcon = mdiDeleteForever
  readonly restoreSavedIcon = mdiBackupRestore

  readonly WRITE_USER_ROLE = "venue:transitions:write"

  /** Style of transition area's polygons. */
  taStyle = defaultStyle

  /** Emits when user clicks save button. */
  saveClicks = new Subject<void>()

  /** Observable indicating if loading overlay should be displayed. */
  loading = new BehaviorSubject<boolean>(true)

  /** Flag indicating that downloading floor coordinate system config failed. */
  noCS = false

  /** Flag indicating that geting image dimensions failed. */
  noImgDim = false

  /** Saved data that has been loaded from backend. */
  savedData: TransitionAreas | null = null

  /** Subject to inject newly saved transition areas data and reload component. */
  private taOverrideStream = new Subject<TransitionAreas>()

  @ViewChild("taLayer") taLayer: VectorLayerComponent

  private unsubscribe: Observable<void>

  constructor(
    public currentFloor: CurrentFloor,
    private floorCSConfigs: FloorCSConfigsService,
    private taService: TransitionAreasService,
    private coordinateConverter: CoordinateConverter,
    private dialog: MatDialog,
    private authUtilService: AuthUtilService
  ) {
    super()
  }

  ngAfterViewInit() {
    this.initHistoryMixin()

    // Get current floor (can later be changed to not rely on fetching floor data - only floor id is needed)
    const floorStream = this.currentFloor.floor.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
      shareReplay(1)
    )

    // On floor change - display loading indicator
    floorStream.subscribe(() => this.loading.next(true))

    // On floor change - reset errors
    floorStream.subscribe(() => this.resetErrors())

    // Get current floor coordinate system config
    const csStream = floorStream.pipe(
      filter((f) => !!f),
      switchMap((f) =>
        this.floorCSConfigs.getFloorCSConfig(f.id).pipe(catchError((err) => this.catchCSError(err)))
      ),
      takeUntil(this.unsubscribe),
      share()
    )

    // Get saved transition areas, but only after receiving cs config
    const taStream = merge(
      this.taOverrideStream.pipe(
        map((ta) => ({ data: ta, initHistory: false })),
        tap(() => this.loading.next(true))
      ),
      csStream.pipe(
        filter((cs) => !!cs),
        switchMap((cs) =>
          this.taService
            .getTransitionAreas(cs.floorId)
            .pipe(catchError((err) => this.catchTAError(err)))
        ),
        map((ta) => ({ data: ta, initHistory: true }))
      )
    ).pipe(takeUntil(this.unsubscribe))

    // Combine cs, transition areas and imgDim, then initialize converter and load data
    combineLatest(
      csStream,
      this.imgDimSubject.pipe(
        switchMap((dimOrErr) => {
          if (isImageDimensions(dimOrErr)) {
            return of(dimOrErr)
          }

          return this.catchIDError(dimOrErr)
        })
      ),
      taStream
    )
      .pipe(
        filter(([cs, imgDim, ta]) => !!cs && !!imgDim),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([cs, imgDim, ta]) => {
        this.coordinateConverter.load(cs, imgDim.height)
        this.loadSavedAreas(cs, imgDim, ta)
      })

    this.saveClicks
      .pipe(debounceTime(200), takeUntil(this.unsubscribe))
      .subscribe(() => this.save())
  }

  protected layersWithHistory(): VectorLayerComponent[] {
    return [this.taLayer]
  }

  /**
   * Check if user has permissions to perform CRUD operations.
   */
  hasUserWritePermissions() {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  /**
   * Save button should be disabled if downloading floor coordinate system config failed
   * or user does not have permissions to perform CRUD operations.
   */
  saveDisabled = this.noCS || !this.hasUserWritePermissions()

  /** Handle remove all button click by clearing the map and saving layer history state. */
  removeAll(): void {
    this.clear()
    this.layerHistory.saveState()
  }

  /** Handle restore button click by loading saved data. */
  restoreSaved(): void {
    this.taOverrideStream.next(this.savedData)
  }

  /** Clear the map */
  clear() {
    this.taLayer.clearFeatures()
  }

  private loadSavedAreas(cs: FloorCSConfigResponse, imgDim: ImageDimensions, ta: DataToLoad): void {
    if (ta.initHistory) {
      this.layerHistory.clear()
    }

    this.clear()

    this.loading.next(false)

    this.savedData = ta.data ?? null

    // if there are no transition areas, then there is nothing to load
    if (!ta?.data?.transitionAreas?.length) {
      return
    }

    // Otherwise, fill map with polygons
    this.taLayer.loadFeatures(this.toObjectData(ta.data))

    if (ta.initHistory) {
      this.layerHistory.init()
    } else {
      this.layerHistory.saveState()
    }
  }

  private save(): void {
    const progressDialog = this.openSaveProgressDialog()

    const transitionAreas = this.getDataFromMap()

    this.taService
      .saveTransitionAreas(this.currentFloor.getFloor().id, { transitionAreas })
      .pipe(
        takeUntil(this.unsubscribe),
        delay(500), // Delay 500ms to not instantly blink with the saving dialog
        finalize(() => progressDialog.close())
      )
      .subscribe(
        (data) => this.taOverrideStream.next(data),
        (err) => console.error(err) // TODO Show error on snackbar
      )
  }

  private toObjectData(data: TransitionAreas): ObjectData[] {
    return data.transitionAreas.map(
      (s): ObjectData => ({
        points: s.points.map((p) => this.coordinateConverter.toPixel(p)),
      })
    )
  }

  private getDataFromMap(): TransitionAreaShape[] {
    let sidx = 0

    const shapes = this.taLayer.source
      .getFeatures()
      .map((f) => f as Feature<Polygon>)
      .map((f) => this.toShape(f, sidx++))

    return shapes
  }

  private toShape(feature: Feature<Polygon>, id: number): TransitionAreaShape {
    const coords: Coordinate[] = feature.getGeometry().getCoordinates()[0]
    let points: Point[] = coords.map((c) => this.coordinateConverter.toMetric({ x: c[0], y: c[1] }))

    return {
      id,
      shape: "POLYGON",
      points,
    }
  }

  private resetErrors() {
    this.noCS = false
    this.noImgDim = false
  }

  /**
   * On cs error, set noCS error flag and return empty observable.
   *
   * Also hide loading indicator, nothing to load after this error.
   */
  private catchCSError(err: any): Observable<FloorCSConfigResponse> {
    console.error("Error fetching floor coordinate system config", err)
    this.noCS = true

    this.loading.next(false)

    return empty()
  }

  /**
   * On image dimensions error, set noImgDim flag and return empty observable.
   */
  private catchIDError(err: any): Observable<ImageDimensions> {
    console.error("Error getting image dimensions", err)

    this.noImgDim = true

    this.loading.next(false)

    return empty()
  }

  /**
   * On transition areas fetch error, return observable with null.
   *
   * With null TransitionAreas everything is fine, just there is no data to load.
   */
  private catchTAError(err: any): Observable<TransitionAreas> {
    console.warn("Error fetching transition areas", err)
    return of(null)
  }

  /**
   * Open ProgressDialog for save operation
   */
  private openSaveProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "transition-areas.save_in_progress",
    }
    return this.dialog.open(ProgressDialog, { data })
  }
}

interface DataToLoad {
  data: TransitionAreas
  initHistory: boolean
}

// TODO Another place with a copy of these - extract common tools to separate file
enum MapTool {
  ADD = "ADD",
  REMOVE = "REMOVE",
  MOVE = "MOVE",
  MODIFY = "MODIFY",
}
