import { VENUE_ROOT_STATE_NAME } from "@venue/venues/venues-state-names"
import { FloorFormComponent } from "../components/floor-form/floor-form.component"
import { EDIT_FLOOR_STATE_NAME } from "../floor.state-names"

export const EDIT_FLOOR_STATE = {
  parent: VENUE_ROOT_STATE_NAME,
  name: EDIT_FLOOR_STATE_NAME,
  // There can be a intermediate state floor root - would allow to improve header bar easily
  url: "/floorsmgmt/:floorId/edit",
  component: FloorFormComponent
}
