import { NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { WhitelistModule } from "@venue/whitelist"
import { SharedModule } from "../shared"
import * as mipsconfig from "./"

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: mipsconfig.MIPS_CONFIG_STATES }),
        SharedModule,
        WhitelistModule
    ],
    declarations: [
        mipsconfig.MipsConfigSidebarComponent,
        mipsconfig.MipsConfigComponent
    ]
})
export class MipsConfigModule {}
