<loading-overlay *ngIf="loading | async"></loading-overlay>
<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">

        <input #apImportInput
               type="file"
               hidden="true"
               accept="application/json"
               [formControl]="apImportForm">

        <access-points-sidebar #sidebar
                               class="sidebar"
                               [saveDisabled]="saveDisabled"
                               [importDisabled]="importDisabled"
                               (submit)="save()"
                               (import)="apImportInput.click()">
        </access-points-sidebar>

    </mat-sidenav>
    <mat-sidenav-content>
        <ng-container *ngIf="noCS || noImgDim">
            <div fxFlex
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 class="error-overlay">
                <h1 translate>editor-error.header</h1>
                <span *ngIf="noCS"
                      translate>editor-error.no-cs</span>
                <span *ngIf="noImgDim"
                      translate>editor-error.no-img-dim</span>
            </div>
        </ng-container>
        <openlayers-map *letVar="toggledMapTool | async as mapTool"
                        fxFlex>
            <floor-image-layer [floor]="currentFloor.floor | async"
                               (imgDim)="onImageDimChange($event)">
            </floor-image-layer>
            <vector-layer #apLayer
                          name="apLayer"
                          [style]="devicesStyle">
            </vector-layer>
            <select-feature-interaction #selectEditInteraction
                                        [layers]="[ apLayer ]"
                                        (selectionChange)="onFeatureEdit($event[0])"
                                        [disabled]="(toggledMapTool | async) !== 'EDIT'">
            </select-feature-interaction>
            <draw-interaction [layer]="apLayer"
                              drawType="Point"
                              [snap]="false"
                              (featureDrawn)="onFeatureDrawn($event)"
                              [disabled]="(toggledMapTool | async) !== 'ADD'">
            </draw-interaction>
            <remove-interaction [layers]="[ apLayer ]"
                                [disabled]="(toggledMapTool | async) !== 'REMOVE'"
                                (featureRemoved)="onFeatureRemoved($event)">
            </remove-interaction>
            <move-interaction [layers]="[ apLayer ]"
                              [disabled]="(toggledMapTool | async) !== 'MOVE'">
            </move-interaction>
            <map-toolbar>
                <mat-button-toggle-group name="mapTool"
                                         multiple
                                         [disabled]="!hasUserWritePermissions()"
                                         matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}"
                                         (change)="changeMapTool($event)">
                    <mat-button-toggle title='{{ "access-points.map-toolbar.add-ap" | translate }}'
                                       value="ADD"
                                       [disableRipple]="true">
                        <ic-icon [icon]="addApIcon"></ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "access-points.map-toolbar.remove-ap" | translate }}'
                                       value="REMOVE"
                                       [disableRipple]="true">
                        <ic-icon [icon]="removeApIcon"></ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "access-points.map-toolbar.move-ap" | translate }}'
                                       value="MOVE"
                                       [disableRipple]="true">
                        <ic-icon [icon]="moveApIcon"></ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "access-points.map-toolbar.edit-ap" | translate }}'
                                       value="EDIT"
                                       [disableRipple]="true">
                        <ic-icon [icon]="editApIcon"></ic-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </map-toolbar>
        </openlayers-map>
    </mat-sidenav-content>
</mat-sidenav-container>
