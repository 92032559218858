import { CollectionViewer, DataSource } from "@angular/cdk/collections"
import { Building, BuildingsService } from "@openapi/venue"
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs"
import { map, startWith, takeUntil, tap } from "rxjs/operators"

const PAGE_SIZE = 15

export class BuildingsDataSource implements DataSource<Building> {
  private dataCache: Building[] = []
  private fetchedPages = new Set<number>()

  private dataSubject = new BehaviorSubject<Building[]>([])
  private unsubscribe = new Subject<void>()
  private refreshSubject = new Subject<void>()

  constructor(private buildingsService: BuildingsService) {}

  connect(collectionViewer: CollectionViewer): Observable<Building[] | ReadonlyArray<Building>> {
    this.fetchPage()

    let refreshStream = this.refreshSubject.pipe(
      tap(() => this.clearCache()),
      startWith({}),
      takeUntil(this.unsubscribe)
    )

    combineLatest(collectionViewer.viewChange, refreshStream, (a, b) => a)
      .pipe(
        map((range) => {
          const startPage = this.getPageForIndex(range.start)
          const endPage = this.getPageForIndex(range.end)
          return { startPage, endPage }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(({ startPage, endPage }) => {
        for (let i = startPage; i <= endPage; i++) {
          this.fetchPage(i)
        }
      })
    return this.dataSubject.asObservable().pipe(takeUntil(this.unsubscribe))
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  refresh(): void {
    this.refreshSubject.next()
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / PAGE_SIZE)
  }

  private fetchPage(page = 0): void {
    if (this.fetchedPages.has(page)) {
      return
    }
    this.fetchedPages.add(page)

    this.buildingsService
      .getBuildings(page, PAGE_SIZE, ["name"])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (!this.dataCache.length) {
          this.dataCache = new Array(data.totalElements)
        }
        this.dataCache.splice(page * PAGE_SIZE, PAGE_SIZE, ...data.content)
        this.dataSubject.next(this.dataCache)
      })
  }

  private clearCache(): void {
    this.fetchedPages.clear()
  }
}
