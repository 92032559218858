import { Overlay, OverlayPositionBuilder, OverlayRef } from "@angular/cdk/overlay"
import { TemplatePortal } from "@angular/cdk/portal"
import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Injectable,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from "@angular/core"
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms"
import { NgbTimeAdapter, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap"
import { TranslateService } from "@ngx-translate/core"
import { withUnsubscribe } from "@venue/shared"
import { DateTime } from "luxon"
import { Subject } from "rxjs"
import { distinctUntilChanged, filter, map, take, takeUntil } from "rxjs/operators"

@Injectable()
export class LuxonTimeAdapter extends NgbTimeAdapter<DateTime> {
    fromModel(time: DateTime): NgbTimeStruct {
        if (!time) return null

        return {
            hour: time.hour,
            minute: time.minute,
            second: time.second
        }
    }

    toModel(time: NgbTimeStruct): DateTime {
        if (!time) return null
        return DateTime.fromObject(time)
    }
}

@withUnsubscribe
@Component({
    selector: "time-picker",
    templateUrl: "./time-picker.component.html",
    providers: [
        { provide: NgbTimeAdapter, useClass: LuxonTimeAdapter },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerComponent),
            multi: true
        }
    ]
})
export class TimePickerComponent
    implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
    @Input() label = this.translateService.instant("datetime-picker.time-label")
    @Input() min?: DateTime
    @Input() max?: DateTime

    @ViewChild("timePopover") timepickerTemplate: TemplateRef<any>
    @ViewChild("timeInput") timeInput: ElementRef

    time = new FormControl(DateTime.local())
    touched = new Subject<void>()

    private overlayRef: OverlayRef
    private templatePortal: TemplatePortal<any>

    private unsubscribe: Subject<void>

    constructor(
        private viewContainer: ViewContainerRef,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private translateService: TranslateService
    ) {}

    ngAfterViewInit(): void {
        this.templatePortal = new TemplatePortal(this.timepickerTemplate, this.viewContainer)

        this.time.valueChanges
            .pipe(
                takeUntil(this.unsubscribe),
                distinctUntilChanged((a, b) => +a === +b), // Protect from angularjs 'lets set it to the same value on each digest cycle' ;)
                map(v => [v, this.validateTime(v)]),
                filter(([preVaild, postValid]) => +preVaild !== +postValid) // Dont reset value to same value that triggered this
            )
            .subscribe(v => this.time.setValue(v))
    }

    ngOnChanges(changes: SimpleChanges): void {
        const time = this.validateTime(this.time.value)
        if (+time !== +this.time.value) {
            this.time.setValue(time)
        }
    }

    ngOnDestroy(): void {
        this.disposeTimepickerOverlay()
    }

    private validateTime(dateTime: DateTime): DateTime {
        if (!dateTime) return dateTime

        const sameDay = (d: DateTime): DateTime =>
            d
                ? d.set({
                      day: dateTime.day,
                      month: dateTime.month,
                      year: dateTime.year
                  })
                : d

        const min = sameDay(this.min)
        const max = sameDay(this.max)
        if (min && min > dateTime) {
            dateTime = min
        }
        if (max && max < dateTime) {
            dateTime = max
        }
        return dateTime
    }

    openTimepicker(): void {
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: "mat-overlay-transparent-backdrop",
            positionStrategy: this.overlayPositionBuilder
                .flexibleConnectedTo(this.timeInput)
                .withPositions([
                    {
                        originX: "end",
                        originY: "bottom",
                        overlayX: "end",
                        overlayY: "top",
                        offsetY: 10
                    }
                ])
        })
        this.overlayRef.attach(this.templatePortal)
        this.overlayRef
            .backdropClick()
            .pipe(take(1))
            .subscribe(() => this.disposeTimepickerOverlay())
    }

    getTimeString(): string {
        const time = this.time.value
        return time ? this.time.value.toLocaleString(DateTime.TIME_SIMPLE) : ""
    }

    private disposeTimepickerOverlay(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose()
            this.overlayRef = null
        }
    }

    writeValue(obj: DateTime): void {
        this.time.setValue(obj)
    }

    registerOnChange(fn: any): void {
        this.time.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(fn)
    }

    registerOnTouched(fn: any): void {
        this.touched.pipe(takeUntil(this.unsubscribe)).subscribe(fn)
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            throw new Error("Method not implemented.")
        }
    }
}
