import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import { FloorIpsImageComponent } from "../components"
import { FLOOR_IPS_IMAGE_STATE_NAME } from "../floor-cs.state-names"

export const FLOOR_IPS_IMAGE_STATE = {
  parent: EDITOR_STATE_NAME,
  name: FLOOR_IPS_IMAGE_STATE_NAME,
  url: "/:floorId/image",
  component: FloorIpsImageComponent,
}
