<mat-toolbar color="primary">
    <h3 class="title">
        <ic-icon [icon]="icon"> </ic-icon>
        {{ 'serverselect.dialog.title' | translate }}
    </h3>
</mat-toolbar>
<div class="content-wrapper add-server-dialog-wrapper">
    <form [formGroup]="addServerForm"
          fxLayout="column">
        <mat-dialog-content fxLayout="column"
                            fxFlex>
            <mat-form-field>
                <mat-label>{{ 'serverselect.dialog.name' | translate }}</mat-label>
                <input matInput
                       autocomplete="off"
                       id="server_name_input"
                       type="text"
                       required
                       formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'serverselect.dialog.protocol' | translate }}</mat-label>
                <mat-select required
                            formControlName="protocol">
                    <mat-option *ngFor="let p of protocols"
                                [value]="p">{{ p }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'serverselect.dialog.address' | translate }}</mat-label>
                <input matInput
                       autocomplete="off"
                       type="text"
                       required
                       formControlName="host">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'serverselect.dialog.port' | translate }}</mat-label>
                <input matInput
                       type="number"
                       required
                       formControlName="port">
            </mat-form-field>
            <div *ngIf="addServerForm.controls.port.invalid && (addServerForm.controls.port.dirty || addServerForm.controls.port.touched)"
                 class="alert alert-danger">
                Port must be from range 1-65535.
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button
                    type="submit"
                    color="primary"
                    [disabled]="!addServerForm.valid"
                    (click)="onOk()">
                {{ 'Ok' | translate }}
            </button>
            <button mat-button
                    (click)="onCancel()">{{ 'Cancel' | translate }}</button>
        </mat-dialog-actions>
    </form>
</div>
