import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import { FloorCSComponent } from "../components"
import { FLOOR_CS_STATE_NAME } from "../floor-cs.state-names"

export const FLOOR_CS_STATE = {
  parent: EDITOR_STATE_NAME,
  name: FLOOR_CS_STATE_NAME,
  url: "/:floorId/cs",
  component: FloorCSComponent,
}
