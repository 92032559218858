<div class="fill-parent"
     fxLayout="column">
    <mat-toolbar>
        <span fxFlex></span>
        <button mat-button
                color="primary"
                (click)="create()"
                matTooltip="{{ (hasUserWritePermissions() ? 'floor-list.create' : 'no_permissions_msg') 
                    | translate }}"
                *ngIf="isCRUDModeEnabled() | async"
                [disabled]="!hasUserWritePermissions()">
            <ic-icon [icon]="createFloorIcon"></ic-icon>
        </button>
    </mat-toolbar>
    <table mat-table
           matSort
           [dataSource]="dataSource"
           fxFlex>

        <ng-container matColumnDef="level">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="level">{{ 'floor-model.level' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">{{ entry.level || "0" }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>{{ 'floor-model.name' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">{{ entry.name }}</td>
        </ng-container>

        <ng-container matColumnDef="hasImage">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>{{ 'floor-model.has-image' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">
                <ic-icon class="image-status"
                         [ngClass]="{ 'has-image': entry.hasImage }"
                         [icon]="entry.hasImage ? hasImageIcon : noImageIcon"></ic-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="createdTime">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="createdAt">{{ 'floor-model.created-at' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">{{ entry.createdTime | datetime }}</td>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>{{ 'floor-model.updated-at' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">{{ entry.updatedAt | datetime }}</td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'floor-list.edit-column' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">
                <button mat-icon-button
                        color="primary"
                        (click)="edit(entry)"
                        matTooltip="{{ (hasUserWritePermissions() ? 'floor-list.edit' : 'no_permissions_msg') 
                            | translate }}"
                        [disabled]="!hasUserWritePermissions()">
                    <ic-icon [icon]="editFloorIcon"></ic-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="remove">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'floor-list.remove-column' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">
                <button mat-icon-button
                        color="primary"
                        (click)="remove(entry)"
                        matTooltip="{{ (hasUserWritePermissions() ? 'floor-list.remove' : 'no_permissions_msg') 
                            | translate }}"
                        [disabled]="!hasUserWritePermissions()">
                    <ic-icon [icon]="removeFloorIcon"></ic-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="traces">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>{{ 'floor-list.traces' | translate }}</th>
            <td mat-cell
                *matCellDef="let entry">
                <button mat-icon-button
                        color="primary"
                        (click)="openFloorTracesList(entry)"
                        matTooltip="{{ 'floor-list.traces-list-tooltip' | translate:entry }}">
                    <ic-icon [icon]="tracesIcon"></ic-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                   showFirstLastButtons></mat-paginator>
</div>
