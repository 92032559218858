<ng-template #template>
    <div class="ol-unselectable ol-control map-legend-container">
        <div class="legend"
             fxLayout="column">
            <div class="title"
                 translate>legend</div>
            <div class="elements"
                 fxLayout="column"
                 fxLayoutGap="0.2rem">
                <ng-content> </ng-content>
            </div>
        </div>
    </div>
</ng-template>
