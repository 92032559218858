const COLON_CHARS = /:/g
const EVERY_TWO_CHARS = /(..)(?=.)/g
const NON_MAC_CHARS = /[^a-fA-F0-9]/g

export function standarizedMac(mac: string): string {
  return mac
    .toUpperCase()
    .replace(COLON_CHARS, "")
    .replace(NON_MAC_CHARS, "")
    .replace(EVERY_TWO_CHARS, "$1:")
    .substring(0, 17)
}

export function standarizeIfCorrectType(mac: string, type: Source): string {
  mac = mac || ""
  if (isMacSource(type)) {
    return standarizedMac(mac)
  }
  return mac
}

export function internalMacFormat(mac: string): string {
  return mac
    .toLowerCase()
    .replace(COLON_CHARS, "")
    .substring(0, 12)
}

export enum Source {
    MIPS = "MIPS",
    SIPS = "SIPS",
    BLE = "BLE"
}

export function isMacSource(s: Source): boolean {
    return s === Source.BLE || s === Source.SIPS
}
