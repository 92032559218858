/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Time } from './time';


export interface AutomatedTuningSetting { 
    createdTime?: string;
    id?: number;
    startTime?: Time;
    status?: boolean;
    tuningDuration?: number;
    tuningRepeat?: AutomatedTuningSettingTuningRepeatEnum;
}
export enum AutomatedTuningSettingTuningRepeatEnum {
    Daily = 'DAILY',
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY',
    Monthly = 'MONTHLY',
    Yearly = 'YEARLY',
    OnlyOnce = 'ONLY_ONCE'
};



