import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import { MatTableDataSource } from "@angular/material/table"
import { PositioningModel } from "@venue/api"

class VerificationSummary {
  globalAverage: number
  averageError: number
  minError: number
  maxError: number
  constructor(model: PositioningModel) {
    this.globalAverage = model.globalAverage
    this.averageError = model.averageError
    this.minError = model.minError
    this.maxError = model.maxError
  }
}

interface VerificationEntry {
  [key: string]: string
}

function dataSourceFromModel(model: PositioningModel): MatTableDataSource<VerificationEntry> {
  const entries: VerificationEntry[] = []
  model.recordsStatistics.forEach((rowData, rowIdx) => {
    const entry: VerificationEntry = {}
    rowData.forEach((cellData, cellIdx) => {
      entry[model.columnNames[cellIdx]] = cellData
    })
    entries.push(entry)
  })
  return new MatTableDataSource(entries)
}

@Component({
  selector: "positioning-model-table",
  templateUrl: "./positioning-model-table.component.html",
})
export class PositioningModelTable implements OnInit, AfterViewInit {
  @Input() model: PositioningModel
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(MatPaginator) paginator: MatPaginator

  displayedColumns: string[]
  dataSource: MatTableDataSource<VerificationEntry>
  summary: VerificationSummary

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.displayedColumns = this.model.columnNames
    this.summary = new VerificationSummary(this.model)
  }

  ngAfterViewInit(): void {
    this.dataSource = dataSourceFromModel(this.model)

    this.sort.disableClear = true
    this.sort.sort({ disableClear: true, id: "Mean", start: "asc" })
    this.dataSource.sort = this.sort

    this.dataSource.paginator = this.paginator

    this.cdRef.detectChanges()
  }
}
