import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core"
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs"
import { StateService, UIRouterGlobals } from "@uirouter/core"
import { ACCESS_POINTS_EDITOR_STATE_NAME } from "@venue/access-points/access-points.state-names"
import { CurrentFloor } from "@venue/core"
import {
  FLOOR_CS_STATE_NAME,
  FLOOR_IPS_IMAGE_STATE_NAME,
  FLOOR_PROD_CS_STATE_NAME,
  FLOOR_WGS_MAP_STATE_NAME,
} from "@venue/floor-cs/floor-cs.state-names"
import { withUnsubscribe } from "@venue/shared"
import { TRANSITION_AREAS_STATE_NAME } from "@venue/transition-areas/transition-areas.state-names"
import { WALLS_EDITOR_STATE_NAME } from "@venue/walls/walls.state-names"
import { Observable } from "rxjs"
import { filter, map, startWith, takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "editor",
  templateUrl: "./editor.component.html",
})
export class EditorComponent implements AfterViewInit, OnDestroy {
  readonly tabs: string[] = [
    FLOOR_IPS_IMAGE_STATE_NAME,
    FLOOR_CS_STATE_NAME,
    FLOOR_PROD_CS_STATE_NAME,
    FLOOR_WGS_MAP_STATE_NAME,
    WALLS_EDITOR_STATE_NAME,
    ACCESS_POINTS_EDITOR_STATE_NAME,
    TRANSITION_AREAS_STATE_NAME,
  ]

  @ViewChild("editorTabs") editorTabs: MatTabGroup

  private unsubscribe: Observable<any>

  constructor(
    private currentFloor: CurrentFloor,
    private state: StateService,
    private router: UIRouterGlobals
  ) {}

  ngAfterViewInit(): void {
    this.currentFloor.floor
      .pipe(
        filter((f) => !!f),
        takeUntil(this.unsubscribe)
      )
      .subscribe((f) => {
        let currParams = this.state.params

        if (currParams.floorId == f.id || currParams.floor == f.id) {
          return
        }

        this.state.transitionTo(this.state.current.name, {
          venueId: currParams.venueId,
          floorId: f.id,
        })
      })

    this.router.success$
      .pipe(
        map((t) => t.$to().name),
        startWith(this.state.current.name),
        takeUntil(this.unsubscribe)
      )
      .subscribe((state) => this.stateChanged(state))
  }

  ngOnDestroy(): void {
    this.currentFloor.setFloor(null)
  }

  tabChanged(change: MatTabChangeEvent): void {
    const tab = this.tabs[change.index]

    if (this.state.current.name !== tab) {
      const params = this.state.params

      this.state.go(tab, params)
    }
  }

  stateChanged(state: string): void {
    this.editorTabs.selectedIndex = this.tabs.indexOf(this.state.current.name)
  }
}
