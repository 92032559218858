import { Component, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Floor } from "@openapi/venue"
import { CurrentFloor, CurrentVenue } from "@venue/core"
import { withUnsubscribe } from "@venue/shared"
import { Subject } from "rxjs"
import { filter, map, takeUntil } from "rxjs/operators"

// TODO React to floor in state ?
@withUnsubscribe
@Component({
  selector: "floor-select",
  templateUrl: "./floor-select.component.html",
})
export class FloorSelectComponent implements OnInit {
  floor = new FormControl(null)
  floors: Floor[] = []

  private unsubscribe: Subject<void>

  constructor(private currentFloor: CurrentFloor, private currentVenue: CurrentVenue) {}

  ngOnInit(): void {
    this.currentVenue.floors
      .pipe(
        map((f) => f ?? []),
        takeUntil(this.unsubscribe)
      )
      .subscribe((floors) => (this.floors = floors))

    this.currentFloor.floor
      .pipe(
        takeUntil(this.unsubscribe),
        filter((floor) => floor !== this.floor.value)
      )
      .subscribe((floor) => this.floor.setValue(floor))

    this.floor.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((floor) => this.currentFloor.setFloor(floor))
  }
}
