import { mapStyleFactory } from "@venue/maps"
import Fill from "ol/style/Fill"
import Stroke from "ol/style/Stroke"
import Style from "ol/style/Style"
import { SELECTED_WALL_STROKE, SELECTED_WALL_FILL, HOVERED_WALL_STROKE, HOVERED_WALL_FILL } from "./common-wall-styles";
import tinycolor = require("tinycolor2");

export const userWallMainColor = "#59A9FF"

export const USER_WALLS_DEFAULT_STROKE = new Stroke({
    color: userWallMainColor,
    width: 3
})

// Fill is required for hover (getFeatureAtPixel function) to work - but its alpha value can be 0
const userWallFill = new Fill({
    color: tinycolor(userWallMainColor).setAlpha(0).toRgbString()
})

const defaultStyle = new Style({
    stroke: USER_WALLS_DEFAULT_STROKE,
    fill: userWallFill
})

const selectedStyle = new Style({
    stroke: SELECTED_WALL_STROKE,
    fill: SELECTED_WALL_FILL
})

const hoveredStyle = new Style({
    stroke: HOVERED_WALL_STROKE,
    fill: HOVERED_WALL_FILL
})

export const USER_WALLS_STYLE = mapStyleFactory({
    defaultStyle,
    selectedStyle,
    hoveredStyle
})
