<loading-overlay *ngIf="loading"></loading-overlay>
<section fxLayout="column"
         fxLayoutGap="1rem">
    <mat-card *ngFor="let link of links"
              class="venue-menu-entry mat-elevation-z5"
              [ngClass]="{ 'disabled': link.requiresFloor && !floorsPresent }"
              (click)="onMenuClick(link)"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="1rem">
        <ic-icon [icon]="link.iconifyIcon"
                 width="2.5rem"
                 height="2.5rem"> </ic-icon>
        <div>
            <h3>{{ link.name }}</h3>
            <span>{{ link.description }}</span>
        </div>
    </mat-card>
</section>
