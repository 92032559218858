import { Component, OnInit } from "@angular/core"
import { FormBuilder, Validators } from "@angular/forms"
import { MatDialogRef } from "@angular/material/dialog"
import mdiFileOutline from "@iconify/icons-mdi/file-outline"
import { v4 as uuidv4 } from "uuid"

const LS_EXPORT_USER_KEY = "export-trace-user"

/**
 * Dialog with trace metadata form that needs to be filled before exporting trace to server.
 */
@Component({
  selector: "export-trace-dialog",
  templateUrl: "./export-trace.dialog.html",
})
export class ExportTraceDialog implements OnInit {
  readonly icon = mdiFileOutline

  traceMetadataForm = this.fb.group({
    user: ["", Validators.required],
    traceId: ["", Validators.required],
  })

  constructor(private dialogRef: MatDialogRef<ExportTraceDialog>, private fb: FormBuilder) {
    dialogRef.addPanelClass("dialog-with-toolbar")
  }

  ngOnInit() {
    const user = localStorage.getItem(LS_EXPORT_USER_KEY) ?? ""

    this.traceMetadataForm.patchValue({ user, traceId: uuidv4() })
  }

  onOk() {
    const value: ExportTraceDialogOutput = this.traceMetadataForm.value

    localStorage.setItem(LS_EXPORT_USER_KEY, value.user)

    this.dialogRef.close(value)
  }

  onCancel(): void {
    this.dialogRef.close()
  }
}

/**
 * Output of the dialog.
 *
 * This is a subset of TraceMetadata fields, that should be modifiable by the user.
 */
export interface ExportTraceDialogOutput {
  user: string
  traceId: string
}
