import { SelectionModel } from "@angular/cdk/collections"
import { Component, OnInit } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { MatSnackBar } from "@angular/material/snack-bar"
import mdiAlertOutline from "@iconify/icons-mdi/alert-outline"
import mdiPackageVariantClosed from "@iconify/icons-mdi/package-variant-closed"
import { TranslateService } from "@ngx-translate/core"
import { Floor, PackagesService, ValidationErrors, ValidationResult } from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import { CurrentVenue } from "@venue/core"
import { ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { VENUE_MENU_STATE_NAME } from "@venue/venues/venues-state-names"
import * as $ from "jquery"
import { BehaviorSubject, from, Subject } from "rxjs"
import { delay, filter, finalize, take, takeUntil } from "rxjs/operators"

interface FloorWithValidation extends Floor {
  validation: Partial<ValidationResult>
  validationWarning?: boolean
}

type ValidationCategory = keyof ValidationErrors

@withUnsubscribe
@Component({
  selector: "vv-generate-package-form",
  templateUrl: "./generate-package-form.component.html",
})
export class GeneratePackageForm implements OnInit {
  readonly dialogIcon = mdiPackageVariantClosed
  readonly alertIcon = mdiAlertOutline

  readonly WRITE_USER_ROLE = "venue:packages:write"

  floors: FloorWithValidation[] = []
  selection = new SelectionModel<Floor>(true, [])

  allFloorsSelected = new BehaviorSubject<boolean>(false)
  someFloorsSelected = new BehaviorSubject<boolean>(false)

  generationInProgress = false

  form = this.fb.group({
    packageDescription: [""],
  })

  private unsubscribe: Subject<void>
  private validFloors: Floor[] = []

  constructor(
    private dialog: MatDialog,
    private currentVenue: CurrentVenue,
    private packagesService: PackagesService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private authUtilService: AuthUtilService,
    private state: StateService
  ) {}

  ngOnInit(): void {
    this.currentVenue.floors
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((floors) => this.loadFloors(floors))

    this.selection.changed.pipe(takeUntil(this.unsubscribe)).subscribe((sc) => {
      const valid = this.validFloors
      const selected = sc.source.selected
      this.allFloorsSelected.next(valid.length !== 0 && selected.length === valid.length)
      this.someFloorsSelected.next(selected.length !== 0 && valid.length !== selected.length)
    })
  }

  /**
   * Check if user has permissions to perform CRUD operations.
   */
  hasUserWritePermissions(): boolean {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  onOk(): void {
    const progressDialog = this.openUploadProgressDialog()
    this.generationInProgress = true
    this.packagesService
      .generatePackage(
        this.currentVenue.getVenue().id,
        {
          description: this.form.value.packageDescription || null,
        },
        this.selection.selected.map((f) => f.id),
        "response"
      )
      .pipe(
        delay(500), // Delay 500ms to not instantly blink with the loading overlay
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      .subscribe(
        (resp) => {
          this.translate.stream("config_packages.generate.success_message").subscribe((msg) => {
            this.snackBar.open(msg, null, { duration: 5 * 1000 })
          })
          this.state.go(VENUE_MENU_STATE_NAME)
        },
        (error) => {
          this.translate.stream("config_packages.generate.error_message").subscribe((msg) => {
            this.snackBar.open(msg, null, { duration: 5 * 1000 })
          })
        }
      )
  }

  toggleFloor(floor: Floor): void {
    this.selection.toggle(floor)
  }

  toggleAllFloors(): void {
    this.allFloorsSelected.getValue()
      ? this.selection.clear()
      : this.validFloors.forEach((f) => this.selection.select(f))
  }

  private loadFloors(floors: Floor[]): void {
    this.packagesService
      .validateConfig(this.currentVenue.getVenue().id)
      .subscribe((buildingValidation) => {
        this.selection.clear()
        this.floors = $.extend(true, [], floors)
        this.floors.sort((a, b) => a.level - b.level)

        const validations = buildingValidation.results
        this.floors.forEach((f) => {
          f.validation = validations.find((v) => v.floorId === f.id) ?? { valid: false }

          let hasWarning = false
          const categories = Object.keys(f.validation.validation ?? {}) as ValidationCategory[]
          categories.forEach((c) => {
            from(f.validation.validation[c])
              .pipe(
                filter((ve) => !!ve),
                filter((ve) => ve.severity == "WARNING"),
                take(1)
              )
              .subscribe((v) => (hasWarning = true))
          })

          f.validationWarning = f.validation.valid && hasWarning
        })

        this.validFloors = this.floors.filter((f) => f.validation && f.validation.valid)
      })
  }

  private openUploadProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "config_packages.package_dialog.generation_message",
    }
    return this.dialog.open(ProgressDialog, { data })
  }
}
