import { wallDefaultStroke } from "@venue/components/map/styles/WallStyle"
import { mapStyleFactory } from "@venue/maps"
import { cloneDeep } from "lodash"
import { Stroke, Style } from "ol/style"

// TODO Extract common values as default style in maps module

const defaultStyle = new Style({
  stroke: new Stroke({
    color: wallDefaultStroke.getColor(),
    width: 10,
  }),
})

const selectedStyle = cloneDeep(defaultStyle)
selectedStyle.getStroke().setColor("rgb(0,100,200)")

const hoveredStyle = cloneDeep(defaultStyle)
hoveredStyle.getStroke().setColor("rgb(0,0,255)")

export const TracePathStyle = mapStyleFactory({
  defaultStyle,
  selectedStyle,
  hoveredStyle,
})
