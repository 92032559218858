import { DragDropModule } from "@angular/cdk/drag-drop"
import { ScrollingModule } from "@angular/cdk/scrolling"
import { NgModule } from "@angular/core"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { MatButtonModule } from "@angular/material/button"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { MatCardModule } from "@angular/material/card"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatRippleModule } from "@angular/material/core"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatDialogModule } from "@angular/material/dialog"
import { MatExpansionModule } from "@angular/material/expansion"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import { MatListModule } from "@angular/material/list"
import { MatMenuModule } from "@angular/material/menu"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatRadioModule } from "@angular/material/radio"
import { MatSelectModule } from "@angular/material/select"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatSlideToggleModule } from "@angular/material/slide-toggle"
import { MatSliderModule } from "@angular/material/slider"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatSortModule } from "@angular/material/sort"
import { MatStepperModule } from "@angular/material/stepper"
import { MatTableModule } from "@angular/material/table"
import { MatTabsModule } from "@angular/material/tabs"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatTooltipModule } from "@angular/material/tooltip"
import { BrowserModule } from "@angular/platform-browser"
import { NgbAlertModule, NgbPopoverModule, NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap"
import { TranslateModule } from "@ngx-translate/core"
import { UIRouterModule } from "@uirouter/angular"
import * as shared from "@venue/shared"
import { IconModule } from "@visurel/iconify-angular"

const MATERIAL_MODULES = [
  DragDropModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
]

const BOOTSTRAP_MODULES = [NgbPopoverModule, NgbTimepickerModule, NgbAlertModule]

const DIRECTIVES = [
  shared.MacDirective,
  shared.EmbeddedHtmlElementDirective,
  shared.LetVarDirective,
]

const COMPONENTS = [
  shared.ConfirmDialog,
  shared.DatePickerComponent,
  shared.DatetimePickerComponent,
  shared.FileSelectorComponent,
  shared.FloatingFabButton,
  shared.FloorSelectComponent,
  shared.HeaderBarComponent,
  shared.JsonFormatterComponent,
  shared.LoadingOverlayComponent,
  shared.MacInputComponent,
  shared.MainSidenavComponent,
  shared.ProgressDialog,
  shared.TimePickerComponent,
  shared.VenueSelectComponent,
]

const PIPES = [
  shared.DatetimePipe,
  shared.OverflowPipe,
  shared.DataSizePipe,
  shared.MacPipe,
  shared.AppConstantPipe,
]

@NgModule({
    imports: [
        BrowserModule,
        ...MATERIAL_MODULES,
        ...BOOTSTRAP_MODULES,
        ScrollingModule,
        TranslateModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        UIRouterModule.forChild(),
        IconModule,
    ],
    exports: [
        BrowserModule,
        ...MATERIAL_MODULES,
        ...BOOTSTRAP_MODULES,
        ScrollingModule,
        TranslateModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        ...DIRECTIVES,
        ...COMPONENTS,
        ...PIPES,
        IconModule,
    ],
    providers: [shared.MainSidenavService],
    declarations: [...DIRECTIVES, ...COMPONENTS, ...PIPES]
})
export class SharedModule {}
