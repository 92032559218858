import { HttpResponse } from "@angular/common/http"

export interface ImageDimensions {
  width: number
  height: number
}

export function toBlobWithDim(response: HttpResponse<Blob>): [Blob, ImageDimensions] {
  return [
    response.body,
    {
      width: parseInt(response.headers.get("width")),
      height: parseInt(response.headers.get("height")),
    },
  ]
}

export function isImageDimensions(v: any): v is ImageDimensions {
  let imgDim = v as ImageDimensions
  return imgDim == null || (v.width != undefined && v.height != undefined)
}
