import { Component, Host, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core"
import HighlightFeatureInteraction from "@venue/components/map/interactions/HighlightFeatureInteraction"
import MoveFeatureInteraction, {
  MOVE_FEATURES_EVENT,
} from "@venue/components/map/interactions/MoveFeatureInteraction"
import SelectFeatureInteraction from "@venue/components/map/interactions/SelectFeatureInteraction"
import { Feature } from "ol"
import { Subject } from "rxjs"
import { OpenlayersMapComponent, VectorLayerComponent } from "."

@Component({
  selector: "move-interaction",
  template: "",
})
export class MoveInteractionComponent implements OnChanges, OnDestroy {
  @Input() layers: VectorLayerComponent[] = []
  @Input() disabled?: boolean

  @Output() featuresMoved = new Subject<Feature<any>[]>()

  private selectInteraction: SelectFeatureInteraction
  private moveInteraction: MoveFeatureInteraction
  private highlightInteraction: HighlightFeatureInteraction

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.dispose()
    this.init()
  }

  ngOnDestroy(): void {
    this.dispose()
  }

  init(): void {
    if (this.disabled || !this.layers || !this.layers.length) {
      return
    }

    const layers = this.layers.map((l) => l.layer)
    const layerNames = this.layers.map((l) => l.name)

    this.selectInteraction = new SelectFeatureInteraction({
      layers,
      layerNames,
    })

    this.moveInteraction = new MoveFeatureInteraction({
      layers,
      layerNames,
      selectInteraction: this.selectInteraction,
    })

    this.highlightInteraction = new HighlightFeatureInteraction({ layers })

    this.mapComponent.map.addInteraction(this.selectInteraction)
    this.mapComponent.map.addInteraction(this.moveInteraction)
    this.mapComponent.map.addInteraction(this.highlightInteraction)

    // @ts-ignore
    this.moveInteraction.on(MOVE_FEATURES_EVENT, (e) => this.featuresMoved.next(e.features))
  }

  dispose(): void {
    if (this.selectInteraction) {
      this.mapComponent.map.removeInteraction(this.selectInteraction)
      this.selectInteraction = null
    }

    if (this.moveInteraction) {
      this.mapComponent.map.removeInteraction(this.moveInteraction)
      this.moveInteraction = null
    }

    if (this.highlightInteraction) {
      this.mapComponent.map.removeInteraction(this.highlightInteraction)
      this.highlightInteraction = null
    }
  }
}
