<loading-overlay *ngIf="loading"></loading-overlay>
<section fxLayout="column"
         fxLayoutGap="1rem">

    <h3>{{ (mode == 'create' ? 'venues.create-venue' : 'venues.edit-venue') | translate }}</h3>
    <form [formGroup]="form"
          fxLayout="column">

        <mat-form-field>
            <mat-label>{{ 'venue-form.name' | translate }}</mat-label>
            <input matInput
                   type="text"
                   required
                   formControlName="name">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'venue-form.slug' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="slug">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'venue-form.description' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="description">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'venue-form.country' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="country">
        </mat-form-field>

        <button mat-raised-button
                type="submit"
                color="primary"
                [disabled]="!form.valid"
                (click)="save()">
            {{ (mode == 'create' ? 'venue-form.create' : 'venue-form.update') | translate }}
        </button>
    </form>
</section>
