<div class="fill-parent"
     fxLayout="row" fxLayoutAlign="center">
    <mat-card class="building-whitelist-content" fxLayout="column">

        <section class="description" translate>
            mipsWhiteList.description
        </section>
        <mat-slide-toggle [formControl]="overrideGlobalControl">
            Override global whitelist configuration.
        </mat-slide-toggle>
        <whitelist *ngIf="overrideGlobalControl.value && reactToOverrideGlobalControl"></whitelist>
    </mat-card>
</div>
