<section fxLayout="column" fxLayoutGap="1rem">
    <h3>{{ "Create Configuration" | translate }}</h3>
    <div fxLayout="row">
        <div fxLayout="column" class="floors-checkboxes half-size">
            <mat-checkbox
                [checked]="allFloorsSelected | async"
                [indeterminate]="someFloorsSelected | async"
                (change)="$event ? toggleAllFloors() : null"
            >
                {{ "config_packages.package_dialog.select_all_floors" | translate }}
            </mat-checkbox>
            <div class="auto-overflow">
                <div *ngFor="let f of floors; let last = last">
                    <div class="list-item-container">
                        <ng-template #popover>
                            <vv-package-validation-popover [validation]="f.validation">
                            </vv-package-validation-popover>
                        </ng-template>
                        <div
                            class="list-item"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            [ngbPopover]="popover"
                            popoverClass="validation-popover"
                            triggers="mouseenter:mouseleave"
                            container="body"
                            placement="right"
                            autoClose="inside"
                            (click)="f.validation.valid ? selection.toggle(f) : null"
                        >
                            <mat-checkbox
                                [disabled]="!f.validation.valid"
                                (click)="$event.stopPropagation()"
                                (change)="$event ? toggleFloor(f) : null"
                                [checked]="selection.isSelected(f)"
                            >
                                {{
                                    "config_packages.package_dialog.floor_list_item" | translate : f
                                }}
                            </mat-checkbox>
                            <span fxFlex></span>
                            <ic-icon
                                *ngIf="!f.validation.valid || f.validationWarning"
                                [icon]="alertIcon"
                            ></ic-icon>
                        </div>
                        <mat-divider *ngIf="!last"></mat-divider>
                    </div>
                </div>
            </div>
        </div>
        <div class="break"></div>
        <mat-form-field class="half-size" [formGroup]="form">
            <mat-label id="uploadPackage-DescriptionLabel" translate>
                config_packages.package_dialog.description_label
            </mat-label>
            <textarea
                matInput
                id="uploadPackage-DescriptionInput"
                placeholder="{{
                    'config_packages.package_dialog.description_placeholder' | translate
                }}"
                formControlName="packageDescription"
            ></textarea>
        </mat-form-field>
    </div>

    <button
        mat-raised-button
        color="primary"
        [disabled]="selection.isEmpty()"
        (click)="onOk()"
        matTooltip="{{ (hasUserWritePermissions() ? '' : 'no_permissions_msg') | translate }}"
    >
        {{ "config_packages.package_dialog.generate_button" | translate }}
    </button>
</section>
