import {
  Component,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core"
import { AppConstants } from "@venue/app.constants"
import { View } from "ol"
import { Coordinate } from "ol/coordinate"
import { Tile as TileLayer } from "ol/layer"
import { fromLonLat, Projection } from "ol/proj"
import { OSM } from "ol/source"
import { OpenlayersMapComponent } from ".."

@Component({
  selector: "osm-layer",
  template: "",
})
export class OSMLayerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() name?: string
  @Output("projection") projectionOut = new EventEmitter<Projection>()

  readonly source = new OSM({ url: AppConstants.ipsOsmUrl })
  readonly layer = new TileLayer({
    source: this.source,
  })

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnInit(): void {
    this.mapComponent.map.setView(
      new View({
        center: fromLonLat([127.053084, 37.539763]),
        zoom: 18,
        maxZoom: 19,
        // Set the world extent (EPSG:3857, web mercator), so users can't move past world boundary
        // Reason: floor layer will display incorrectly on "wrapped" map
        extent: [-20026376.39, -20048966.1, 20026376.39, 20048966.1],
      })
    )

    this.mapComponent.map.addLayer(this.layer)

    this.projectionOut.next(this.mapComponent.map.getView().getProjection())
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.name) {
      this.layer.set("title", this.name)
    }
  }

  ngOnDestroy(): void {
    this.mapComponent.map.removeLayer(this.layer)
  }

  centerView(location: Coordinate): void {
    this.mapComponent.map.getView().setCenter(location)
  }
}
