import { Collection, Feature, Map as olMap, MapBrowserEvent } from "ol"
import { Modify } from "ol/interaction"
import { ModifyEvent } from "ol/interaction/Modify"
import { Vector as VectorLayer } from "ol/layer"
import { Vector as VectorSource } from "ol/source"
import AbstractMouseInteraction from "./AbstractMouseInteraction"

export const MODIFY_FEATURES_EVENT = "modifyfeatures"

export default class ModifyFeatureInteraction extends AbstractMouseInteraction {
  private layer: VectorLayer<VectorSource<any>>
  private source: VectorSource<any>
  private layerName: string

  private modifyInteraction: Modify
  private modifyInProgress = false

  private featuresRevisions = new Map<Feature<any>, number>()

  constructor(options: ModifyFeatureInteractionOptions) {
    super()

    Object.assign(this, options)
    this.source = this.layer.getSource()

    this.modifyInteraction = new Modify({ source: this.source })

    this.modifyInteraction.on("modifystart", (e) => this.onModifyStart(e))
    this.modifyInteraction.on("modifyend", (e) => this.onModifyEnd(e))
  }

  private onModifyStart(e: ModifyEvent): void {
    ;(e.features as Collection<Feature<any>>).forEach((f) =>
      this.featuresRevisions.set(f, f.getRevision())
    )
  }

  private onModifyEnd(e: ModifyEvent): void {
    if (this.modifyInProgress) {
      this.source.dispatchEvent("changed_" + this.layerName)
      this.modifyInProgress = false
    }

    const modifiedFeatures = (e.features as Collection<Feature<any>>)
      .getArray()
      .filter((f) => this.featuresRevisions.get(f) != f.getRevision())

    this.dispatchEvent({ type: MODIFY_FEATURES_EVENT, features: modifiedFeatures } as any)
  }

  handleBrowserEvent(e: MapBrowserEvent<any>): boolean {
    return super.handleBrowserEvent(e) && this.modifyInteraction.handleEvent(e)
  }

  down(e: MapBrowserEvent<any>): boolean {
    this.modifyInProgress = true
    // XXX Access to private api
    return (<any>this.modifyInteraction).handleDownEvent(e)
  }

  drag(e: MapBrowserEvent<any>): boolean {
    // XXX Access to private api
    const ret: boolean = (<any>this.modifyInteraction).handleDragEvent(e)
    this.source.dispatchEvent("preview_" + this.layerName)
    return ret
  }

  move(e: MapBrowserEvent<any>): boolean {
    // XXX Access to private api
    return (<any>this.modifyInteraction).handleMoveEvent(e)
  }

  up(e: MapBrowserEvent<any>): boolean {
    return (<any>this.modifyInteraction).handleUpEvent(e)
  }

  setMap(map: olMap): void {
    super.setMap(map)
    this.modifyInteraction.setMap(map)
  }
}

export interface ModifyFeatureInteractionOptions {
  layer: VectorLayer<VectorSource<any>>
  layerName: string
}
