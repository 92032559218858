import { Directive, Host, Input, OnDestroy, OnInit } from "@angular/core"
import { VectorLayerComponent } from "@venue/maps"
import RenderEvent from "ol/render/Event"
import { WallsCompositorService } from "./walls-compositor.service"

@Directive({
  selector: "vector-layer[wallsCompositor]",
})
export class WallsCompositorDirective implements OnInit, OnDestroy {
  @Input() outerWall = false

  private precomposeListener = (e: RenderEvent): void => this.precompose(e)
  private postcomposeListener = (e: RenderEvent): void => this.postcompose(e)

  constructor(
    @Host() private vectorLayer: VectorLayerComponent,
    private compositorService: WallsCompositorService
  ) {}

  ngOnInit(): void {
    this.vectorLayer.layer.on("prerender", this.precomposeListener)
    this.vectorLayer.layer.on("postrender", this.postcomposeListener)
  }

  ngOnDestroy(): void {
    this.vectorLayer.layer.un("prerender", this.precomposeListener)
    this.vectorLayer.layer.un("postrender", this.postcomposeListener)
  }

  private precompose(event: RenderEvent): void {
    this.compositorService.precompose(event, this.outerWall)
  }

  private postcompose(event: RenderEvent): void {
    this.compositorService.postcompose(event, this.outerWall)
  }
}
