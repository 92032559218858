import { Collection, Feature, Map, MapBrowserEvent } from "ol"
import { never } from "ol/events/condition"
import { getCenter } from "ol/extent"
import { Modify } from "ol/interaction"
import { ModifyEvent } from "ol/interaction/Modify"
import { Vector as VectorLayer } from "ol/layer"
import { Vector as VectorSource } from "ol/source"
import AbstractMouseInteraction from "./AbstractMouseInteraction"

/**
 * Rotate feature using its vertices (exactly like modify interaction)
 * and polygon center as rotation anchor.
 *
 * Based on https://openlayers.org/en/latest/examples/modify-scale-and-rotate.html
 */
export class ModifyRotateInteraction extends AbstractMouseInteraction {
  private layer: VectorLayer<VectorSource<any>>
  private source: VectorSource<any>

  private modifyInteraction: Modify

  constructor(options: ModifyRotateInteractionOptions) {
    super()

    Object.assign(this, options)
    this.source = this.layer.getSource()

    const defaultStyle = new Modify({ source: this.source }).getOverlay().getStyleFunction()

    this.modifyInteraction = new Modify({
      insertVertexCondition: never,
      deleteCondition: never,
      source: this.source,
      style: (feature: Feature<any>, res) => {
        feature.get("features").forEach((modifyFeature: Feature<any>) => {
          const modifyGeometry = modifyFeature.get("modifyGeometry")
          if (modifyGeometry) {
            const point = feature.getGeometry().getCoordinates()
            let modifyPoint = modifyGeometry.point
            if (!modifyPoint) {
              // save the initial geometry and vertex position
              modifyPoint = point
              modifyGeometry.point = modifyPoint
              modifyGeometry.geometry0 = modifyGeometry.geometry
              // get anchor and minimum radius of vertices to be used
              const center = getCenter(modifyGeometry.geometry0.getExtent())
              modifyGeometry.center = center
            }

            const center = modifyGeometry.center
            let dx, dy
            dx = modifyPoint[0] - center[0]
            dy = modifyPoint[1] - center[1]
            const initialRadius = Math.sqrt(dx * dx + dy * dy)
            const initialAngle = Math.atan2(dy, dx)
            dx = point[0] - center[0]
            dy = point[1] - center[1]
            const currentRadius = Math.sqrt(dx * dx + dy * dy)
            if (currentRadius > 0) {
              const currentAngle = Math.atan2(dy, dx)
              const geometry = modifyGeometry.geometry0.clone()
              geometry.rotate(currentAngle - initialAngle, center)
              modifyGeometry.geometry = geometry
              // modifyFeature.getGeometry().setCoordinates(geometry.getCoordinates())
            }
          }
        })
        return defaultStyle(feature, null)
      },
    })

    this.modifyInteraction.on("modifystart", (e) => this.onModifyStart(e))
    this.modifyInteraction.on("modifyend", (e) => this.onModifyEnd(e))
  }

  private onModifyStart(e: ModifyEvent): any {
    const features = e.features as Collection<Feature<any>>
    features.forEach((f) => {
      f.set("modifyGeometry", { geometry: f.getGeometry().clone() }, true)
    })
  }

  private onModifyEnd(e: ModifyEvent): any {
    const features = e.features as Collection<Feature<any>>
    features.forEach((f) => {
      const modifyGeometry = f.get("modifyGeometry")
      if (modifyGeometry) {
        f.setGeometry(modifyGeometry.geometry)
        f.unset("modifyGeometry", true)
      }
    })
  }

  down(e: MapBrowserEvent<any>): boolean {
    return (<any>this.modifyInteraction).handleDownEvent(e)
  }

  drag(e: MapBrowserEvent<any>): boolean {
    const ret: boolean = (<any>this.modifyInteraction).handleDragEvent(e)
    return ret
  }

  move(e: MapBrowserEvent<any>): boolean {
    return (<any>this.modifyInteraction).handleMoveEvent(e)
  }

  up(e: MapBrowserEvent<any>): boolean {
    return (<any>this.modifyInteraction).handleUpEvent(e)
  }

  setMap(map: Map): void {
    super.setMap(map)
    this.modifyInteraction.setMap(map)
  }
}

export interface ModifyRotateInteractionOptions {
  layer: VectorLayer<VectorSource<any>>
}
