import { CollectionViewer, DataSource } from "@angular/cdk/collections"
import { Pageable, PageInfo, PageInfoImpl, TuningHistory, TuningHistoryService } from "@venue/api"
import { BehaviorSubject, Observable, Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"

export class TuningHistoryDataSource implements DataSource<TuningHistory> {
    private dataSubject = new BehaviorSubject<TuningHistory[]>([])
    private pageInfoSubject = new BehaviorSubject<PageInfo>(new PageInfoImpl({}))
    private unsubscribe = new Subject<void>()

    constructor(private tuningHistoryService: TuningHistoryService) {}

    connect(collectionViewer: CollectionViewer): Observable<TuningHistory[]> {
        return this.dataSubject.asObservable()
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.dataSubject.complete()
    }

    get pageInfo(): Observable<PageInfo> {
        return this.pageInfoSubject.asObservable()
    }

    loadTuningHistory(pageable: Partial<Pageable>): void {
        this.tuningHistoryService
            .list(pageable)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                data._embedded.forEach(th => {
                    th.details = th.details || <any>{}
                })
                this.dataSubject.next(data._embedded)
                this.pageInfoSubject.next(data.page)
            })
    }
}
