import { Injectable } from "@angular/core"
import { CoordinateSystem, Point } from "@openapi/venue"
import { ImageDimensions } from "@venue/api"
import { RectangleDefinition } from "@venue/components/floor/floormap/transformations/DrawRectangleInteraction"
import { mat3 } from "gl-matrix"
import { Coordinate } from "ol/coordinate"

@Injectable()
export class FloorCSConvertHelperService {
  rectangleDefinitionFrom(pixelCS: CoordinateSystem, imgHeight: number): RectangleDefinition {
    return {
      blue: this.pixelCoordinateFrom(pixelCS.blue, imgHeight),
      yellow: this.pixelCoordinateFrom(pixelCS.yellow, imgHeight),
      pink: this.pixelCoordinateFrom(pixelCS.pink, imgHeight),
    }
  }

  pixelCoordinateFrom(point: Point, imgHeight: number): Coordinate {
    return [point.x, imgHeight - point.y]
  }

  pixelCSFrom(rect: RectangleDefinition, imgHeight: number): CoordinateSystem {
    return {
      blue: this.pixelPointFrom(rect.blue, imgHeight),
      yellow: this.pixelPointFrom(rect.yellow, imgHeight),
      pink: this.pixelPointFrom(rect.pink, imgHeight),
    }
  }

  metricCSFrom(width: number, length: number): CoordinateSystem {
    return {
      blue: this.metricPointFrom([0, 0]),
      yellow: this.metricPointFrom([width, 0]),
      pink: this.metricPointFrom([0, length]),
    }
  }

  pixelPointFrom(coord: Coordinate, imgHeight: number): Point {
    return { x: coord[0], y: imgHeight - coord[1] }
  }

  metricPointFrom(coord: Coordinate): Point {
    return { x: coord[0], y: coord[1] }
  }

  mat3From(rect: RectangleDefinition): mat3
  mat3From(imgDim: ImageDimensions): mat3
  mat3From(rectOrImgDim: RectangleDefinition | ImageDimensions): mat3 {
    if (this.isRectDef(rectOrImgDim)) {
      let rect = rectOrImgDim
      const [b, y, p] = [rect.blue, rect.yellow, rect.pink]

      // prettier-ignore
      return mat3.fromValues(
        b[0] , b[1] , 1 ,
        y[0] , y[1] , 1 ,
        p[0] , p[1] , 1
      )
    }

    // If not rect then img dim
    let imgDim = rectOrImgDim

    const [w, h] = [imgDim.width, imgDim.height]

    // prettier-ignore
    return mat3.fromValues(
      0 , 0 , 1 ,
      w , 0 , 1 ,
      0 , h , 1
    )
  }

  isRectDef(value: any): value is RectangleDefinition {
    let rect = value as RectangleDefinition
    return rect.blue !== undefined && Array.isArray(rect.blue)
  }
}
