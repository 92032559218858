import { Component, Input } from "@angular/core"

@Component({
    selector: "map-legend-element",
    templateUrl: "./map-legend-element.component.html"
})
export class MapLegendElementComponent {
    @Input() fillColor: string
    @Input() strokeColor: string
    @Input() translationKey: string
}
