export interface Point {
    x: number
    y: number
}

export function isPoint(o: any): o is Point {
    return (<Point>o).x !== undefined && (<Point>o).y !== undefined
}

export function pointOf(x: number, y: number): Point {
    return { x, y }
}
