import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import { FloorWgsComponent } from "../components"
import { FLOOR_WGS_EDITOR_STATE_NAME } from "../floor-cs.state-names"

// This is wgs editor
export const FLOOR_WGS_EDITOR_STATE = {
  parent: EDITOR_STATE_NAME,
  name: FLOOR_WGS_EDITOR_STATE_NAME,
  url: "/:floorId/wgs",
  component: FloorWgsComponent,
}
