import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    Renderer2,
    SimpleChanges
} from "@angular/core"

/**
 * Directive used to embed html element from a variable inside the template.
 * Initially created for development purposes to display element created
 * using document.createElement inside an element that can be hidden using *ngIf.
 */
@Directive({
    selector: "[embeddedHtmlElement]"
})
export class EmbeddedHtmlElementDirective implements OnChanges, OnDestroy {
    @Input() embeddedHtmlElement: HTMLElement

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngOnChanges(changes: SimpleChanges): void {
        const elementChange = changes.embeddedHtmlElement
        if (elementChange) {
            if (elementChange.previousValue) {
                this.renderer.removeChild(this.element.nativeElement, elementChange.previousValue)
            }
            if (elementChange.currentValue) {
                this.renderer.appendChild(this.element.nativeElement, elementChange.currentValue)
            }
        }
    }

    ngOnDestroy(): void {
        if (this.embeddedHtmlElement) {
            this.renderer.removeChild(this.element.nativeElement, this.embeddedHtmlElement)
        }
    }
}
