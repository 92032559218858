import { Component, Host, OnDestroy, OnInit } from "@angular/core"
// @ts-ignore
// TODO Define type for it or contribute to @types/ol ?
import FullScreen from "ol/control/FullScreen"
import { OpenlayersMapComponent } from "."

// XXX Consider changing to directive
@Component({
    selector: "ol-fullscreen",
    template: ""
})
export class OlFullscreenComponent implements OnInit, OnDestroy {
    private fullScreenControl = new FullScreen()

    constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

    ngOnInit(): void {
        this.mapComponent.map.addControl(this.fullScreenControl)
    }

    ngOnDestroy(): void {
        this.mapComponent.map.removeControl(this.fullScreenControl)
    }
}
