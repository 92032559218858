import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import mdiDelete from "@iconify/icons-mdi/delete"
import mdiFileDownload from "@iconify/icons-mdi/file-download"
import mdiFileUpload from "@iconify/icons-mdi/file-upload"
import { MobileRecordsControllerService, RecordMetadata } from "@openapi/mipsengine"
import { downloadBlob, withUnsubscribe } from "@venue/shared"
import { Subject } from "rxjs"
import { finalize, takeUntil } from "rxjs/operators"
import { MobileRecordsDataSource } from "./mobile-records.data-source"

@withUnsubscribe
@Component({
  selector: "mobile-records",
  templateUrl: "./mobile-records.component.html",
})
export class MobileRecordsComponent implements OnInit, AfterViewInit {
  readonly uploadRecordIcon = mdiFileUpload
  readonly deleteRecordIcon = mdiDelete
  readonly downloadRecordIcon = mdiFileDownload

  recordFiles = new FormControl("")
  recordsDataSource: MobileRecordsDataSource

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  displayedColumns = ["select", "time", "version", "floors", "download"]
  uploadInProgress = false

  private unsubscribe: Subject<void>
  private recordsInput: HTMLInputElement

  constructor(
    private recordsService: MobileRecordsControllerService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.recordFiles.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.uploadRecords())
    this.recordsInput = <HTMLInputElement>document.getElementById("recordsInput")
  }

  ngAfterViewInit(): void {
    this.sort.disableClear = true
    this.sort.sort({ id: "time", start: "desc", disableClear: true })
    this.recordsDataSource = new MobileRecordsDataSource(
      this.recordsService,
      this.paginator,
      this.sort
    )

    this.cdRef.detectChanges()
  }

  removeRecords(): void {
    const recordsToRemove = this.recordsDataSource.selection.selected.map((v) => v.filename)
    // TODO Snackbar
    this.recordsService
      .deleteMultipleMobileRecordsUsingPOST(recordsToRemove)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => this.recordsDataSource.refresh(),
        (error) => {
          console.error("Error")
        }
      )
  }

  selectRecordFiles(): void {
    this.recordsInput.click()
  }

  downloadRecord(record: RecordMetadata, event: Event): void {
    event.stopPropagation()
    this.recordsService
      .getMobileRecordsUsingGET(record.filename)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (blob) => downloadBlob(blob, `${record.filename}.record`),
        // TODO Snackbar
        (error) => console.error("Unable to download record")
      )
  }

  private uploadRecords(): void {
    this.uploadInProgress = true

    const files = Array.from(this.recordsInput.files)

    this.recordsInput.value = ""

    // TODO Snackbar
    this.recordsService
      .saveMobileRecordsUsingPOST(files)
      .pipe(
        finalize(() => (this.uploadInProgress = false)),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        (response) => {
          this.recordsDataSource.refresh()
          console.log(`Uploaded ${response.success} files successfully.`)

          response.fail.forEach((f) => console.warn(`Failed to upload ${f}`))
        },
        (error) => console.error("Error uploading files.")
      )
  }
}
