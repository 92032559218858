import {
  HOVERED_WALL_FILL,
  HOVERED_WALL_STROKE,
  SELECTED_WALL_FILL,
  SELECTED_WALL_STROKE,
} from "@venue/walls/map-styles/common-wall-styles"
import { Feature } from "ol"
import { Fill, Stroke, Style } from "ol/style"

const wallDefaultStroke = new Stroke({
  color: "#FF6600",
  width: 3,
})
const wallDefaultFill = new Fill({
  color: "rgba(255,102,0,0.3)",
})

// Wall Style
const wallDefaultStyle = new Style({
  stroke: wallDefaultStroke,
  fill: wallDefaultFill,
})
const wallSelectedStyle = new Style({
  stroke: SELECTED_WALL_STROKE,
  fill: SELECTED_WALL_FILL,
})
const wallHoveredStyle = new Style({
  stroke: HOVERED_WALL_STROKE,
  fill: HOVERED_WALL_FILL,
})

// Contour Style
const contourDefaultStyle = new Style({
  stroke: wallDefaultStroke,
})
const contourSelectedStyle = new Style({
  stroke: SELECTED_WALL_STROKE,
})
const contourHoveredStyle = new Style({
  stroke: HOVERED_WALL_STROKE,
})

export {
  wallDefaultStroke,
  wallDefaultFill,
  SELECTED_WALL_STROKE,
  SELECTED_WALL_FILL,
  HOVERED_WALL_STROKE,
  HOVERED_WALL_FILL,
}

export function defaultStyle(feature: Feature<any>): Style {
  if (feature.get("hovered")) {
    return wallHoveredStyle
  } else if (feature.get("selected")) {
    return wallSelectedStyle
  } else {
    return wallDefaultStyle
  }
}

export function onlyContourStyle(feature: Feature<any>): Style {
  if (feature.get("hovered")) {
    return contourHoveredStyle
  } else if (feature.get("selected")) {
    return contourSelectedStyle
  } else {
    return contourDefaultStyle
  }
}
