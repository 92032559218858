import {
  AfterViewInit,
  Component,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core"
import { Feature } from "ol"
import GeometryType from "ol/geom/GeometryType"
import Draw, { DrawEvent } from "ol/interaction/Draw"
import Snap from "ol/interaction/Snap"
import { Subject } from "rxjs"
import { OpenlayersMapComponent, VectorLayerComponent } from "."

@Component({
  selector: "draw-interaction",
  template: "",
})
export class DrawInteractionComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() layer: VectorLayerComponent
  @Input() drawType = GeometryType.POLYGON
  @Input() disabled?: boolean
  @Input() snap = true

  @Output() featureDrawn = new Subject<Feature<any>>()

  private drawInteraction: Draw
  private snapInteraction: Snap

  private initialized = false

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnDestroy(): void {
    this.dispose()
  }

  ngAfterViewInit(): void {
    this.initComponent()
    this.initialized = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      this.initComponent()
    }
  }

  private initComponent(): void {
    this.initInteraction()
  }

  private initInteraction(): void {
    this.dispose()

    if (this.disabled === true || !this.layer) {
      return
    }

    this.drawInteraction = new Draw({
      type: this.drawType,
      source: this.layer.source,
    })

    this.drawInteraction.on("drawend", (drawEndEvent: DrawEvent) => {
      this.featureDrawn.next(drawEndEvent.feature)
    })
    this.mapComponent.map.addInteraction(this.drawInteraction)

    if (this.snap) {
      this.snapInteraction = new Snap({ source: this.layer.source })
      this.mapComponent.map.addInteraction(this.snapInteraction)
    }
  }

  private dispose(): void {
    if (this.drawInteraction) {
      this.mapComponent.map.removeInteraction(this.drawInteraction)
      this.drawInteraction = null
    }

    if (this.snapInteraction) {
      this.mapComponent.map.removeInteraction(this.snapInteraction)
      this.snapInteraction = null
    }
  }
}
