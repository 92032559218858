import { vec2, vec3 } from 'gl-matrix'

export function getVector(a: vec3, b: vec3): vec3 {
    return vec3.fromValues(b[0] - a[0], b[1] - a[1], 0)
}

export function absVec3(v: vec3): vec3 {
    return vec3.fromValues(Math.abs(v[0]), Math.abs(v[1]), Math.abs(v[2]))
}

export function absVec2(v: vec2): vec2 {
    return vec2.fromValues(Math.abs(v[0]), Math.abs(v[1]))
}

export function angle(a: vec3, b: vec3): number {
    const angle = vec3.angle(a, b)

    // https://stackoverflow.com/questions/5188561/signed-angle-between-two-3d-vectors-with-same-origin-within-the-same-plane
    const cross = vec3.cross(vec3.create(), a, b)
    const planeNormal = vec3.fromValues(0,0,1)
    return angle * Math.sign(vec3.dot(planeNormal, cross))
}
