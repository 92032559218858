import { Component, Input, Output } from "@angular/core"
import { Subject } from "rxjs"

@Component({
    selector: "floor-wgs-sidebar",
    templateUrl: "./floor-wgs-sidebar.component.html"
})
export class FloorWgsSidebarComponent {
    @Input() saveDisabled?: boolean

    @Output() submit = new Subject<void>()

    // XXX There is no floor number input at the moment - it is required to set it right now!
}
