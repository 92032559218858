<div fxLayout="column" fxFlex>
    <mat-stepper #stepper>
        <mat-step>
            <ng-template matStepLabel>{{ "import-venue.pck-upload.step" | translate }}</ng-template>
            <package-uploader (selectImportPckEvent)="upload($event)"></package-uploader>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>{{ "import-venue.floors-map.step" | translate }}</ng-template>
            <floors-mapper
                [importSessionId]="importSessionId"
                (commitEvent)="commit($event)"
            ></floors-mapper>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>{{ "import-venue.done.step" | translate }}</ng-template>
        </mat-step>
    </mat-stepper>
</div>
