<cdk-virtual-scroll-viewport itemSize="87"
                             fxFlex>
    <div *cdkVirtualFor="let venue of dataSource"
         class="venue-card-container">
        <mat-card class="mat-elevation-z5"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  (click)="showVenue(venue)"
                  matRipple>
            <div fxLayout="column"
                 class="venueInfo"
                 fxLayoutAlign="start start"
                 fxFlex>
                <h3>
                    <ic-icon [icon]="venueIcon"></ic-icon>
                    {{ venue?.name }}
                </h3>
                <h4>{{ venue?.city }}</h4>
                <p>{{ venue?.country }}</p>
            </div>
            <span fxFlex></span>
            <div layout="row"
                 layout-align="end center">
                <button mat-icon-button
                        (click)="$event.stopPropagation(); editVenue(venue)"
                        matTooltip="{{ (hasUserWritePermissions() ? 'venues.edit-venue' : 'no_permissions_msg') 
                            | translate }}"
                        *ngIf="isCRUDModeEnabled() | async"
                        [disabled]="!hasUserWritePermissions()">
                    <ic-icon [icon]="editVenueIcon"></ic-icon>
                </button>
                <button mat-icon-button
                        (click)="$event.stopPropagation(); importVenue(venue)"
                        matTooltip="{{ (hasUserImportPermissions() ? 'venues.import-venue' : 'no_permissions_msg') 
                            | translate }}"
                        *ngIf="isCRUDModeEnabled() | async"
                        [disabled]="!hasUserImportPermissions()">
                    <ic-icon [icon]="importVenueIcon"></ic-icon>
                </button>
                <button mat-icon-button
                        (click)="$event.stopPropagation(); deleteVenue(venue)"
                        matTooltip="{{ (hasUserWritePermissions() ? 'venues.delete-venue' : 'no_permissions_msg') 
                            | translate }}"
                        *ngIf="isCRUDModeEnabled() | async"
                        [disabled]="!hasUserWritePermissions()">
                    <ic-icon [icon]="deleteVenueIcon"></ic-icon>
                </button>
            </div>
        </mat-card>
    </div>
</cdk-virtual-scroll-viewport>

<floating-fab-button tooltip="{{ (hasUserWritePermissions() ? 'venues.create-venue' : 'no_permissions_msg') 
                        | translate }}"
                     (click)="createVenue()"
                     *ngIf="isCRUDModeEnabled() | async"
                     [disabled]="!hasUserWritePermissions()"> </floating-fab-button>
