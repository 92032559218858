<loading-overlay *ngIf="loading | async"></loading-overlay>
<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">
        <walls-sidebar #sidebar
                       class="sidebar"
                       [saveDisabled]="saveDisabled"
                       [toggleDisabled]="!hasUserWritePermissions()">
        </walls-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-container *ngIf="noCS || noImgDim">
            <div fxFlex
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 class="error-overlay">
                <h1 translate>editor-error.header</h1>
                <span *ngIf="noCS"
                      translate>editor-error.no-cs</span>
                <span *ngIf="noImgDim"
                      translate>editor-error.no-img-dim</span>
            </div>
        </ng-container>
        <openlayers-map *letVar="toggledMapTool | async as mapTool"
                        fxFlex>
            <floor-image-layer [floor]="currentFloor.floor | async"
                               (imgDim)="onImageDimChange($event)">
            </floor-image-layer>
            <vector-layer #generatedOuterWallLayer
                          name="generatedOuterWallsLayer"
                          [style]="generatedWallsStyle"
                          wallsCompositor
                          [outerWall]=true>
            </vector-layer>
            <vector-layer #generatedWallsLayer
                          name="generatedWallsLayer"
                          [style]="generatedWallsStyle"
                          wallsCompositor>
            </vector-layer>
            <vector-layer #userWallsLayer
                          name="userWallsLayer"
                          [style]="userWallsStyle">
            </vector-layer>
            <vector-layer #doorsLayer
                          name="doorsLayer"
                          [style]="doorsStyle">
            </vector-layer>
            <draw-interaction [layer]="userWallsLayer"
                              [drawType]="mapTool === 'ADD_WALL_CIRCLE' ? 'Circle' : 'Polygon'"
                              [snap]="true"
                              [disabled]="mapTool !== 'ADD_WALL' && mapTool !== 'ADD_WALL_CIRCLE'"
                              (featureDrawn)="onUserWallDrawn($event)">
            </draw-interaction>
            <draw-interaction [layer]="doorsLayer"
                              drawType="Polygon"
                              [snap]="true"
                              [disabled]="mapTool !== 'ADD_DOOR'"
                              (featureDrawn)="onUserDoorDrawn($event)">
            </draw-interaction>
            <remove-interaction [layers]="[ userWallsLayer, doorsLayer ]"
                                [disabled]="mapTool !== 'REMOVE'"
                                (featureRemoved)="onFeatureRemoved($event)">
            </remove-interaction>
            <move-interaction [layers]="[ userWallsLayer, doorsLayer ]"
                              [disabled]="mapTool !== 'MOVE'"
                              (featuresMoved)="onFeaturesMoved($event)">
            </move-interaction>
            <modify-interaction [layers]="[ userWallsLayer, doorsLayer ]"
                                [disabled]="mapTool !== 'MODIFY'"
                                (featuresModified)="onFeaturesModified($event)">
            </modify-interaction>
            <map-toolbar>
                <mat-button-toggle-group name="mapTool"
                                         multiple
                                         [disabled]="!hasUserWritePermissions()"
                                         matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate ) : '' }}"
                                         (change)="changeMapTool($event)">
                    <mat-button-toggle title='{{ "walls.map_toolbar.draw_wall" | translate }}'
                                       value="ADD_WALL"
                                       [disableRipple]="true">
                        <img height="32px"
                             width="32px"
                             src="assets/images/shape-polygon-solid-plus.svg">
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "walls.map_toolbar.draw_wall" | translate }}'
                                       value="ADD_WALL_CIRCLE"
                                       [disableRipple]="true">
                        <img height="32px"
                             width="32px"
                             src="assets/images/shape-circle-solid-plus.svg">
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "walls.map_toolbar.draw_door" | translate }}'
                                       value="ADD_DOOR"
                                       [disableRipple]="true">
                        <ic-icon [icon]="addDoorIcon"> </ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "polygon_editor.controls.delete_polygon_tooltip" | translate }}'
                                       value="REMOVE"
                                       [disableRipple]="true">
                        <img height="32px"
                             width="32px"
                             src="assets/images/shape-polygon-minus.svg">
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "polygon_editor.controls.move_polygon_tooltip" | translate }}'
                                       value="MOVE"
                                       [disableRipple]="true">
                        <ic-icon [icon]="moveIcon"> </ic-icon>
                    </mat-button-toggle>
                    <mat-button-toggle title='{{ "polygon_editor.controls.modify_polygon_tooltip" | translate }}'
                                       value="MODIFY"
                                       [disableRipple]="true">
                        <ic-icon [icon]="modifyIcon"> </ic-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <button title='{{ "polygon_editor.controls.undo_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate ) : '' }}"
                        (click)="undo()"
                        [disabled]="!canUndo">
                    <ic-icon [icon]="undoIcon"> </ic-icon>
                </button>
                <button title='{{ "polygon_editor.controls.redo_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate ) : '' }}"
                        (click)="redo()"
                        [disabled]="!canRedo">
                    <ic-icon [icon]="redoIcon"> </ic-icon>
                </button>
                <button title='{{ "constraints_generator.detect_doors.button_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate ) : '' }}"
                        (click)="detectDoors()"
                        [disabled]="!hasUserWritePermissions()">
                    <ic-icon [icon]="detectDoorIcon"> </ic-icon>
                </button>
                <button title='{{ "polygon_editor.controls.delete_all_polygons_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate ) : '' }}"
                        (click)="removeAll()"
                        [disabled]="!hasUserWritePermissions()">
                    <ic-icon [icon]="removeAllIcon"> </ic-icon>
                </button>
                <button title='{{ "polygon_editor.controls.reset_polygon_layer_tooltip" | translate }}'
                        matTooltip="{{ !hasUserWritePermissions() ? ( 'no_permissions_msg' | translate ) : '' }}"
                        (click)="resetWallsToSavedState()"
                        [disabled]="!hasUserWritePermissions()">
                    <ic-icon [icon]="resetWallsIcon"> </ic-icon>
                </button>
            </map-toolbar>
            <map-legend>
                <map-legend-element [fillColor]="generatedWallsFillColor()"
                                    translationKey="walls.legend.generated_walls"> </map-legend-element>
                <map-legend-element fillColor="white"
                                    translationKey="walls.legend.accessible_areas"> </map-legend-element>
                <map-legend-element [strokeColor]="userWallsStrokeColor()"
                                    translationKey="walls.legend.user_walls"> </map-legend-element>
                <map-legend-element [strokeColor]="accessibleAreasStrokeColor()"
                                    translationKey="walls.legend.user_doors"> </map-legend-element>
            </map-legend>
        </openlayers-map>
        <div #floodCanvasContainer
             *ngIf="(sidebar.showFloorCanvas | async)"
             [embeddedHtmlElement]="constraintsGenerator.canvas">

        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
