import { Pipe, PipeTransform } from "@angular/core"

const units = {
    "KB": 1000,
    "MB": 1000000,
    "GB": 1000000000
}

@Pipe({
    name: "dataSize"
})
export class DataSizePipe implements PipeTransform {
    transform(value: number, unit: "KB" | "MB" | "GB"): string {
        return `${ (value / units[unit]).toFixed(2) } ${unit}`
    }
}
