import { NgModule } from '@angular/core'

import { UIRouterModule } from "@uirouter/angular"

import * as tuningHistory from './'
import { SharedModule } from '@venue/shared'

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: tuningHistory.TUNING_HISTORY_STATES }),
        SharedModule
    ],
    declarations: [
        tuningHistory.TuningHistoryDetailsDialog,
        tuningHistory.TuningHistoryComponent
    ]
})
export class TuningHistoryModule {}
