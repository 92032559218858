import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import { FloorWgsMapComponent } from "../components"
import { FLOOR_WGS_MAP_STATE_NAME } from "../floor-cs.state-names"

export const FLOOR_WGS_MAP_STATE = {
  parent: EDITOR_STATE_NAME,
  name: FLOOR_WGS_MAP_STATE_NAME,
  url: "/:floorId/wgs-map",
  component: FloorWgsMapComponent,
}
