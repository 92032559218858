import {
  Component,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core"
import { Floor, FloorCSConfigsService, FloorsService } from "@openapi/venue"
import { ImageDimensions, toBlobWithDim } from "@venue/api"
import { CoordinateTransforms } from "@venue/components/floor/floormap/transformations/CoordinateTransforms"
import { withUnsubscribe } from "@venue/shared"
import { isEqual } from "lodash"
// @ts-ignore
import { getCenter } from "ol/extent"
import Image from "ol/layer/Image"
import { addCoordinateTransforms } from "ol/proj"
import Projection from "ol/proj/Projection"
import ImageStatic from "ol/source/ImageStatic"
import View from "ol/View"
import { combineLatest, Observable, of, Subject, throwError } from "rxjs"
import {
  catchError,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators"
import { OpenlayersMapComponent } from "."

export interface ImageColorParams {
  red?: number
  green?: number
  blue?: number
}

export enum ImageType {
  BASE = "base",
  ADJUST = "adjust",
  PROD = "prod",
}

type ImageTypeValue = `${ImageType}`

@withUnsubscribe
@Component({
  selector: "floor-image-layer",
  template: "",
})
export class FloorImageLayerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() floor: Floor

  /**
   * If base (prototype) image should be downloaded
   *
   * @deprecated superseeded by imageType
   */
  @Input() protoImage?: boolean

  /**
   * Type of image source to use
   */
  @Input() imageType?: ImageTypeValue = ImageType.ADJUST

  /**
   * Get type of image source to use.
   *
   * Handles imageType and deprecated protoImage in one go.
   *
   * Returns:
   * - ImageType.ADJUST if protoImage is false
   * - ImageType.BASE if protoImage is true
   * - null if imageType has invalid value
   * - value of imageType if protoImage is undefined and imageType has valid value
   */
  private getImageType(): ImageTypeValue {
    if (this.protoImage === undefined) {
      if (
        this.imageType == ImageType.BASE ||
        this.imageType == ImageType.ADJUST ||
        this.imageType == ImageType.PROD
      ) {
        return this.imageType
      }

      console.error("Incorrect ImageType passed from template: ", this.imageType)
      return null
    }

    return this.protoImage ? ImageType.BASE : ImageType.ADJUST
  }

  /**
   * Tint to request on the image
   */
  @Input() tint?: ImageColorParams

  /**
   * Used to apply coordinate transforms between this layer projection and map projection.
   */
  @Input() mapProjection: Projection

  /**
   * Indicates if this layer should initialize map projection.
   *
   * Use together with mapProjection.
   */
  @Input() initMapProjection = true

  /**
   * Coordinate transforms to apply between this layer projection and map projection.
   *
   * Use together with mapProjection.
   */
  @Input() transforms?: CoordinateTransforms

  /** Opacity of the layer */
  @Input() opacity?: number

  // XXX If this stays and will still be used in components - it needs to emit some value
  // after error, otherwise it will break views after downloading image fails
  // ex: when there is no image
  @Output("imgDim") imgDimOutput = new EventEmitter<ImageDimensions | string>()

  @Output("projection") projectionOut = new EventEmitter<Projection>()

  private imgUrl: string
  private imgDim: ImageDimensions
  private imageLayer = new Image()

  private transformsCallbackRemover?: () => void

  private imgRequestStream = new Subject<ImgRequest>()
  private projAndTransformStream = new Subject<ProjAndTransform>()

  private unsubscribe: Observable<void>

  constructor(
    @Host() private mapComponent: OpenlayersMapComponent,
    private floors: FloorsService,
    private floorCSConfigs: FloorCSConfigsService
  ) {}

  ngOnInit(): void {
    this.mapComponent.map.addLayer(this.imageLayer)
    this.imageLayer.setOpacity(this.opacity ?? 100)

    const imgStream = this.imgRequestStream.pipe(
      startWith({
        floorId: this.floor?.id,
        imageType: this.getImageType(),
        tint: this.tint,
      }),
      distinctUntilChanged(isEqual),
      switchMap((request) =>
        this.requestImage(request).pipe(
          map((data) => ({ data, request } as ImgData)),
          tap((imgData) => {
            // On image change  - emit img dimensions
            let img = imgData.data
            let dim = img ? img[1] : null
            this.imgDimOutput.emit(dim)
          }),
          catchError((err) => {
            // On image error - pass error to imgDimOutput and recover with null
            this.imgDimOutput.emit(err)
            return of(null)
          })
        )
      ),
      takeUntil(this.unsubscribe)
    )

    combineLatest(
      imgStream,
      this.projAndTransformStream.pipe(
        startWith({
          initMapProjection: this.initMapProjection,
          mapProjection: this.mapProjection,
          transforms: this.transforms,
        })
      )
    )
      .pipe(
        tap(() => this.dispose()),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([imgData, pt]) => this.loadImage(imgData, pt))
  }

  ngOnDestroy(): void {
    this.dispose()
    this.mapComponent.map.removeLayer(this.imageLayer)
  }

  private requestImage(imgReq: ImgRequest): Observable<[Blob, ImageDimensions]> {
    const floorId = imgReq.floorId

    if (floorId == null) {
      // No floor = no image
      return of(null)
    }

    if (imgReq.imageType == ImageType.BASE && this.tint) {
      console.error("Tint unsuported for proto image")
      return of(null)
    }

    let imgObservable: Observable<[Blob, ImageDimensions]>

    if (imgReq.imageType == ImageType.BASE) {
      imgObservable = this.floorCSConfigs
        .getBaseFloorIpsImage(floorId, "response")
        .pipe(map(toBlobWithDim))
    } else if (imgReq.imageType == ImageType.ADJUST) {
      imgObservable = this.floorCSConfigs
        .getFloorIpsImage(floorId, this.tint?.red, this.tint?.green, this.tint?.blue, "response")
        .pipe(map(toBlobWithDim))
    } else if (imgReq.imageType == ImageType.PROD) {
      imgObservable = this.floors
        .getFloorProdImage(floorId, this.tint?.red, this.tint?.green, this.tint?.blue, "response")
        .pipe(map(toBlobWithDim))
    } else {
      const err =
        "Unknown type of image source used. This is an error in the application code and should not happen."
      console.error(err)
      return throwError(err)
    }

    return imgObservable.pipe(
      catchError((err) => {
        let errMsg = "No image could be downloaded"
        console.error(errMsg)
        return throwError(errMsg)
      })
    )
  }

  private dispose(): void {
    if (this.imgUrl) {
      URL.revokeObjectURL(this.imgUrl)
      this.imgUrl = null
      this.imgDim = null
    }

    if (this.transformsCallbackRemover) {
      this.transformsCallbackRemover()
      this.transformsCallbackRemover = null
    }

    this.imageLayer.setSource(null)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.floor || changes.protoImage || changes.imageType || changes.tint) {
      this.imgRequestStream.next({
        floorId: this.floor?.id,
        imageType: this.getImageType(),
        tint: this.tint,
      })
    }

    if (changes.mapProjection || changes.initMapProjection || changes.transforms) {
      this.projAndTransformStream.next({
        initMapProjection: this.initMapProjection,
        mapProjection: this.mapProjection,
        transforms: this.transforms,
      })
    }

    if (changes.opacity) {
      this.imageLayer.setOpacity(this.opacity ?? 100)
    }
  }

  private loadImage(img: ImgData, pt: ProjAndTransform): void {
    // No image - nothing to do
    if (!img?.data) {
      return
    }

    // Proceed with image
    this.imgUrl = URL.createObjectURL(img.data[0])
    this.imgDim = img.data[1]

    // Create projection for this image
    const projection = this.getImageProjection(img.data[1], img.request.imageType)

    // If needed - initialize map view with this image projection
    if (pt.initMapProjection) {
      this.initMapView(projection)
    }

    // Initialize image layer
    this.initImageLayer(projection)

    // If transforms and mapProjection are provided - setup coordinate transforms with openlayers
    if (pt.transforms && pt.mapProjection) {
      addCoordinateTransforms(
        this.mapProjection,
        projection,
        (c) => this.transforms.toLocal(c),
        (c) => this.transforms.toWorld(c)
      )

      if (this.transformsCallbackRemover != null) {
        throw new Error("Transforms already registered")
      }

      this.transformsCallbackRemover = this.transforms.onChange(() =>
        this.imageLayer.getSource().changed()
      )
    }

    this.projectionOut.emit(projection)
  }

  private getImageProjection(imgDim: ImageDimensions, imageType: ImageTypeValue): Projection {
    const { width, height } = imgDim
    return new Projection({
      code: imageType + "_image_proj",
      units: "pixels",
      extent: [-width, -height, 2 * width, 2 * height],
    })
  }

  private initImageLayer(projection: Projection): void {
    const { width, height } = this.imgDim

    this.imageLayer.setSource(
      new ImageStatic({
        url: this.imgUrl,
        projection: projection,
        imageSize: [width, height],
        imageExtent: [0, 0, width, height],
      })
    )

    this.mapComponent.map.updateSize()
  }

  private initMapView(projection: Projection): void {
    const view = new View({
      extent: projection.getExtent(),
      zoom: 3,
      minZoom: 2,
      maxZoom: 7,
      projection,
      center: getCenter(projection.getExtent()),
    })
    this.mapComponent.map.setView(view)
  }
}

interface ImgRequest {
  floorId: number
  imageType: ImageTypeValue
  tint?: ImageColorParams
}

interface ImgData {
  data?: [Blob, ImageDimensions]
  request: ImgRequest
}

interface ProjAndTransform {
  initMapProjection: boolean
  mapProjection?: Projection
  transforms?: CoordinateTransforms
}
