<loading-overlay *ngIf="loading | async"></loading-overlay>
<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">
        <floor-cs-sidebar class="sidebar"
                          [(width)]="width"
                          [(length)]="length"
                          [inputDisabled]="!(inputEnabled | async)"
                          [submitDisabled]="!(saveEnabled | async)"
                          (submit)="save()">
        </floor-cs-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-container *ngIf="(noImgDim | async) || (noAdjImgDim | async)">
            <div fxFlex
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 class="error-overlay">
                <h1 translate>editor-error.header</h1>
                <span *ngIf="noImgDim | async"
                      translate>editor-error.no-img-dim</span>
                <span *ngIf="noAdjImgDim | async"
                      translate>editor-error.no-adj-img-dim</span>
            </div>
        </ng-container>

        <button *ngIf="toBaseButtonVisible | async"
                mat-raised-button
                [disabled]="!hasUserWritePermissions()"
                matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}"
                class="state-switch"
                color="primary"
                (click)="switchMode()">
            {{ 'floor.setup_switcher_button.second_setup_button' | translate }}
        </button>
        <button *ngIf="toAdjustButtonVisible | async"
                mat-raised-button
                class="state-switch"
                color="warn"
                (click)="switchMode()">
            {{ 'floor.setup_switcher_button.first_setup_button' | translate }}
        </button>

        <openlayers-map fxFlex>
            <map-control *ngIf="adjustMode | async"
                         class="ol-opacity-container">
                <label>{{ 'floor.second_setup.opacity_slider.text_label' | translate }}</label>
                <input #opacitySlider
                       type="range"
                       class="ol-opacity-slider"
                       [formControl]="opacityForm">
                <label>{{ opacitySlider.value }}%</label>
            </map-control>
            <floor-image-layer *ngIf="mapVisible | async"
                               [floor]="currentFloor.floor | async"
                               [protoImage]="adjustMode | async"
                               (imgDim)="onImageDimChange($event)"
                               (projection)="setMapProjection($event)">
            </floor-image-layer>
            <floor-image-layer *ngIf="adjustMode | async"
                               [floor]="currentFloor.floor | async"
                               [mapProjection]="mapProjection"
                               [transforms]="transforms"
                               [initMapProjection]="false"
                               [tint]='{ "red": -50, "green": 50, "blue": -50 }'
                               [opacity]="opacityForm.value / 100.0"
                               (imgDim)="hasUserWritePermissions() ? onAdjustImageDimChange($event) : loading.next(false)">
            </floor-image-layer>
            <vector-layer #labelLayer
                          name="labelLayer"
                          [zIndex]="1">
            </vector-layer>
            <draw-rectangle-interaction (rectangleDrawn)="onRectangleDrawn($event)"
                                        [disabled]="!(drawRectangleEnabled | async)">
            </draw-rectangle-interaction>
            <map-toolbar>
                <button *ngIf="baseMode | async"
                        title='{{ "floor.first_setup.clear_features_control.button_tooltip" | translate }}'
                        [disabled]="!(clearBaseCSButtonEnabled | async)"
                        (click)="clearBaseCS()">
                    <ic-icon width="32px"
                             height="32px"
                             [icon]="clearBaseCSIcon"> </ic-icon>
                </button>
                <button *ngIf="adjustMode | async"
                        title='{{ "floor.second_setup.adjust_image.auto_adjust_tooltip" | translate }}'
                        (click)="autoAdjustImage()"
                        [disabled]="!hasUserWritePermissions()"
                        matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}">
                    <img height="32px"
                         width="32px"
                         src="assets/images/cog_arrows.png">
                </button>
                <button *ngIf="adjustMode | async"
                        title='{{ "floor.second_setup.adjust_image.reset_tooltip" | translate }}'
                        (click)="resetAdjustImage()"
                        [disabled]="!hasUserWritePermissions()"
                        matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}">
                    <ic-icon width="32px"
                             height="32px"
                             [icon]="resetImageIcon"> </ic-icon>
                </button>
            </map-toolbar>
        </openlayers-map>
    </mat-sidenav-content>
</mat-sidenav-container>
