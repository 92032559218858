import { Feature } from "ol"
import { Style } from "ol/style"
import { StyleFunction } from "ol/style/Style"

const HOVERED_PROPERTY_KEY = "hovered"
const SELECTED_PROPERTY_KEY = "selected"

export interface EditorFeatureStyles {
  defaultStyle: Style | StyleFunction
  selectedStyle?: Style | StyleFunction
  hoveredStyle?: Style | StyleFunction
}

export function mapStyleFactory(s: EditorFeatureStyles): StyleFunction {
  return (feature: Feature<any>, viewRes: number) => {
    let result: Style | StyleFunction
    if (s.hoveredStyle && feature.get(HOVERED_PROPERTY_KEY)) {
      result = s.hoveredStyle
    } else if (s.selectedStyle && feature.get(SELECTED_PROPERTY_KEY)) {
      result = s.selectedStyle
    } else {
      result = s.defaultStyle
    }
    return typeof result === "function" ? result(feature, viewRes) : result
  }
}
