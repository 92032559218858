import { Feature } from "ol"
import BaseEvent from "ol/events/Event"
import { Source } from "ol/source"
import { VectorLayerComponent } from "../components/vector-layer.component"
import { LayerHistory } from "../layer-history"

/**
 * Adds LayerHistory to the class and provides
 * default implementation for interaction callbacks,
 * that just call layerHistory.saveState()
 *
 * Classes using it still need to call layerHistory.init() after loading
 * data from backend or layerHistory.saveState() after performing any
 * modifications to layers.
 *
 * Because of Angular (poor) magic, it is required that classes using this mixin
 * call initHistoryMixin() in ngAfterViewInit() method, so ts-mixer does not
 * run into problem with mixing Angular decorators.
 */
export abstract class LayerHistoryMixin {
  protected layerHistory: LayerHistory

  get canUndo(): boolean {
    return this.layerHistory?.canUndo() ?? false
  }

  get canRedo(): boolean {
    return this.layerHistory?.canRedo() ?? false
  }

  protected abstract layersWithHistory(): VectorLayerComponent[]

  initHistoryMixin(): void {
    this.layerHistory = new LayerHistory(this.layersWithHistory())
  }

  undo(): void {
    this.layerHistory.undo()
  }

  redo(): void {
    this.layerHistory.redo()
  }

  onFeatureDrawn(feature: Feature<any>): void {
    let layerListeners = new Map<Source, (v: BaseEvent) => void>()

    // Feature is not yet available in layer source, but will be added next
    this.layersWithHistory().forEach((l) => {
      let listener = () => {
        // Once feature has been added to the layer - save history state
        this.layerHistory.saveState()

        // ... and then, remove all listeners
        layerListeners.forEach((l, s) => s.un("change", l))
      }
      l.source.on("change", listener)

      layerListeners.set(l.source, listener)
    })
  }

  onFeatureRemoved(feature: Feature<any>): void {
    this.layerHistory.saveState()
  }

  onFeaturesMoved(features: Feature<any>[]): void {
    this.layerHistory.saveState()
  }

  onFeaturesModified(features: Feature<any>[]): void {
    this.layerHistory.saveState()
  }

  onFeatureTransformed(features: Feature<any>): void {
    this.layerHistory.saveState()
  }
}
