import { FLOOR_CS_STATE } from "./floor-cs.state"
import { FLOOR_IPS_IMAGE_STATE } from "./floor-ips-image.state"
import { FLOOR_PROD_CS_STATE } from "./floor-prod-cs.state"
import { FLOOR_WGS_EDITOR_STATE } from "./floor-wgs-editor.state"
import { FLOOR_WGS_MAP_STATE } from "./floor-wgs-map.state"

export const FLOOR_CS_STATES = [
  FLOOR_CS_STATE,
  FLOOR_IPS_IMAGE_STATE,
  FLOOR_PROD_CS_STATE,
  FLOOR_WGS_EDITOR_STATE,
  FLOOR_WGS_MAP_STATE,
]
