export const AppConstants = {
  dev: devModeEnabled(),
  defaultLanguage: defaultLanguage(),
  ipsOsmUrl: ipsOsmUrl(),
  frontUrl: frontUrl(),
  frontBasePath: frontBasePath(),
  flags: featureFlags(),
}

function devModeEnabled(): boolean {
  return localStorage.getItem("dev-mode") === "true"
}

function defaultLanguage(): string {
  return "en"
}

function ipsOsmUrl(): string {
  return "https://ips.sprc.samsung.pl/tile/{z}/{x}/{y}.png"
}

function frontBasePath(): string {
  const pathname = window.location.pathname
  const lastSlashIdx = pathname.lastIndexOf("/")

  // In case someone entered by /basePath/index.html, we remove what is left after last slash
  // This would not work if our url would be something like http://some.domain/basePath#/
  // where pathname would be '/basePath', without the slash,
  // but I think there is no such case
  const basePath = pathname.substring(0, lastSlashIdx)

  return basePath
}

function frontUrl(): string {
  return window.location.origin + frontBasePath()
}

// Possible refactor required, features not used for long
function featureFlags() {
  return {
    serverSelection: false,
    quickLanguageSelection: false,
    logout: false,
  }
}
