import { Feature, Map, MapBrowserEvent } from "ol"
import { Coordinate } from "ol/coordinate"
import { LineString, Point, Polygon } from "ol/geom"
import { DragBox } from "ol/interaction"
import { Layer } from "ol/layer"
import { Vector as VectorSource } from "ol/source"
import AbstractMouseInteraction from "./AbstractMouseInteraction"

export interface SelectFeatureOptions {
  filter?: FeatureFilter
  multiSelect?: boolean
  layers: Layer<any>[]
  layerNames: string[]
  selectedFeaturesArray?: Feature<any>[]
  onSelectionChange?: () => void
  enabled?: () => boolean
}

export class SelectFeatureInteraction extends AbstractMouseInteraction {
  private multiSelect: boolean
  private layers: Layer<any>[]
  private layerNames: string[]

  readonly selectedFeatures: Feature<any>[]
  private featuresToSelect: Feature<any>[] = [] // Used during drag to build-up selectedFeatures array

  private shouldSelect = (e: any): boolean => (e.pointerEvent || e.originalEvent).ctrlKey // @types insist that there is no ctrlKey property
  private dragBoxInteraction = new DragBox({
    condition: this.shouldSelect,
    onBoxEnd: null, // TODO Check if this can be used to simplify this interaction
  })

  private notifySelectionChange: () => void
  private isEnabled: () => boolean

  private selectionInProgress = false
  private filter: FeatureFilter

  private clearSourceListener = () => this.deselectAllFeatures()

  constructor(options: SelectFeatureOptions) {
    super()
    this.filter = options.filter || (() => true)
    this.multiSelect = options.multiSelect !== undefined ? options.multiSelect : true
    this.layers = options.layers
    this.layerNames = options.layerNames

    this.selectedFeatures =
      options.selectedFeaturesArray !== undefined ? options.selectedFeaturesArray : []
    this.notifySelectionChange = options.onSelectionChange ? options.onSelectionChange : () => {}
    this.isEnabled = options.enabled !== undefined ? options.enabled : () => true
  }

  // XXX Add deselect feature method?
  selectFeature(feature: Feature<any>): void {
    let selectionChanged = false
    if (feature == null && !this.multiSelect) {
      selectionChanged = this._deselectAllFeatures()
    } else if (feature) {
      selectionChanged = this._selectFeature(feature)
    }
    // Note: If added during eslint check. Makes sense, but could break something.
    if (selectionChanged) {
      this.notifySelectionChange()
    }
  }

  deselectAllFeatures(): void {
    if (this._deselectAllFeatures()) {
      this.notifySelectionChange()
    }
  }

  click(e: MapBrowserEvent<any>): void {
    if (!this.isEnabled()) {
      return
    }

    const [feature] = this.getFeatureAtPixel(e.pixel, this.layers)

    if (feature) {
      const fIdx = this.selectedFeatures.indexOf(feature)
      if (fIdx === -1) {
        this._selectFeature(feature)
      } else {
        this._deselectFeature(feature, fIdx)
      }
      this.notifySelectionChange()
    } else {
      if (this._deselectAllFeatures()) {
        this.notifySelectionChange()
      }
    }
  }

  /**
   * Retest already selected features with feature filter and unselect non matching
   */
  refilter(): void {
    this.selectedFeatures.filter((f) => !this.filter(f)).forEach((f) => this._deselectFeature(f))
    this.notifySelectionChange()
  }

  /**
   * @return true if selection changed
   */
  private _selectFeature(feature: Feature<any>): boolean {
    if (!this.filter(feature)) {
      return false
    }
    const wasSelected = feature.get("selected")
    const numOfAlreadySelected = this.selectedFeatures.length
    if (!this.multiSelect) {
      this._deselectAllFeatures()
    }
    if (!this.selectedFeatures.includes(feature)) {
      this.selectedFeatures.push(feature)
    }
    feature.set("selected", true)
    return !(wasSelected && numOfAlreadySelected === this.selectedFeatures.length)
  }

  private _deselectFeature(feature: Feature<any>, idx?: number): void {
    if (typeof idx === "undefined") {
      idx = this.selectedFeatures.indexOf(feature)
    }
    this.selectedFeatures.splice(idx, 1)
    feature.set("selected", false)
  }

  /**
   * @return true if any feature was deselected
   */
  private _deselectAllFeatures(): boolean {
    if (!this.selectedFeatures.length) {
      return false
    }
    this.selectedFeatures.slice().forEach((f) => this._deselectFeature(f))
    return true
  }

  down(e: MapBrowserEvent<any>): boolean {
    if (!this.isEnabled()) {
      return
    }

    if (this.shouldSelect(e) && this.multiSelect) {
      this.selectedFeatures.forEach((f) => f.set("__previouslySelected", true, true))
      this.selectionInProgress = true
      this.map.dispatchEvent("disablehover")
      return (<any>this.dragBoxInteraction).handleDownEvent(e)
    }
  }

  drag(e: MapBrowserEvent<any>): boolean {
    if (this.selectionInProgress) {
      const ret = (<any>this.dragBoxInteraction).handleDragEvent(e)
      this.featuresToSelect
        .filter((f) => !f.get("__previouslySelected"))
        .forEach((f) => f.set("selected", false))
      this.featuresToSelect = []
      const dragBoxGeometry = this.dragBoxInteraction.getGeometry()
      const extent = dragBoxGeometry.getExtent()

      this.layers.forEach((l) => {
        const source: VectorSource<any> = l.getSource()
        source.forEachFeatureIntersectingExtent(extent, (f) => {
          if (!this.filter(f)) {
            return
          }
          const g = f.getGeometry()
          const type = g.getType()
          let coords: Coordinate[]
          if (type === "Polygon") {
            coords = (<Polygon>g).getCoordinates()[0]
          } else if (type === "LineString") {
            coords = (<LineString>g).getCoordinates()
          } else {
            coords = [(<Point>g).getCoordinates()]
          }
          if (coords.every((c) => dragBoxGeometry.intersectsCoordinate(c))) {
            this.featuresToSelect.push(f)
            f.set("selected", true)
          }
        })
      })

      return ret
    }
  }

  up(e: MapBrowserEvent<any>): boolean {
    let selectionChanged = false
    if (this.selectionInProgress) {
      this.selectionInProgress = false
      this.map.dispatchEvent("enablehover")
      ;(<any>this.dragBoxInteraction).handleUpEvent(e)
      this.featuresToSelect.forEach((f) => {
        selectionChanged = this._selectFeature(f) || selectionChanged
      })
      this.featuresToSelect = []
    }
    this.layers.forEach((l, lIdx) => {
      l.getSource().dispatchEvent("changed_" + this.layerNames[lIdx])
    })
    if (selectionChanged) {
      this.notifySelectionChange()
    }
    this.selectedFeatures.forEach((f) => f.set("__previouslySelected", undefined, true))
    return false
  }

  setMap(map: Map): void {
    if (this.map) {
      this.layers
        .map((l) => l.getSource() as VectorSource<any>)
        .forEach((s) => s.un("clear", this.clearSourceListener))
    }

    this.deselectAllFeatures()
    super.setMap(map)

    if (!map) {
      return
    }

    this.layers
      .map((l) => l.getSource() as VectorSource<any>)
      .forEach((s) => s.on("clear", this.clearSourceListener))
  }
}

export default SelectFeatureInteraction

export type FeatureFilter = (f: Feature<any>) => boolean
