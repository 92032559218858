<section fxLayout="column"
         fxLayoutGap="0.5rem"
         fxFlex>
    <floor-select>
    </floor-select>

    <mat-slide-toggle [formControl]="imageBasedGenerationForm"
                      matTooltip="{{ ( !toggleDisabled ? 'walls.image-based-generation-tooltip' : 'no_permissions_msg' ) | translate }}"
                      [disabled]="toggleDisabled">
        {{ 'walls.image-based-generation' | translate }}
    </mat-slide-toggle>

    <button mat-raised-button
            color="primary"
            [disabled]="saveDisabled"
            matTooltip="{{ saveDisabled ? ('no_permissions_msg' | translate) : '' }}"
            type="submit"
            (click)="submit.next()">{{ 'Save' | translate }}</button>

    <section *ngIf="( 'dev' | appConstant )"
             fxLayout="column">
        <h3>Dev</h3>
        <mat-slide-toggle [formControl]="showFloorCanvasForm">
            Show flood fill canvas
        </mat-slide-toggle>
    </section>
</section>
