import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AutoTuningSettingsControllerService } from './api/auto-tuning-settings-controller.service';
import { MacGlobalWhitelistControllerService } from './api/mac-global-whitelist-controller.service';
import { MacWhiteListControllerService } from './api/mac-white-list-controller.service';
import { ManualTuningControllerService } from './api/manual-tuning-controller.service';
import { MobileRecordsControllerService } from './api/mobile-records-controller.service';
import { PackageControllerService } from './api/package-controller.service';
import { PackageReplacementControllerService } from './api/package-replacement-controller.service';
import { RecordingControllerService } from './api/recording-controller.service';
import { ServerScansControllerService } from './api/server-scans-controller.service';
import { SipsScanReceiverControllerService } from './api/sips-scan-receiver-controller.service';
import { TuningControllerService } from './api/tuning-controller.service';
import { TuningHistoryEntityService } from './api/tuning-history-entity.service';
import { VerificationControllerService } from './api/verification-controller.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
