import { Component, Input } from "@angular/core"
import { FormControl } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import mdiCloseCircle from "@iconify/icons-mdi/close-circle"
import { ServerConfig } from "@venue/api"
import { AppConstants } from "@venue/app.constants"
import { AddServerDialog } from "."

const SAVED_SERVERS_KEY = "saved_servers"

@Component({
  selector: "server-select",
  templateUrl: "./server-select.component.html",
})
export class ServerSelectComponent {
  readonly removeServerIcon = mdiCloseCircle

  @Input("serverFormControl") server: FormControl

  readonly serverSelectionEnabled = AppConstants.dev || AppConstants.flags.serverSelection
  servers: ServerConfig[]

  constructor(private dialog: MatDialog) {
    this.servers = JSON.parse(localStorage.getItem(SAVED_SERVERS_KEY))
    // Backward compatibility - previously server port was a string
    this.servers.forEach((s) => (s.port = parseInt(<any>s.port)))
  }

  ngOnInit(): void {
    if (this.serverSelectionEnabled) {
      this.server = this.server || new FormControl(null)
      this.initSelector()
    }
  }

  addServer(): void {
    const dialogRef = this.dialog.open(AddServerDialog)

    dialogRef.afterClosed().subscribe((server) => {
      if (server) {
        this.servers.push(server)
        this.saveServers()
        this.server.setValue(server)
      } else {
        this.server.setValue(null)
      }
    })
  }

  removeServer(e: Event, idx: number): void {
    e.stopPropagation()
    if (this.servers[idx] == this.server.value) {
      this.server.setValue(null)
    }
    this.servers.splice(idx, 1)
    this.saveServers()
  }

  private initSelector(): void {
    if (this.servers.length) {
      this.server.setValue(this.servers[0])
    }
  }

  private saveServers(): void {
    localStorage.setItem(SAVED_SERVERS_KEY, JSON.stringify(this.servers))
  }
}
