import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout"
import { Component, Input } from "@angular/core"
import { MatSidenav } from "@angular/material/sidenav"
import mdiChevronRight from "@iconify/icons-mdi/chevron-right"
import mdiMenu from "@iconify/icons-mdi/menu"
import mdiMenuDown from "@iconify/icons-mdi/menu-down"
import mdiServer from "@iconify/icons-mdi/server"
import { Building, Floor } from "@openapi/venue"
import { StateDeclaration, StateService, UIRouter } from "@uirouter/core"
import { AppConstants } from "@venue/app.constants"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import { CurrentFloor, CurrentVenue } from "@venue/core"
import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import {
  CREATE_FLOOR_STATE_NAME,
  EDIT_FLOOR_STATE_NAME,
  FLOOR_LIST_STATE_NAME,
} from "@venue/floor/floor.state-names"
import { withUnsubscribe } from "@venue/shared/decorators/with-unsubscribe.decorator"
import {
  TRACES_LIST_STATE_NAME,
  TRACE_PATH_FILE_STATE_NAME,
  TRACE_PATH_STATE_NAME,
} from "@venue/traces/traces.state-names"
import { IMPORT_VENUE_STATE_NAME, VENUE_ROOT_STATE_NAME } from "@venue/venues/venues-state-names"
import { Observable } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { MainSidenavService } from "../main-sidenav/main-sidenav.service"

@withUnsubscribe
@Component({
  selector: "header-bar",
  templateUrl: "./header-bar.component.html",
})
export class HeaderBarComponent {
  readonly menuIcon = mdiMenu
  readonly headerArrowIcon = mdiChevronRight
  readonly changeServerIcon = mdiServer
  readonly userMenuIcon = mdiMenuDown

  @Input() mainSidenav: MatSidenav

  venue: Observable<Building>
  floor: Observable<Floor>

  navigation: boolean
  substates: string[] = []
  shouldHideName = false

  private unsubscribe: Observable<any>

  constructor(
    public state: StateService,
    uiRouter: UIRouter,
    currentVenue: CurrentVenue,
    currentFloor: CurrentFloor,
    breakpointObserver: BreakpointObserver,
    public mainSidenavService: MainSidenavService,
    public authUtils: AuthUtilService
  ) {
    breakpointObserver
      .observe(Breakpoints.XSmall)
      .subscribe((result) => (this.shouldHideName = result.matches))

    this.venue = currentVenue.venue
    this.floor = currentFloor.floor

    uiRouter.globals.success$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((transition) => this.onStateChanged(transition.to()))
  }

  private onStateChanged(state: StateDeclaration): void {
    this.initSubstateName(state)
    this.initNavigation(state)
  }

  changeServer(): void {
    this.state.go("serverselect")
  }

  openSettings(): void {
    this.mainSidenav.open()
  }

  substateClick(substate: string): void {
    this.state.go(substate, this.state.params)
  }

  // TODO This component is getting ridiculous - re-implement it (issue: #161)
  private initSubstateName(state: StateDeclaration): void {
    this.substates = []

    // Old Angular.js editor states
    if (state.name.startsWith("app.editor.")) {
      let substate = state.name.substring(11) // Remove 'app.editor'
      const dotIdx = substate.indexOf(".")
      if (dotIdx > 0) {
        substate = substate.substring(0, dotIdx) // Remove '.itemSelected
      }

      this.substates.push(substate)
    }
    // New Angular editor states
    else if (state.parent == EDITOR_STATE_NAME || state.parent == IMPORT_VENUE_STATE_NAME) {
      this.substates.push(state.name)
    } else {
      let curState = state
      let parentStateName = curState.parent

      // Stop on empty or venue-root for now
      while (parentStateName && curState.name != VENUE_ROOT_STATE_NAME) {
        this.substates.push(curState.name)
        curState = this.state.get(parentStateName)
        parentStateName = curState.parent
      }

      // If there is no venue-root in hierarchy then remove substates
      // We are not yet ready for full dynamic header generation
      if (curState.name != VENUE_ROOT_STATE_NAME) {
        this.substates = []
      }

      let lastSubstate = this.substates[this.substates.length - 1]

      // If state is trace-path or trace-path-file, then insert traces-list before it
      if (lastSubstate == TRACE_PATH_STATE_NAME || lastSubstate == TRACE_PATH_FILE_STATE_NAME) {
        this.substates = [TRACES_LIST_STATE_NAME].concat(this.substates)
      }

      // If state is traces-list, trace-path or trace-path-file then insert floor name before it, to display for which floor we are fetching traces
      if (
        lastSubstate == TRACES_LIST_STATE_NAME ||
        lastSubstate == TRACE_PATH_STATE_NAME ||
        lastSubstate == TRACE_PATH_FILE_STATE_NAME
      ) {
        this.substates = ["CURRENT_FLOOR"].concat(this.substates)
      }

      // If state is create-floor, edit-floor, traces-list, trace-path or trace-path-file then insert floor-list state into substates
      // It isn't really part of state graph, but we want the link!
      if (
        lastSubstate == CREATE_FLOOR_STATE_NAME ||
        lastSubstate == EDIT_FLOOR_STATE_NAME ||
        lastSubstate == TRACES_LIST_STATE_NAME ||
        lastSubstate == TRACE_PATH_STATE_NAME ||
        lastSubstate == TRACE_PATH_FILE_STATE_NAME
      ) {
        this.substates = [FLOOR_LIST_STATE_NAME].concat(this.substates)
      }
    }
  }

  private initNavigation(state: StateDeclaration): void {
    const blockedStates = ["login", "serverselect"]
    this.navigation = !blockedStates.includes(state.name)
  }

  openAuthUserProfile(): void {
    const profileUrl = this.authUtils.authUserProfileUrl()
    const newTab = window.open(profileUrl, "_blank")

    newTab?.focus()

    if (!newTab) {
      console.error("Unable to open user profile in new tab")

      // Let's try again, this time in current tab and without checking if we succeded
      window.open(profileUrl)
    }
  }
}
