<section fxLayout="column"
         fxLayoutGap="0.5rem"
         fxFlex>
    <floor-select>
    </floor-select>

    <button mat-raised-button
            color="primary"
            matTooltip="{{ saveDisabled ? ( 'no_permissions_msg' | translate ) : '' }}"
            [disabled]="saveDisabled"
            type="submit"
            (click)="submit.next()">{{ 'Save' | translate }}</button>

    <button mat-raised-button
            matTooltip="{{ importDisabled ? ( 'no_permissions_msg' | translate ) : '' }}"
            [disabled]="importDisabled"
            (click)="import.next()">{{ 'Import' | translate }}</button>
</section>
