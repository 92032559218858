import { NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { MapsModule } from "@venue/maps"
import { SharedModule } from "@venue/shared"
import * as m from "."

@NgModule({
  imports: [
    UIRouterModule.forChild({ states: m.EDITOR_STATES }),
    SharedModule,
    MapsModule,
  ],
  declarations: [m.EditorComponent],
})
export class EditorModule {}
