import { registerLocaleData } from "@angular/common"
import { HttpClient, HttpClientModule } from "@angular/common/http"
import localeKo from "@angular/common/locales/ko"
import localePl from "@angular/common/locales/pl"
import { Injector, LOCALE_ID, NgModule } from "@angular/core"
import { FlexLayoutModule } from "@angular/flex-layout"
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS,
} from "@lib/angular-luxon-adapter"
import { NgbTimepickerConfig } from "@ng-bootstrap/ng-bootstrap"
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"
import { UIRouterModule } from "@uirouter/angular"
import { UIRouter } from "@uirouter/core"
import { Visualizer } from "@uirouter/visualizer"
import { AccessPointsModule } from "@venue/access-points"
import { AppConstants } from "@venue/app.constants"
import { AuthModule } from "@venue/auth"
import { ConfigModule } from "@venue/config"
import { ConfigPackagesModule } from "@venue/config-packages"
import { CoreModule } from "@venue/core"
import { EditorModule } from "@venue/editor"
import { FloorModule } from "@venue/floor"
import { FloorCSModule } from "@venue/floor-cs/floor-cs.module"
import { ImdfViewModule } from "@venue/imdf-view"
import { ImportModule } from "@venue/import"
import { MipsConfigModule } from "@venue/mips-config"
import { ModeModule } from "@venue/mode"
import { ProfileModule } from "@venue/profile"
import { ServerSelectionModule } from "@venue/server-selection"
import { SharedModule } from "@venue/shared"
import { TracesModule } from "@venue/traces/traces.module"
import { TransitionAreasModule } from "@venue/transition-areas/transition-areas.module"
import { TuningModule } from "@venue/tuning"
import { VenuesModule, VENUES_STATE_NAME } from "@venue/venues"
import { WallsModule } from "@venue/walls"
import { WhitelistModule } from "@venue/whitelist"
import isElectron from "is-electron"
import { DateTime, Settings as LuxonSettings } from "luxon"
import * as m from "."

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "assets/languages/", ".json")
}

registerLocaleData(localeKo, "ko")
registerLocaleData(localePl, "pl")

@NgModule({
  imports: [
    UIRouterModule.forRoot({ useHash: true, config: uiRouterConfig }),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule.withConfig({ useColumnBasisZero: false }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule,
    AccessPointsModule,
    ConfigModule,
    ConfigPackagesModule,
    CoreModule,
    EditorModule,
    FloorCSModule,
    FloorModule,
    ImdfViewModule,
    ImportModule,
    MipsConfigModule,
    ModeModule,
    ProfileModule,
    ServerSelectionModule,
    SharedModule,
    TracesModule,
    TransitionAreasModule,
    TuningModule,
    VenuesModule,
    WallsModule,
    WhitelistModule,
  ],
  providers: [
    // XXX Would import MatLuxonDateModule - but it does not work yet
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: LOCALE_ID, useValue: getCurrentLocale() },
  ],
  declarations: [m.AppComponent],
  bootstrap: [m.AppComponent],
})
export class AppModule {
  constructor(
    private translate: TranslateService,
    private ngbTimepickerConfig: NgbTimepickerConfig
  ) {
    if (isElectron()) {
      // Move to service or something?
      document.addEventListener("keydown", (e) => {
        if (e.shiftKey && e.ctrlKey && e.key == "I") {
          const { remote } = window.require("electron") as any
          remote.getCurrentWindow().toggleDevTools()
        }
      })
    }

    // XXX Find better place for initializing translation service?
    this.translate.setDefaultLang(AppConstants.defaultLanguage)
    const currentLocale = getCurrentLocale()
    this.translate.use(currentLocale)

    LuxonSettings.defaultLocale = currentLocale

    // Ugly but works
    this.ngbTimepickerConfig.meridian =
      DateTime.local().toLocaleString(DateTime.TIME_SIMPLE).toLowerCase().search(/am|pm/) !== -1
  }
}

function getCurrentLocale(): string {
  const localLang = getCurrentFrontendLocale()
  let currentLang = localLang ? localLang : AppConstants.defaultLanguage

  if (AppConstants.flags.quickLanguageSelection) {
    currentLang = localStorage.getItem("language") || currentLang
  }
  return currentLang
}

// Modified part of b.arcon 'language changed' feature, that uses value stored by frontend
// This implementation is simply incorrect, should query Foundation for the language
// TODO Decide later what to do with it - impl correctly or plain remove
function getCurrentFrontendLocale(): string {
  const settingsLocalStorage = JSON.parse(localStorage.getItem("settings"))
  return settingsLocalStorage ? settingsLocalStorage.language : AppConstants.defaultLanguage
}

function uiRouterConfig(router: UIRouter, injector: Injector) {
  router.urlService.rules.initial({ state: VENUES_STATE_NAME })

  if (AppConstants.dev) {
    router.plugin(Visualizer)
  }
}
