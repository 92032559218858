import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import { PageWhitelistMacAddress, WhitelistMacAddress } from "@openapi/mipsengine"
import { Pageable, pageableOf, WhitelistServiceInterface } from "@venue/api"
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs"
import { map, mergeMap, startWith, takeUntil, tap } from "rxjs/operators"

export class WhitelistDataSource implements DataSource<WhitelistMacAddress> {
    private dataSubject = new BehaviorSubject<WhitelistMacAddress[]>([])
    private unsubscribe = new Subject<void>()
    private refreshSubject = new Subject<void>()

    selection = new SelectionModel<WhitelistMacAddress>(true, [])

    constructor(
        private whitelistService: WhitelistServiceInterface,
        private paginator: MatPaginator,
        private sort: MatSort
    ) {}

    connect(collectionViewer: CollectionViewer): Observable<WhitelistMacAddress[]> {
        const pageableStream = combineLatest(
            this.sort.sortChange.pipe(startWith(this.sort)),
            this.paginator.page.pipe(startWith(this.paginator))
        ).pipe(
            takeUntil(this.unsubscribe),
            map(([sort, page]) => pageableOf(page, sort))
        )

        combineLatest(pageableStream, this.refreshSubject.pipe(startWith({})), (a, b) => a)
            .pipe(
                mergeMap(pageable => this.fetchPage(pageable)),
                tap(page => this.updatePaginator(page)),
                tap(page => this.refreshSelection(page))
            )
            .subscribe(page => this.dataSubject.next(page.content))

        return this.dataSubject.asObservable()
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.dataSubject.complete()
        this.refreshSubject.complete()
    }

    refresh(): void {
        this.refreshSubject.next()
    }

    isAllSelected(): boolean {
        return this.selection.selected.length == this.dataSubject.value.length
    }

    toggleAllSelected(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSubject.value.forEach(v => this.selection.select(v))
    }

    private fetchPage(pageable: Partial<Pageable>): Observable<PageWhitelistMacAddress> {
        return this.whitelistService.getEntries(pageable).pipe(takeUntil(this.unsubscribe))
    }

    private updatePaginator(page: PageWhitelistMacAddress): void {
        this.paginator.length = page.totalElements
    }

    /** Unselect not visible elements */
    private refreshSelection(page: PageWhitelistMacAddress): void {
        const selectedMacs = this.selection.selected.map(v => v.mac)
        const toSelect = page.content.filter(v => selectedMacs.includes(v.mac))
        const toDeselect = page.content.filter(v => !selectedMacs.includes(v.mac))

        this.selection.clear()
        this.selection.select(...toSelect)
        this.selection.deselect(...toDeselect)
    }
}
