<mat-form-field class="mac"
                fxFlex>
    <mat-label>{{ (label === null || label == undefined) ? ('MAC' | translate) : label }}</mat-label>
    <input #macInput
           matInput
           [(mac)]="macInput.value"
           type="text"
           [formControl]="mac"
           [required]="required" />
</mat-form-field>
