import { TuningHistoryComponent } from './'

const tuningHistoryState = {
    parent: "tuning",
    name: "tuning-history",
    url: "/tuning-history",
    component: TuningHistoryComponent
}

export const TUNING_HISTORY_STATES = [ tuningHistoryState ]
