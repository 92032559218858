import { Component, forwardRef, Input } from "@angular/core"
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { withUnsubscribe } from "@venue/shared"
import { DateTime } from "luxon"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"

// XXX Temporary control used in AngularJS part - most likely delete after upgrade
@withUnsubscribe
@Component({
  selector: "date-picker",
  templateUrl: "./date-picker.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() label = this.translateService.instant("datetime-picker.date-label")

  date = new FormControl(DateTime.local())
  touched = new Subject<void>()

  private initialized = false

  private unsubscribe: Subject<void>

  constructor(private translateService: TranslateService) {}

  writeValue(obj: DateTime): void {
    this.date.setValue(obj)
  }
  registerOnChange(fn: any): void {
    this.date.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(fn)
  }
  registerOnTouched(fn: any): void {
    this.touched.pipe(takeUntil(this.unsubscribe)).subscribe(fn)
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      throw new Error("Method not implemented.")
    }
  }
}
