import { Component, Input, Output } from "@angular/core"
import { withUnsubscribe } from "@venue/shared"
import mdiPlus from "@iconify/icons-mdi/plus"
import { Subject } from "rxjs"

@withUnsubscribe
@Component({
  selector: "floating-fab-button",
  templateUrl: "./floating-fab-button.component.html"
})
export class FloatingFabButton {
  @Input() icon = mdiPlus
  @Input() tooltip?: string
  @Input() disabled?: boolean

}
