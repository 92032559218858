import { SelectionModel } from "@angular/cdk/collections"
import { Component, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import {
  ManualTuningControllerService,
  ManualTuningSolution,
  MobileRecordsControllerService,
  PackageControllerService,
  RecordMetadata,
} from "@openapi/mipsengine"
import { PackagePart } from "@openapi/mipsengine/model/package-part"
import { withUnsubscribe } from "@venue/shared"
import { setDisabledFormControl } from "@venue/shared/utils/form-control-helpers"
import { BehaviorSubject, combineLatest, interval, Subject } from "rxjs"
import {
  distinctUntilChanged,
  filter,
  finalize,
  pairwise,
  startWith,
  takeUntil,
  tap,
} from "rxjs/operators"
import { PositioningModelDialog } from "."

@withUnsubscribe
@Component({
  selector: "manual-tuning",
  templateUrl: "./manual-tuning.component.html",
})
export class ManualTuningComponent implements OnInit {
  configPackage = new FormControl(null)
  configPackages: PackagePart[] = []
  packageRecords: RecordMetadata[] = []

  durationTimeEnabled = new FormControl(false)
  durationTime = new FormControl(1)
  durationUnit = new FormControl(1)

  tuningInProgress = new BehaviorSubject(false)
  tuningStatusLoading = new BehaviorSubject(false)
  loadingModelVerification = new BehaviorSubject(false)

  tuningResult: ManualTuningSolution = null

  bestSolutionLearningSet: number = null

  public packageRecordsSelection = new SelectionModel<RecordMetadata>(true, [])

  private unsubscribe: Subject<void>

  constructor(
    private recordsService: MobileRecordsControllerService,
    private tuningService: ManualTuningControllerService,
    private tuningPackagesService: PackageControllerService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getConfigPackages()

    // TODO Paging ?
    this.configPackage.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((p) => this.getPackageRecords(p))

    this.getTuningState()

    interval(3000)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.getTuningState())

    this.tuningInProgress
      .pipe(
        takeUntil(this.unsubscribe),
        filter((running) => running)
      )
      .subscribe(() => this.getTuningSolution())

    this.tuningInProgress
      .pipe(
        startWith(false),
        takeUntil(this.unsubscribe),
        pairwise(),
        filter(([oldValue, newValue]) => oldValue === true && newValue === false)
      )
      .subscribe(() => this.getTuningResult())

    this.bindFormControlDisabled()
  }

  private bindFormControlDisabled(): void {
    this.tuningInProgress
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((disabled) => {
        setDisabledFormControl(this.configPackage, disabled)
        setDisabledFormControl(this.durationTimeEnabled, disabled)
      })

    combineLatest(
      this.tuningInProgress,
      this.durationTimeEnabled.valueChanges.pipe(startWith(this.durationTimeEnabled.value)),
      (tip, dte) => tip || !dte
    )
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((durationTimeFormDisabled) => {
        setDisabledFormControl(this.durationTime, durationTimeFormDisabled)
        setDisabledFormControl(this.durationUnit, durationTimeFormDisabled)
      })
  }

  checkAllRecords(): void {
    if (this.allRecordsSelected()) {
      this.packageRecordsSelection.clear()
    } else {
      this.packageRecordsSelection.select(...this.packageRecords)
    }
  }

  allRecordsSelected(): boolean {
    return (
      this.packageRecordsSelection.selected.length === this.packageRecords.length &&
      this.packageRecords.length !== 0
    )
  }

  toggleTuning(): void {
    this.tuningStatusLoading.next(true)

    if (this.tuningInProgress.value) {
      this.stopTuning()
    } else {
      this.startTuning()
    }
  }

  showPositioningModel(): void {
    const configPackage: PackagePart = this.configPackage.value
    const recordFilenames: string[] = this.packageRecordsSelection.selected.map((v) => v.filename)

    this.loadingModelVerification.next(true)
    this.tuningService
      .positioningModelVerificationUsingPOST({
        packageVersion: configPackage.packageVersion,
        recordFilenames: recordFilenames,
        duration: 0, // Is this unused on the backend??
      })
      .pipe(
        tap(() => this.loadingModelVerification.next(false)),
        takeUntil(this.unsubscribe)
      )
      .subscribe((v) => {
        this.dialog.open(PositioningModelDialog, { data: v })
      })
  }

  private getConfigPackages(): void {
    this.configPackage.setValue(null)
    this.tuningPackagesService
      .getConfigPackagesUsingGET()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((configPackages) => {
        this.configPackages = configPackages
      })
  }

  private getTuningState(): void {
    this.tuningService
      .isRunningUsingGET()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((running) => this.tuningInProgress.next(running))
  }

  private getTuningSolution(): void {
    this.tuningService
      .bestSolutionUsingGET()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((solution) => {
        if (solution >= 0) {
          this.bestSolutionLearningSet = solution
        } else {
          this.bestSolutionLearningSet = null
        }
      })
  }

  private getTuningResult(): void {
    this.tuningStatusLoading.next(false)
    this.tuningService
      .stoppedSolutionManualTuningUsingGET()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => this.setTuningSolution(result))
  }

  private startTuning(): void {
    this.bestSolutionLearningSet = null
    this.tuningResult = null

    let duration = 0
    if (this.durationTimeEnabled.value) {
      duration = this.durationTime.value * this.durationUnit.value
    }

    const configPackage: PackagePart = this.configPackage.value
    const recordFilenames: string[] = this.packageRecordsSelection.selected.map((v) => v.filename)

    this.tuningService
      .startManualTuningUsingPOST({
        packageVersion: configPackage.packageVersion,
        recordFilenames,
        duration,
      })
      .pipe(
        finalize(() => this.tuningStatusLoading.next(false)),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.tuningInProgress.next(true))
  }

  private stopTuning(): void {
    // Loading status and tuning in progress will be changed when
    // isrunning endpoint returns false - in getTuningResult method
    this.tuningService
      .stopManualTuningUsingPOST()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((solution) => {
        if (solution) {
          this.setTuningSolution(solution)
        }
      })
  }

  private setTuningSolution(solution: ManualTuningSolution): void {
    if (solution && solution.originFitness !== null) {
      this.tuningResult = solution
      this.bestSolutionLearningSet =
        solution.learningSetFitness >= 0 ? solution.learningSetFitness : null
    }
    this.getConfigPackages()
  }

  private getPackageRecords(pck: PackagePart): void {
    this.packageRecordsSelection.clear()
    this.packageRecords = []
    if (pck) {
      this.recordsService
        .getAllMobileRecordsByVersionUsingGET(pck.packageVersion)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((records) => (this.packageRecords = records))
    }
  }
}
