import { Injectable } from "@angular/core"
import * as Cookies from "js-cookie"

const TOKEN_KEY = "authToken"

@Injectable()
export class AuthTokenService {
    setToken(token: string): void {
        sessionStorage.setItem(TOKEN_KEY, token)
    }

    getToken(): string {
        return sessionStorage.getItem(TOKEN_KEY) || Cookies.get(TOKEN_KEY)
    }

    removeToken(): void {
        sessionStorage.removeItem(TOKEN_KEY)
    }
}
