export function downloadBlob(blob: Blob, filename: string): void
export function downloadBlob(obj: any, filename: string): void

export function downloadBlob(v: any, filename: string): void {
  let blob: Blob

  if (v instanceof Blob) {
    blob = v
  } else {
    const str = JSON.stringify(v)
    const bytes = new TextEncoder().encode(str)
    blob = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    })
  }

  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = filename
  a.click()
  URL.revokeObjectURL(url)
}
