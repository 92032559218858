import { Injectable } from "@angular/core"
import { ConfigService } from "@venue/config"
import { OAuthService } from "angular-oauth2-oidc"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

/**
 * Utility service for auth operations.
 */
@Injectable()
export class AuthUtilService {
  private authEnabled = false

  constructor(private oauthService: OAuthService, private configService: ConfigService) {
    this.checkAuthEnabled().subscribe((enabled) => (this.authEnabled = enabled))
  }

  /**
   * Check if auth is enabled.
   */
  enabled(): boolean {
    return this.authEnabled
  }

  /**
   * Start login operation.
   *
   * Redirects user to auth server to log in and get auth token.
   *
   * If user has active session on the auth server, then calling this method will
   * result in 2 quick redirects - from UI to auth server and then back to UI.
   *
   * Does nothing if auth is disabled.
   */
  login(): void {
    if (!this.authEnabled) {
      return
    }

    this.oauthService.initLoginFlow()
  }

  /**
   * Start logout operation.
   *
   * Removes tokens and redirects user to logout endpoint of the auth server (if one is configured).
   *
   * Does nothing if auth is disabled.
   */
  logout(): void {
    if (!this.authEnabled) {
      return
    }

    this.oauthService.logOut()
  }

  /**
   * Returns url to auth server's account management page.
   *
   * Returns empty string if auth is disabled.
   */
  // XXX Not sure about this implementation, maybe we should query auth server for this?
  //     Or maybe we need to set it in our auth config ?
  authUserProfileUrl(): string {
    if (!this.authEnabled) {
      return ""
    }

    if (!this.oauthService.issuer) {
      throw new Error("Unknown issuer uri")
    }

    return this.oauthService.issuer + /account/
  }

  /**
   * Checks if user is authenticated.
   *
   * It is assumed that user is authenticated if valid access and id tokens are present.
   *
   * Returns true if auth is disabled.
   */
  authenticated(): boolean {
    if (!this.authEnabled) {
      return true
    }

    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()
  }

  /**
   * Returns first name of the user.
   *
   * Returns empty string if auth is disabled.
   */
  whoami(): string {
    if (!this.authEnabled) {
      return ""
    }

    const id = this.oauthService.getIdentityClaims() as any
    return id.given_name
  }

  /**
   * Returns user's roles.
   *
   * User's roles are currently taken from id token's custom 'authority' claim.
   *
   * Returns empty array if auth is disabled.
   */
  // XXX Not sure if we should take roles from auth token, id token or user info endpoint.
  //     It depends on the auth server configuration.
  // TODO This function possibly needs to be changed when integrating with catcha's login.
  userRoles(): string[] {
    if (!this.authEnabled) {
      return []
    }

    const claims = this.oauthService.getIdentityClaims() as any
    return claims?.authority ?? []
  }

  /**
   * Checks if user has a role assigned to him or her.
   * @param role checking role
   * @returns true if user has given role or auth is disabled else false
   */
  checkUserRole(role: string): boolean {
    return this.authEnabled ? this.userRoles().includes(role) : true
  }

  /**
   * Check if auth is enabled
   */
  protected checkAuthEnabled(): Observable<boolean> {
    return this.configService.getConfigData().pipe(map((data) => data.auth.enabled))
  }
}
