import { Component, OnInit } from "@angular/core"
import mdiCancel from "@iconify/icons-mdi/cancel"

@Component({
  selector: "ssf-access-denied",
  templateUrl: "./access-denied.component.html",
  styleUrls: ["./access-denied.component.scss"],
})
export class AccessDeniedComponent implements OnInit {
  readonly icon = mdiCancel
  constructor() {}

  ngOnInit() {}
}
