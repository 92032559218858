import AbstractMouseInteraction from "@venue/components/map/interactions/AbstractMouseInteraction"
import { mat3, vec2, vec3 } from "gl-matrix"
import { isEqual } from "lodash"
import { MapBrowserEvent } from "ol"
import { Coordinate } from "ol/coordinate"
import { boundingExtent, getCenter } from "ol/extent"
import Feature from "ol/Feature"
import LineString from "ol/geom/LineString"
import Point from "ol/geom/Point"
import Polygon from "ol/geom/Polygon"
import VectorLayer from "ol/layer/Vector"
import Map from "ol/Map"
import VectorSource from "ol/source/Vector"
import { createEditingStyle } from "ol/style/Style"
import { ImageTransforms } from "./ImageTransforms"
import { angle, getVector } from "./VectorUtils"

function pointsEqual(a: Coordinate, b: Coordinate): boolean {
  return Math.abs(a[0] - b[0]) < 1 && Math.abs(a[1] - b[1]) < 1 // We are measuring in pixels
}

/**
 * Get matrices to transform to local rectangle coordinates and back.
 *
 * @param {Array.<Number>} B - blue point
 * @param {Array.<Number>} Y - yellow point
 * @returns {Array.<mat3>} matrix to local coordinate system and matrix to world coordinate system
 */
function getTransformMatrices(B: Coordinate, Y: Coordinate): ImageTransforms {
  const tv = vec3.fromValues(B[0], B[1], 0)
  const BOv = getVector(<vec3>(<any>B), <vec3>(<any>[B[0] + 1, B[1]]))
  const BYv = getVector(<vec3>(<any>B), <vec3>(<any>Y))
  const ang = angle(BOv, BYv)
  const translationMatrix = mat3.fromTranslation(mat3.create(), <vec2>(<any>tv))
  const rotationMatrix = mat3.fromRotation(mat3.create(), ang)
  const transformMatrix = mat3.mul(mat3.create(), rotationMatrix, translationMatrix)

  const transforms = new ImageTransforms()
  transforms.setTransform(transformMatrix)

  return transforms
}

function getPGPoints(
  points: Coordinate[],
  mousePoint: Coordinate,
  transforms: ImageTransforms
): [Coordinate, Coordinate] {
  const b = points[0]
  const y = points[1]
  const point = transforms.toLocal(mousePoint)
  const localB = transforms.toLocal(b)
  const localY = transforms.toLocal(y)

  const localP = [localB[0], point[1], 1]
  const localG = [localY[0], point[1], 1]

  const p = <Coordinate>transforms.toWorld(localP)
  const g = <Coordinate>transforms.toWorld(localG)
  return [p, g]
}

function sortPoints(points: number[][]): void {
  points.pop() // Remove last point required by ol line ring
  const extent: number[] = boundingExtent(points)
  const center: number[] = getCenter(extent)
  center[2] = 1
  points.sort((a, b) => {
    a[2] = 1
    b[2] = 1
    const ca = getVector(<vec3>(<any>center), <vec3>(<any>a))
    const cb = getVector(<vec3>(<any>center), <vec3>(<any>b))
    const angleCA = angle(vec3.fromValues(0, 1, 0), ca)
    const angleCB = angle(vec3.fromValues(0, 1, 0), cb)
    if (angleCA < angleCB) {
      return -1
    } else if (angleCA === angleCB) {
      return 0
    } else {
      return 1
    }
  })
  points.push(points[0]) // Close line ring
}

type DrawStartListener = (p: Coordinate) => void
type DrawEndListener = (ps: Coordinate[]) => void

/**
 * Rectangle points with our coordinate system naming convention.
 */
export type RectangleDefinition = {
  /** Origin point of rectangle. First point that user clicked. */
  blue: Coordinate

  yellow: Coordinate
  pink: Coordinate
}

export class DrawRectangleInteraction extends AbstractMouseInteraction {
  private points: Coordinate[] = []
  private guidePoint = new Feature({
    geometry: new Point([0, 0]),
  })
  private nowDrawingLine: Feature<LineString>
  private nowDrawingRectangle: Feature<Polygon>
  private transforms: ImageTransforms
  private vectorSource = new VectorSource<LineString | Polygon>({ features: [this.guidePoint] })
  private vectorLayer: VectorLayer<VectorSource<LineString | Polygon>>

  constructor() {
    super()

    const styles = createEditingStyle()
    styles.Polygon = styles.LineString
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
      style: (f) => styles[f.getGeometry().getType()],
      zIndex: 999,
    })
  }

  // Most likely can be simplified, but to avoid any error
  // I copied old implementation here
  onRectangleDrawn(listener: (points: RectangleDefinition) => void) {
    var originPoint: Coordinate

    // @ts-ignore
    this.on("drawstart", (point: Coordinate) => (originPoint = point))

    // @ts-ignore
    this.on("drawend", (coords: Coordinate[]) => {
      var bIdx = coords.findIndex((v) => isEqual(v, originPoint))
      // Openlayers draws counter-clockwise
      var b = coords[bIdx]
      var y = coords[(bIdx - (1 % 4) + 4) % 4] // https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
      var p = coords[bIdx + (1 % 4)]
      var points: RectangleDefinition = { blue: b, yellow: y, pink: p }

      listener(points)
    })
  }

  click(e: MapBrowserEvent<any>): void {
    const point = e.map.getCoordinateFromPixel(e.pixel)
    const len = this.points.length
    switch (len) {
      case 0: {
        this.points.push(point)
        this.points.push(point)
        const feature = new Feature<LineString>({
          geometry: new LineString(this.points),
        })
        this.nowDrawingLine = feature
        this.vectorSource.addFeature(feature)
        this.getListeners("drawstart").forEach((l) => (l as any as DrawStartListener)(point))
        break
      }
      case 2:
        if (!pointsEqual(this.points[0], point)) {
          this.transforms = getTransformMatrices(this.points[0], this.points[1])

          const [p, g] = getPGPoints(this.points, point, this.transforms)

          this.points.push(<Coordinate>g, <Coordinate>p, this.points[0])

          const feature = new Feature<Polygon>({
            geometry: new Polygon([this.points]),
          })

          this.nowDrawingRectangle = feature
          this.vectorSource.removeFeature(this.nowDrawingLine)
          this.nowDrawingLine = null
          this.vectorSource.addFeature(feature)
        }

        break
      case 5:
        if (!pointsEqual(this.points[0], this.points[3])) {
          this.vectorSource.removeFeature(this.nowDrawingRectangle)
          this.nowDrawingRectangle = null
          sortPoints(this.points)
          this.getListeners("drawend").forEach((l) => (l as any as DrawEndListener)(this.points))
          this.points = []
        }
        break
    }
  }

  move(e: MapBrowserEvent<any>): void {
    const point = e.map.getCoordinateFromPixel(e.pixel)
    ;(<Point>this.guidePoint.getGeometry()).setCoordinates(point)
    if (this.nowDrawingLine) {
      const geometry = <LineString>this.nowDrawingLine.getGeometry()
      this.points[1] = point
      geometry.setCoordinates(this.points)
    } else if (this.nowDrawingRectangle) {
      const geometry = <Polygon>this.nowDrawingRectangle.getGeometry()

      const [p, g] = getPGPoints(this.points, point, this.transforms)

      this.points[2] = g
      this.points[3] = p
      ;(<Point>this.guidePoint.getGeometry()).setCoordinates(g)

      geometry.setCoordinates([this.points])
    }
  }

  setMap(map: Map): void {
    if (this.map) {
      this.map.removeLayer(this.vectorLayer)
    }
    super.setMap(map)
    if (map) {
      map.addLayer(this.vectorLayer)
    }
  }
}

export default DrawRectangleInteraction
