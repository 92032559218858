import { Component } from "@angular/core"
import { FormBuilder, Validators } from "@angular/forms"
import { MatDialogRef } from "@angular/material/dialog"
import mdiCellphone from "@iconify/icons-mdi/cellphone"

@Component({
  selector: "add-whitelist-mac-dialog",
  templateUrl: "./add-whitelist-mac.dialog.html",
})
export class AddWhitelistMacDialog {
  readonly icon = mdiCellphone

  // TODO Add mac validator
  macForm = this.fb.group({
    mac: ["", Validators.required],
  })

  constructor(private dialogRef: MatDialogRef<AddWhitelistMacDialog>, private fb: FormBuilder) {
    dialogRef.addPanelClass("dialog-with-toolbar")
  }

  onOk(): void {
    this.dialogRef.close(this.macForm.value)
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onCancel(): void {
    this.dialogRef.close()
  }
}
