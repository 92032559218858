import { Feature, Map, MapBrowserEvent } from "ol"
import { Coordinate } from "ol/coordinate"
import { Circle, Geometry, Polygon } from "ol/geom"
import PointerInteraction from "ol/interaction/Pointer"
import { Layer } from "ol/layer"
import { Vector as VectorSource } from "ol/source"

function isPolygon(geom: Geometry): geom is Polygon {
  return (<Polygon>geom).getArea !== undefined
}

function isCircle(geom: Geometry): geom is Circle {
  return geom instanceof Circle
}

export default class AbstractMouseInteraction extends PointerInteraction {
  map: Map

  private dragInProgress = false

  constructor() {
    super({ })

    PointerInteraction.call(this, {
      handleDownEvent: this.down,
      handleDragEvent: this.drag,
      handleUpEvent: this.up,
      handleMoveEvent: this.move,
      handleEvent: this.handleBrowserEvent,
    })

    this.dragInProgress = false
  }

  handleBrowserEvent(evt: MapBrowserEvent<any>): boolean {
    switch (evt.type) {
      case "click":
        this.click(evt)
        break
      case "pointermove":
        this.move(evt)
        if (this.dragInProgress) {
          this.drag(evt)
          return false
        }
        break
      case "pointerdown":
        this.dragInProgress = this.down(evt)
        return !this.dragInProgress
      case "pointerup":
        if (this.dragInProgress) {
          this.dragInProgress = this.up(evt)
          return !this.dragInProgress
        }
        break
    }
    return true
  }

  down(evt: MapBrowserEvent<any>): boolean {
    return false
  }

  drag(evt: MapBrowserEvent<any>): boolean {
    return false
  }

  up(evt: MapBrowserEvent<any>): boolean {
    return false
  }

  move(evt: MapBrowserEvent<any>): void {}

  click(evt: MapBrowserEvent<any>): void {}

  getFeatureAtPixel<T extends Geometry>(
    pixel: Coordinate,
    layers: Layer<VectorSource<T>>[]
  ): [Feature<T>, Layer<VectorSource<T>>] | [] {
    if (!layers || !this.map) return []

    let area = Number.MAX_VALUE
    let result: [Feature<T>, Layer<VectorSource<T>>]
    let nonPolygonGeometry: [Feature<T>, Layer<VectorSource<T>>]

    this.map.forEachFeatureAtPixel(pixel, (f: Feature<T>, l: Layer<VectorSource<T>>) => {
      if (layers.includes(l) && l.getSource().getFeatures().includes(f)) {
        const geometry = f.getGeometry()
        if (isPolygon(geometry)) {
          const fa = geometry.getArea()
          if (fa < area) {
            area = fa
            result = [f, l]
          }
        } else if (isCircle(geometry)) {
          const r = geometry.getRadius()
          const fa = Math.PI * r * r
          if (fa < area) {
            area = fa
            result = [f, l]
          }
        } else if (!nonPolygonGeometry) {
          nonPolygonGeometry = [f, l]
        }
      }
    })

    return result || nonPolygonGeometry || []
  }

  setMap(map: Map): void {
    super.setMap(map)
    this.map = map
  }
}
