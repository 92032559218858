<mat-form-field *ngIf="serverSelectionEnabled"
                fxFlex>
    <mat-label>{{ 'serverselect.placeholder' | translate }}</mat-label>
    <mat-select [formControl]="server"
                required>
        <mat-option *ngFor="let s of servers; let idx = index"
                    [attr.data-index]="idx"
                    [value]="s">
            <div fxFlex>
                <span>{{ s.name }}</span>
                <span fxFlex></span>
                <ic-icon [icon]="removeServerIcon"
                         (click)="removeServer($event, idx)"> </ic-icon>
            </div>
        </mat-option>
        <mat-option (click)="addServer()"
                    value=""> {{ 'serverselect.add_server' | translate }} </mat-option>
    </mat-select>
</mat-form-field>
