import { mat3, vec2, vec3 } from "gl-matrix"
import { pull } from "lodash"
import { CallbackRemover, CoordinateTransforms, TransformFunction } from "./CoordinateTransforms"

export type ImageTransformsCallback = (tf: TransformFunction) => void

function getTransformFun(matrix: mat3): TransformFunction {
  return (c: number[], out?: number[]): number[] => {
    if (out == null) {
      out = []
    }
    c.forEach((value, i) => {
      if (i % 2 == 1) {
        // openlayers can provide all coords in one array, so we handle each  'pair' of them here
        const coordsVec = vec3.fromValues(c[i - 1], c[i], 1)
        const outVec = vec3.transformMat3(vec3.create(), coordsVec, matrix)
        out[i - 1] = outVec[0]
        out[i] = outVec[1]
      }
    })
    return out
  }
}

class ImageTransforms implements CoordinateTransforms {
  private callbacks: ImageTransformsCallback[] = []
  private toLocalMatrix = mat3.create()
  private toWorldMatrix = mat3.create()
  private scaleMatrix = mat3.create()

  setTransform(matrix: mat3): void {
    matrix = mat3.invert(mat3.create(), matrix)
    matrix = mat3.multiply(mat3.create(), this.toWorldMatrix, matrix)
    this.update(matrix)
  }

  translate(translateVector: vec2): void {
    translateVector = vec2.fromValues(-translateVector[0], -translateVector[1])
    const matrix = mat3.fromTranslation(mat3.create(), translateVector)
    this.update(matrix)
  }

  rotate(angle: number, worldAnchor: vec2): void {
    const anchor = worldAnchor
    angle = -angle
    const translation = mat3.fromTranslation(mat3.create(), vec2.fromValues(anchor[0], anchor[1]))
    const rotation = mat3.fromRotation(mat3.create(), angle)
    const invTranslation = mat3.invert(mat3.create(), translation)
    let matrix = mat3.multiply(mat3.create(), translation, rotation)
    matrix = mat3.multiply(mat3.create(), matrix, invTranslation)
    this.update(matrix)
  }

  scale(scaleVector: vec3, localAnchor: vec2, multiplyByOldScale?: boolean): void {
    multiplyByOldScale = multiplyByOldScale === undefined ? false : multiplyByOldScale
    const anchor = localAnchor
    // third element is not used
    scaleVector = vec3.inverse(vec3.create(), scaleVector)

    const translation = mat3.fromTranslation(mat3.create(), vec2.fromValues(anchor[0], anchor[1]))
    const invTranslation = mat3.invert(mat3.create(), translation)

    let scale = mat3.fromScaling(mat3.create(), <vec2>(<any>scaleVector)) // Intentional conversion
    if (multiplyByOldScale) {
      scale = mat3.multiply(mat3.create(), scale, this.scaleMatrix)
    }
    // Fix for auto resizing when one coordinate is blocked
    if (scaleVector[0] == 1) {
      scale[0] = 1
    }
    if (scaleVector[1] == 1) {
      scale[4] = 1
    }

    let matrix = mat3.multiply(mat3.create(), this.toWorldMatrix, translation)
    matrix = mat3.multiply(mat3.create(), matrix, scale)
    matrix = mat3.multiply(mat3.create(), matrix, invTranslation)
    matrix = mat3.multiply(mat3.create(), matrix, this.toLocalMatrix)

    this.scaleMatrix = scale
    this.update(matrix)
  }

  update(transformMatrix: mat3): void {
    const oldLocalMatrix = this.toLocalMatrix
    this.toLocalMatrix = mat3.multiply(mat3.create(), this.toLocalMatrix, transformMatrix)
    mat3.invert(this.toWorldMatrix, this.toLocalMatrix)

    transformMatrix = mat3.multiply(mat3.create(), this.toWorldMatrix, oldLocalMatrix)

    this.callbacks.forEach((c) => c(getTransformFun(transformMatrix)))
  }

  /**
   * Adds callback to transforms features using given transform function.
   *
   * Will transform world coordinates by first applying old (previous)
   * local transform, to get coordinates old (previous) local coordinates
   * and then applying new (current) world transform.
   * In other words:
   * old world coords
   *     -- (old to local transform) --> local coords
   *     -- (new to world transform) --> new world coords
   *
   * @return callback remover
   */
  onChange(callback: ImageTransformsCallback): CallbackRemover {
    this.callbacks.push(callback)

    return () => {
      pull(this.callbacks, callback)
    }
  }

  toLocal(coords: number[], out?: number[]): number[] {
    return getTransformFun(this.toLocalMatrix)(coords, out)
  }

  toWorld(coords: number[], out?: number[]): number[] {
    return getTransformFun(this.toWorldMatrix)(coords, out)
  }
}

function findTransform(fromMat3: mat3, toMat3: mat3): mat3 {
  const [B, C] = [fromMat3, toMat3]
  const Bi = mat3.invert(mat3.create(), B)
  return mat3.multiply(mat3.create(), C, Bi)
}

export default ImageTransforms

export { findTransform, ImageTransforms }
