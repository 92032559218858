import { NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { MapsModule } from "@venue/maps"
import { SharedModule } from "../shared"
import * as m from "./"

@NgModule({
    imports: [UIRouterModule.forChild({ states: m.TRACES_STATES }), SharedModule, MapsModule],
    declarations: [m.TracesListComponent, m.TracePathComponent, m.ExportTraceDialog]
})
export class TracesModule {}
