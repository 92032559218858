import { DomPortalOutlet, TemplatePortal } from "@angular/cdk/portal"
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Host,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core"
import Control from "ol/control/Control"
import { OpenlayersMapComponent } from "../openlayers-map.component"

@Component({
  selector: "map-legend",
  templateUrl: "./map-legend.component.html",
})
export class MapLegendComponent implements OnInit, OnDestroy {
  // No idea why I can't query it by TemplateRef, like its done in MapToolbarComponent
  @ViewChild("template", { static: true }) template: TemplateRef<any>

  private toolbarViewRef: EmbeddedViewRef<any>
  private control: Control

  constructor(
    @Host() private mapComponent: OpenlayersMapComponent,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private ar: ApplicationRef,
    private injector: Injector
  ) {}

  // TODO Its copied from map-toolbar -> extract common code to separate file
  ngOnInit(): void {
    const templatePortal = new TemplatePortal(this.template, this.viewContainerRef)
    const outletElement = document.createElement("div")
    this.toolbarViewRef = new DomPortalOutlet(
      outletElement,
      this.cfr,
      this.ar,
      this.injector
    ).attachTemplatePortal(templatePortal)
    this.control = new Control({ element: outletElement })

    this.mapComponent.map.addControl(this.control)
  }

  ngOnDestroy(): void {
    this.mapComponent.map.removeControl(this.control)
    this.toolbarViewRef.destroy()
  }
}

export interface MapLegendElement {
  fillColor: string
  strokeColor: string
  translationKey: string
}
