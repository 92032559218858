import { Component } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatSnackBar } from "@angular/material/snack-bar"
import mdiCity from "@iconify/icons-mdi/city"
import mdiDelete from "@iconify/icons-mdi/delete"
import mdiFloorPlan from "@iconify/icons-mdi/floor-plan"
import mdiImport from "@iconify/icons-mdi/import"
import mdiPencil from "@iconify/icons-mdi/pencil"
import mdiSettingsBox from "@iconify/icons-mdi/settings-box"
import { TranslateService } from "@ngx-translate/core"
import { Building, BuildingsService, PackagesService } from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { ConfirmDialog, ConfirmDialogData, downloadBlob, withUnsubscribe } from "@venue/shared"
import {
  CREATE_VENUE_STATE_NAME,
  EDIT_VENUE_STATE_NAME,
  IMPORT_VENUE_STATE_NAME,
  VENUE_MENU_STATE_NAME,
} from "@venue/venues/venues-state-names"
import { combineLatest, Observable } from "rxjs"
import { filter, flatMap, map, takeUntil } from "rxjs/operators"
import { BuildingsDataSource } from "."
import { ModeService } from "@venue/mode"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"

@withUnsubscribe
@Component({
  selector: "venues-component",
  templateUrl: "./venues.component.html",
})
export class VenuesComponent {
  venueIcon = mdiCity
  downloadConfig = mdiSettingsBox
  downloadMap = mdiFloorPlan
  editVenueIcon = mdiPencil
  deleteVenueIcon = mdiDelete
  importVenueIcon = mdiImport

  dataSource = new BuildingsDataSource(this.buildingsService)

  private unsubscribe: Observable<any>

  readonly WRITE_USER_ROLE = "venue:buildings:write"
  readonly IMPORT_USER_ROLE = "venue:import"

  constructor(
    private buildingsService: BuildingsService,
    private packagesService: PackagesService,
    private state: StateService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private modeService: ModeService,
    private authUtilService: AuthUtilService
  ) {}

  showVenue(building: Building): void {
    this.state.go(VENUE_MENU_STATE_NAME, { venueId: building.id })
  }

  createVenue(): void {
    if (!this.hasUserWritePermissions()) {
      return
    }

    this.state.go(CREATE_VENUE_STATE_NAME)
  }

  editVenue(building: Building): void {
    this.state.go(EDIT_VENUE_STATE_NAME, { venueId: building.id })
  }

  deleteVenue(building: Building): void {
    let title = this.translate.get("venues.delete-venue-confirm-dialog.title")
    let content = this.translate.get("venues.delete-venue-confirm-dialog.content", {
      venueName: building.name,
    })

    combineLatest(title, content, (t, c) => ({ title: t, content: c } as ConfirmDialogData))
      .pipe(
        flatMap((data) => this.dialog.open(ConfirmDialog, { data: data }).afterClosed()),
        filter((shouldSave: boolean) => !!shouldSave)
      )
      .subscribe(() => {
        this.doDeleteVenue(building)
      })
  }

  importVenue(building: Building): void {
    this.state.go(IMPORT_VENUE_STATE_NAME, { venueId: building.id })
  }

  private doDeleteVenue(building: Building): void {
    // TODO Spinner until request completes?
    this.buildingsService.deleteBuilding(building.id).subscribe(
      (ok) => {
        this.dataSource.refresh()
      },
      (error) => {
        // TODO Translate errors ??
        this.snackBar.open(error, "", { duration: 5 * 1000 })
      }
    )
  }

  isCRUDModeEnabled(): Observable<boolean> {
    return this.modeService.isCRUDModeEnabled()
  }

  hasUserWritePermissions(): boolean {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  hasUserImportPermissions(): boolean {
    return this.authUtilService.checkUserRole(this.IMPORT_USER_ROLE)
  }
}
