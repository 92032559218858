import { Injectable, OnDestroy } from "@angular/core"
import { ImageProcessingService } from "@openapi/venue"
import { Point } from "@venue/api"
import { loadImage } from "@venue/components/floor/floormap/ImageUtils"
import { WithUnsubscribe } from "@venue/shared/utils/with-unsubscribe.mixin"
// @ts-ignore
import * as MagicWand from "magic-wand-tool"
import { Coordinate } from "ol/coordinate"
import { Circle, Polygon } from "ol/geom"
import { of, partition, Subject } from "rxjs"
import { catchError, distinctUntilChanged, switchMap, takeUntil } from "rxjs/operators"

@Injectable()
export class FloodFillConstraintsGenerator extends WithUnsubscribe implements OnDestroy {
  canvas = document.createElement("canvas")
  generatedConstraints = new Subject<GeneratedConstraint[]>()

  private image: HTMLImageElement
  private inputData: FloodFillPolygonInput[] = []

  private floorIdStream = new Subject<number>()

  /**
   * Flag determining generator mode.
   *
   * If true, black canvas is used as a base for the generator.
   * Otherwise, preprocessed floor image with removed color background is used.
   */
  private useBlackCanvas = false

  constructor(private imgProc: ImageProcessingService) {
    super()

    // If floor id changed, clear the data
    this.floorIdStream
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
      .subscribe(() => this.clearData())

    const [existingFloor, nullFloor] = partition(this.floorIdStream, (f) => !!f)

    nullFloor.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      // TODO Display error on null image - but differentiatie it from situation where floor is changing
      console.log("Unable to load floor")
    })

    existingFloor
      .pipe(
        switchMap((id) => this.imgProc.getBinaryImage(id).pipe(catchError((e) => of(null)))),
        switchMap((img: Blob) => (img ? loadImage(img) : of(null))),
        takeUntil(this.unsubscribe)
      )
      .subscribe((image: HTMLImageElement) => {
        if (!image) {
          this.clearData()
          return
        }
        // TODO Handle null img - when floor id was non null, but loading of binary floor image failed
        this.canvas.width = image.width
        this.canvas.height = image.height
        this.image = image
        this.generateConstraints(this.inputData)
      })
  }

  init(floorId: number, useBlackCanvas = false): void {
    this.useBlackCanvas = useBlackCanvas
    this.floorIdStream.next(floorId)
  }

  private clearData(): void {
    this.image = null
    this.inputData = []
    this.generatedConstraints.next([])
  }

  /**
   * Uses given input to generate actual floor constraints.
   * Order of input elements matters and should be from oldest (first drawn or modified)
   * to newest (last drawn or modified).
   */
  generateConstraints(inputData: FloodFillPolygonInput[]): void {
    this.inputData = inputData
    if (this.useBlackCanvas || this.image) {
      this.clearCanvas()
      inputData.forEach((i) => this.drawShape(i))

      const doors = inputData.filter((i) => i.type == "door")

      // Find start object by looking at center pixel of each polygon
      const startObject = doors.find((i) => this.whiteOnCenter(i.shape))

      let startPoint: Coordinate

      // If found - set the center as starting point of flood fill
      if (startObject) {
        startPoint = this.getCenterPoint(startObject.shape)
      }
      // Otherwise lets scan all polygons looking for first white pixel
      else {
        for (let d of doors) {
          const whitePixelCoord = this.findWhite(d.shape)

          if (whitePixelCoord) {
            startPoint = whitePixelCoord
            break
          }
        }
      }

      // If starting point has not been found, clear generated constraints and return
      if (!startPoint) {
        this.generatedConstraints.next([])
        return
      }

      // If starting point has been found, generate constraints
      const c = this.canvas
      const ctx = c.getContext("2d")
      const constraints = this.floodFill(ctx.getImageData(0, 0, c.width, c.height), startPoint)
      this.generatedConstraints.next(constraints)
    }
  }

  private clearCanvas(): void {
    const canvas = this.canvas
    const ctx = canvas.getContext("2d")
    ctx.beginPath()
    ctx.rect(0, 0, canvas.width, canvas.height)

    if (this.useBlackCanvas) {
      ctx.fillStyle = "black"
      ctx.fill()
    } else {
      ctx.fillStyle = "white"
      ctx.fill()
      ctx.drawImage(this.image, 0, 0)
    }
  }

  private drawShape(inputData: FloodFillPolygonInput): void {
    const ctx = this.canvas.getContext("2d")

    const style = inputData.type === "wall" ? "black" : "white"
    ctx.fillStyle = style
    ctx.strokeStyle = style
    ctx.lineWidth = 3

    const shape = inputData.shape

    ctx.beginPath()
    if (shape instanceof Polygon) {
      const points: Coordinate[] = shape.getCoordinates()[0]
      this.drawPolygon(points)
    } else {
      const c = shape.getCenter()
      const r = shape.getRadius()
      this.drawCircle(c, r)
    }

    ctx.fill()
    ctx.stroke()
  }

  private drawPolygon(points: Coordinate[]): void {
    const height = this.canvas.height
    const ctx = this.canvas.getContext("2d")

    ctx.moveTo(points[0][0], height - points[0][1])
    points.forEach((p) => ctx.lineTo(p[0], height - p[1]))
  }

  private drawCircle([x, y]: Coordinate, radius: number): void {
    const height = this.canvas.height
    const ctx = this.canvas.getContext("2d")

    ctx.arc(x, height - y, radius, 0, 2 * Math.PI)
  }

  private whiteOnCenter(shape: Polygon | Circle): boolean {
    const center = this.getCenterPoint(shape)

    const pixel = this.getPixel(center)

    return this.isPixelWhite(pixel)
  }

  private findWhite(shape: Polygon | Circle): Coordinate {
    const height = this.canvas.height
    const e = shape.getExtent()

    const maxX = e[2]
    const maxY = e[3]

    for (let y = Math.ceil(e[1]); y < maxY; y++) {
      for (let x = Math.ceil(e[0]); x < maxX; x++) {
        if (shape.intersectsCoordinate([x, y])) {
          // Canvas has flipped Y coordinates
          const flipY = height - y
          const pix = this.getPixel([x, flipY])

          if (this.isPixelWhite(pix)) {
            return [x, flipY]
          }
        }
      }
    }

    return null
  }

  private getPixel(coord: Coordinate): Uint8ClampedArray {
    return this.canvas.getContext("2d").getImageData(coord[0], coord[1], 1, 1).data
  }

  private isPixelWhite(pixel: Uint8ClampedArray): boolean {
    return pixel[0] === 255 && pixel[1] === 255 && pixel[2] === 255
  }

  private getCenterPoint(shape: Polygon | Circle): Coordinate {
    const h = this.canvas.height
    const center =
      shape instanceof Circle ? shape.getCenter() : shape.getInteriorPoint().getCoordinates()
    center[1] = h - center[1]
    return center
  }

  private floodFill(imageData: ImageData, point: Coordinate): GeneratedConstraint[] {
    // TODO Add types

    const p = {
      x: Math.floor(point[0]),
      y: Math.floor(point[1]),
    }

    const img = imageData

    let mask = MagicWand.floodFill(
      {
        data: img.data,
        width: img.width,
        height: img.height,
        bytes: 4,
      },
      p.x,
      p.y,
      1
    )

    // TODO Add blur wall sidebar to make it configurable
    const blur = 2
    mask = MagicWand.gaussBlurOnlyBorder(mask, blur)

    const contours = MagicWand.traceContours(mask)
    const cs: GeneratedConstraint[] = MagicWand.simplifyContours(contours, 1, 30).map(
      (w: any, wIdx: any) => ({
        points: w.points.map((p: any) => ({
          x: p.x,
          y: this.canvas.height - p.y,
        })),
        outerWall: !w.inner,
      })
    )

    return cs //.filter(function (o) { return o.inner; });
  }
}

export interface FloodFillPolygonInput {
  type: "wall" | "door"
  shape: Polygon | Circle
}

export interface GeneratedConstraint {
  points: Point[]
  outerWall: boolean
}
