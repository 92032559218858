import { Component, EventEmitter, Output } from "@angular/core"
import { withUnsubscribe } from "@venue/shared"

@withUnsubscribe
@Component({
  selector: "package-uploader",
  templateUrl: "./package-uploader.component.html",
})
export class PackageUploaderComponent {
  @Output() selectImportPckEvent: EventEmitter<File> = new EventEmitter()

  constructor() {}

  selectPck(file: File) {
    this.selectImportPckEvent.emit(file)
  }
}
