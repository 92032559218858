import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import mdiInformationOutline from "@iconify/icons-mdi/information-outline"

@Component({
  selector: "tuning-history-details-dialog",
  templateUrl: "./tuning-history-details.dialog.html",
})
export class TuningHistoryDetailsDialog {
  readonly icon = mdiInformationOutline

  constructor(
    private dialogRef: MatDialogRef<TuningHistoryDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.addPanelClass("dialog-with-toolbar")
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onNoClick(): void {
    this.onClose()
  }
}
