import { Injectable } from "@angular/core"
import { ConfigService, Mode } from "@venue/config"
import { Observable, of } from "rxjs"
import { map, tap } from "rxjs/operators"

/**
 * Service providing current deployment mode.
 */
@Injectable()
export class ModeService {
  readonly CRUD_MODE = "BUILDING_SERVICE"
  private currentMode: Observable<Mode>

  constructor(private configService: ConfigService) {
    this.currentMode = configService.getConfigData().pipe(map((configData) => configData.mode))
  }

  /**
   * Get current deployment mode specified in json file.
   */
  getMode(): Observable<Mode> {
    return this.currentMode
  }

  /**
   * Checks if system is on in CRUD mode.
   * Determines if CRUD operations should be visible due to deployment mode
   */
  isCRUDModeEnabled(): Observable<boolean> {
    return this.currentMode.pipe(
      map((mode) => mode.toString()),
      map((modeStr) => modeStr === this.CRUD_MODE)
    )
  }
}
