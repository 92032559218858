import { Component, OnInit } from "@angular/core"
import { StateService } from "@uirouter/core";

@Component({
    selector: "tuning",
    templateUrl: "./tuning.component.html"
})
export class TuningComponent implements OnInit {

    constructor(private stateService: StateService) {}

    ngOnInit(): void {
        if (this.stateService.$current.name === "tuning") {
            this.stateService.go("auto-tuning")
        }
    }
}
