<mat-toolbar color="primary">
    <h3> Model positioning verification </h3>
    <span fxFlex></span>
    <button mat-icon-button
            (click)="close()">
        <ic-icon [icon]="closeIcon"> </ic-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <mat-tab-group dynamicHeight>
        <mat-tab *ngFor="let tab of tabs"
                 label="{{ tab.name }}">
            <positioning-model-table [model]="tab.model"></positioning-model-table>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
