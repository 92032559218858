import { Component, Input, OnChanges, SimpleChanges, ElementRef, Renderer2 } from '@angular/core'

import JSONFormatter from 'json-formatter-js'

@Component({
    selector: "json-formatter",
    template: ""
})
export class JsonFormatterComponent implements OnChanges {
    @Input() json: any
    @Input() open = 0

    private currentView: HTMLDivElement

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.currentView) {
            this.renderer.removeChild(this.elementRef, this.currentView)
        }
        this.currentView = new JSONFormatter(this.json, this.open).render()
        this.renderer.appendChild(this.elementRef.nativeElement, this.currentView)
    }
}
