import { Component, Input, Output } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subject } from "rxjs"
import { map, startWith } from "rxjs/operators"

@Component({
  selector: "walls-sidebar",
  templateUrl: "./walls-sidebar.component.html",
})
export class WallsSidebarComponent {
  @Input() saveDisabled?: boolean
  @Input() toggleDisabled?: boolean

  @Output() submit = new Subject<void>()

  imageBasedGenerationForm = new FormControl(true)
  showFloorCanvasForm = new FormControl(false)

  @Output() blackCanvasGenerator = this.imageBasedGenerationForm.valueChanges.pipe(
    startWith(this.imageBasedGenerationForm.value),
    map((v) => !v)
  )

  @Output() showFloorCanvas = this.showFloorCanvasForm.valueChanges

  get blackCanvasGeneratorValue(): boolean {
    return !this.imageBasedGenerationForm.value
  }

  set blackCanvasGeneratorValue(v: boolean) {
    this.imageBasedGenerationForm.setValue(!v)
  }
}
