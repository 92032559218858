import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { ONPage } from "./ONPage"

@Injectable()
export class OSMNamesApiService {
  // TODO Use external configuration
  private readonly BASE_URL = "https://ips.sprc.samsung.pl/osmnames"

  constructor(private http: HttpClient) {}

  query(query: String): Observable<ONPage> {
    return this.http.get<ONPage>(`${this.BASE_URL}/q/${query}.js`)
  }
}
