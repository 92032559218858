import { Pipe, PipeTransform } from "@angular/core"
import { AppConstants } from "@venue/app.constants"

@Pipe({
  name: "appConstant",
})
export class AppConstantPipe implements PipeTransform {
  transform(value: string): any {
    const constants = AppConstants as { [key: string]: any }
    return constants[value]
  }
}
