<div class="fill-parent" fxLayout="column">
    <table mat-table matSort [dataSource]="dataSource" fxFlex>
        <ng-container matColumnDef="dateFrom">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tuning_history.entry.start-date' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.dateFrom | datetime }}</td>
        </ng-container>
        <ng-container matColumnDef="dateTo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tuning_history.entry.end-date' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.dateTo | datetime }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>{{ 'tuning_history.entry.type' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ 'tuning_history.type.' + entry.details.type | translate }}</td>
        </ng-container>
        <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef>{{ 'tuning_history.entry.result' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ 'tuning_history.result.' + entry.details.result | translate }}</td>
        </ng-container>
        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef>{{ 'tuning_history.entry.score' | translate }}</th>
            <td mat-cell *matCellDef="let entry">{{ entry.details.score }}</td>
        </ng-container>
        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>{{ 'tuning_history.entry.details' | translate }}</th>
            <td mat-cell *matCellDef="let entry"> <button mat-raised-button (click)="showDetails(entry)">{{ 'tuning_history.entry.details' | translate }}</button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>
