import { NgModule } from '@angular/core'

import { UIRouterModule } from "@uirouter/angular"

import * as tuning from './'
import { SharedModule } from '../shared'

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: tuning.STATES }),
        SharedModule,
        tuning.TuningHistoryModule
    ],
    declarations: [
        tuning.TuningComponent,
        tuning.TuningSidebarComponent,
        tuning.AutoTuningComponent,
        tuning.ManualTuningComponent,
        tuning.MobileRecordsComponent,
        tuning.TuningSolutionPipe,
        tuning.PositioningModelTable,
        tuning.PositioningModelDialog
    ]
})
export class TuningModule {}
