import { Sort } from "@angular/material/sort"

export interface Pageable {
  page: number
  size: number
  sort: string
}

export function pageableOf(
  paginator: { pageIndex: number; pageSize: number },
  sort?: Sort
): Partial<Pageable> {
  const pageable: Partial<Pageable> = { page: paginator.pageIndex, size: paginator.pageSize }
  if (sort) {
    pageable.sort = `${sort.active},${sort.direction}`
  }
  return pageable
}
