import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import { TransitionAreasComponent } from "../components"
import { TRANSITION_AREAS_STATE_NAME } from "../transition-areas.state-names"

export const TRANSITION_AREAS_STATE = {
  parent: EDITOR_STATE_NAME,
  name: TRANSITION_AREAS_STATE_NAME,
  url: "/:floorId/transitions",
  component: TransitionAreasComponent,
}
