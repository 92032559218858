import { AfterViewInit, Component, Directive, OnDestroy, ViewChild } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import mdiRestore from "@iconify/icons-mdi/restore"
import mdiTrashCan from "@iconify/icons-mdi/trash-can"
import { TranslateService } from "@ngx-translate/core"
import { FloorCSConfig, FloorCSConfigResponse, FloorCSConfigsService } from "@openapi/venue"
import { ImageDimensions, isImageDimensions } from "@venue/api"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import { RectangleDefinition } from "@venue/components/floor/floormap/transformations/DrawRectangleInteraction"
import {
  findTransform,
  ImageTransforms,
} from "@venue/components/floor/floormap/transformations/ImageTransforms"
import TransformInteractions from "@venue/components/floor/floormap/transformations/interactions"
import { CurrentFloor } from "@venue/core"
import { AutoAdjustHelperService, FloorCSConvertHelperService } from "@venue/floor-cs/services"
import {
  ImageDimensionsReceiverMixin,
  OpenlayersMapComponent,
  VectorLayerComponent,
} from "@venue/maps"
import { ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { mat3 } from "gl-matrix"
import { isEqual } from "lodash"
import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { Point as olPoint } from "ol/geom"
import { Projection } from "ol/proj"
import { Fill, Stroke, Style, Text } from "ol/style"
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from "rxjs"
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs/operators"

enum Mode {
  /** Editor did not yet determine which mode should be enabled */
  UNSET = "UNSET",
  /** Setup base coordinate system mode */
  BASE = "BASE",
  /** Adjust coordinate system mode */
  ADJUST = "ADJUST",
}

@Directive()
abstract class FloorCSComponentBase extends ImageDimensionsReceiverMixin {}

@withUnsubscribe
@Component({
  selector: "floor-cs",
  templateUrl: "./floor-cs.component.html",
})
export class FloorCSComponent extends FloorCSComponentBase implements AfterViewInit, OnDestroy {
  readonly clearBaseCSIcon = mdiTrashCan
  readonly resetImageIcon = mdiRestore
  readonly WRITE_USER_ROLE = "venue:floorcs:write"

  /**
   * Currently saved coordinate system.
   *
   * Used:
   * - in adjust mode during save operation
   * - to determine if switch to adjust mode is possible
   */
  private currentSavedCS = new BehaviorSubject<FloorCSConfig>(null)

  /** Width of base cs rectangle in meters. Sidebar field. */
  width = 2

  /** Length of base cs rectangle in meters. Sidebar field. */
  length = 2

  /** Background map projection - used to transform coordinates between background image and adjust image layer. */
  mapProjection: Projection

  /** Observable indicating if loading overlay should be displayed. */
  loading = new BehaviorSubject<boolean>(true)

  /** Current mode in use. */
  mode = new BehaviorSubject(Mode.UNSET)

  /** Flag indicating that there was an error with getting image dimensions. */
  noImgDim = new BehaviorSubject(false)

  /** Flag indicating that there was an error with getting adjust image dimensions. */
  noAdjImgDim = new BehaviorSubject(false)

  /** Template helper, to show elements specific to BASE mode */
  baseMode = this.mode.pipe(map((m) => m == Mode.BASE))

  /** Template helper, to show elements specific to ADJUST mode */
  adjustMode = this.mode.pipe(map((m) => m == Mode.ADJUST))

  /** Background map is visible if mode is not UNSET. */
  mapVisible = this.mode.pipe(map((m) => m != Mode.UNSET))

  /** Indicates if base cs rectangle is drawn. */
  baseCSConfigured = new BehaviorSubject(false)

  /** Clear base cs button enabled base cs configured. */
  clearBaseCSButtonEnabled = this.baseCSConfigured.pipe(map((c) => !!c))

  /** Checks if user can perform CRUD operations */
  hasUserWritePermissions(): boolean {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  /** Draw rectangle enabled in base mode if base cs not configured and user has write perms. */
  drawRectangleEnabled = combineLatest(
    this.mode,
    this.baseCSConfigured,
    of(this.hasUserWritePermissions())
  ).pipe(map(([m, c, p]) => m === Mode.BASE && !c && p))

  /** In BASE mode save button is enabled when base cs is configured and image is present. */
  baseModeSaveEnabled = combineLatest(this.mode, this.baseCSConfigured, this.noImgDim).pipe(
    map(([m, c, noImage]) => m == Mode.BASE && c && !noImage)
  )

  /** In ADJUST mode save button is enabled when both images are present. */
  adjustModeSaveEnabled = combineLatest(this.mode, this.noImgDim, this.noAdjImgDim).pipe(
    map(([m, noImage, noAdjImage]) => m == Mode.ADJUST && !noImage && !noAdjImage)
  )

  /** Save button is enabled if user has write perms and either of above conditions is true. */
  saveEnabled = combineLatest(
    this.baseModeSaveEnabled,
    this.adjustModeSaveEnabled,
    of(this.hasUserWritePermissions())
  ).pipe(map(([bse, ase, perms]) => (bse || ase) && perms))

  /** Input button is enabled if user has write perms and BASE mode is enabled */
  inputEnabled = combineLatest(this.baseMode, of(this.hasUserWritePermissions())).pipe(
    map(([baseMode, perms]) => baseMode && perms)
  )

  /** Switch to base cs setup button is visible in ADJUST mode. */
  toBaseButtonVisible = this.mode.pipe(map((m) => m == Mode.ADJUST))

  /** Switch to adjust cs setup button is visible in BASE mode only if cs is set. */
  toAdjustButtonVisible = combineLatest(this.mode, this.currentSavedCS).pipe(
    map(([m, cs]) => m == Mode.BASE && cs != null)
  )

  /** Reactive form for opacity slider, to control adjust image opacity. */
  opacityForm = this.fb.control(100)

  /** Subject to inject newly saved coordinate data and reload component */
  private csOverrideStream = new Subject<FloorCSConfigResponse>()

  // Under this cryptic name hides our machinery to handle base coordinate system rectangle
  // Kind-of legacy code used under Angular.js version of VenueMate,
  // but it is in no way tied to Angular.js framework
  transforms = new ImageTransforms()
  transformInteractions: TransformInteractions

  @ViewChild("labelLayer") labelLayer: VectorLayerComponent
  @ViewChild(OpenlayersMapComponent) mapComponent: OpenlayersMapComponent

  private adjustImgDimSubject = new BehaviorSubject<ImageDimensions | string>(null)

  private unsubscribe: Observable<any>

  constructor(
    public currentFloor: CurrentFloor,
    private translate: TranslateService,
    private floorCSConfigs: FloorCSConfigsService,
    private autoAdjustHelper: AutoAdjustHelperService,
    private csConverter: FloorCSConvertHelperService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private authUtilService: AuthUtilService
  ) {
    super()
  }

  ngAfterViewInit(): void {
    // Each time mode changes:
    // - perform cleanup
    // - enable loading
    // - push null image dim to reset streams
    this.mode.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe)).subscribe((m) => {
      this.loading.next(true)
      this.cleanup()
      this.adjustImgDimSubject.next(null)
      this.imgDimSubject.next(null)
    })

    // Get current floor - we actually only need floor id, so this can be simplified later
    const floorStream = this.currentFloor.floor.pipe(
      distinctUntilChanged(isEqual),
      takeUntil(this.unsubscribe),
      shareReplay(1)
    )

    // On floor change - reset errors
    floorStream.subscribe(() => this.resetErrors())

    // imgDim with error check - set image load error flag on error
    const iDimOrNullStream = this.imgDimSubject.pipe(
      map((dimOrErr) => {
        if (isImageDimensions(dimOrErr)) {
          return dimOrErr
        }

        this.onImgDimError(dimOrErr as string)
        return null
      })
    )

    // adjImgDim with error check - set adjust image load error flag on error
    const aiDimOrNullStream = this.adjustImgDimSubject.pipe(
      map((dimOrErr) => {
        if (isImageDimensions(dimOrErr)) {
          return dimOrErr
        }

        this.onAdjImgDimError(dimOrErr as string)
        return null
      })
    )

    // Map current floor to its coordinate system config
    const csStream = merge(
      // Would be cleaner with mergeWith operator - sth. to consider after upgrade to rxjs7
      this.csOverrideStream.pipe(tap(() => this.loading.next(true))), // Enable loading after cs override
      floorStream.pipe(
        // When floor changed, mode is UNSET, until we get current cs config
        tap(() => this.mode.next(Mode.UNSET)),
        filter((f) => !!f),
        switchMap((f) =>
          this.floorCSConfigs.getFloorCSConfig(f.id).pipe(catchError((err) => of(null)))
        )
      )
    ).pipe(startWith(null), takeUntil(this.unsubscribe), share())

    // Set current cs when cs config is loaded and determine mode
    // - this will start image loading, as mode is determined by current cs config
    csStream.subscribe((cs) => this.loadCSAndSelectMode(cs))

    // Combine cs with base image dimensions
    const csWithBaseImgDimStream = combineLatest(csStream, iDimOrNullStream).pipe(
      filter(([cs, imgDim]) => !!imgDim),
      takeUntil(this.unsubscribe),
      share()
    )

    // Load saved base setup when cs config and image dimensions are loaded in BASE mode
    csWithBaseImgDimStream
      .pipe(
        // Only in BASE mode
        withLatestFrom(this.mode),
        filter(([d, mode]) => mode == Mode.BASE),
        map(([d]) => d)
      )
      .subscribe(([cs, imgDim]) => this.loadSavedBaseSetup(cs, imgDim))

    // Combine cs with both image dimensions
    const csWithBothImgDimStream = combineLatest(
      csStream,
      iDimOrNullStream,
      aiDimOrNullStream
    ).pipe(
      filter(([cs, imgDim, adjImgDim]) => !!cs && !!imgDim && !!adjImgDim),
      takeUntil(this.unsubscribe),
      share()
    )

    // Load saved adjust setup when cs config, base imgDim and adjustImg are loaded in ADJUST mode
    csWithBothImgDimStream
      .pipe(
        // Only in adjust mode
        withLatestFrom(this.mode),
        filter(([d, mode]) => mode == Mode.ADJUST),
        map(([d]) => d)
      )
      .subscribe(([cs, imgDim, adjImgDim]) => this.loadSavedAdjustSetup(cs, imgDim, adjImgDim))

    // If transforms change - apply them on labels layer
    this.transforms.onChange((transformFun) =>
      this.labelLayer.source
        .getFeatures()
        .map((f) => f.getGeometry())
        .forEach((g) => g.applyTransform(transformFun))
    )
  }

  ngOnDestroy(): void {
    this.cleanup()
  }

  /**
   * Used to receive image dimensions of adjust image layer.
   */
  onAdjustImageDimChange(imgDimOrErrorMsg: ImageDimensions | string): void {
    this.adjustImgDimSubject.next(imgDimOrErrorMsg)
  }

  /**
   * Used to receive map projection, that will be passed back to adjust image layer.
   */
  setMapProjection(projection: Projection) {
    this.mapProjection = projection
  }

  /**
   * Save current data in backend
   */
  save() {
    let mode = this.mode.getValue()
    if (mode == Mode.BASE) {
      this.saveBase()
    } else if (mode == Mode.ADJUST) {
      this.saveAdjust()
    }
  }

  onRectangleDrawn(rect: RectangleDefinition) {
    const imgDim = this.imgDimSubject.getValue()
    if (!isImageDimensions(imgDim)) {
      // ImgDim Error - nothing to do here
      return
    }

    const [imgW, imgH] = [imgDim.width, imgDim.height]

    console.log("Create transform interactions - onRectangleDrawn")
    this.transformInteractions = new TransformInteractions(
      this.mapComponent.map,
      [0, 0, imgW, imgH],
      this.transforms,
      null,
      true,
      "green"
    )

    this.transformInteractions.addInteractions()

    const from = this.csConverter.mat3From(imgDim)
    const to = this.csConverter.mat3From(rect)
    const transformMatrix = findTransform(from, to)

    this.transforms.setTransform(transformMatrix)

    // TODO Do not use instant?
    this.addLabel([0, imgH / 2], this.translate.instant("floor.first_setup.length_label"))
    this.addLabel([imgW / 2, 0], this.translate.instant("floor.first_setup.width_label"))

    this.baseCSConfigured.next(true)
  }

  /**
   * Clear base coordinate system setup.
   *
   * Removes current base setup rectangle and re-enables draw rectangle interaction,
   * by performing cleanup of everything onRectangleDrawn function did in reverse order.
   */
  clearBaseCS() {
    this.baseCSConfigured.next(false)

    this.transforms.setTransform(mat3.create())

    this.labelLayer.source.clear()

    if (this.transformInteractions) {
      this.transformInteractions.removeInteractions()
      this.transformInteractions = null
    }
  }

  /**
   * Auto adjust image by calling image processing endpoints
   */
  autoAdjustImage() {
    this.autoAdjustHelper.autoAdjustImage(this.transforms, false, this.unsubscribe)
  }

  /**
   * Reset adjust image position
   */
  resetAdjustImage() {
    this.transforms.setTransform(mat3.create())
  }

  /**
   * Toggle current mode.
   */
  switchMode() {
    switch (this.mode.getValue()) {
      case Mode.ADJUST: {
        this.mode.next(Mode.BASE)
        break
      }
      case Mode.BASE: {
        // TODO Validation - only switch if base was set
        this.mode.next(Mode.ADJUST)
        break
      }
    }
  }

  /**
   * Store current saved cs and determine mode
   *
   * - Stores currentSavedCS for further use
   * - Determines mode to use
   * - Populates sidebar form with width and length values
   */
  private loadCSAndSelectMode(cs: FloorCSConfigResponse): void {
    let newMode: Mode

    if (cs) {
      newMode = Mode.ADJUST
      this.width = cs.metricCS.yellow.x
      this.length = cs.metricCS.pink.y
    } else {
      newMode = Mode.BASE
    }

    this.mode.next(newMode)
    this.currentSavedCS.next(cs)
  }

  /**
   * Clean up component state.
   */
  private cleanup() {
    // Clearing base cs will also clear adjust mode transforms, so there is nothing more to do
    this.clearBaseCS()

    // Reset errors, in case we cleanup to change mode
    this.resetErrors()
  }

  /**
   * Load base setup received from backend.
   */
  private loadSavedBaseSetup(cs: FloorCSConfigResponse, imgDim: ImageDimensions) {
    this.cleanup()

    // If cs is set - load it
    if (cs) {
      // Use cs.adjustedPixelCS to load so our rectangle is adjusted after changing image (as the name implies)
      this.onRectangleDrawn(
        this.csConverter.rectangleDefinitionFrom(cs.adjustedPixelCS, imgDim.height)
      )
    }

    this.loading.next(false)
  }

  /**
   * Load adjust setup received from backend.
   */
  private loadSavedAdjustSetup(
    cs: FloorCSConfigResponse,
    imgDim: ImageDimensions,
    adjImgDim: ImageDimensions
  ) {
    this.cleanup()

    const apx = this.csConverter.rectangleDefinitionFrom(cs.adjustedPixelCS, adjImgDim.height)
    const bpx = this.csConverter.rectangleDefinitionFrom(cs.basePixelCS, imgDim.height)

    const to = this.csConverter.mat3From(bpx)
    const from = this.csConverter.mat3From(apx)
    const transformMatrix = findTransform(from, to)

    this.transforms.setTransform(transformMatrix)

    console.log("Create transform interactions - loadSavedAdjustSetup")
    this.transformInteractions = new TransformInteractions(
      this.mapComponent.map,
      [0, 0, adjImgDim.width, adjImgDim.height],
      this.transforms,
      null
    )

    this.transformInteractions.addInteractions()

    this.loading.next(false)
  }

  /**
   * Setup base coordinate system in backend
   */
  private saveBase() {
    // Relies heavily on TransformInteractions implementation,
    // where the base cs rectangle is the image border,
    // that has been transformed to user configured rectangle
    // by ImageTransforms matrices
    const imgDim = this.imgDimSubject.getValue()
    if (!isImageDimensions(imgDim)) {
      // Should not happen
      throw new Error("Save not allowed without imgDim")
    }
    const [imgW, imgH] = [imgDim.width, imgDim.height]
    const points = {
      blue: this.transforms.toWorld([0, 0, 1]),
      yellow: this.transforms.toWorld([imgW, 0, 1]),
      pink: this.transforms.toWorld([0, imgH, 1]),
    }

    const progressDialog = this.openSaveProgressDialog()

    this.floorCSConfigs
      .setFloorCSBaseConfig(this.currentFloor.getFloor().id, {
        basePixelCS: this.csConverter.pixelCSFrom(points, imgH),
        metricCS: this.csConverter.metricCSFrom(this.width, this.length),
      })
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      // TODO Handle error
      .subscribe((cs) => this.csOverrideStream.next(cs))
  }

  /**
   * Adjust coordinate system in backend
   */
  private saveAdjust() {
    const imgDim = this.imgDimSubject.getValue()
    if (!isImageDimensions(imgDim)) {
      // Should not happen
      throw new Error("Save not allowed without imgDim")
    }
    // Convert base pixel cs to map coordinates of base image
    const baseRect = this.csConverter.rectangleDefinitionFrom(
      this.currentSavedCS.getValue().basePixelCS,
      imgDim.height
    )

    // Transform base map coordinates to adjusted coordinates with defined transform matrix
    const points = {
      blue: this.transforms.toLocal(baseRect.blue),
      yellow: this.transforms.toLocal(baseRect.yellow),
      pink: this.transforms.toLocal(baseRect.pink),
    }

    // Convert adjusted map coordinates to adjusted pixel cs
    const adjImgDim = this.adjustImgDimSubject.getValue()
    if (!isImageDimensions(adjImgDim)) {
      // Should not happen
      throw new Error("Save not allowed without imgDim")
    }
    const adjustedPixelCS = this.csConverter.pixelCSFrom(points, adjImgDim.height)

    const progressDialog = this.openSaveProgressDialog()

    // Finally, save it
    this.floorCSConfigs
      .setFloorCSAdjustConfig(this.currentFloor.getFloor().id, { adjustedPixelCS })
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => progressDialog.close())
      )
      // TODO Handle error
      .subscribe((cs) => this.csOverrideStream.next(cs))
  }

  /**
   * Add label to map
   */
  private addLabel(coords: Coordinate, text: string) {
    let label = new Feature<olPoint>({
      geometry: new olPoint(coords),
      name: "Labels",
    })

    let labelStyle = new Style({
      text: new Text({
        font: "20px sans-serif",
        text: text,
        stroke: new Stroke({
          color: "white",
          width: 5,
        }),
        fill: new Fill({
          color: "blue",
        }),
      }),
    })

    label.setStyle(labelStyle)
    this.labelLayer.source.addFeature(label)

    label.getGeometry().applyTransform((coords, out) => this.transforms.toWorld(coords, out))
  }

  /**
   * Open ProgressDialog for save operation
   */
  private openSaveProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "floor.save_in_progress",
    }
    return this.dialog.open(ProgressDialog, { data })
  }

  private resetErrors(): void {
    this.noImgDim.next(false)
    this.noAdjImgDim.next(false)
  }

  private onImgDimError(err: any) {
    this.noImgDim.next(true)
    this.loading.next(false)
  }

  private onAdjImgDimError(err: any) {
    this.noAdjImgDim.next(true)
    this.loading.next(false)
  }
}
