import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import mdiClose from "@iconify/icons-mdi/close"
import { PositioningModel } from "@venue/api"

class TabData {
  constructor(public name: string, public model: PositioningModel) {}
}

@Component({
  selector: "positioning-model-dialog",
  templateUrl: "./positioning-model.dialog.html",
})
export class PositioningModelDialog {
  readonly closeIcon = mdiClose

  tabs: TabData[]

  constructor(
    private dialogRef: MatDialogRef<PositioningModelDialog>,
    @Inject(MAT_DIALOG_DATA) private models: PositioningModel[]
  ) {
    dialogRef.addPanelClass("dialog-with-toolbar")

    this.tabs = [
      new TabData("Wifi Scans", models[0]),
      new TabData("Learning Set", models[1]),
      new TabData("Verification Set", models[2]),
    ]
  }

  onNoClick(): void {
    this.close()
  }

  close(): void {
    this.dialogRef.close()
  }
}
