import { AfterViewInit, Component, Directive, ViewChild } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import mdiCheck from "@iconify/icons-mdi/check"
import mdiClose from "@iconify/icons-mdi/close"
import mdiCursorMove from "@iconify/icons-mdi/cursor-move"
import mdiDeleteForever from "@iconify/icons-mdi/delete-forever"
import mdiMapMarkerMinus from "@iconify/icons-mdi/map-marker-minus"
import mdiMapMarkerPlus from "@iconify/icons-mdi/map-marker-plus"
import mdiPencil from "@iconify/icons-mdi/pencil"
import { FloorCSConfigResponse, FloorCSConfigsService, FloorsService } from "@openapi/venue"
import { ImageDimensions, isImageDimensions, Location, Point } from "@venue/api"
import { CurrentFloor, CurrentVenue } from "@venue/core"
import {
  ImageDimensionsReceiverMixin,
  MapToolReceiverMixin,
  SelectFeatureInteractionComponent,
  VectorLayerComponent,
} from "@venue/maps"
import { withUnsubscribe } from "@venue/shared"
import { GlobalCoordinateSystem } from "@venue/shared/utils/wgs-converter"
import { Feature } from "ol"
import { Point as olPoint } from "ol/geom"
import { Subject, zip } from "rxjs"
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
  tap,
} from "rxjs/operators"
import { Mixin } from "ts-mixer"
import { PROTO_POINT_PROPERTY_KEY, WGS_LOCATION_PROPERTY_KEY } from "./property-keys"
import { ProtoPoint } from "./proto-point"
import { PROTOTYPE_POINT_STYLE } from "./prototype-point-style"

const WGS_FORM_PROPERTY_KEY = "wgsFormGroup"

class FloorWgsToolReceiver extends MapToolReceiverMixin<MapTool> {}

@Directive()
abstract class FloorWgsComponentBase extends Mixin(
  ImageDimensionsReceiverMixin,
  FloorWgsToolReceiver
) {}

@withUnsubscribe
@Component({
  selector: "floor-wgs",
  templateUrl: "./floor-wgs.component.html",
})
export class FloorWgsComponent extends FloorWgsComponentBase implements AfterViewInit {
  readonly cancelWgsEditIcon = mdiClose
  readonly applyWgsEditIcon = mdiCheck
  readonly addPointIcon = mdiMapMarkerPlus
  readonly removePointIcon = mdiMapMarkerMinus
  readonly moveIcon = mdiCursorMove
  readonly editIcon = mdiPencil
  readonly removeAllIcon = mdiDeleteForever

  @ViewChild(VectorLayerComponent) prototypeLayer: VectorLayerComponent
  @ViewChild("selectEditInteraction") selectEditInteraction: SelectFeatureInteractionComponent

  featuresWithVisibleOverlays = new Set<Feature<olPoint>>()

  prototypeStyle = PROTOTYPE_POINT_STYLE

  protoPoints = new PrototypePointsModel()

  private unsubscribe: Subject<void>

  constructor(
    public currentFloor: CurrentFloor,
    private fb: FormBuilder,
    private floors: FloorsService,
    private floorCSApi: FloorCSConfigsService,
    private currentVenue: CurrentVenue
  ) {
    super()
  }

  ngAfterViewInit(): void {
    console.error("This component is not updated to latest api!")

    const floorStream = this.currentFloor.floor.pipe(
      startWith(null),
      distinctUntilChanged(),
      tap(() => this.removePoints())
    )

    zip(
      floorStream,
      this.imgDimSubject.pipe(
        map((dimOrErr) => {
          if (isImageDimensions(dimOrErr)) {
            return dimOrErr
          }

          console.log("No image dim!")
          return null
        })
      )
    )
      .pipe(
        filter(([f, imgDim]) => !!f && !!imgDim),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([f, imgDim]) => {
        // Note: We should load wgscs from floorsCSApi here, update if this component will be needed.
        // this.loadSetup(f, imgDim)
      })

    this.toggledMapTool
      .pipe(distinctUntilChanged(), pairwise(), takeUntil(this.unsubscribe))
      .subscribe(([oldTool, newTool]) => {
        switch (oldTool) {
          case MapTool.ADD:
            this.featuresWithVisibleOverlays.forEach((f) => {
              this.prototypeLayer.source.removeFeature(f)
              this.protoPoints.removePoint(f)
            })
          // falls through
          case MapTool.EDIT:
            this.featuresWithVisibleOverlays.clear()
        }
      })
  }

  getWgsForm(feature: Feature<olPoint>): FormGroup {
    let form: FormGroup = feature.get(WGS_FORM_PROPERTY_KEY)
    if (!form) {
      const location: Location = feature.get(WGS_LOCATION_PROPERTY_KEY) || {}
      form = this.fb.group({
        longitude: [
          location.longitude,
          [Validators.required, Validators.min(-180), Validators.max(180)],
        ],
        latitude: [
          location.latitude,
          [Validators.required, Validators.min(-90), Validators.max(90)],
        ],
      })
      feature.set(WGS_FORM_PROPERTY_KEY, form)
    }
    return form
  }

  onFeatureDrawn(feature: Feature<olPoint>): void {
    this.featuresWithVisibleOverlays.add(feature)
    this.protoPoints.addPoint(feature)
  }

  onFeatureRemoved(feature: Feature<olPoint>): void {
    // XXX Is it even possible to have active overlay during feature removal?
    // Same appiles to positioning-accuracy.component
    this.featuresWithVisibleOverlays.delete(feature)
    this.protoPoints.removePoint(feature)
  }

  onEditDevice(feature: Feature<olPoint>): void {
    this.featuresWithVisibleOverlays.forEach((f) => this.cancelWgsEdit(f))
    if (feature) {
      this.featuresWithVisibleOverlays.add(feature)
    }
  }

  removePoints(): void {
    this.featuresWithVisibleOverlays.clear()
    this.prototypeLayer.clearFeatures()
    this.protoPoints.clear()
  }

  applyWgsEdit(feature: Feature<olPoint>, wgsCoords: Location): void {
    this.featuresWithVisibleOverlays.delete(feature)

    feature.set(WGS_LOCATION_PROPERTY_KEY, wgsCoords)
    feature.set(WGS_FORM_PROPERTY_KEY, undefined)

    if (this.toggledMapTool.value === MapTool.EDIT) {
      this.selectEditInteraction.deselectFeature(feature)
    }
  }

  cancelWgsEdit(feature: Feature<olPoint>): void {
    this.featuresWithVisibleOverlays.delete(feature)

    const newDevice = feature.get(WGS_LOCATION_PROPERTY_KEY) === undefined
    feature.set(WGS_FORM_PROPERTY_KEY, undefined)

    if (newDevice) {
      this.prototypeLayer.source.removeFeature(feature)
      this.protoPoints.removePoint(feature)
    } else if (this.toggledMapTool.value === MapTool.EDIT) {
      this.selectEditInteraction.deselectFeature(feature)
    }
  }

  save(): void {
    const wgsCS = this.extractGlobalCoordinateSystem()
    this.floorCSApi
      .setFloorCSWgsConfig(this.currentFloor.getFloor().id, { wgsCS })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (floor) => {
          // TODO Display saved message
          //
          // Note: Following commented code updated currentFloor floor data, but
          //       now it should not be necessary. Also, note that this component
          //       is in fact not used! So lets do not waste more time for it.
          //
          // const loadedFloors = this.currentVenue.getFloors()
          // const floorIdx = loadedFloors.findIndex((f) => f.id === floor.floorId)
          // // Note: Line below does not trigger listeners on floors changes.
          // //     Could lead to a bug with not up-to-date floor if some component/service clones floors array.
          // loadedFloors[floorIdx] = floor
          // this.currentFloor.setFloor(floor)
          // TODO Change state to second setup
        },
        (error) => {
          // TODO Display error message
        }
      )
  }

  private loadSetup(f: FloorCSConfigResponse, imgDim: ImageDimensions): void {
    if (!f) {
      return
    }

    if (f.basePixelCS) {
      this.protoPoints.blue = this.createProtoPointFeature(
        flipVertical(f.basePixelCS.blue, imgDim.height),
        f.wgsCS.blue,
        ProtoPoint.BLUE
      )
      this.protoPoints.yellow = this.createProtoPointFeature(
        flipVertical(f.basePixelCS.yellow, imgDim.height),
        f.wgsCS.yellow,
        ProtoPoint.YELLOW
      )
      this.protoPoints.pink = this.createProtoPointFeature(
        flipVertical(f.basePixelCS.pink, imgDim.height),
        f.wgsCS.pink,
        ProtoPoint.PINK
      )
    }

    this.prototypeLayer.source.addFeatures(
      [this.protoPoints.blue, this.protoPoints.yellow, this.protoPoints.pink].filter((p) => !!p)
    )
  }

  /**
   * Return feature if all data was provided, otherwise null.
   */
  private createProtoPointFeature(
    pixel: Point,
    global: Location,
    pointType: ProtoPoint
  ): Feature<olPoint> {
    if (
      pixel &&
      pixel.x !== undefined &&
      pixel.y !== undefined &&
      global &&
      global.latitude !== undefined &&
      global.longitude !== undefined &&
      pointType !== undefined
    ) {
      const feature = new Feature<olPoint>({
        geometry: new olPoint([pixel.x, pixel.y]),
      })
      feature.set(PROTO_POINT_PROPERTY_KEY, pointType)
      feature.set(WGS_LOCATION_PROPERTY_KEY, global)
      return feature
    }
    return null
  }

  private extractGlobalCoordinateSystem(): GlobalCoordinateSystem {
    return {
      blue: this.protoPoints.blue.get(WGS_LOCATION_PROPERTY_KEY),
      yellow: this.protoPoints.yellow.get(WGS_LOCATION_PROPERTY_KEY),
      pink: this.protoPoints.pink.get(WGS_LOCATION_PROPERTY_KEY),
    }
  }
}

enum MapTool {
  ADD = "ADD",
  REMOVE = "REMOVE",
  MOVE = "MOVE",
  EDIT = "EDIT",
  REMOVE_ALL = "REMOVE_ALL",
}

// XXX Extract to separate file?
class PrototypePointsModel {
  blue?: Feature<olPoint>
  yellow?: Feature<olPoint>
  pink?: Feature<olPoint>

  canAddPoint(): boolean {
    return !this.blue || !this.yellow || !this.pink
  }

  allPointsDefined(): boolean {
    return (
      !this.canAddPoint() &&
      this.blue.get(WGS_LOCATION_PROPERTY_KEY) &&
      this.yellow.get(WGS_LOCATION_PROPERTY_KEY) &&
      this.pink.get(WGS_LOCATION_PROPERTY_KEY)
    )
  }

  addPoint(f: Feature<olPoint>): void {
    if (!this.blue) {
      this.blue = f
      f.set(PROTO_POINT_PROPERTY_KEY, ProtoPoint.BLUE)
    } else if (!this.yellow) {
      this.yellow = f
      f.set(PROTO_POINT_PROPERTY_KEY, ProtoPoint.YELLOW)
    } else if (!this.pink) {
      this.pink = f
      f.set(PROTO_POINT_PROPERTY_KEY, ProtoPoint.PINK)
    } else {
      throw new Error("Can't add point")
    }
  }

  removePoint(f: Feature<olPoint>): void {
    if (this.blue === f) {
      this.blue = undefined
    } else if (this.yellow === f) {
      this.yellow = undefined
    } else if (this.pink === f) {
      this.pink = undefined
    }
  }

  clear(): void {
    this.blue = this.yellow = this.pink = undefined
  }
}

// XXX Extract to utils?
function getPointCoordinates(feature: Feature<olPoint>): Point {
  const coords = (<olPoint>feature.getGeometry()).getCoordinates()
  return { x: coords[0], y: coords[1] }
}

// XXX Extract to utils?
// XXX Consider flipping in api services - would require img height knowledge from backend, but could simplify frontend a lot
function flipVertical(point: Point, imgHeight: number): Point {
  return {
    x: point.x,
    y: imgHeight - point.y,
  }
}
