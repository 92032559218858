import { NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { MapsModule } from "@venue/maps"
import { SharedModule } from "@venue/shared"
import * as m from "."

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: [m.ACCESS_POINTS_EDITOR_STATE] }),
        SharedModule,
        MapsModule
    ],
    declarations: [m.AccessPointsComponent, m.AccessPointsSidebarComponent, m.AccessPointFormDialog]
})
export class AccessPointsModule {}
