import { Injectable } from "@angular/core"
import { ServerConfig } from "./server-config.service"

@Injectable()
export class ApiUrl {
    constructor(private server: ServerConfig) {}

    // XXX Possibly remove later
    getAbsAddress(path: string): string {
        return this.host() + (path || "")
    }

    // XXX Rename to getVenueAddress?
    getAddress(path: string): string {
        return this.url() + (path || "")
    }

    getFoundationAddress(path: string): string {
        return this.host() + "/foundation" + (path || "")
    }

    getSipsAddress(path: string): string {
        return this.host() + "/sips" + (path || "")
    }

    getMotAddress(path: string): string {
        return this.host() + "/api/ips" + (path || "")
    }

    getLoginAddress(path: string): string {
        return this.host() + "/api" + (path || "")
    }

    private host(): string {
        if (this.server.host && this.server.host.length > 0) {
            return `${this.server.protocol || "http"}://${this.server.host}:${this.server.port ||
                8765}`
        }
        return ""
    }

    private url(): string {
        let url = ""
        if (this.server.context !== null) {
            url += this.host() + "/" + this.server.context
        }
        return url
    }
}
