<loading-overlay *ngIf="(loading | async) && !noTP"></loading-overlay>
<div *letVar="noCS || noTP || noImgDim as hasError"
     class="content-wrapper"
     fxFlex>

    <div *ngIf="hasError"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="center center"
         class="error-overlay">
        <h1 translate>editor-error.header</h1>
        <span *ngIf="noCS"
              translate>editor-error.no-cs</span>
        <span *ngIf="noImgDim"
              translate>editor-error.no-img-dim</span>
        <span *ngIf="noTP"
              translate>trace-path.no-tp</span>
    </div>

    <div class="buttons-container"
         fxLayout="column"
         fxLayoutGap="1em">
        <button *ngIf="!hasError"
                mat-raised-button
                class="import-button"
                color="primary"
                [disabled]="importDisabled | async"
                (click)="importPath()">
            {{ 'trace-path.import-button' | translate }}
        </button>

        <button *ngIf="!hasError"
                mat-raised-button
                class="export-button"
                [disabled]="exportDisabled | async"
                (click)="exportTraceFile()">
            {{ 'trace-path.export-button' | translate }}
        </button>

        <button *ngIf="!hasError"
                mat-raised-button
                class="export-button"
                [disabled]="exportDisabled | async"
                (click)="exportTraceToServer()">
            {{ 'trace-path.export-to-server-button' | translate }}
        </button>
    </div>

    <mat-card *ngIf="editFeature"
              class="edit-path-form-card">
        <mat-card-content>
            <div class="path-width-label-container"
                 fxLayout="row"
                 fxLayoutAlign="space-between">
                <label class="path-width-name-label"
                       translate>trace-path.edit-card.path-width</label>
                <label class="path-width-value-label"> {{ formatPathWidthLabel(pathWidthSlider.value) }}</label>
            </div>
            <mat-slider #pathWidthSlider
                        step="0.1"
                        min="0.5"
                        max="5"
                        [value]="pathWidth"
                        (input)="pathWidthSliderMove($event.value)"
                        (change)="pathWidthSliderChange($event.value)"></mat-slider>
        </mat-card-content>
    </mat-card>

    <openlayers-map *letVar="toggledMapTool | async as mapTool"
                    fxFlex>
        <floor-image-layer [floor]="currentFloor | async"
                           (imgDim)="onImageDimChange($event)">
        </floor-image-layer>
        <vector-layer #accessibleAreasLayer
                      name="accessibleAreasLayer"
                      [style]="accessibleAreasStyle">
        </vector-layer>
        <vector-layer #tpLayer
                      name="tpLayer"
                      [style]="tpStyle">
        </vector-layer>
        <modify-interaction [layers]="[ tpLayer ]"
                            [disabled]="mapTool !== 'MODIFY'"
                            (featuresModified)="onFeaturesModified($event)">
        </modify-interaction>
        <transform-interaction [layers]="[ tpLayer ]"
                               [disabled]="mapTool !== 'TRANSFORM'"
                               (featureTransformed)="onFeatureTransformed($event)">
        </transform-interaction>
        <select-feature-interaction #selectEditInteraction
                                    [layers]="[ tpLayer ]"
                                    (selectionChange)="onFeatureEdit($event[0])"
                                    [disabled]="mapTool !== 'EDIT'">
        </select-feature-interaction>
        <multi-vertex-modify-interaction [layers]="[ tpLayer ]"
                                         [disabled]="mapTool !== 'MULTI_MODIFY'"
                                         (verticesModified)="onFeaturesModified([$event])">
        </multi-vertex-modify-interaction>

        <map-toolbar>
            <mat-button-toggle-group name="mapTool"
                                     multiple
                                     (change)="changeMapTool($event)">
                <mat-button-toggle title='{{ "polygon_editor.controls.transform_polygon_tooltip" | translate }}'
                                   value="TRANSFORM"
                                   [disableRipple]="true">
                    <ic-icon [icon]="transformIcon"></ic-icon>
                </mat-button-toggle>
                <mat-button-toggle title='{{ "polygon_editor.controls.modify_polygon_tooltip" | translate }}'
                                   value="MODIFY"
                                   [disableRipple]="true">
                    <ic-icon [icon]="modifyPolygonIcon"></ic-icon>
                </mat-button-toggle>
                <mat-button-toggle title='{{ "polygon_editor.controls.multi_modify_tooltip" | translate }}'
                                   value="MULTI_MODIFY"
                                   [disableRipple]="true">
                    <ic-icon [icon]="multiModifyIcon"></ic-icon>
                </mat-button-toggle>
                <mat-button-toggle title='{{ "polygon_editor.controls.edit_polygon_tooltip" | translate }}'
                                   value="EDIT"
                                   [disableRipple]="true">
                    <ic-icon [icon]="editIcon"></ic-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <button title='{{ "polygon_editor.controls.undo_tooltip" | translate }}'
                    (click)="undo()"
                    [disabled]="!canUndo">
                <ic-icon width="32px"
                         height="32px"
                         [icon]="undoIcon"> </ic-icon>
            </button>
            <button title='{{ "polygon_editor.controls.redo_tooltip" | translate }}'
                    (click)="redo()"
                    [disabled]="!canRedo">
                <ic-icon width="32px"
                         height="32px"
                         [icon]="redoIcon"> </ic-icon>
            </button>
            <button title='{{ "polygon_editor.controls.reset_polygon_layer_tooltip" | translate }}'
                    (click)="restoreSaved()">
                <ic-icon width="32px"
                         height="32px"
                         [icon]="restoreSavedIcon"> </ic-icon>
            </button>
        </map-toolbar>
    </openlayers-map>
</div>
