import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import {
  MobileRecordsControllerService,
  PageRecordMetadata,
  RecordMetadata,
} from "@openapi/mipsengine"
import { Pageable, pageableOf } from "@venue/api"
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs"
import { map, mergeMap, startWith, takeUntil, tap } from "rxjs/operators"

export class MobileRecordsDataSource implements DataSource<RecordMetadata> {
  private dataSubject = new BehaviorSubject<RecordMetadata[]>([])
  private unsubscribe = new Subject<void>()
  private refreshSubject = new Subject<void>()

  selection = new SelectionModel<RecordMetadata>(true, [])

  constructor(
    private recordsService: MobileRecordsControllerService,
    private paginator: MatPaginator,
    private sort: MatSort
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<RecordMetadata[]> {
    const pageableStream = combineLatest(
      this.sort.sortChange.pipe(startWith(this.sort)),
      this.paginator.page.pipe(startWith(this.paginator))
    ).pipe(
      takeUntil(this.unsubscribe),
      map(([sort, page]) => pageableOf(page, sort))
    )

    combineLatest(pageableStream, this.refreshSubject.pipe(startWith({})), (a, b) => a)
      .pipe(
        mergeMap((pageable) => this.fetchPage(pageable)),
        tap((page) => this.updatePaginator(page)),
        tap((page) => this.refreshSelection(page))
      )
      .subscribe((page) => this.dataSubject.next(page.content))
    return this.dataSubject.asObservable()
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.dataSubject.complete()
    this.refreshSubject.complete()
  }

  refresh(): void {
    this.refreshSubject.next()
  }

  isAllSelected(): boolean {
    return this.selection.selected.length == this.dataSubject.value.length
  }

  toggleAllSelected(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSubject.value.forEach((v) => this.selection.select(v))
  }

  private fetchPage(pageable: Partial<Pageable>): Observable<PageRecordMetadata> {
    return this.recordsService
      .getRecordsUsingGET(pageable.page, pageable.size, pageable.sort)
      .pipe(takeUntil(this.unsubscribe))
  }

  private updatePaginator(page: PageRecordMetadata): void {
    this.paginator.length = page.totalElements
  }

  private refreshSelection(page: PageRecordMetadata): void {
    const selectedRecordIds = this.selection.selected.map((v) => v.recordId)
    const toSelect = page.content.filter((v) => selectedRecordIds.includes(v.recordId))
    const toDeselect = page.content.filter((v) => !selectedRecordIds.includes(v.recordId))

    this.selection.clear()
    this.selection.select(...toSelect)
    this.selection.deselect(...toDeselect)
  }
}
