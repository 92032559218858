import { HttpErrorResponse } from "@angular/common/http"
import { AfterViewInit, Component, Directive, ViewChild } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import mdiBackupRestore from "@iconify/icons-mdi/backup-restore"
import mdiCursorMove from "@iconify/icons-mdi/cursor-move"
import mdiDeleteForever from "@iconify/icons-mdi/delete-forever"
import mdiRedo from "@iconify/icons-mdi/redo"
import mdiShapePolygonPlus from "@iconify/icons-mdi/shape-polygon-plus"
import mdiUndo from "@iconify/icons-mdi/undo"
import mdiVectorPolyline from "@iconify/icons-mdi/vector-polyline"
import vsDoorOpen from "@iconify/icons-vs/door-open"
import {
  FloorCSConfigResponse,
  FloorCSConfigsService,
  ImageProcessingService,
  Point,
  UserAccessibleAreas,
  UserAccessibleAreaShape,
  UserAccessibleAreasService,
  UserWalls,
  UserWallShape,
  UserWallsService,
  Walls,
  WallShape,
  WallsRequest,
  WallsService,
} from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { ImageDimensions, isImageDimensions } from "@venue/api"
import { AuthUtilService } from "@venue/auth/services/auth-util.service"
import {
  defaultStyle as DOORS_STYLE,
  USER_DOORS_DEFAULT_STROKE,
} from "@venue/components/map/styles/DoorStyle"
import { CoordinateConverter, CurrentFloor } from "@venue/core"
import {
  ImageDimensionsReceiverMixin,
  LayerHistoryEvent,
  ObjectData,
  ObjectProperties,
  VectorLayerComponent,
} from "@venue/maps"
import { LayerHistoryMixin, MapToolReceiverMixin } from "@venue/maps/utils"
import { ProgressDialog, ProgressDialogData } from "@venue/shared"
import { withUnsubscribe } from "@venue/shared/decorators"
import { sortedIndexBy } from "lodash"
import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { Circle, Polygon } from "ol/geom"
import {
  BehaviorSubject,
  combineLatest,
  empty,
  Observable,
  of,
  Subject,
  throwError,
  timer,
  zip,
} from "rxjs"
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators"
import { Mixin } from "ts-mixer"
import { WallsSidebarComponent } from "."
import { WallsCompositorService } from "./compositor/walls-compositor.service"
import {
  GENERATED_WALLS_DEFAULT_FILL,
  GENERATED_WALLS_STYLE,
} from "./map-styles/generated-wall.map-style"
import { USER_WALLS_DEFAULT_STROKE, USER_WALLS_STYLE } from "./map-styles/user-wall.map-style"
import {
  FloodFillConstraintsGenerator,
  GeneratedConstraint,
} from "./utils/flood-fill-constraints-generator"

// Wall or door
const SHAPE_TYPE_PROPERTY = "shape_type"
// XXX Consider extracting shape id management to separate file
const SHAPE_ID_PROPERTY = "shape_id"

type WallGeometry = Polygon | Circle
type WallFeature = Feature<WallGeometry>

// Note: this class is not ready for layer history
class ShapeIdManager {
  shapes: WallFeature[] = []
  lastId = 0

  featureAdded(feature: WallFeature): void {
    feature.set(SHAPE_ID_PROPERTY, ++this.lastId)
    this.shapes.push(feature)
  }

  featureRemoved(feature: WallFeature): void {
    const idx = sortedIndexBy(this.shapes, feature, (f) => f.get(SHAPE_ID_PROPERTY))
    this.shapes.splice(idx, 1)
    this.reassignIds()
  }

  featureModified(feature: WallFeature): void {
    this.featureRemoved(feature)
    this.featureAdded(feature)
  }

  clear(): void {
    this.shapes = []
    this.lastId = 0
  }

  private reassignIds(): void {
    this.lastId = 0
    this.shapes.forEach((f) => f.set(SHAPE_ID_PROPERTY, ++this.lastId))
  }
}

class WallMapToolReceiverMixin extends MapToolReceiverMixin<MapTool> {}

@Directive()
abstract class WallsComponentBase extends Mixin(
  ImageDimensionsReceiverMixin,
  WallMapToolReceiverMixin,
  LayerHistoryMixin
) {}

@withUnsubscribe
@Component({
  selector: "walls",
  templateUrl: "./walls.component.html",
  providers: [FloodFillConstraintsGenerator, WallsCompositorService],
})
export class WallsComponent extends WallsComponentBase implements AfterViewInit {
  readonly addDoorIcon = mdiShapePolygonPlus
  readonly moveIcon = mdiCursorMove
  readonly modifyIcon = mdiVectorPolyline
  readonly removeAllIcon = mdiDeleteForever
  readonly resetWallsIcon = mdiBackupRestore
  readonly detectDoorIcon = vsDoorOpen
  readonly undoIcon = mdiUndo
  readonly redoIcon = mdiRedo

  readonly generatedWallsStyle = GENERATED_WALLS_STYLE
  readonly generatedWallsFill = GENERATED_WALLS_DEFAULT_FILL
  readonly userWallsStyle = USER_WALLS_STYLE
  readonly userWallsStroke = USER_WALLS_DEFAULT_STROKE
  readonly doorsStyle = DOORS_STYLE
  readonly doorsStroke = USER_DOORS_DEFAULT_STROKE

  readonly WRITE_USER_ROLE = "venue:walls:write"

  /** Observable indicating if loading overlay should be displayed. */
  loading = new BehaviorSubject(true)

  /** Flag indicating that downloading floor coordinate system config failed. */
  noCS = false

  /** Flag indicating that geting image dimensions failed. */
  noImgDim = false

  @ViewChild("generatedOuterWallLayer") generatedOuterWallLayer: VectorLayerComponent
  @ViewChild("generatedWallsLayer") generatedWallsLayer: VectorLayerComponent
  @ViewChild("userWallsLayer") userWallsLayer: VectorLayerComponent
  @ViewChild("doorsLayer") doorsLayer: VectorLayerComponent

  @ViewChild(WallsSidebarComponent) sidebar: WallsSidebarComponent

  private loadedGeneratedWalls: Walls
  private loadedUserWalls: UserWalls
  private loadedDoors: UserAccessibleAreas

  private progressDialog: MatDialogRef<ProgressDialog>

  private shapeIdManager = new ShapeIdManager()

  private unsubscribe: Subject<void>

  constructor(
    public currentFloor: CurrentFloor,
    public constraintsGenerator: FloodFillConstraintsGenerator,
    private coordinateConverter: CoordinateConverter,
    private walls: WallsService,
    private userWalls: UserWallsService,
    private userDoors: UserAccessibleAreasService,
    private imageProcessing: ImageProcessingService,
    private floorCSConfigs: FloorCSConfigsService,
    private dialog: MatDialog,
    private state: StateService,
    private authUtilService: AuthUtilService
  ) {
    super()
  }

  protected layersWithHistory(): VectorLayerComponent[] {
    return [this.userWallsLayer, this.doorsLayer]
  }

  ngAfterViewInit(): void {
    this.initHistoryMixin()

    // Get current floor (can later be changed to not rely on fetching floor data - only floor id is needed)
    const floorStream = this.currentFloor.floor.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
      shareReplay(1)
    )

    // On floor change - display loading indicator
    floorStream.subscribe(() => this.loading.next(true))

    // On floor change - reset errors
    floorStream.subscribe(() => this.resetErrors())

    // On floor change - clear map
    floorStream.subscribe(() => this.clearMap())

    // On floor or blackCanvasGenerator change - initialize FloodFillConstraintsGenerator
    combineLatest(floorStream, this.sidebar.blackCanvasGenerator)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([f, blackCanvas]) => this.constraintsGenerator.init(f?.id, blackCanvas))

    // Get current floor coordinate system config
    const csStream = floorStream.pipe(
      filter((f) => !!f),
      switchMap((f) =>
        this.floorCSConfigs.getFloorCSConfig(f.id).pipe(catchError((err) => this.catchCSError(err)))
      ),
      takeUntil(this.unsubscribe)
    )

    // Fetch walls on floor cs change after loading its image dimensions
    // Init coordinate converter after fetching walls and load them on the map
    combineLatest(
      csStream,
      this.imgDimSubject.pipe(
        switchMap((dimOrErr) => {
          if (isImageDimensions(dimOrErr)) {
            return of(dimOrErr)
          }
          return this.catchIDError(dimOrErr)
        })
      )
    )
      .pipe(
        filter(([cs, imgDim]) => !!cs && !!imgDim),
        switchMap(([cs, imgDim]) =>
          zip(
            // Loading mainly for walls id, to know if we should create or update
            // Would be better to change backend api to use one method for create/update
            // as we allow only one object of given type per floor.
            this.fetchGeneratedWalls(cs.floorId).pipe(
              catchError((e) => this.catchWallsFetchError<Walls>("GENERATED_WALLS_LOAD_ERROR", e))
            ),
            this.fetchUserWalls(cs.floorId).pipe(
              catchError((e) => this.catchWallsFetchError<UserWalls>("USER_WALLS_LOAD_ERROR", e))
            ),
            this.fetchUserAccessibleAreas(cs.floorId).pipe(
              catchError((e) =>
                this.catchWallsFetchError<UserAccessibleAreas>("USER_DOORS_LOAD_ERROR", e)
              )
            )
          ).pipe(tap((walls) => this.coordinateConverter.load(cs, imgDim.height)))
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        ([generatedWalls, userWalls, doors]) => {
          this.sidebar.blackCanvasGeneratorValue = !(generatedWalls?.imageBasedWalls ?? true)
          this.loadData(generatedWalls, userWalls, doors)
          this.layerHistory.init()

          let importPolygons = this.state.params.importPolygons as Array<Array<Coordinate[]>>

          // If we have polygons to import - import them and save history again
          if (importPolygons.length) {
            importPolygons.forEach((coords) => {
              const feature = new Feature<Polygon>(new Polygon(coords))
              this.doorsLayer.source.addFeature(feature)
              this.onUserDoorDrawn(feature, true)
            })

            this.generateConstraints()
            this.layerHistory.saveState()
          }
        },
        (error) => {
          // Disable loading
          this.loading.next(false)

          // TODO Handle error - snackbar
          console.log(error)
        }
      )

    // Save walls on user request, show progress dialog while saving, load saved walls
    this.sidebar.submit
      .pipe(
        filter(() => !this.progressDialog),
        tap(() => (this.progressDialog = this.showSavingInProgressDialog())),
        switchMap(() =>
          // Timer is used to not blink with the dialog, show it for at least half a second
          zip(
            this.saveGeneratedWalls(),
            this.saveUserWalls(),
            this.saveDoors(),
            timer(500),
            (gw, uw, d) => [gw, uw, d] as const
          ).pipe(finalize(() => this.closeProgressDialog()))
        ),
        tap(([gw, uw, d]) => {
          if (!gw || !uw || !d) {
            // TODO Handle error - snackbar
            console.log("Problem with saving walls")
          }
        }),
        filter((data) => data.every((v) => v)),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([gw, uw, d]) => {
        this.loadData(gw, uw, d)
        this.layerHistory.saveState()
      })

    // Load generated constraints onto generatedWallsLayer
    this.constraintsGenerator.generatedConstraints
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((gcs) => this.loadGeneratedConstraints(gcs))

    this.layerHistory.events
      .pipe(
        filter((e) => e === LayerHistoryEvent.LOADED),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        const doors = this.doorsLayer.source.getFeatures()
        const walls = this.userWallsLayer.source.getFeatures()

        this.shapeIdManager.clear()

        walls
          .concat(doors)
          .sort((a, b) => a.get(SHAPE_ID_PROPERTY) - b.get(SHAPE_ID_PROPERTY))
          .forEach((f) => this.shapeIdManager.featureAdded(f as WallFeature))

        this.generateConstraints()
      })
  }

  /**
   * On walls fetch error, return null observable if error was 404, error observable on other errors
   */
  private catchWallsFetchError<T>(message: string, error: HttpErrorResponse): Observable<T> {
    if (error.status === 404) {
      return of(null)
    } else {
      return throwError({
        message,
        error,
      })
    }
  }

  /**
   * On cs error, set noCS error flag and return empty observable.
   *
   * Also hide loading indicator, nothing to load after this error.
   */
  private catchCSError(err: any): Observable<FloorCSConfigResponse> {
    console.error("Error fetching floor coordinate system config", err)
    this.noCS = true

    this.loading.next(false)

    return empty()
  }

  /**
   * On image dimensions error, set noImgDim flag and return empty observable.
   */
  private catchIDError(err: any): Observable<ImageDimensions> {
    console.error("Error getting image dimensions", err)

    this.noImgDim = true

    this.loading.next(false)

    return empty()
  }

  private resetErrors(): void {
    this.noCS = false
    this.noImgDim = false
  }

  /**
   * Check if user has permissions to perform CRUD operations.
   */
  hasUserWritePermissions() {
    return this.authUtilService.checkUserRole(this.WRITE_USER_ROLE)
  }

  /**
   * Save button should be disabled if downloading floor coordinate system config failed
   * or user does not have permissions to perform CRUD operations.
   */
  saveDisabled = this.noCS || !this.hasUserWritePermissions()

  detectDoors(): void {
    if (this.progressDialog) {
      return
    }

    this.progressDialog = this.showDetectingDoorsInProgressDialog()

    this.imageProcessing
      .detectDoors(this.currentFloor.getFloor().id)
      .pipe(
        finalize(() => this.closeProgressDialog()),
        takeUntil(this.unsubscribe)
      )
      .subscribe((data) => {
        const imgDim = this.imgDimSubject.getValue()
        if (!isImageDimensions(imgDim)) {
          // Should not happen
          console.error("Unable to load detected doors without image dimensions!")
          return
        }
        const imgHeight = imgDim.height

        data.doors
          .map((door) => door.points)
          .map((points) => points.map((p) => [p.x, imgHeight - p.y]))
          .forEach((points) => {
            points[4] = points[0]
            const feature = new Feature<Polygon>({
              geometry: new Polygon([points]),
            })
            this.doorsLayer.source.addFeature(feature)
            this.onUserDoorDrawn(feature, true)
          })
        this.generateConstraints()
        this.layerHistory.saveState()
      })
  }

  removeAll(): void {
    this.userWallsLayer.clearFeatures()
    this.doorsLayer.clearFeatures()
    this.generatedWallsLayer.clearFeatures()
    this.generatedOuterWallLayer.clearFeatures()
    this.shapeIdManager.clear()
    this.layerHistory.saveState()
  }

  resetWallsToSavedState(): void {
    this.loadData(this.loadedGeneratedWalls, this.loadedUserWalls, this.loadedDoors)
    this.layerHistory.saveState()
  }

  onUserWallDrawn(feature: WallFeature): void {
    this.shapeIdManager.featureAdded(feature)
    feature.set(SHAPE_TYPE_PROPERTY, "wall")
    this.generateConstraints()

    this.userWallsLayer.source.once("change", () => {
      this.layerHistory.saveState()
    })
  }

  onUserDoorDrawn(feature: WallFeature, skipConstraintsGeneration = false): void {
    this.shapeIdManager.featureAdded(feature)
    feature.set(SHAPE_TYPE_PROPERTY, "door")
    if (!skipConstraintsGeneration) {
      this.generateConstraints()

      this.doorsLayer.source.once("change", () => {
        this.layerHistory.saveState()
      })
    }
  }

  override onFeatureRemoved(feature: WallFeature): void {
    this.shapeIdManager.featureRemoved(feature)
    this.generateConstraints()
    super.onFeatureRemoved(feature)
  }

  override onFeaturesMoved(features: WallFeature[]): void {
    this.onFeaturesMovedOrModified(features)
    super.onFeaturesMoved(features)
  }

  override onFeaturesModified(features: WallFeature[]): void {
    this.onFeaturesMovedOrModified(features)
    super.onFeaturesModified(features)
  }

  private onFeaturesMovedOrModified(features: WallFeature[]): void {
    features
      .sort((a, b) => a.get(SHAPE_ID_PROPERTY) - b.get(SHAPE_ID_PROPERTY))
      .forEach((f) => this.shapeIdManager.featureModified(f))
    this.generateConstraints()
  }

  private showSavingInProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "walls.saving_in_progress_dialog_text",
    }
    return this.dialog.open(ProgressDialog, { data })
  }

  private showDetectingDoorsInProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "constraints_generator.detect_doors.progress_text",
    }
    return this.dialog.open(ProgressDialog, { data })
  }

  private closeProgressDialog(): void {
    this.progressDialog.close()
    this.progressDialog = null
  }

  private fetchGeneratedWalls(floorId: number): Observable<Walls> {
    return this.walls.getWalls(floorId)
  }

  private fetchUserWalls(floorId: number): Observable<UserWalls> {
    return this.userWalls.getUserWalls(floorId)
  }

  private fetchUserAccessibleAreas(floorId: number): Observable<UserAccessibleAreas> {
    return this.userDoors.getUserAccessibleAreas(floorId)
  }

  private loadData(generatedWalls: Walls, userWalls: UserWalls, doors: UserAccessibleAreas): void {
    this.loadedGeneratedWalls = generatedWalls
    this.loadedUserWalls = userWalls
    this.loadedDoors = doors

    this.shapeIdManager.clear()

    let userWallsFeatures: WallFeature[] = []
    let userDoorsFeatures: WallFeature[] = []

    if (userWalls) {
      userWallsFeatures = this.userWallsLayer.loadFeatures(
        this.toObjectData(userWalls.userWalls)
      ) as WallFeature[]
      userWallsFeatures.forEach((f) => f.set(SHAPE_TYPE_PROPERTY, "wall"))
    }
    if (doors) {
      userDoorsFeatures = this.doorsLayer.loadFeatures(
        this.toObjectData(doors.userAccessibleAreas)
      ) as WallFeature[]
      userDoorsFeatures.forEach((f) => f.set(SHAPE_TYPE_PROPERTY, "door"))
    }

    userWallsFeatures
      .concat(userDoorsFeatures)
      .sort((a, b) => a.get(SHAPE_ID_PROPERTY) - b.get(SHAPE_ID_PROPERTY))
      .forEach((f) => this.shapeIdManager.featureAdded(f))

    this.generateConstraints()

    this.loading.next(false)
  }

  private loadGeneratedConstraints(generatedConstraints: GeneratedConstraint[]): void {
    const outerWallIdx = generatedConstraints.findIndex((w) => w.outerWall)
    const outerWall = generatedConstraints.splice(outerWallIdx, 1)
    this.generatedOuterWallLayer.loadFeatures(outerWall)
    this.generatedWallsLayer.loadFeatures(generatedConstraints)
  }

  private toObjectData(shapes: UserWallShape[] | UserAccessibleAreaShape[]): ObjectData[] {
    const props = (s: UserWallShape | UserAccessibleAreaShape): ObjectProperties => {
      const props: ObjectProperties = {}
      props[SHAPE_ID_PROPERTY] = s.id
      return props
    }

    const mapShape = (s: UserWallShape | UserAccessibleAreaShape): ObjectData => ({
      points: s.points.map((p) => this.coordinateConverter.toPixel(p)),
      properties: props(s),
    })

    const mapCircleShape = (s: UserWallShape | UserAccessibleAreaShape): ObjectData => ({
      center: this.coordinateConverter.toPixel(s.points[0]),
      radius: this.coordinateConverter.toPixel(s.points[1]).x,
      properties: props(s),
    })

    return shapes.map((s) => (s.shape === "CIRCLE" ? mapCircleShape(s) : mapShape(s)))
  }

  private getGeneratedDataFromMap(): WallShape[] {
    const outerWall = this.getDataFromMap(this.generatedOuterWallLayer)
    const shapes = this.getDataFromMap(this.generatedWallsLayer)

    const outerWallShape = outerWall[0]
    if (outerWallShape) {
      outerWallShape.id = 1
      shapes.splice(0, 0, outerWallShape)
    }

    return shapes
  }

  private getDataFromMap(layer: VectorLayerComponent): UserWallShape[] {
    const wallFeatures = layer.source.getFeatures()

    let shapes: UserWallShape[]
    if (layer === this.generatedWallsLayer) {
      let shapeIdx = 2
      shapes = wallFeatures.map(
        (f) => <UserWallShape>this.wallFeatureToShape(f as WallFeature, shapeIdx++)
      )
    } else {
      shapes = wallFeatures.map((f) => <UserWallShape>this.wallFeatureToShape(f as WallFeature))
    }

    return shapes
  }

  private wallFeatureToShape(wallFeature: WallFeature, shapeIdx?: number): UserWallShape {
    const circle = wallFeature.getGeometry() instanceof Circle

    let points: Point[]

    // TODO Change backend to acknowledge existence of circles, instead of storing radius as second point
    if (circle) {
      const g = <Circle>wallFeature.getGeometry()
      const c = g.getCenter()
      const r = g.getRadius()

      points = [
        { x: c[0], y: c[1] },
        { x: r, y: r },
      ].map((p) => this.coordinateConverter.toMetric(p))
    } else {
      const coords: Coordinate[] = (<Polygon>wallFeature.getGeometry()).getCoordinates()[0]
      points = coords.map((c) => this.coordinateConverter.toMetric({ x: c[0], y: c[1] }))
    }

    return {
      id: shapeIdx || wallFeature.get(SHAPE_ID_PROPERTY),
      shape: circle ? "CIRCLE" : "POLYGON",
      points: points,
    }
  }

  private clearMap(): void {
    this.userWallsLayer.clearFeatures()
    this.doorsLayer.clearFeatures()
    this.generatedWallsLayer.clearFeatures()
    this.generatedOuterWallLayer.clearFeatures()
    this.loadedGeneratedWalls = null
    this.loadedUserWalls = null
    this.loadedDoors = null
  }

  private saveGeneratedWalls(): Observable<Walls> {
    const generatedWalls = this.getGeneratedDataFromMap()

    const wallsToSave: WallsRequest = {
      walls: generatedWalls,
      imageBasedWalls: !this.sidebar.blackCanvasGeneratorValue,
    }

    return this.walls.saveWalls(this.currentFloor.getFloor().id, wallsToSave).pipe(
      catchError((e) => of(null)),
      takeUntil(this.unsubscribe)
    )
  }

  private saveUserWalls(): Observable<UserWalls> {
    const userWalls = this.getDataFromMap(this.userWallsLayer)
    return this.userWalls.saveUserWalls(this.currentFloor.getFloor().id, { userWalls })
  }

  private saveDoors(): Observable<UserAccessibleAreas> {
    const userAccessibleAreas = this.getDataFromMap(this.doorsLayer)
    return this.userDoors.saveUserAccessibleAreas(this.currentFloor.getFloor().id, {
      userAccessibleAreas,
    })
  }

  private generateConstraints(): void {
    const data = this.shapeIdManager.shapes.map((feature) => ({
      type: <"wall" | "door">feature.get(SHAPE_TYPE_PROPERTY),
      shape: <Polygon | Circle>feature.getGeometry(),
    }))
    this.constraintsGenerator.generateConstraints(data)
  }

  // Map legend colors - added for casting
  generatedWallsFillColor(): string {
    let color = this.generatedWallsFill.getColor()

    if (typeof color !== "string") {
      throw new Error("Unexpected color type")
    }

    return color as string
  }

  userWallsStrokeColor(): string {
    let color = this.userWallsStroke.getColor()

    if (typeof color !== "string") {
      throw new Error("Unexpected color type")
    }

    return color as string
  }

  accessibleAreasStrokeColor(): string {
    let color = this.doorsStroke.getColor()

    if (typeof color !== "string") {
      throw new Error("Unexpected color type")
    }

    return color as string
  }
}

// XXX Copy-paste from positioning-accuracy.component. Extract common map tools to separate file.
enum MapTool {
  ADD_WALL = "ADD_WALL",
  ADD_WALL_CIRCLE = "ADD_WALL_CIRCLE",
  ADD_DOOR = "ADD_DOOR",
  REMOVE = "REMOVE",
  MOVE = "MOVE",
  MODIFY = "MODIFY",
  REMOVE_ALL = "REMOVE_ALL",
}
