import { NgModule } from "@angular/core"
import { UIRouterModule } from "@uirouter/angular"
import { ImportModule } from "@venue/import"
import { SharedModule } from "../shared"
import * as m from "./"

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: m.VENUES_STATES }),
        SharedModule,
        ImportModule
    ],

    declarations: [
        m.VenuesComponent,
        m.VenueMenuComponent,
        m.VenueRootComponent,
        m.VenueFormComponent,
        m.VenueImportComponent
    ]
})
export class VenuesModule {}
