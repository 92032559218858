import { CoordinateSystem, WgsCoordinateSystem } from "@openapi/venue"
import { ImageDimensions, Location, Point } from "@venue/api"
import { CoordinateConverter } from "@venue/core"
import { Geodesic } from "geographiclib"
import { vec2 } from "gl-matrix"
import { pull } from "lodash"
import { ProjectionLike, transform } from "ol/proj"
import { CallbackRemover, ChangeCallback, CoordinateTransforms } from "./CoordinateTransforms"

/**
 * Transforms between global wgs coordinates and local
 * pixel coordinates of the floorplan.
 *
 * Uses WGS origin point (assumed to be [0,0] of the image in pixels)
 * and CoordinateConverter used to transform between wgs and pixel coordinates.
 *
 * WgsTransforms world coords = wgs coordinate system
 * WgsTransforms local coords = local pixel coordinate system
 */
export class WgsTransforms implements CoordinateTransforms {
  /** Wgs coords of image [0, 0] pixel position. */
  private wgsImgOrigin: Location

  /** Top image border azimuth. */
  private aziOR: number

  /** Local metric coords of image [0, 0] pixel position. */
  private metricImgOrigin: Point

  /** Local metric coords of image [imgWidth, 0] pixel position. */
  private metricImgRotate: Point

  /** Local metric Origin-Rotate vector. */
  private metricOR: vec2

  private changeCallbacks: ChangeCallback[] = []

  constructor(
    /** Conversion between local metric and pixel coordinate systems. */
    private converter: CoordinateConverter,
    /** Map projection. Required to convert coords to wgs. */
    private mapProjection: ProjectionLike,
    /** Image dimensions. */
    imgDim: ImageDimensions
  ) {
    this.metricImgOrigin = converter.toMetric({ x: 0, y: imgDim.height })

    this.metricImgRotate = converter.toMetric({ x: imgDim.width, y: imgDim.height })

    this.metricOR = vec2.fromValues(
      this.metricImgRotate.x - this.metricImgOrigin.x,
      this.metricImgRotate.y - this.metricImgOrigin.y
    )
  }

  initWgs(wgsCS: WgsCoordinateSystem, metricCS: CoordinateSystem): void
  initWgs(
    /** Wgs coords of image [0, 0] pixel position. */
    wgsImgOrigin: Location,
    /** Top image border azimuth. */
    aziOR: number
  ): void

  initWgs(
    wgsImgOriginOrWgsCS: Location | WgsCoordinateSystem,
    aziOROrMetricCS: number | CoordinateSystem
  ): void {
    if (typeof aziOROrMetricCS == "number") {
      this.wgsImgOrigin = wgsImgOriginOrWgsCS as Location
      this.aziOR = aziOROrMetricCS
    } else {
      const wgsCS = wgsImgOriginOrWgsCS as WgsCoordinateSystem
      const metricCS = aziOROrMetricCS as CoordinateSystem
      // B - blue, Y - yellow, P - pink
      // O - origin point (top-left of the image), R - rotate point (top-right of the image)

      // a) Calculate OR azimuth:
      //   1. BY azimuth
      const geoDataBY = Geodesic.WGS84.Inverse(
        wgsCS.blue.latitude,
        wgsCS.blue.longitude,
        wgsCS.yellow.latitude,
        wgsCS.yellow.longitude
      )

      const aziBY = geoDataBY.azi1

      //   2. Angle between OR and BY = α
      const OR = this.metricOR
      const BY = vec2.fromValues(
        metricCS.yellow.x - metricCS.blue.x,
        metricCS.yellow.y - metricCS.blue.y
      )

      const α = (vec2.angle(OR, BY) * 180) / Math.PI

      //   3. OR azimuth = aziBY - α
      const aziOR = aziBY - α

      // b) Calculate wgs origin
      //   1. |OB|
      const OB = vec2.fromValues(
        metricCS.blue.x - this.metricImgOrigin.x,
        metricCS.blue.y - this.metricImgOrigin.y
      )

      const distOB = vec2.length(OB)

      //   2. Angle between OR and OB = β
      const β = (vec2.angle(OR, OB) * 180) / Math.PI

      //   3. BO azimuth
      const aziOB = aziOR + β
      const aziBO = aziOB <= 180 ? aziOB + 180 : aziOB - 180

      //   4. wgs origin
      const geoDataBO = Geodesic.WGS84.Direct(
        wgsCS.blue.latitude,
        wgsCS.blue.longitude,
        aziBO,
        distOB
      )

      this.wgsImgOrigin = { longitude: geoDataBO.lon2, latitude: geoDataBO.lat2 }
      this.aziOR = aziOR
    }

    this.changeCallbacks.forEach((c) => c())
  }

  /**
   * Return current OR azimuth
   */
  getAziOR(): number {
    return this.aziOR
  }

  toLocal(coords: number[], out?: number[]): number[] {
    const wgsCoords = transform(coords, this.mapProjection, "EPSG:4326")

    const [lon, lat] = wgsCoords

    // O - origin point of image, X - our target point
    const geoDataOX = Geodesic.WGS84.Inverse(
      this.wgsImgOrigin.latitude,
      this.wgsImgOrigin.longitude,
      lat,
      lon
    )

    const aziOX = geoDataOX.azi1
    const distOX = geoDataOX.s12

    // Angle between OR and OX = α
    const α = ((aziOX - this.aziOR) * Math.PI) / 180

    // Rotate metric R by alpha
    const rotR = vec2.rotate(
      vec2.create(),
      vec2.fromValues(this.metricImgRotate.x, this.metricImgRotate.y),
      vec2.fromValues(this.metricImgOrigin.x, this.metricImgOrigin.y),
      α
    )

    const metricO = vec2.fromValues(this.metricImgOrigin.x, this.metricImgOrigin.y)

    // Create O - rotR vector, which after rescaling will become metricOX vector
    let metricOX = vec2.fromValues(rotR[0] - metricO[0], rotR[1] - metricO[1])

    // Rescale to get final metricOX vector
    metricOX = vec2.scale(vec2.create(), metricOX, distOX / vec2.length(metricOX))

    // Get metric X
    let metricX = vec2.add(vec2.create(), metricO, metricOX)

    // Transform to pixel cs
    const pix = this.converter.toPixel({ x: metricX[0], y: metricX[1] })

    out = out ?? []
    out[0] = pix.x
    out[1] = pix.y

    return out
  }

  toWorld(coords: number[], out?: number[]): number[] {
    // Transform to metric cs
    const metricX = this.converter.toMetric({ x: coords[0], y: coords[1] })

    const metricOX = vec2.fromValues(
      metricX.x - this.metricImgOrigin.x,
      metricX.y - this.metricImgOrigin.y
    )

    // Angle between OR and OX = α
    const α = vec2.angle(this.metricOR, metricOX)

    const aziOX = this.aziOR + (α * 180) / Math.PI
    const distOX = vec2.length(metricOX)

    const geoDataOX = Geodesic.WGS84.Direct(
      this.wgsImgOrigin.latitude,
      this.wgsImgOrigin.longitude,
      aziOX,
      distOX
    )

    const ret = transform([geoDataOX.lon2, geoDataOX.lat2], "EPSG:4326", this.mapProjection)

    out = out ?? []
    out[0] = ret[0]
    out[1] = ret[1]

    return out
  }

  onChange(callback: ChangeCallback): CallbackRemover {
    this.changeCallbacks.push(callback)

    return () => {
      pull(this.changeCallbacks, callback)
    }
  }
}
