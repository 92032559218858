import { Component, Host, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core"
import DeleteFeatureInteraction from "@venue/components/map/interactions/DeleteFeatureInteraction"
import HighlightFeatureInteraction from "@venue/components/map/interactions/HighlightFeatureInteraction"
import { Feature } from "ol"
import { Subject } from "rxjs"
import { OpenlayersMapComponent, VectorLayerComponent } from "."

@Component({
  selector: "remove-interaction",
  template: "",
})
export class RemoveInteractionComponent implements OnChanges, OnDestroy {
  @Input() layers: VectorLayerComponent[] = []
  @Input() disabled?: boolean

  @Output() featureRemoved = new Subject<Feature<any>>()

  private removeInteraction: DeleteFeatureInteraction
  private highlightInteraction: HighlightFeatureInteraction

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.dispose()
    this.init()
  }

  ngOnDestroy(): void {
    this.dispose()
  }

  init(): void {
    if (this.disabled || !this.layers || !this.layers.length) {
      return
    }

    const interactionOpts = {
      layers: this.layers.map((l) => l.layer),
    }

    this.removeInteraction = new DeleteFeatureInteraction(interactionOpts)
    this.highlightInteraction = new HighlightFeatureInteraction(interactionOpts)

    this.mapComponent.map.addInteraction(this.removeInteraction)
    this.mapComponent.map.addInteraction(this.highlightInteraction)

    // @ts-ignore
    this.removeInteraction.on("removefeature", (e) => this.featureRemoved.next(e.feature))
  }

  dispose(): void {
    if (this.removeInteraction) {
      this.mapComponent.map.removeInteraction(this.removeInteraction)
      this.removeInteraction = null
    }

    if (this.highlightInteraction) {
      this.mapComponent.map.removeInteraction(this.highlightInteraction)
      this.highlightInteraction = null
    }
  }
}
