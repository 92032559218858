<mat-toolbar color="primary"
             fxLayout="row"
             fxLayoutAlign="start center">
    <button mat-icon-button
            [hidden]="!navigation || (mainSidenavService.isForcedOpen | async)"
            (click)="openSettings()"
            matTooltip="{{'Settings' | translate}}"
            class="settings-button">
        <ic-icon [icon]="menuIcon"> </ic-icon>
    </button>
    <div fxLayout="row"
         fxFlex
         class="fill-height">
        <div fxLayout="row"
             fxLayoutAlign="start center">
            <ng-container *ngIf="!shouldHideName">
                <span *ngIf="navigation"
                      uiSref="venues"
                      class="ipp-link">{{ 'VenueMate' | translate }}</span>
                <span *ngIf="!navigation"
                      class="ipp-link">{{ 'VenueMate' | translate }}</span>
            </ng-container>
            <span *ngIf="(venue | async); let venue"
                  hide-sm
                  hide-xs
                  fxLayout="row"
                  fxLayoutAlign="start center">
                <span class="arrow">
                    <ic-icon [icon]="headerArrowIcon"> </ic-icon>
                </span>
                <span class="ipp-link"
                      (click)="state.go('venue-menu', { venueId: state.params.venueId })">{{venue.name}}</span>
            </span>
            <span *ngFor="let substate of substates; last as isLast"
                  hide-xs
                  fxLayout="row"
                  fxLayoutAlign="start center">
                <span class="arrow">
                    <ic-icon [icon]="headerArrowIcon"> </ic-icon>
                </span>
                <ng-container *ngIf="substate == 'CURRENT_FLOOR'; else stateBlock">
                    <span *letVar="floor | async as f">
                        {{ f?.name }} [L: {{ f?.level }}]
                    </span>
                </ng-container>
                <ng-template #stateBlock>
                    <span (click)="!isLast ? substateClick(substate) : null"
                          [ngClass]="{ 'ipp-link': !isLast }">
                        {{ 'state_name.' + substate | translate:state.params }}
                    </span>
                </ng-template>
            </span>
        </div>

        <span fxFlex></span>
    </div>

    <ng-container *ngIf="authUtils.enabled()">
        <ng-container *ngIf="authUtils.authenticated(); then authBlock else noAuthBlock"></ng-container>
    </ng-container>

    <ng-template #noAuthBlock>
        <div class="without-auth"
             fxLayout="row"
             fxLayoutAlign="start center">
            <button class="login"
                    mat-raised-button
                    (click)="authUtils.login()">
                <span class="no-container"
                      translate>header.login</span>
            </button>
        </div>
    </ng-template>

    <ng-template #authBlock>
        <div class="with-auth"
             fxLayout="row"
             fxLayoutAlign="start center">
            <div class="hello">
                <span translate
                      [translateParams]="{ name: authUtils.whoami() }">
                    header.hello
                </span>
            </div>
            <button class="user-menu"
                    mat-icon-button
                    [matMenuTriggerFor]="userMenu">
                <ic-icon class="center"
                         [icon]="userMenuIcon"> </ic-icon>
            </button>

            <mat-menu #userMenu="matMenu">
                <button class="auth-user-profile"
                        mat-menu-item
                        (click)="openAuthUserProfile()">
                    <span class="no-container"
                          translate>
                        header.auth-user-profile
                    </span>
                </button>
                <button class="logout"
                        mat-menu-item
                        (click)="authUtils.logout()">
                    <span class="no-container"
                          translate>
                        header.logout
                    </span>
                </button>
            </mat-menu>
        </div>
    </ng-template>

</mat-toolbar>
