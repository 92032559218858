import { Component, Host } from "@angular/core"
import { MatSidenav } from "@angular/material/sidenav"
import mdiDomain from "@iconify/icons-mdi/domain"
import mdiSettings from "@iconify/icons-mdi/settings"
import mdiSpeedometer from "@iconify/icons-mdi/speedometer"

@Component({
  selector: "main-sidenav",
  templateUrl: "./main-sidenav.component.html",
})
export class MainSidenavComponent {
  readonly buildingsIcon = mdiDomain
  readonly mipsConfigIcon = mdiSettings
  readonly tuningIcon = mdiSpeedometer

  constructor(@Host() private sidenav: MatSidenav) {}

  close(): void {
    this.sidenav.close()
  }
}
