import { DomPortalOutlet, TemplatePortal } from "@angular/cdk/portal"
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Host,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core"
import { withUnsubscribe } from "@venue/shared"
import * as _ from "lodash"
import { Overlay } from "ol"
import { Coordinate } from "ol/coordinate"
import OverlayPositioning from "ol/OverlayPositioning"
import { Subject } from "rxjs"
import { distinctUntilChanged, filter, map } from "rxjs/operators"
import { OpenlayersMapComponent } from "."

@withUnsubscribe
@Component({
  selector: "map-overlay",
  template: "<ng-template><div class='map-overlay'><ng-content></ng-content></div></ng-template>",
})
export class MapOverlayComponent implements OnInit, OnDestroy {
  @Input() position: Coordinate
  @Input() ignorePositionChanges?: boolean

  @Input() offsetX?: number
  @Input() offsetY?: number

  @ViewChild(TemplateRef, { static: true }) contentTemplate: TemplateRef<any>

  private overlay: Overlay
  private embeddedViewRef: EmbeddedViewRef<any>

  private positionSubject = new Subject<Coordinate>()
  private unsubscribe: Subject<void>

  constructor(
    @Host() private mapComponent: OpenlayersMapComponent,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private ar: ApplicationRef,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    const templatePortal = new TemplatePortal(this.contentTemplate, this.viewContainerRef)
    const element = document.createElement("div")
    this.embeddedViewRef = new DomPortalOutlet(
      element,
      this.cfr,
      this.ar,
      this.injector
    ).attachTemplatePortal(templatePortal)

    this.overlay = new Overlay({
      positioning: OverlayPositioning.CENTER_LEFT,
      element,
      insertFirst: false,
      autoPan: true,
      autoPanAnimation: { duration: 250 },
    })

    this.mapComponent.map.addOverlay(this.overlay)

    this.overlay.setPosition(this.position)

    this.positionSubject
      .pipe(
        filter(() => !this.ignorePositionChanges),
        distinctUntilChanged((a, b) => _.isEqual(a, b)),
        map((pos) => [pos[0] + (this.offsetX || 0), pos[1] + (this.offsetY || 0)])
      )
      .subscribe((pos) => this.overlay.setPosition(pos))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.overlay) {
      this.positionSubject.next(this.position)
    }
  }

  ngOnDestroy(): void {
    this.mapComponent.map.removeOverlay(this.overlay)
    this.embeddedViewRef.destroy()
  }
}
