import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import mdiHelpCircle from "@iconify/icons-mdi/help-circle"

export class ConfirmDialogData {
  title: string
  content: string
}

@Component({
  selector: "confirm-dialog",
  templateUrl: "./confirm.dialog.html",
})
export class ConfirmDialog {
  readonly icon = mdiHelpCircle

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private dialogRef: MatDialogRef<ConfirmDialog>
  ) {
    dialogRef.addPanelClass("dialog-with-toolbar")
  }

  onOk(): void {
    this.dialogRef.close(true)
  }

  onNoClick(): void {
    this.dialogRef.close(false)
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }
}
