import { AfterViewInit, Component, forwardRef, Input, OnInit } from "@angular/core"
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from "@angular/forms"
import { withUnsubscribe } from "@venue/shared"
import { internalMacFormat, standarizedMac } from "@venue/shared/utils/standarize-mac"
import * as _ from "lodash"
import { Subject } from "rxjs"
import { filter, map, takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "mac-input",
  templateUrl: "./mac-input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MacInputComponent),
      multi: true
    }
  ]
})
export class MacInputComponent implements AfterViewInit, ControlValueAccessor {
  @Input() label: string
  @Input() required = false

  mac = this.fb.control("")

  private touched = new Subject<void>()
  private unsubscribe: Subject<void>
  private initialized = false

  constructor(private fb: FormBuilder) {}

  ngAfterViewInit(): void {
    this.initialized = true
    // Set value again, so input field displays correct value
    this.mac.setValue(this.mac.value)
  }

  getValue(): string {
    let mac = _.clone(this.mac.value)
    if (mac) {
      mac = internalMacFormat(mac)
    }
    return mac
  }

  writeValue(value: string): void {
    value = value || ""
    value = standarizedMac(value)
    this.mac.setValue(value)
  }

  registerOnChange(fn: any): void {
    this.mac.valueChanges
      .pipe(
        filter((mac: string) => !!mac),
        map(mac => _.clone(mac)),
        map(mac => internalMacFormat(mac)),
        filter(() => this.initialized),
        takeUntil(this.unsubscribe)
      )
      .subscribe(fn)
  }

  registerOnTouched(fn: any): void {
    this.touched.pipe(takeUntil(this.unsubscribe)).subscribe(fn)
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      throw new Error("Method not implemented.")
    }
  }
}
