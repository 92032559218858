import { Observable, Subject } from "rxjs"
import { first } from "rxjs/operators"

type ImageLoadedCallback = (img: HTMLImageElement) => void

/**
 * Gets image from url and then perform aciton on it.
 */
export function getImageAndRun(imgUrl: string, imgConsumer: ImageLoadedCallback): void {
  const img = new Image()
  img.addEventListener("load", function () {
    imgConsumer(this)
  })
  img.src = imgUrl
}

export function loadImage(imgOrUrl: string | Blob): Observable<HTMLImageElement> {
  let imgUrl: string

  if (imgOrUrl instanceof Blob) {
    imgUrl = URL.createObjectURL(imgOrUrl)
  } else {
    imgUrl = imgOrUrl
  }

  const s = new Subject<HTMLImageElement>()
  getImageAndRun(imgUrl, (img) => {
    s.next(img)
    URL.revokeObjectURL(imgUrl)
  })

  return s.pipe(first())
}
