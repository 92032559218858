import AbstractMouseInteraction from "@venue/components/map/interactions/AbstractMouseInteraction"
import { glMatrix, vec2, vec3 } from "gl-matrix"
import * as _ from "lodash"
import { Feature, Map, MapBrowserEvent } from "ol"
import { Control } from "ol/control"
import { Coordinate } from "ol/coordinate"
import { getCenter } from "ol/extent"
import { Point, Polygon } from "ol/geom"
import { fromExtent } from "ol/geom/Polygon"
import { Interaction } from "ol/interaction"
import { Vector as VectorLayer } from "ol/layer"
import { Vector as VectorSource } from "ol/source"
import { Circle, Fill, Icon, Stroke, Style } from "ol/style"
import { ImageTransforms } from "./ImageTransforms"
import { absVec3, angle } from "./VectorUtils"

function getVector(a: number[], b: number[]): vec3 {
  return vec3.fromValues(b[0] - a[0], b[1] - a[1], 0)
}

export type ChangeCallback = () => void

class Scale extends AbstractMouseInteraction {
  private localAnchor: number[]
  private scalingPoint: number[]
  private lastVec: vec3

  constructor(
    private transforms: ImageTransforms,
    private anchor: Point,
    private features: Feature<Point>[],
    private callback: ChangeCallback = () => {}
  ) {
    super()
  }

  down(e: MapBrowserEvent<any>): boolean {
    const featuresAtPixel = (e.map.getFeaturesAtPixel(e.pixel) || []) as Feature<Point>[]
    const feature = featuresAtPixel.find((f) => this.features.includes(f))
    if (feature) {
      this.localAnchor = this.transforms.toLocal(this.anchor.getCoordinates())
      const geometry = feature.getGeometry()
      this.scalingPoint = this.transforms.toLocal(geometry.getCoordinates())
      this.lastVec = getVector(this.localAnchor, this.scalingPoint)
      return true
    }
  }

  drag(e: MapBrowserEvent<any>): boolean {
    const ov = this.lastVec
    const nv = getVector(
      this.localAnchor,
      this.transforms.toLocal(e.map.getCoordinateFromPixel(e.pixel))
    )

    const scaleVector = this.validateVector(absVec3(vec3.divide(vec3.create(), nv, ov)))

    this.transforms.scale(scaleVector, <vec2>(<any>this.localAnchor), true)

    this.lastVec = nv
    return false
  }

  up(e: MapBrowserEvent<any>) {
    this.callback()
    return super.up(e)
  }

  /**
   * Blocks scaling on the element equal to the anchor
   * @param {vec3} vec - vector to validate
   */
  // XXX This function has a problem in some cases - most likely after subsequent resizes.
  private validateVector(vec: vec3): vec3 {
    const a = this.localAnchor
    const p = this.scalingPoint
    const out = vec3.clone(vec)
    if (Math.abs(a[0] - p[0]) < 10) {
      // console.log("block x");
      out[0] = 1
    }
    if (Math.abs(a[1] - p[1]) < 10) {
      // console.log("block y");
      out[1] = 1
    }
    // console.log("non block, a = (%d, %d), sp = (%d, %d)", a[0], a[1], p[0], p[1]);
    return out
  }
}

class Rotate extends AbstractMouseInteraction {
  private lastVec: vec3

  constructor(
    private transforms: ImageTransforms,
    private anchor: Point,
    private rotationFeature: Feature<Point>,
    private callback: ChangeCallback = () => {}
  ) {
    super()
  }

  down(e: MapBrowserEvent<any>): boolean {
    const featuresAtPixel = (e.map.getFeaturesAtPixel(e.pixel) || []) as Feature<Point>[]
    const feature = featuresAtPixel.find((f) => f === this.rotationFeature)
    if (feature) {
      this.lastVec = getVector(this.anchor.getCoordinates(), e.map.getCoordinateFromPixel(e.pixel))
      return true
    }
  }

  drag(e: MapBrowserEvent<any>): boolean {
    const ov = this.lastVec
    const nv = getVector(this.anchor.getCoordinates(), e.map.getCoordinateFromPixel(e.pixel))
    const a = angle(ov, nv)

    this.transforms.rotate(a, <vec2>(<any>this.anchor.getCoordinates()))
    this.lastVec = nv
    return false
  }

  up(e: MapBrowserEvent<any>) {
    this.callback()
    return super.up(e)
  }
}

class MoveImage extends AbstractMouseInteraction {
  private disabled: boolean
  private lastCoords: number[]

  constructor(
    private transforms: ImageTransforms,
    private imageExtent: number[],
    private callback: ChangeCallback = () => {}
  ) {
    super()
  }

  disable(value: boolean): void {
    this.disabled = value
  }

  down(e: MapBrowserEvent<any>): boolean {
    if (this.disabled) return false

    const coords = e.map.getCoordinateFromPixel(e.pixel)
    const lc = this.transforms.toLocal(coords)
    const ext = this.imageExtent
    if (lc[0] >= ext[0] && lc[0] <= ext[2] && lc[1] >= ext[1] && lc[1] <= ext[3]) {
      this.lastCoords = coords
      return true
    }
  }

  drag(e: MapBrowserEvent<any>): boolean {
    const oc = this.lastCoords
    const nc = e.map.getCoordinateFromPixel(e.pixel)
    const translation = [nc[0] - oc[0], nc[1] - oc[1]]
    this.transforms.translate(<vec2>(<any>translation))
    this.lastCoords = nc
    return false
  }

  up(e: MapBrowserEvent<any>) {
    this.callback()
    return super.up(e)
  }
}

class MoveAnchor extends AbstractMouseInteraction {
  private geometry: Point
  private locked = false
  private disabled = false
  private imageCenter: Coordinate
  private lastCoords: Coordinate
  private clickPixel: Coordinate

  constructor(
    private transforms: ImageTransforms,
    private imgExtent: number[],
    private feature: Feature<Point>,
    private onClick: (locked: boolean) => void
  ) {
    super()
    this.geometry = feature.getGeometry()
  }

  disable(value: boolean): void {
    this.disabled = value
  }

  down(e: MapBrowserEvent<any>): boolean {
    const featuresAtPixel = (e.map.getFeaturesAtPixel(e.pixel) || []) as Feature<Point>[]
    const feature = featuresAtPixel.find((f) => f === this.feature)
    if (feature) {
      this.imageCenter = getCenter(this.imgExtent)
      this.lastCoords = this.geometry.getCoordinates()
      this.clickPixel = e.pixel
      return true
    }
  }

  drag(e: MapBrowserEvent<any>): boolean {
    if (this.disabled) return false

    const ex = this.imgExtent
    const oc = this.lastCoords
    let nc = this.transforms.toLocal(e.map.getCoordinateFromPixel(e.pixel))

    if (nc[0] < ex[0]) {
      nc[0] = ex[0]
    } else if (nc[0] > ex[2]) {
      nc[0] = ex[2]
    }

    if (nc[1] < ex[1]) {
      nc[1] = ex[1]
    } else if (nc[1] > ex[3]) {
      nc[1] = ex[3]
    }

    nc = this.transforms.toWorld(nc)

    const vec = getVector(oc, nc)

    this.geometry.translate(vec[0], vec[1])

    this.lastCoords = <Coordinate>nc
    return false
  }

  up(e: MapBrowserEvent<any>): boolean {
    if (_.isEqual(e.pixel, this.clickPixel) && this.onClick) {
      this.locked = !this.locked
      this.onClick(this.locked)
    }
    return false
  }
}

const strokeColor = "#3399CC"

function getDefaultFeatureStyle(): Style {
  const fill = new Fill({
    color: "rgba(255,255,255,0.4)",
  })
  const stroke = new Stroke({
    color: strokeColor,
    width: 1.25,
  })
  return new Style({
    image: new Circle({
      fill: fill,
      stroke: stroke,
      radius: 5,
    }),
    fill: fill,
    stroke: stroke,
  })
}

function addBorderLayer(
  map: Map,
  imgExtent: number[],
  transforms: ImageTransforms
): VectorLayer<VectorSource<Polygon>> {
  const borderGeometry = fromExtent(imgExtent)
  const borderFeature = new Feature({
    geometry: borderGeometry,
  })

  const style = getDefaultFeatureStyle()
  style.setFill(null)

  const vectorLayer = new VectorLayer({
    style: style,
    source: new VectorSource<Polygon>({
      features: [borderFeature],
    }),
  })

  borderGeometry.applyTransform((coords: number[], out: number[]) =>
    transforms.toWorld(coords, out)
  )
  transforms.onChange((transformFun) => borderGeometry.applyTransform(transformFun))

  map.addLayer(vectorLayer)
  return vectorLayer
}

function addAnchorLayer(
  map: Map,
  imgExtent: number[],
  transforms: ImageTransforms
): VectorLayer<VectorSource<Point>> {
  const anchorGeometry = new Point([
    imgExtent[0] + (imgExtent[2] - imgExtent[0]) / 2,
    imgExtent[1] + (imgExtent[3] - imgExtent[1]) / 2,
  ])
  const anchorFeature = new Feature(anchorGeometry)

  const iconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      src: "assets/images/anchor.png",
    }),
  })

  const lockedIconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      src: "assets/images/anchor_locked.png",
    }),
  })
  anchorFeature.setStyle(iconStyle)

  // TODO Use to ol property, maybe move to MoveAnchor class
  ;(<any>anchorFeature).setLock = (lock: boolean) => {
    if (lock) {
      anchorFeature.setStyle(lockedIconStyle)
    } else {
      anchorFeature.setStyle(iconStyle)
    }
  }

  const vectorSource = new VectorSource<Point>({
    features: [anchorFeature],
  })

  const vectorLayer = new VectorLayer({
    source: vectorSource,
  })

  anchorGeometry.applyTransform((coords, out) => transforms.toWorld(coords, out))
  transforms.onChange((transformFun) => anchorGeometry.applyTransform(transformFun))

  map.addLayer(vectorLayer)
  return vectorLayer
}

function addRotateLayer(
  map: Map,
  imgExtent: number[],
  transforms: ImageTransforms
): VectorLayer<VectorSource<Point>> {
  const iconOffset = 35 // same as icon size

  const e = imgExtent

  // prettier-ignore
  let border = [
    e[0], e[3],
    e[2], e[3],
    e[2], e[1],
    e[0], e[1],
  ]

  border = transforms.toWorld(border)

  const pointsCoords = (b: number[]) => {
    const A = vec2.fromValues(b[0], b[1])
    const B = vec2.fromValues(b[2], b[3])
    const C = vec2.fromValues(b[6], b[7])

    // half of one AB
    const AS = vec2.scale(vec2.create(), vec2.sub(vec2.create(), B, A), 0.5)

    const CA = vec2.sub(vec2.create(), A, C)

    // vec in CA's direction of icon offset length
    const CZ = vec2.scale(vec2.create(), CA, iconOffset / vec2.length(CA))

    // O = icon position
    const AO = vec2.add(vec2.create(), AS, CZ)

    return vec2.add(vec2.create(), A, AO) as any as number[]
  }

  const iconGeometry = new Point(pointsCoords(border))
  const iconFeature = new Feature({
    geometry: iconGeometry,
  })

  const iconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      src: "assets/images/material-design-icons/ic_rotate_black_circle.png",
    }),
  })

  iconFeature.setStyle(iconStyle)

  const vectorSource = new VectorSource<Point>({
    features: [iconFeature],
  })

  const vectorLayer = new VectorLayer({
    source: vectorSource,
  })

  transforms.onChange((transformFun) => {
    border = transformFun(border)
    iconGeometry.setCoordinates(pointsCoords(border))
  })

  map.addLayer(vectorLayer)
  return vectorLayer
}

function addScaleLayer(
  map: Map,
  imgExtent: number[],
  transforms: ImageTransforms,
  colorOrigin: boolean
): VectorLayer<VectorSource<Point>> {
  const scaleGeometry: Point[] = []
  scaleGeometry.push(new Point([imgExtent[0], imgExtent[1]]))
  scaleGeometry.push(new Point([imgExtent[2], imgExtent[1]]))
  scaleGeometry.push(new Point([imgExtent[0], imgExtent[3]]))
  scaleGeometry.push(new Point([imgExtent[2], imgExtent[3]]))

  const scaleFeatures = scaleGeometry.map((g) => new Feature(g))
  const vectorSource = new VectorSource<Point>({
    features: scaleFeatures,
  })

  const featureRadius = 10
  const style = getDefaultFeatureStyle()
  const styleImg = style.getImage() as Circle
  styleImg.setRadius(featureRadius)

  if (colorOrigin) {
    const originStyle = getDefaultFeatureStyle()
    originStyle.getFill().setColor("rgba(255,0,0,0.6)")

    const originStyleImg = originStyle.getImage() as Circle
    originStyleImg.setRadius(featureRadius)
    scaleFeatures[0].setStyle(originStyle)
  }

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style,
  })

  scaleGeometry.forEach((g) => g.applyTransform((coords, out) => transforms.toWorld(coords, out)))
  transforms.onChange((transformFun) =>
    scaleGeometry.forEach((g) => g.applyTransform(transformFun))
  )

  map.addLayer(vectorLayer)
  return vectorLayer
}

// TODO Type for control argument
function setTransformFunctions(
  anchorGeometry: Point,
  transforms: ImageTransforms,
  control: any
): void {
  const anchor = (): Coordinate => anchorGeometry.getCoordinates()
  const localAnchor = (): Coordinate => <Coordinate>transforms.toLocal(anchor())

  control.transformFunctions = {
    rotateLeft: () => rotateLeft(anchor(), transforms),
    rotateRight: () => rotateRight(anchor(), transforms),
    scaleWidthUp: () => scaleXUp(localAnchor(), transforms),
    scaleWidthDown: () => scaleXDown(localAnchor(), transforms),
    scaleHeightUp: () => scaleYUp(localAnchor(), transforms),
    scaleHeightDown: () => scaleYDown(localAnchor(), transforms),
  }
}

function scaleXUp(localAnchor: Coordinate, transforms: ImageTransforms): void {
  transforms.scale(<vec3>(<any>[1.01, 1, 0]), <vec2>(<any>localAnchor))
}

function scaleXDown(localAnchor: Coordinate, transforms: ImageTransforms): void {
  transforms.scale(<vec3>(<any>[0.99, 1, 0]), <vec2>(<any>localAnchor))
}

function scaleYUp(localAnchor: Coordinate, transforms: ImageTransforms): void {
  transforms.scale(<vec3>(<any>[1, 1.01, 0]), <vec2>(<any>localAnchor))
}

function scaleYDown(localAnchor: Coordinate, transforms: ImageTransforms): void {
  transforms.scale(<vec3>(<any>[1, 0.99, 0]), <vec2>(<any>localAnchor))
}

function rotateLeft(anchor: Coordinate, transforms: ImageTransforms): void {
  transforms.rotate(glMatrix.toRadian(1), <vec2>(<any>anchor))
}

function rotateRight(anchor: Coordinate, transforms: ImageTransforms): void {
  transforms.rotate(glMatrix.toRadian(-1), <vec2>(<any>anchor))
}

function keyboardInteractions(
  anchorGeometry: Point,
  event: KeyboardEvent,
  transforms: ImageTransforms
): void {
  const keyCode = event.keyCode
  const anchor = anchorGeometry.getCoordinates()
  const localAnchor = <Coordinate>transforms.toLocal(anchor)

  switch (keyCode) {
    // Up arrow
    case 38: {
      scaleYUp(localAnchor, transforms)
      event.preventDefault()
      break
    }
    // Down arrow
    case 40: {
      scaleYDown(localAnchor, transforms)
      event.preventDefault()
      break
    }
    // Left arrow
    case 37: {
      if (event.ctrlKey) {
        rotateLeft(anchor, transforms)
      } else {
        scaleXDown(localAnchor, transforms)
      }
      event.preventDefault()
      break
    }
    // Right Arrow
    case 39: {
      if (event.ctrlKey) {
        rotateRight(anchor, transforms)
      } else {
        scaleXUp(localAnchor, transforms)
      }
      event.preventDefault()
      break
    }
    default: {
      break
    }
  }
}

// TODO Specify control type
export default class TransformInteractions {
  private layers: VectorLayer<VectorSource<Point | Polygon>>[] = []
  private interactions: Interaction[] = []
  private controls: Control[] = []
  private _keyboardListener: (e: any) => void

  private callbacks = new Set<ChangeCallback>()

  constructor(
    private map: Map,
    private extent: number[],
    private transforms: ImageTransforms,
    private transformationsControl: any = null,
    private colorOrigin: boolean = false,
    private borderColor?: string
  ) {
    if (!borderColor) {
      this.borderColor = "#3399CC"
    }
  }

  addInteractions(): void {
    const [m, e, t] = [this.map, this.extent, this.transforms]
    const borderLayer = addBorderLayer(m, e, t)
    const rotateLayer = addRotateLayer(m, e, t)
    const scaleLayer = addScaleLayer(m, e, t, this.colorOrigin)
    const anchorLayer = addAnchorLayer(m, e, t)

    this.layers.push(borderLayer, rotateLayer, scaleLayer, anchorLayer)

    const rotateFeature = rotateLayer.getSource().getFeatures()[0]
    const scaleFeatures = scaleLayer.getSource().getFeatures()
    const anchorFeature = anchorLayer.getSource().getFeatures()[0]
    const anchorGeometry = <Point>anchorFeature.getGeometry()

    const onAnchorClick = (lock: boolean): void => {
      // TODO Use ol property?
      ;(<any>anchorFeature).setLock(lock)
      moveImage.disable(lock)
      moveAnchor.disable(lock)
    }

    const moveImage = new MoveImage(t, e, () => this.onChange())
    const rotate = new Rotate(t, anchorGeometry, rotateFeature, () => this.onChange())
    const scale = new Scale(t, anchorGeometry, scaleFeatures, () => this.onChange())
    const moveAnchor = new MoveAnchor(t, e, anchorFeature, onAnchorClick)

    this.interactions.push(moveImage, rotate, scale, moveAnchor)

    this.interactions.forEach((i) => m.addInteraction(i))

    if (this.transformationsControl) {
      setTransformFunctions(anchorGeometry, this.transforms, this.transformationsControl)
      this.controls.push(this.transformationsControl)
      this.map.addControl(this.transformationsControl)
    }

    this._keyboardListener = (e) => {
      keyboardInteractions(anchorGeometry, e, this.transforms)
    }
    document.addEventListener("keydown", this._keyboardListener, false)
  }

  removeInteractions(): void {
    this.layers.forEach((l) => this.map.removeLayer(l))
    this.interactions.forEach((i) => this.map.removeInteraction(i))
    this.controls.forEach((c) => this.map.removeControl(c))
    document.removeEventListener("keydown", this._keyboardListener)
  }

  addCallback(callback: ChangeCallback): void {
    this.callbacks.add(callback)
  }

  removeCallback(callback: ChangeCallback): void {
    this.callbacks.delete(callback)
  }

  private onChange() {
    this.callbacks.forEach((c) => c())
  }
}
