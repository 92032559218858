import { EDITOR_STATE_NAME } from "@venue/editor/editor.state-names"
import { AccessPointsComponent } from "./access-points.component"
import { ACCESS_POINTS_EDITOR_STATE_NAME } from "./access-points.state-names"

export const ACCESS_POINTS_EDITOR_STATE = {
  parent: EDITOR_STATE_NAME,
  name: ACCESS_POINTS_EDITOR_STATE_NAME,
  url: "/:floorId/aps",
  component: AccessPointsComponent,
}
