<mat-toolbar color="primary">
    <h3 class="title">
        <ic-icon [icon]="icon"> </ic-icon>
        {{ (title || 'please_wait') | translate }}
    </h3>
</mat-toolbar>
<div class="content-wrapper progress-dialog-wrapper">
    <mat-dialog-content fxLayout="column"
                        fxFlex>
        <div fxLayout="column"
             fxLayoutAlign="start center">
            <p [innerHTML]="progressTextTranslationKey | translate"></p>
            <div class="spinner-wrapper">
                <mat-spinner diameter="50"> </mat-spinner>
            </div>
        </div>
    </mat-dialog-content>
</div>
