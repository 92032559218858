import { Feature, Map, MapBrowserEvent } from "ol"
import { Coordinate } from "ol/coordinate"
import { Layer } from "ol/layer"
import AbstractMouseInteraction from "./AbstractMouseInteraction"

export interface HighlightFeatureOptions {
  layers: Layer<any>[]
}

type HoveredTarget = {
  feature?: Feature<any>
  layer?: Layer<any>
}

export default class HighlightFeatureInteraction extends AbstractMouseInteraction {
  private hightlightLayers: Layer<any>[]
  private hovered: HoveredTarget = {}

  private hoverFeatureListener = (e: MapBrowserEvent<any>): void => this.highlightFeature(e.pixel)
  private enableHoverListener = (): void => this.setHoverEnabled(true)
  private disableHoverListener = (): void => this.setHoverEnabled(false)

  private enabled = true

  constructor(options: HighlightFeatureOptions) {
    super()

    this.hightlightLayers = options.layers
  }

  move(e: MapBrowserEvent<any>): void {
    this.highlightFeature(e.pixel)
  }

  setHoverEnabled(enabled: boolean): void {
    this.enabled = enabled
    if (this.hovered.feature) {
      this.hovered.feature.set("hovered", false)
    }
  }

  highlightFeature(pixel: Coordinate): void {
    if (!this.enabled) return

    const oldFeature = this.hovered.feature

    const [feature, layer] = this.getFeatureAtPixel(pixel, this.hightlightLayers)

    this.hovered.feature = feature
    this.hovered.layer = layer

    if (feature) {
      feature.set("hovered", true)
    }
    if (oldFeature && oldFeature !== feature) {
      oldFeature.set("hovered", false)
    }
  }

  setMap(map: Map): void {
    if (this.map) {
      // @ts-ignore
      this.map.un("hoverfeature", this.hoverFeatureListener)
      // @ts-ignore
      this.map.un("enablehover", this.enableHoverListener)
      // @ts-ignore
      this.map.un("disablehover", this.disableHoverListener)
    }
    super.setMap(map)
    if (this.map) {
      // @ts-ignore
      this.map.on("hoverfeature", this.hoverFeatureListener)
      // @ts-ignore
      this.map.on("enablehover", this.enableHoverListener)
      // @ts-ignore
      this.map.on("disablehover", this.disableHoverListener)
    }
  }
}
