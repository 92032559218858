import { AfterViewInit, Component, Directive, OnInit, ViewChild } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { MatSnackBar } from "@angular/material/snack-bar"
import mdiBackupRestore from "@iconify/icons-mdi/backup-restore"
import mdiPencil from "@iconify/icons-mdi/pencil"
import mdiRedo from "@iconify/icons-mdi/redo"
import mdiUndo from "@iconify/icons-mdi/undo"
import mdiVectorPolygon from "@iconify/icons-mdi/vector-polygon"
import mdiVectorPolyline from "@iconify/icons-mdi/vector-polyline"
import whhTransform from "@iconify/icons-whh/transform"
import { TranslateService } from "@ngx-translate/core"
import {
  Building,
  Floor,
  FloorCSConfigResponse,
  FloorCSConfigsService,
  FloorsService,
  IpsTracesService,
  RecordEntry,
  Trace,
} from "@openapi/venue"
import { StateService, UIRouter } from "@uirouter/core"
import { ImageDimensions, isImageDimensions, Point } from "@venue/api"
import { CoordinateConverter, CurrentFloor, CurrentVenue } from "@venue/core"
import {
  ImageDimensionsReceiverMixin,
  LayerHistoryEvent,
  LayerHistoryMixin,
  MapToolReceiverMixin,
  MultiVertexModifyInteractionComponent,
  ObjectData,
  TransformInteractionComponent,
  VectorLayerComponent,
} from "@venue/maps"
import { downloadBlob, ProgressDialog, ProgressDialogData, withUnsubscribe } from "@venue/shared"
import { TRACE_PATH_FILE_STATE_NAME, TRACE_PATH_STATE_NAME } from "@venue/traces/traces.state-names"
import { WALLS_EDITOR_STATE_NAME } from "@venue/walls/walls.state-names"
import { vec2 } from "gl-matrix"
import { flatten, isEqual } from "lodash"
import { DateTime } from "luxon"
import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { LineString, Polygon } from "ol/geom"
import VectorSource from "ol/source/Vector"
import { Fill, Style } from "ol/style"
import {
  BehaviorSubject,
  combineLatest,
  empty,
  from,
  merge,
  Observable,
  of,
  Subject,
  throwError,
  zip,
} from "rxjs"
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  pairwise,
  share,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  toArray,
  withLatestFrom,
} from "rxjs/operators"
import { Mixin } from "ts-mixer"
import {
  ExportTraceDialog,
  ExportTraceDialogOutput,
} from "../export-trace-dialog/export-trace.dialog"
import { TracePathStyle } from "./trace-path.map-style"

const WAYPOINT_PIXEL = "TYPE_WAYPOINT"
const WAYPOINT_METRIC = "TYPE_WAYPOINT_METRIC"

/** Property key to access timestamps array. */
const TIMESTAMP_ARRAY_PROPERTY_KEY = "TIMESTAMP_ARRAY"

/** Property key to access timestamps map. */
const TIMESTAMP_MAP_PROPERTY_KEY = "TIMESTAMP_MAP"

/**
 * Array of vertex'es timestamps. Matches vertex by its index in the geometry.
 *
 * Used to get vertex timestamp data when moving vertices (or whole geometry).
 */
type TimestampArray = number[]

/**
 * Map from vertex Coordinate serialized to json string to timestamp.
 *
 * Json string is used as a key, as there is no way to customize object equality in javascript's Map and
 * use Coordinate directly.
 *
 * Used to get vertex timestamp data when adding or removing vertices.
 */
type TimestampMap = Map<string, number>

class TracePathToolReceiver extends MapToolReceiverMixin<MapTool> {}

@Directive()
abstract class TracePathComponentBase extends Mixin(
  ImageDimensionsReceiverMixin,
  TracePathToolReceiver,
  LayerHistoryMixin
) {}

@withUnsubscribe
@Component({
  selector: "trace-path",
  templateUrl: "./trace-path.component.html",
})
export class TracePathComponent extends TracePathComponentBase implements OnInit, AfterViewInit {
  readonly transformIcon = whhTransform
  readonly modifyPolygonIcon = mdiVectorPolyline
  readonly multiModifyIcon = mdiVectorPolygon
  readonly editIcon = mdiPencil
  readonly undoIcon = mdiUndo
  readonly redoIcon = mdiRedo
  readonly restoreSavedIcon = mdiBackupRestore

  readonly currentVenue: Observable<Building>
  readonly currentFloor: Observable<Floor>

  /** Style of accessible area preview. */
  accessibleAreasStyle = new Style({ fill: new Fill({ color: "rgba(100, 200, 0, 0.3)" }) })

  /** Style of transition area's polygons. */
  tpStyle = TracePathStyle

  /** Observable indicating if loading overlay should be displayed. */
  loading = new BehaviorSubject<boolean>(true)

  @ViewChild("accessibleAreasLayer") accessibleAreasLayer: VectorLayerComponent
  @ViewChild("tpLayer") tpLayer: VectorLayerComponent
  @ViewChild(TransformInteractionComponent) transformInteraction: TransformInteractionComponent
  @ViewChild(MultiVertexModifyInteractionComponent)
  multiVertexModifyInteraction: MultiVertexModifyInteractionComponent

  /** Path for which edit form should be shown. */
  editFeature: Feature<LineString>

  /** Selected feature path width (in meters), bound to the slider. */
  pathWidth: number

  /** Subject to reset trace path. */
  resetSubject = new Subject<void>()

  /** Flag indicating that downloading floor coordinate system config failed. */
  noCS = false

  /** Flag indicating that downloading trace's path failed. */
  noTP = false

  /** Flag indicating that geting image dimensions failed. */
  noImgDim = false

  /**
   * Flag indicating that import is disabled.
   *
   * Import is disabled when there is no path polygon.
   */
  importDisabled = new BehaviorSubject(true)

  /**
   * Flag indicating that export is disabled.
   *
   * Export is disabled together with import.
   */
  exportDisabled = this.importDisabled.asObservable()

  private unsubscribe: Observable<void>

  constructor(
    private currentVenueService: CurrentVenue,
    private currentFloorService: CurrentFloor,
    private uiRouter: UIRouter,
    private floors: FloorsService,
    private floorCSConfigs: FloorCSConfigsService,
    private traces: IpsTracesService,
    private coordinateConverter: CoordinateConverter,
    private state: StateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    super()

    this.currentVenue = currentVenueService.venue
    this.currentFloor = currentFloorService.floor
  }

  ngOnInit() {
    // Set CurrentFloor - if we introduce intermediate floor-root step then this can be done in such state
    this.uiRouter.globals.params$
      .pipe(
        map((params) => Number.parseInt(params.floorId)),
        switchMap((floorId) => this.floors.getFloor(floorId)),
        takeUntil(this.unsubscribe),
        finalize(() => this.currentFloorService.setFloor(null))
      )
      .subscribe((floor) => this.currentFloorService.setFloor(floor))

    // Get current floor (can later be changed to not rely on fetching floor data - only floor id is needed)
    const floorStream = this.currentFloor.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
      shareReplay(1)
    )

    // On floor change - display loading indicator
    floorStream.subscribe(() => this.loading.next(true))

    // Get current floor coordinate system config
    const csStream = floorStream.pipe(
      filter((f) => !!f),
      switchMap((f) =>
        this.floorCSConfigs.getFloorCSConfig(f.id).pipe(catchError((err) => this.catchCSError(err)))
      ),
      takeUntil(this.unsubscribe),
      share()
    )

    let tpStream: Observable<Trace>

    // If TRACE_PATH_STATE, then get trace path from server
    if (this.state.$current.name == TRACE_PATH_STATE_NAME) {
      tpStream = this.traceFromServer(true, [WAYPOINT_PIXEL, WAYPOINT_METRIC])
    }
    // If TRACE_PATH_FILE_STATE and file is present - we get the trace path from this file
    else if (
      this.state.$current.name == TRACE_PATH_FILE_STATE_NAME &&
      this.state.params.traceFile
    ) {
      tpStream = this.traceFromFile(true, [WAYPOINT_PIXEL, WAYPOINT_METRIC])
    }
    // Otherwise we have no trace, so handle it as an error
    else {
      tpStream = this.catchTPError("No file to load!")
    }

    tpStream = tpStream.pipe(takeUntil(this.unsubscribe), shareReplay(1))

    const dataStream = merge(
      tpStream.pipe(map((v) => [v, true] as const)),
      this.resetSubject.pipe(
        switchMap(() => tpStream),
        map((v) => [v, false] as const)
      )
    ).pipe(takeUntil(this.unsubscribe))

    // On reset - also reset Transform and MultiVertexModify interactions
    this.resetSubject.subscribe(() => {
      this.transformInteraction.init()
      this.multiVertexModifyInteraction.init()
    })

    // Combine cs, path and imgDim, init converter and load data onto the map
    combineLatest(
      csStream,
      this.imgDimSubject.pipe(
        switchMap((dimOrError) =>
          isImageDimensions(dimOrError) ? of(dimOrError) : this.catchIDError(dimOrError)
        )
      ),
      dataStream
    )
      .pipe(
        filter(([a, b, [c, d]]) => !!a && !!b && !!c),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([cs, imgDim, [tp, initHistory]]) => {
        this.coordinateConverter.load(cs, imgDim.height)
        this.loadPath(cs, imgDim, tp, initHistory)
      })
  }

  ngAfterViewInit(): void {
    this.initHistoryMixin()

    // Re-generate accessible areas after loading state from history
    this.layerHistory.events
      .pipe(
        filter((e) => e === LayerHistoryEvent.LOADED),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.generateAccessibleAreas())
  }

  formatPathWidthLabel(value: number): string {
    return value.toFixed(1) + "m"
  }

  pathWidthSliderMove(value: number) {
    this.editFeature.setProperties({ pathWidth: value })
    this.generateAccessibleAreas()
  }

  pathWidthSliderChange(value: number) {
    this.pathWidthSliderMove(value)
    this.layerHistory.saveState()
  }

  restoreSaved(): void {
    this.resetSubject.next()
  }

  protected layersWithHistory(): VectorLayerComponent[] {
    return [this.tpLayer]
  }

  override onFeaturesModified(features: Feature<LineString>[]): void {
    features.forEach((feature) => this.updateTraceTimestamps(feature))

    super.onFeaturesModified(features)
    this.generateAccessibleAreas()
  }

  override onFeatureTransformed(feature: Feature<LineString>): void {
    this.updateTraceTimestamps(feature)

    super.onFeatureTransformed(feature)
    this.generateAccessibleAreas()
  }

  onFeatureEdit(feature: Feature<LineString>): void {
    this.editFeature = feature
    this.pathWidth = feature?.getProperties().pathWidth ?? 1
  }

  /**
   * Import generated accessible areas to walls editor.
   */
  importPath() {
    this.state.go(WALLS_EDITOR_STATE_NAME, {
      venueId: this.state.params.venueId,
      floorId: this.state.params.floorId,
      // Array of Polygon coords, # 1 array
      // where polygon coords is an array of linear ring coords, # 2 array
      // where linear ring coords is an array of Coordinate # 3 array = Coordinate[]
      importPolygons: this.accessibleAreasLayer.source
        .getFeatures()
        .map((f) => (f.getGeometry() as Polygon).getCoordinates()) as Array<Array<Coordinate[]>>,
    })
  }

  /**
   * Export edited path as trace record and save it as file on disk.
   */
  exportTraceFile() {
    const exportedTrace = this.exportTrace()

    let progressDialog: MatDialogRef<ProgressDialog>

    const traceMetadata = this.getTraceMetadata().pipe(
      tap(() => (progressDialog = this.openExportTraceProgressDialog()))
    )

    zip(exportedTrace, traceMetadata)
      .pipe(
        withLatestFrom(this.currentVenue, this.currentFloor),
        map(
          ([[trace, metadata], venue, floor]): TraceFileData => ({
            building: venue.slug,
            floor: floor.slug,
            user: metadata.user,
            path: metadata.traceId,
            createdAt: DateTime.now().toRFC2822(),
            data: trace,
          })
        ),
        // Hide progress dialog when file is ready
        finalize(() => progressDialog?.close()),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        (data) => {
          const filename = `${data.building}_${data.user}_${data.floor}_${data.path}.json`

          downloadBlob(data, filename)
        },
        (err) =>
          this.snackBar.open(this.translate.instant("trace-path.export-trace-error"), "", {
            duration: 5 * 1000,
          })
      )
  }

  /**
   * Export edited path as trace record and upload it to ips-data-service.
   */
  exportTraceToServer() {
    const exportedTrace = this.exportTrace()

    let progressDialog: MatDialogRef<ProgressDialog>

    const traceMetadata = this.getTraceMetadata().pipe(
      tap(() => (progressDialog = this.openExportTraceProgressDialog()))
    )

    zip(exportedTrace, traceMetadata)
      .pipe(
        withLatestFrom(this.currentVenue, this.currentFloor),
        map(
          ([[trace, metadata], venue, floor]): Trace => ({
            buildingId: venue.id,
            floorId: floor.id,
            user: metadata.user,
            traceId: metadata.traceId,
            createdAt: DateTime.now().toISO(),
            data: trace,
          })
        ),
        switchMap((trace) => this.traces.uploadTrace(trace).pipe(map(() => trace.traceId))),
        finalize(() => progressDialog?.close()),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        (traceId) =>
          this.snackBar.open(
            this.translate.instant("trace-path.server-export.success", { traceId }),
            "",
            {
              duration: 5 * 1000,
            }
          ),
        (error) =>
          this.snackBar.open(this.translate.instant("trace-path.server-export.error"), "", {
            duration: 5 * 1000,
          })
      )
  }

  /**
   * Export edited path as trace record.
   */
  private exportTrace(): Observable<Array<RecordEntry>> {
    // Nothing to do in case there is an error - button should be disabled in that case
    if (this.noTP || this.noCS || this.noImgDim) {
      return
    }

    // Get full record with all sensors data
    const currentState = this.state.$current.name
    let traceRecord =
      currentState == TRACE_PATH_STATE_NAME ? this.traceFromServer() : this.traceFromFile()

    return traceRecord.pipe(
      first(),
      // Remove existing waypoints and add edited ones
      map((trace) =>
        trace.data
          .filter((v) => v.datatype != WAYPOINT_PIXEL && v.datatype != WAYPOINT_METRIC)
          // TODO Select path on map first if we want to support multiple paths on the map at once
          .concat(this.getPathFromMap(this.tpLayer.source.getFeatures()[0] as Feature<LineString>))
          // Sort by timestamp
          .sort((a, b) => a.timestamp - b.timestamp)
      ),
      takeUntil(this.unsubscribe)
    )
  }

  /**
   * Load trace's path onto the map.
   */
  private loadPath(
    cs: FloorCSConfigResponse,
    imgDim: ImageDimensions,
    tp: Trace,
    initHistory = true
  ): void {
    this.tpLayer.clearFeatures()

    if (initHistory) {
      this.layerHistory.clear()
    }

    // Check if metric data is avaliable and if it is, then filter out pixel data
    if (tp.data.find((v) => v.datatype === WAYPOINT_METRIC)) {
      tp.data = tp.data.filter((v) => v.datatype === WAYPOINT_METRIC)
    }

    // Sort data by timestamp
    tp.data.sort((a, b) => a.timestamp - b.timestamp)

    this.tpLayer.loadFeatures([this.toObjectData(tp.data, imgDim.height)], "LineString")

    // After loading path, geometry will be simplified
    // Update timestampArray to match this new geometry
    {
      this.tpLayer.source.getFeatures().forEach((feature) => {
        const timestampMap = feature.getProperties()[TIMESTAMP_MAP_PROPERTY_KEY] as TimestampMap

        const geom = feature.getGeometry() as LineString

        const timestampArray = geom.getCoordinates().map((c) => timestampMap.get(JSON.stringify(c)))

        const propUpdate: any = {}
        propUpdate[TIMESTAMP_ARRAY_PROPERTY_KEY] = timestampArray

        feature.setProperties(propUpdate)
      })
    }

    if (initHistory) {
      this.layerHistory.init()
    } else {
      this.layerHistory.saveState()
    }

    this.generateAccessibleAreas()

    this.loading.next(false)
  }

  private toObjectData(data: RecordEntry[], imgHeight: number): ObjectData {
    const timestampMap: TimestampMap = new Map()
    const timestampArray: TimestampArray = []

    const properties: any = {}
    properties[TIMESTAMP_ARRAY_PROPERTY_KEY] = timestampArray
    properties[TIMESTAMP_MAP_PROPERTY_KEY] = timestampMap

    return {
      points: data
        .map((r) => [r.datatype, r.sensordata.split(","), r.timestamp] as const)
        .map(([type, v, timestamp]) => {
          let p: Point = {
            x: Number.parseFloat(v[0]),
            y: Number.parseFloat(v[1]),
          }

          if (type === WAYPOINT_METRIC) {
            // If metric waypoint then convert to pixel
            p = this.coordinateConverter.toPixel(p)
          } else {
            // If pixel waypoint then flip vertical
            p.y = imgHeight - p.y
          }

          const coord: Coordinate = [p.x, p.y]

          timestampMap.set(JSON.stringify(coord), timestamp)
          timestampArray.push(timestamp)

          return coord
        }),
      properties,
    }
  }

  private clearAccessibleAreas() {
    this.accessibleAreasLayer.clearFeatures(true)
    this.importDisabled.next(true)
  }

  private generateAccessibleAreas() {
    // First, clear accessible areas layer
    this.clearAccessibleAreas()

    const source = this.tpLayer.source as VectorSource<LineString>

    const vecFromPoints = (a: number[], b: number[]) => vec2.fromValues(b[0] - a[0], b[1] - a[1])

    // return pair of vectors of given length orthogonal to given vector
    const orthoVecs = (v: vec2, l: number): [vec2, vec2] => {
      // create vectors
      let a = vec2.fromValues(v[1], -v[0])
      let b = vec2.fromValues(-v[1], v[0])

      // rescale
      let aLen = vec2.length(a)
      let bLen = vec2.length(b)
      a = aLen == 0 ? vec2.create() : vec2.scale(vec2.create(), a, l / aLen)
      b = bLen == 0 ? vec2.create() : vec2.scale(vec2.create(), b, l / bLen)

      return [a, b]
    }

    from(source.getFeatures())
      .pipe(
        // Map each path...
        mergeMap((path) =>
          of(path).pipe(
            // Map to coordinates
            concatMap((f) => from(f.getGeometry().getCoordinates())),
            // Map to point
            map((c) => ({ x: c[0], y: c[1] })),
            // Convert to meters
            map((p) => this.coordinateConverter.toMetric(p)),
            // Map as array
            map((p) => [p.x, p.y]),
            // Group coordinate pairs (line segments)
            pairwise(),
            // Map to points translated by orthogonal vectors of pathWidth length
            concatMap(([a, b]) => {
              const lineVec = vecFromPoints(a, b)
              const pathWidth = path.getProperties().pathWidth ?? 1
              const [oa, ob] = orthoVecs(lineVec, pathWidth)

              return of(
                [vec2.add(vec2.create(), a, oa), vec2.add(vec2.create(), a, ob)],
                [vec2.add(vec2.create(), b, oa), vec2.add(vec2.create(), b, ob)]
              ) as any as Observable<[Coordinate, Coordinate]>
            }),
            toArray()
          )
        ),
        // Map to coords of polygons of given width
        map((polySegments) => {
          let coords: Coordinate[] = []

          const firstHalf = from(polySegments).pipe(map((v) => v[0]))

          // Add first half
          firstHalf.subscribe((v) => coords.push(v))

          const secondHalf = from(polySegments.reverse()).pipe(map((v) => v[1]))

          // Add second half
          secondHalf.subscribe((v) => coords.push(v))

          // Add first point again to close the polygon
          coords.push(coords[0])

          return coords
        }),
        // Convert coords back to pixels
        map((coords) => coords.map((c) => this.coordinateConverter.toPixel({ x: c[0], y: c[1] }))),
        // Map to obj data
        map((points) => ({ points })),
        toArray(),
        takeUntil(this.unsubscribe)
      )
      // And then add new polygons
      .subscribe((polys) => {
        this.accessibleAreasLayer.loadFeatures(polys)
        this.importDisabled.next(false)
      })
  }

  /**
   * Get path from map as record waypoints.
   */
  private getPathFromMap(feature: Feature<LineString>): RecordEntry[] {
    const timestampArray = feature.getProperties()[TIMESTAMP_ARRAY_PROPERTY_KEY] as TimestampArray

    const data = feature
      .getGeometry()
      .getCoordinates()
      .map((coord, idx) => {
        const timestamp = timestampArray[idx]

        const point = {
          x: coord[0],
          y: coord[1],
        }

        const imgHeight = (this.imgDimSubject.getValue() as ImageDimensions).height

        const pixelPoint = { x: point.x, y: imgHeight - point.y }
        const metricPoint = this.coordinateConverter.toMetric(point)

        const pixelWaypoint = {
          datatype: WAYPOINT_PIXEL,
          sensordata: pixelPoint.x + "," + pixelPoint.y,
          timestamp,
        }

        const metricWaypoint = {
          datatype: WAYPOINT_METRIC,
          sensordata: metricPoint.x + "," + metricPoint.y,
          timestamp,
        }

        return [pixelWaypoint, metricWaypoint]
      })

    return flatten(data)
  }

  /**
   * Observable returning trace from the server, using state params for trace id.
   *
   * When handleError is true,
   * if fetching fails or fetched trace has no waypoints, noTP error flag will be set and
   * Observable will return null.
   */
  private traceFromServer(handleError = false, sensors: string[] = []): Observable<Trace> {
    return this.uiRouter.globals.params$.pipe(
      distinctUntilChanged(isEqual),
      filter((p) => p.user && p.venueId && p.traceId),
      switchMap((p) => {
        let trace = this.traces.getTrace(p.user, p.traceId, p.venueId, sensors).pipe(
          switchMap((tp) => {
            const hasWaypoint = tp.data.find(
              (v) => v.datatype === WAYPOINT_METRIC || v.datatype === WAYPOINT_PIXEL
            )

            // Pass if there is at least one waypoint
            if (hasWaypoint) {
              return of(tp)
            }

            // Otherwise, it is an error
            return throwError(new Error("No path in trace"))
          })
        )

        if (handleError) {
          trace = trace.pipe(catchError((err) => this.catchTPError(err)))
        }

        return trace
      })
    )
  }

  /**
   * Observable returning trace from traceFile passed in state params.
   *
   * Do not use this method if there is no traceFile param in state.
   *
   * When handleError is true,
   * if json parsing fails, noTP error flag will be set and Observable will return null.
   */
  private traceFromFile(handleError = false, sensors: string[] = []): Observable<Trace> {
    return this.uiRouter.globals.params$.pipe(
      map((p) => p.traceFile as File),
      switchMap((f) => from(f.text())),
      switchMap((traceText) => {
        let trace: Observable<Trace>

        try {
          trace = of(JSON.parse(traceText) as Trace)
        } catch (err) {
          trace = throwError(err)
        }

        if (handleError) {
          trace = trace.pipe(catchError((err) => this.catchTPError(err)))
        }

        return trace
      }),
      tap((trace) => {
        if (sensors.length) {
          trace.data = trace.data.filter((v) => sensors.includes(v.datatype))
        }
      })
    )
  }

  /**
   * Update TimestampMap or TimestampArray after path modification.
   *
   * When vertices are modified, TimestampMap needs to be updated.
   * When vertices are removed, TimestampArray needs to be updated.
   * When vertices are added, timestamp must be added to TimestampMap and TimestampArray needs to be updated.
   */
  private updateTraceTimestamps(feature: Feature<LineString>) {
    // Update timestampMap
    let timestampArray = feature.getProperties()[TIMESTAMP_ARRAY_PROPERTY_KEY] as TimestampArray
    let timestampMap = feature.getProperties()[TIMESTAMP_MAP_PROPERTY_KEY] as TimestampMap

    const geom = feature.getGeometry() as LineString
    const coords = geom.getCoordinates()

    // Modified vertices case
    if (timestampArray.length == coords.length) {
      this.updateTimestampsAfterModify(feature, coords, timestampArray)
    }
    // Added or removed vertices case
    else {
      this.updateTimestampsAfterAddOrRemove(feature, coords, timestampMap)
    }
  }

  /**
   * Update TimestampMap using data from TimestampArray after modifying vertices.
   */
  private updateTimestampsAfterModify(
    feature: Feature<LineString>,
    coords: Coordinate[],
    timestampArray: TimestampArray
  ) {
    const timestampMap = new Map(coords.map((c, idx) => [JSON.stringify(c), timestampArray[idx]]))

    const propUpdate: any = {}
    propUpdate[TIMESTAMP_MAP_PROPERTY_KEY] = timestampMap

    feature.setProperties(propUpdate)
  }

  /**
   * Update TimestampArray using data from TimestampMap after adding or removing vertices.
   * Calculate timestamp for new vertices and set them in given TimestampMap.
   */
  private updateTimestampsAfterAddOrRemove(
    feature: Feature<LineString>,
    coords: Coordinate[],
    timestampMap: TimestampMap
  ) {
    const timestampArray = coords.map((c, idx) => {
      const jsonString = JSON.stringify(c)
      let timestamp = timestampMap.get(jsonString)

      // If there is no timestamp for the coordinate, then estimate it using neighbor vertices
      if (timestamp == null) {
        // It shouldn't be possible to add vertex at the beginning or the end, so do not handle such case

        const prev = coords[idx - 1]
        const prevDist = vec2.distance(prev, c)
        const prevTimestamp = timestampMap.get(JSON.stringify(prev))

        const next = coords[idx + 1]
        const nextDist = vec2.distance(c, next)
        const nextTimestamp = timestampMap.get(JSON.stringify(next))

        const fullDist = prevDist + nextDist

        const subPathFract = prevDist / fullDist

        const timestampDiff = nextTimestamp - prevTimestamp
        timestamp = Math.round(prevTimestamp + timestampDiff * subPathFract)

        timestampMap.set(jsonString, timestamp)
      }

      return timestamp
    })

    const propUpdate: any = {}
    propUpdate[TIMESTAMP_ARRAY_PROPERTY_KEY] = timestampArray

    feature.setProperties(propUpdate)
  }

  /**
   * Open ProgressDialog for export operation
   */
  private openExportTraceProgressDialog(): MatDialogRef<ProgressDialog> {
    const data: ProgressDialogData = {
      progressTextTranslationKey: "trace-path.export-trace-in-progress",
    }
    return this.dialog.open(ProgressDialog, { data })
  }

  /**
   * Open ExportTraceDialog and return its output.
   */
  private getTraceMetadata(): Observable<ExportTraceDialogOutput> {
    return this.dialog
      .open(ExportTraceDialog)
      .afterClosed()
      .pipe(filter((v) => !!v))
  }

  /**
   * On cs error, set noCS error flag and return empty observable.
   *
   * Also hide loading indicator, nothing to load after this error.
   */
  private catchCSError(err: any): Observable<FloorCSConfigResponse> {
    console.error("Error fetching floor coordinate system config", err)
    this.noCS = true

    this.loading.next(false)

    return empty()
  }

  /**
   * Set tp error flag and return empty observable
   */
  private catchTPError(err: any): Observable<Trace> {
    console.error("Error fetching trace path", err)

    this.noTP = true

    this.loading.next(false)

    return empty()
  }

  /**
   * On image dimensions error, set noImgDim flag and return empty observable.
   */
  private catchIDError(err: any): Observable<ImageDimensions> {
    console.error("Error getting image dimensions", err)

    this.noImgDim = true

    this.loading.next(false)

    return empty()
  }
}

enum MapTool {
  TRANSFORM = "TRANSFORM",
  MODIFY = "MODIFY",
  EDIT = "EDIT",
  MULTI_MODIFY = "MULTI_MODIFY",
}

export interface TraceFileData extends Omit<Trace, "buildingId" | "floorId" | "traceId"> {
  /** Building slug. */
  building: string

  /** Floor slug. */
  floor: string

  /** Path slug. */
  path: string
}
