import { Component, ElementRef, Input, OnInit, Output, ViewChild } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { withUnsubscribe } from "@venue/shared"
import { Observable, Subject } from "rxjs"
import { filter, map, takeUntil, tap } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "file-selector",
  templateUrl: "./file-selector.component.html",
})
export class FileSelectorComponent implements OnInit {
  @Input() iconButton: boolean
  @Input() tooltip: string
  @Input() accept: string

  fileForm = this.fb.control(null)
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>

  @Output() fileSelected = new Subject<File>()

  private unsubscribe: Observable<any>

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.fileForm.valueChanges
      .pipe(
        filter((v) => !!v),
        map(() => this.fileInput.nativeElement.files[0]),
        tap(() => this.fileForm.setValue(null)),
        takeUntil(this.unsubscribe)
      )
      .subscribe((file) => {
        this.fileSelected.next(file)
      })
  }
}
