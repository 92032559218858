import {
  AfterViewInit,
  Component,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core"
import { ImageTransforms } from "@venue/components/floor/floormap/transformations/ImageTransforms"
import TransformInteractions from "@venue/components/floor/floormap/transformations/interactions"
import { Feature } from "ol"
import { Coordinate } from "ol/coordinate"
import { boundingExtent, containsCoordinate } from "ol/extent"
import { LineString, Point } from "ol/geom"
import { DragBox } from "ol/interaction"
import { DragBoxEvent } from "ol/interaction/DragBox"
import { Vector as VectorLayer } from "ol/layer"
import { VectorSourceEvent } from "ol/source/Vector"
import { Circle, Fill, Stroke, Style } from "ol/style"
import { StyleFunction, StyleLike } from "ol/style/Style"
import { Subject } from "rxjs"
import { OpenlayersMapComponent, VectorLayerComponent } from ".."

@Component({
  selector: "multi-vertex-modify-interaction",
  template: "",
})
export class MultiVertexModifyInteractionComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() layers: VectorLayerComponent[]
  @Input() disabled?: boolean

  @Output() verticesModified = new Subject<Feature<any>>()

  private dragBox = new DragBox()

  private initialized = false

  private startPoint: Coordinate = null

  private transforms: ImageTransforms
  private transformInteractions: TransformInteractions
  private transformInteractionsCallback = () => this.verticesModified.next(this.selectedFeature)

  private originalStyles = new Map<VectorLayer<any>, StyleLike>()

  private selectedFeature: Feature<any> = null
  private selectedCoordIndices: number[] = null

  private boxstartListener = (event: DragBoxEvent) => {
    this.startPoint = event.coordinate
    this.removeTransform()

    const prevFeature = this.selectedFeature

    this.selectedFeature = null
    this.selectedCoordIndices = null

    prevFeature?.changed()
  }

  private boxdragListener = (event: DragBoxEvent) => {
    const extent = boundingExtent([event.coordinate, this.startPoint])

    const prevFeature = this.selectedFeature

    this.selectedFeature = this.getSelectedFeature(extent)
    this.selectedCoordIndices = this.getSelectedCoordIndices(extent, this.selectedFeature)

    if (prevFeature && prevFeature != this.selectedFeature) {
      prevFeature.changed()
    }

    this.selectedFeature?.changed()
  }

  private boxendListener = (event: DragBoxEvent) => this.addTransform()

  private clearSourceListener = (event: VectorSourceEvent<any>) => {
    this.removeTransform()
  }

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnDestroy(): void {
    this.dispose()
  }

  ngAfterViewInit(): void {
    this.init()
    this.initialized = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      this.init()
    }
  }

  init() {
    this.dispose()

    if (this.disabled === true || !this.layers) {
      return
    }

    this.mapComponent.map.addInteraction(this.dragBox)
    // @ts-ignore
    this.dragBox.on("boxstart", this.boxstartListener)
    this.dragBox.on("boxdrag", this.boxdragListener)
    this.dragBox.on("boxend", this.boxendListener)

    this.layers.forEach((vlc) => {
      const layer = vlc.layer

      this.originalStyles.set(layer, layer.getStyle())

      layer.setStyle(
        SelectedVerticesStyle(
          layer.getStyleFunction(),
          () => this.selectedFeature,
          () => this.selectedCoordIndices
        )
      )
    })

    this.layers.forEach((l) => l.source.on("clear", this.clearSourceListener))
  }

  dispose() {
    this.layers.forEach((l) => l.source.un("clear", this.clearSourceListener))

    this.removeTransform()

    if (this.originalStyles.size) {
      this.originalStyles.forEach((style, layer) => layer.setStyle(style))
    }

    this.originalStyles.clear()

    this.selectedFeature = null
    this.selectedCoordIndices = null

    this.dragBox.un("boxend", this.boxendListener)
    this.dragBox.un("boxdrag", this.boxdragListener)
    // @ts-ignore
    this.dragBox.un("boxstart", this.boxstartListener)
    this.mapComponent.map.removeInteraction(this.dragBox)
  }

  private addTransform(): void {
    this.removeTransform()

    if (!this.selectedFeature || !this.selectedCoordIndices?.length) {
      return
    }

    this.transforms = new ImageTransforms()

    const margin = 10
    const selectedCoordsExtent = boundingExtent(
      coordIndicesToCoordinates(this.selectedFeature.getGeometry(), this.selectedCoordIndices)
    )

    const transformExtent = [
      selectedCoordsExtent[0] - margin,
      selectedCoordsExtent[1] - margin,
      selectedCoordsExtent[2] + margin,
      selectedCoordsExtent[3] + margin,
    ]

    this.transformInteractions = new TransformInteractions(
      this.mapComponent.map,
      transformExtent,
      this.transforms
    )

    this.transformInteractions.addCallback(this.transformInteractionsCallback)
    this.transformInteractions.addInteractions()
    this.transforms.onChange((transformFun) => {
      this.selectedFeature.getGeometry().applyTransform((coords: number[], out?: number[]) => {
        out = out ?? []

        coords.forEach((value, i) => {
          // Coordinates are flattened in applyTransform function
          if (i % 2 != 1) {
            return
          }

          const coordIdx = (i - 1) / 2

          let x = coords[i - 1]
          let y = coords[i]

          // Only transform selected coords
          if (this.selectedCoordIndices.includes(coordIdx)) {
            ;[x, y] = transformFun([x, y])
          }

          out[i - 1] = x
          out[i] = y
        })
      })
    })
  }

  private removeTransform(): void {
    this.transformInteractions?.removeCallback(this.transformInteractionsCallback)
    this.transformInteractions?.removeInteractions()
    this.transformInteractions = null
  }

  private getSelectedFeature(extent: number[]): Feature<any> {
    for (const l of this.layers) {
      const features = l.source.getFeaturesInExtent(extent)
      if (features?.length) {
        return features[0]
      }
    }

    return null
  }

  private getSelectedCoordIndices(extent: number[], feature: Feature<any>) {
    if (!feature) {
      return []
    }

    const selected: number[] = []

    const geom = feature.getGeometry()

    if (!(geom instanceof LineString)) {
      throw new Error("Geometry not supported")
    }

    geom.getCoordinates().forEach((coord, idx) => {
      if (containsCoordinate(extent, coord)) {
        selected.push(idx)
      }
    })

    return selected
  }
}

function SelectedVerticesStyle(
  origStyle: StyleFunction,
  selectedFeatureFun: () => Feature<any>,
  selectedCoordIndicesFun: () => number[]
): StyleFunction {
  return (f: Feature<any>, res: number) => {
    const selectedFeature = selectedFeatureFun()
    const selectedCoordIndices = selectedCoordIndicesFun()

    // Casting to Style[], will wrap in array later if needed
    let styles: Style[] = (origStyle(f, res) as Style[]) ?? []

    if (selectedFeature != f || !selectedCoordIndices?.length) {
      return styles
    }

    // Stylefunction returns Style | Array<Style>
    if (!Array.isArray(styles)) {
      styles = [styles]
    }

    let segments: Array<number[]> = []
    let lastCi: number

    selectedCoordIndices
      .sort((a, b) => a - b)
      .forEach((ci) => {
        if (lastCi != null && lastCi + 1 == ci) {
          const lastSegment = segments[segments.length - 1]
          lastSegment.push(ci)
        } else {
          segments.push([ci])
        }

        lastCi = ci
      })

    const featureGeometry = f.getGeometry()

    segments.forEach((segment) => {
      const segmentCoords = coordIndicesToCoordinates(featureGeometry, segment)

      if (segmentCoords.length > 1) {
        styles.push(
          new Style({
            stroke: new Stroke({
              color: "rgb(0,100,200,0.5)",
              width: 10, // TODO Should be cofigurable
            }),
            zIndex: 10,
            geometry: new LineString(segmentCoords),
          })
        )
      }

      segmentCoords.forEach((coord) =>
        styles.push(
          new Style({
            image: new Circle({
              radius: 10,
              fill: new Fill({
                color: "rgb(0,100,200,0.5)",
              }),
              stroke: new Stroke({
                color: "rgb(0,100,200)",
                width: 2,
              }),
            }),
            zIndex: 11,
            geometry: new Point(coord),
          })
        )
      )
    })

    return styles
  }
}

function coordIndicesToCoordinates(geometry: LineString, cIds: number[]): Array<Coordinate> {
  return cIds.map((ci) => geometry.getCoordinates()[ci])
}
