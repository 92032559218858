/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TuningResult { 
    intermediateScores?: { [key: string]: number; };
    result?: TuningResultResultEnum;
    score?: number;
    type?: TuningResultTypeEnum;
}
export enum TuningResultResultEnum {
    ParentBest = 'PARENT_BEST',
    OldTunedBest = 'OLD_TUNED_BEST',
    NewTunedBest = 'NEW_TUNED_BEST'
};
export enum TuningResultTypeEnum {
    Auto = 'AUTO',
    Manual = 'MANUAL'
};



