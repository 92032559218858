import { Injectable } from "@angular/core"
import { Floor } from "@openapi/venue"
import { StateService } from "@uirouter/core"
import { WithUnsubscribe } from "@venue/shared/utils/with-unsubscribe.mixin"
import { BehaviorSubject, Observable } from "rxjs"
import { map, takeUntil } from "rxjs/operators"
import { CurrentVenue } from "."

@Injectable()
export class CurrentFloor extends WithUnsubscribe {
  readonly floor: Observable<Floor>

  private floorSubject = new BehaviorSubject<Floor>(null)

  constructor(currentVenue: CurrentVenue, stateService: StateService) {
    super()
    this.floor = this.floorSubject.asObservable()

    currentVenue.floors
      .pipe(
        map((f) => f ?? []),
        takeUntil(this.unsubscribe)
      )
      .subscribe((floors) => {
        if (floors.length) {
          let floor = floors[0]

          if (stateService.params.floorId) {
            floor = floors.filter((f) => f.id === parseInt(stateService.params.floorId))[0] || floor
          }
          this.setFloor(floor)
        } else {
          this.setFloor(null)
        }
      })
  }

  getFloor(): Floor {
    return this.floorSubject.value
  }

  setFloor(floor: Floor): void {
    if (this.floorSubject.value !== floor) {
      this.floorSubject.next(floor)
    }
  }
}
