import { Component, Host, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core"
import {
  DrawRectangleInteraction,
  RectangleDefinition,
} from "@venue/components/floor/floormap/transformations/DrawRectangleInteraction"
import { OpenlayersMapComponent } from "@venue/maps"
import { Subject } from "rxjs"

@Component({
  selector: "draw-rectangle-interaction",
  template: "",
})
export class DrawRectangleInteractionComponent implements OnChanges, OnDestroy {
  @Input() disabled?: boolean

  @Output() rectangleDrawn = new Subject<RectangleDefinition>()

  private drawInteraction: DrawRectangleInteraction

  constructor(@Host() private mapComponent: OpenlayersMapComponent) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.dispose()
    this.init()
  }

  ngOnDestroy(): void {
    this.dispose()
  }

  init(): void {
    this.dispose()

    if (this.disabled === true) {
      return
    }

    this.drawInteraction = new DrawRectangleInteraction()

    this.drawInteraction.onRectangleDrawn((rect) => {
      this.rectangleDrawn.next(rect)
    })

    this.mapComponent.map.addInteraction(this.drawInteraction)
  }

  dispose(): void {
    if (this.drawInteraction) {
      this.mapComponent.map.removeInteraction(this.drawInteraction)
      this.drawInteraction = null
    }
  }
}
