import mdiMapMarker from "@iconify/icons-mdi/map-marker"
import { mapStyleFactory } from "@venue/maps"
import { IconDirective, IconService } from "@visurel/iconify-angular"
import { Feature } from "ol"
import { Point as olPoint } from "ol/geom"
import { Icon, Style } from "ol/style"
import { PROTO_POINT_PROPERTY_KEY } from "./property-keys"
import { ProtoPoint } from "./proto-point"

// Manual usage of the directive in place of direct calls to private api,
// as we don't have angular context here and just need the svg
// Not using Iconify api directly to not maintain iconify bundle - when we use @visurel/iconify-angular anyway now
const iconDirective = new IconDirective(
  <any>{ bypassSecurityTrustHtml: (v: any) => v },
  new IconService()
)
iconDirective.icon = mdiMapMarker
iconDirective.width = "3rem"
iconDirective.height = "3rem"

const tempDiv = document.createElement("div")
const PROTOTYPE_POINT_ICONS = new Map<ProtoPoint, Element>()

const createIconElement = (color: string): Element => {
  iconDirective.color = color
  iconDirective.updateIcon()
  tempDiv.innerHTML = <string>iconDirective.iconHTML
  return tempDiv.firstElementChild
}

PROTOTYPE_POINT_ICONS.set(ProtoPoint.BLUE, createIconElement("DodgerBlue"))
PROTOTYPE_POINT_ICONS.set(ProtoPoint.YELLOW, createIconElement("LemonChiffon"))
PROTOTYPE_POINT_ICONS.set(ProtoPoint.PINK, createIconElement("Plum"))

PROTOTYPE_POINT_ICONS.forEach((v) => v.setAttribute("stroke", "black"))

function prototypePointStyle(feature: Feature<olPoint>, opts?: PrototypePointStyleOptions): Style {
  const icon = PROTOTYPE_POINT_ICONS.get(feature.get(PROTO_POINT_PROPERTY_KEY))
  return new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: `data:image/svg+xml;base64,${btoa(icon.outerHTML)}`,
    }),
  })
}

interface PrototypePointStyleOptions {
  hovered?: boolean
  selected?: boolean
}

export const PROTOTYPE_POINT_STYLE = mapStyleFactory({
  defaultStyle: (f: Feature<olPoint>) => prototypePointStyle(f),
  hoveredStyle: (f: Feature<olPoint>) => prototypePointStyle(f),
  selectedStyle: (f: Feature<olPoint>) => prototypePointStyle(f),
})
