import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core"
import { FormBuilder, Validators } from "@angular/forms"
import { withUnsubscribe } from "@venue/shared"
import { Observable, Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"

@withUnsubscribe
@Component({
  selector: "floor-cs-sidebar",
  templateUrl: "./floor-cs-sidebar.component.html",
})
export class FloorCSSidebarComponent implements OnInit, OnChanges {
  widthForm = this.fb.control(0, [Validators.required, Validators.min(0.01)])
  lengthForm = this.fb.control(0, [Validators.required, Validators.min(0.01)])

  @Input() inputDisabled = false
  @Input() submitDisabled = false

  @Input() width: number
  @Input() length: number

  @Output() widthChange: Observable<number>
  @Output() lengthChange: Observable<number>

  @Output() submit = new Subject<void>()

  private unsubscribe: Observable<any>

  constructor(private fb: FormBuilder) {
    this.widthChange = this.widthForm.valueChanges.pipe(takeUntil(this.unsubscribe))

    this.lengthChange = this.lengthForm.valueChanges.pipe(takeUntil(this.unsubscribe))
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.widthForm.setValue(this.width)
    this.lengthForm.setValue(this.length)

    if (this.inputDisabled) {
      this.widthForm.disable()
      this.lengthForm.disable()
    }
    else {
      this.widthForm.enable()
      this.lengthForm.enable()
    }
  }
}
