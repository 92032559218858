<loading-overlay *ngIf="loading"></loading-overlay>
<section fxLayout="column"
         fxLayoutGap="1rem">

    <h3>{{ (mode == 'create' ? 'floor-form.header.create' : 'floor-form.header.edit') | translate }}</h3>

    <form [formGroup]="form"
          fxLayout="column">

        <div fxLayout="row wrap">
            <div fxLayout="column"
                 class="floor-form-inputs"
                 fxFlex>
                <mat-form-field>
                    <mat-label>{{ 'floor-model.name' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           required
                           formControlName="name">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'floor-model.slug' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           required
                           formControlName="slug">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'floor-model.level' | translate }}</mat-label>
                    <input matInput
                           type="number"
                           required
                           formControlName="level">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'floor-model.height' | translate }}</mat-label>
                    <input matInput
                           type="number"
                           required
                           formControlName="levelHeight">
                </mat-form-field>

                <input #imageInput
                       type="file"
                       hidden="true"
                       accept="image/*"
                       [formControl]="imageForm">
                <button class="select-image-button"
                        mat-raised-button
                        color="accent"
                        (click)="imageInput.click()">
                    {{ 'floor-form.select-image' | translate }}
                </button>
            </div>

            <div fxLayout="column"
                 class="floor-image-panel"
                 fxFlex>
                <img *ngIf="imageObjectUrl"
                     [src]="domSanitizer.bypassSecurityTrustUrl(imageObjectUrl)" />
                <span *ngIf="!imageObjectUrl && !loading"
                      translate> floor-form.no-image-message</span>
            </div>
        </div>

        <button mat-raised-button
                type="submit"
                color="primary"
                [disabled]="!form.valid || loading"
                (click)="save()">
            {{ (mode == 'create' ? 'floor-form.create' : 'floor-form.update') | translate }}
        </button>
    </form>
</section>
