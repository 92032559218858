import { Injectable } from "@angular/core"
import { AppConstants } from "@venue/app.constants"
import { ConfigService } from "@venue/config"
import { AuthConfig } from "angular-oauth2-oidc"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

/**
 * Service responsible for fetching auth configuration from json file.
 */
@Injectable()
export class AuthConfigService {
  constructor(private configService: ConfigService) {}

  /**
   * Get auth configuration for angular-oauth2-oidc library.
   */
  getConfig(): Observable<OptAuthConfig> {
    return this.configService.getConfigData().pipe(
      map((config) => config.auth),
      map((data) => ({
        vsAuthEnabled: data.enabled,

        issuer: data.issuer,
        clientId: data.clientId,
        scope: data.scope,

        // TODO Redirect to current state
        redirectUri: AppConstants.frontUrl + "/#/",
        responseType: "code",
      }))
    )
  }
}

/**
 * Extended AuthConfig with custom field to turn off authentication.
 */
export interface OptAuthConfig extends AuthConfig {
  /**
   * Flag indicating if auth module should be enabled.
   */
  vsAuthEnabled: boolean
}
