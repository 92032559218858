import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AppConstants } from "@venue/app.constants"
import { Observable } from "rxjs"
import { map, shareReplay } from "rxjs/operators"

/**
 * Service providing UI config.
 */
@Injectable()
export class ConfigService {
  readonly CONFIG_JSON_FILE = "ui-config.json"

  /**
   * Configuration of the frontend specified in json file.
   */
  private configData: Observable<ConfigData>

  /**
   * Url to Venue service.
   *
   * Url is in the form: "${base_url}${base_path}", where:
   * - base_url is the vs.base_url value from ui config or window.location.origin if not set
   * - base_path is the vs.base_path value from ui config or AppConstants.frontBasePath if not set
   */
  venueUrl: Observable<string>

  constructor(private http: HttpClient) {
    const configJsonUrl = `${AppConstants.frontUrl}/${this.CONFIG_JSON_FILE}`
    this.configData = this.http.get<ConfigData>(configJsonUrl).pipe(shareReplay(1))

    this.venueUrl = this.configData.pipe(
      map((configData) => {
        const cbu = configData.vs?.base_url
        const base_url = cbu ? cbu : window.location.origin // default if null, undefined or ""

        const cbp = configData.vs?.base_path
        const base_path = cbp ? cbp : AppConstants.frontBasePath // default if null, undefined or ""

        return base_url + base_path
      })
    )
  }

  /**
   * Get configuration of the frontend specified in json file.
   */
  getConfigData(): Observable<ConfigData> {
    return this.configData
  }
}

// DOCS_CONFIG_DATA_START
/**
 * Configuration of the app.
 */
export interface ConfigData {
  vs: VSConfigData
  auth: AuthConfigData
  mode: Mode
  ss: SSConfigData
}

/**
 * Configuration related to Venue service.
 */
export interface VSConfigData {
  /**
   * Base url of the Venue service.
   *
   * If null, undefined or empty string,
   *   then it is assumed to be the same as frontend's location.origin.
   */
  base_url?: string | null

  /**
   * Base (context) path of Venue service api.
   *
   * If null, undefined or empty string,
   *   then it is assumed to be the same as frontend's location.pathname.
   */
  base_path?: string | null
}

/**
 * Auth configuration loaded from json file, used to create angular-oauth2-oidc's AuthConfig.
 */
export interface AuthConfigData {
  /**
   * Determines if frontend should enable auth features.
   *
   * Set to true if backend requires auth token and checks permissions
   * or false if backend's security is disabled.
   */
  enabled: boolean

  /**
   * Url to auth server.
   *
   * Ignored if enabled is false.
   */
  issuer: string

  /**
   * Setup Store Frontend's client id.
   *
   * Ignored if enabled is false.
   */
  clientId: string

  /**
   * Scopes to request.
   *
   * List of scopes that will be requested, separated by spaces.
   *
   * Ignored if enabled is false.
   */
  scope: string
}

/**
 * Mode enumeration
 *
 * CATCHA if Building Service is not deployed
 * and Venue Backend is running in Profile: awsdeploy.
 *
 * BUILDING_SERVICE if Building Service is deployed
 * and Venue Backend is running in Profile: dev.
 */
export enum Mode {
  CATCHA,
  BUILDING_SERVICE,
}

/**
 * Configuration related to Venue service.
 */
export interface SSConfigData {
  /**
   * Base url of the Setup Store service.
   */
  base_url?: string | null

  /**
   * Base (context) path of Venue service api.
   */
  base_path?: string | null
}

// DOCS_CONFIG_DATA_END
