<loading-overlay *ngIf="loading"></loading-overlay>

<div class="auto-overflow" #mappingArea>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="venueFloorName">
            <th mat-header-cell class="floor-column" *matHeaderCellDef>
                {{ "import-venue.floors-map.venue-floor" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <p *ngIf="element.venueFloorSlug != null">
                    {{ getFloorName(element.venueFloorSlug) }}
                </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="packageFloorName">
            <th mat-header-cell class="floor-column" *matHeaderCellDef>
                {{ "import-venue.floors-map.package-floor" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field appearance="fill">
                    <mat-label>{{ "import-venue.floors-map.select" | translate }}</mat-label>
                    <mat-select [(value)]="element.packageFloorSlug">
                        <mat-option *ngIf="element.venueFloorSlug != null">
                            {{ "import-venue.floors-map.none-mapping" | translate }}
                        </mat-option>
                        <mat-option
                            *ngFor="let pckFloor of packageFloors | async"
                            [value]="pckFloor.slug"
                        >
                            {{ pckFloor.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="removeRow">
            <th mat-header-cell class="button-column" *matHeaderCellDef>
                {{ "import-venue.floors-map.remove-mapping" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <button
                    mat-raised-button
                    *ngIf="element.venueFloorSlug == null"
                    color="primary"
                    (click)="removeMapping(element)"
                >
                    <ic-icon [icon]="deleteRowIcon"> </ic-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="addRow">
            <td mat-footer-cell *matFooterCellDef colspan="3" class="centered-column">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="addMapping()"
                    matTooltip="{{ 'import-venue.floors-map.add-mapping' | translate }}"
                >
                    <ic-icon [icon]="addRowIcon"> </ic-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['addRow']; sticky: true"></tr>
    </table>
</div>

<floating-fab-button
    matTooltip="{{ 'import-venue.floors-map.commit-import' | translate }}"
    [icon]="commitIcon"
    (click)="commit()"
>
</floating-fab-button>
