import { Component, OnInit } from "@angular/core"
import { FormBuilder, Validators } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import {
    AutomatedTuningSetting,
    AutoTuningSettingsControllerService
} from "@openapi/mipsengine"
import { withUnsubscribe } from "@venue/shared"
import { DateTime } from "luxon"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"

const START_TIME_FORMAT = "HH:mm:ss"

@withUnsubscribe
@Component({
    selector: "auto-tuning",
    templateUrl: "./auto-tuning.component.html"
})
export class AutoTuningComponent implements OnInit {
    autoTuningConfig = this.fb.group({
        status: [false],
        tuningRepeat: ["", Validators.required],
        startTime: [DateTime.local(), Validators.required],
        tuningDuration: ["", [Validators.required, Validators.min(1), Validators.max(23)]]
    })

    // TODO Translate
    tuningOptions = [
        "DAILY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
        "MONTHLY",
        "YEARLY",
        "ONLY_ONCE"
    ]

    private unsubscribe: Subject<void>

    constructor(
        private fb: FormBuilder,
        private autoTuningService: AutoTuningSettingsControllerService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.autoTuningService.getAutoTuningSettingsUsingGET().subscribe(v => {
            const configFormValue = Object.assign(v, {
                startTime: DateTime.fromFormat(<string>v.startTime, START_TIME_FORMAT)
            })
            this.autoTuningConfig.patchValue(configFormValue, { emitEvent: false })
        })

        this.autoTuningConfig
            .get("status")
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(v => this.toggleTuningValue(v))
    }

    toggleTuningValue(enabled: boolean): void {
        let fun = enabled
            ? this.autoTuningService.startAutoTuningUsingPOST
            : this.autoTuningService.stopAutoTuningUsingPOST
        fun = fun.bind(this.autoTuningService)
        // TODO Show error on snackbar
        fun().subscribe(
            () => {},
            error => this.autoTuningConfig.patchValue({ status: !enabled }, { emitEvent: false })
        )
    }

    // TODO Convert to utc
    submit(): void {
        const configFormValue = this.autoTuningConfig.value
        const config: AutomatedTuningSetting = Object.assign({}, configFormValue, {
            startTime: configFormValue.startTime.toFormat(START_TIME_FORMAT)
        })
        this.autoTuningService.setAutoTuningSettingsUsingPOST(config).subscribe(
            () => {
                // TODO Snackbar!
                // TODO Change this strings to 'translate variables'
                console.warn(this.translate.instant("Tuning parameters set successfully"))
            },
            () => {
                console.warn(this.translate.instant("Tuning parameters were not set"))
            }
        )
    }
}
