import Fill from "ol/style/Fill"
import Stroke from "ol/style/Stroke"

export const SELECTED_WALL_STROKE = new Stroke({
    color: "rgb(0,100,200)",
    width: 3
})
export const SELECTED_WALL_FILL = new Fill({
    color: "rgba(0,160,255,0.3)"
})
export const HOVERED_WALL_STROKE = new Stroke({
    color: "rgb(0,0,255)",
    width: 3
})
export const HOVERED_WALL_FILL = new Fill({
    color: "rgba(0,0,255,0.3)"
})
