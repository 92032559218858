import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatPaginator, PageEvent } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import { Pageable, TuningHistory, TuningHistoryService } from "@venue/api"
import { pageableOf } from "@venue/api/structures/pageable.model"
import { withUnsubscribe } from "@venue/shared"
import { combineLatest, Subject } from "rxjs"
import { map, startWith, takeUntil } from "rxjs/operators"
import { TuningHistoryDetailsDialog } from "./"
import { TuningHistoryDataSource } from "./tuning-history.data-source"

@withUnsubscribe
@Component({
  templateUrl: "./tuning-history.component.html",
})
export class TuningHistoryComponent implements AfterViewInit {
  displayedColumns = ["dateFrom", "dateTo", "type", "result", "score", "details"]
  dataSource: TuningHistoryDataSource

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  private unsubscribe: Subject<void>

  constructor(
    private dialog: MatDialog,
    tuningHistoryService: TuningHistoryService,
    private cdRef: ChangeDetectorRef
  ) {
    this.dataSource = new TuningHistoryDataSource(tuningHistoryService)
  }

  ngAfterViewInit(): void {
    this.sort.disableClear = true // Required, for some reason line below is not enough
    this.sort.sort({ id: "dateFrom", start: "desc", disableClear: true })

    this.paginator.pageSize = 10

    this.dataSource.pageInfo
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((pageInfo) => (this.paginator.length = pageInfo.totalElements))

    combineLatest(
      this.sort.sortChange.pipe(startWith(this.sort)),
      this.paginator.page.pipe(startWith(this.paginator))
    )
      .pipe(
        takeUntil(this.unsubscribe),
        map(([sort, page]) => pageableOf(<PageEvent>page, sort))
      )
      .subscribe((pageable) => this.loadData(pageable))

    this.cdRef.detectChanges()
  }

  showDetails(entry: TuningHistory): void {
    this.dialog.open(TuningHistoryDetailsDialog, { data: entry.details })
  }

  private loadData(pageable: Partial<Pageable>): void {
    this.dataSource.loadTuningHistory(pageable)
  }
}
