<loading-overlay *ngIf="loading | async"></loading-overlay>
<mat-sidenav-container fxFlex>
    <mat-sidenav mode="side"
                 class="editor-sidenav"
                 opened
                 disableClose
                 fxLayout="column">
        <section class="sidebar"
                 fxLayout="column"
                 fxLayoutGap="0.5rem"
                 fxFlex>
            <floor-select>
            </floor-select>

            <button mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="saveDisabled | async"
                    matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}"
                    (click)="saveClicks.next()">{{ 'Save' | translate }}</button>
        </section>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-container *ngIf="(noCS | async) || (noImgDim | async) || (noProdImgDim | async)">
            <div fxFlex
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 class="error-overlay">
                <h1 translate>editor-error.header</h1>
                <span *ngIf="noCS | async"
                      translate>editor-error.no-cs</span>
                <span *ngIf="noImgDim | async"
                      translate>editor-error.no-img-dim</span>
                <span *ngIf="noProdImgDim | async"
                      translate>editor-error.no-prod-img-dim</span>
            </div>
        </ng-container>
        <openlayers-map fxFlex>
            <map-control class="ol-opacity-container">
                <label translate>opacity</label>
                <input #opacitySlider
                       type="range"
                       class="ol-opacity-slider"
                       value="70">
                <label>{{ opacitySlider.value }}%</label>
            </map-control>
            <floor-image-layer [floor]="currentFloor.floor | async"
                               imageType="base"
                               (imgDim)="onImageDimChange($event)"
                               (projection)="mapProjection = $event">
            </floor-image-layer>
            <floor-image-layer [floor]="currentFloor.floor | async"
                               [opacity]="+opacitySlider.value / 100"
                               imageType="prod"
                               [mapProjection]="mapProjection"
                               [transforms]="transforms"
                               [initMapProjection]="false"
                               [tint]='{ "red": -50, "green": -50, "blue": 50 }'
                               (imgDim)="hasUserWritePermissions() ? onProdImageDimChange($event) : loading.next(false)">
            </floor-image-layer>
            <map-toolbar>
                <button title='{{ "floor.second_setup.adjust_image.auto_adjust_tooltip" | translate }}'
                        (click)="autoAdjustImage()"
                        [disabled]="!hasUserWritePermissions()"
                        matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}">
                    <img height="32px"
                         width="32px"
                         src="assets/images/cog_arrows.png">
                </button>
                <button title='{{ "floor.second_setup.adjust_image.reset_tooltip" | translate }}'
                        (click)="resetProdImage()"
                        [disabled]="!hasUserWritePermissions()"
                        matTooltip="{{ !hasUserWritePermissions() ? ('no_permissions_msg' | translate) : '' }}">
                    <ic-icon width="32px"
                             height="32px"
                             [icon]="resetImageIcon"> </ic-icon>
                </button>
            </map-toolbar>
        </openlayers-map>
    </mat-sidenav-content>
</mat-sidenav-container>
